import {
  Component,
  OnInit,
  Renderer2,
  RendererStyleFlags2,
  ViewChild,
  OnDestroy,
  HostListener,
  ElementRef,
  ChangeDetectorRef,
  AfterViewInit,
  ViewEncapsulation,
} from '@angular/core';
import { AllInOneService } from '../../shared/services/all-in-one.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { DatePipe, Location, formatDate } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { PlyrComponent } from 'ngx-plyr';
import * as Plyr from 'plyr';
import { CourseService } from '../../shared/services/course.service';
import { MessageService } from '../../shared/services/message.service';
import { LoadingService } from '../../shared/services/loading.service';
import WebViewer from '@pdftron/webviewer';
import { MatDialog } from '@angular/material/dialog';
import { AnonymousLearningLoginComponent } from '../../shared/components/anonymous-learning-login/anonymous-learning-login.component';
import { SEOService } from '../../shared/services/seo.service';
import { DropboxComponent } from '../../class/dropbox/dropbox.component';
import { AssignmentResult } from '../../shared/models/AssignmentResult';
import { AssignmentComponent } from '../../class/assignment/assignment.component';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { Discussion, Reply } from '../../shared/models/Discussion';
import { ConfirmDialogComponent } from '../../shared/components/confirm-dialog/confirm-dialog.componet';
import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { Subscription } from 'rxjs';
import vimeoPlayer from '@vimeo/player';
import { Player } from '@vime/angular';
import { CookieService } from 'ngx-cookie-service';
import { QuestionComponent } from '../../quiz/question/question.component';
import { PdfViewerComponent } from '../../shared/components/pdf-viewer/pdf-viewer.component';
import { CourseService as InstructorCourseService } from 'projects/e-learning-instructor/src/app/shared/services/course.service';
import { AllInOneService as InstructorService } from 'projects/e-learning-instructor/src/app/shared/services/all-in-one.service';
import { ConfirmDialogTwoComponent } from '../../shared/components/confirm-dialog-two/confirm-dialog-two.component';

declare var $: any;
declare var bootstrap: any;

@Component({
  selector: 'app-course-learn',
  templateUrl: './course-learn.component.html',
  styleUrls: ['./course-learn.component.scss'],
})
export class CourseLearnComponent implements OnInit, OnDestroy, AfterViewInit {
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    if (window.innerWidth < this.mobileViewWidth) {
      this.isMobileView = true;
      if (this.pageview == '2') {
        this.showPageView = true;
        this.pageview = '1';
        this.tabClick(5);
      }
    } else {
      this.isMobileView = false;
      if (this.showPageView) {
        this.pageview = '2';
        this.showPageView = false;
      }
      this.tabClick(1);
    }
  }

  @HostListener('window:beforeunload', ['$event'])
  beforeunloadHandler(event: any) {
    // api call here
    console.log('Course Learn');

    if (
      this.lecture.contenttype == 6 &&
      this.lecture.quizview &&
      !this.isMobileView &&
      !this.isFromInstructor
    ) {
      this.callQuizCacheAPI();
      return false;
    } else {
      return true;
    }
  }

  @ViewChild('viewer')
  viewerRef!: ElementRef;

  @ViewChild('vimeoPlayer')
  vimeoPlayer!: ElementRef;

  @ViewChild(QuestionComponent)
  submitQuiz!: QuestionComponent;

  studytimer: any = '00:00';
  havestudytime: boolean = false;
  studytimeunitid: string = '';
  reducetime: any;
  studyTimeList: any = [];
  gettingstudytimer: boolean = false;

  quiztimer: any = '00:00';
  havequiztimer: boolean = false;
  reducequiztime: any;
  quizTimeLimitList: any = [];

  webviewer: any = '';
  webviewercanvas: any = '';

  autoid: any = '';
  isMobileView: boolean = false;

  course: any = {}; //Student side used as "lecture" and Teacher side used as "unit"
  instructor: any;
  courseid: string = '';
  unitid: string = '';
  loadingVIMEplayer: boolean = false;
  lecture: any = {};
  mobileViewWidth: number = 501;

  isFromInstructor: boolean = false;
  isPublished: boolean = false;

  playedVid: boolean = false;
  currentTime: any;
  endTime: any;

  isLoading: boolean = true;
  isDocumentLoading: boolean = false;
  isGrade: boolean = false;
  isCertificate: boolean = false;
  isFeedback: boolean = false;
  completed: boolean = false;
  classTemplate: any = null;
  timeIntervalFrame: any;

  isFirst: boolean = false;
  isLast: boolean = false;
  lecturelist: any = [];
  sectionsForDropbox: any[] = [];
  assignmentUnits: any[] = [];

  tab_active: number = 1;
  currentIndex: any = 0;
  videoid: string = '';
  videotype: string = 'vimeo';

  assignmentSubscription!: Subscription;
  quizCacheSubscription!: Subscription;

  show: boolean = false;

  plyrevent: any;

  completedunitlist: any = [];
  totalunitlength: any;

  isError: boolean = false;

  YT: any;
  video: any;

  timeAnalyticInterval: any;

  // Anonymous Access Variables
  // 001 - Don't have public access - Should Redirect to Home
  // 002 - Already enrolled this course - Just normal
  // 003 - Not enrolled but have public access - Show Enroll Button - Nav Bar hide My Courses
  // 004 - Not log in and have pubilc access - Show Log In button - Nav Bar hide My Courses
  coursestatus = '';

  enrolling: boolean = false;
  pageview: String = '0';
  fullscreenview: boolean = false;
  showPageView: boolean = false;
  loadingpdf: boolean = true;
  loadingquizz: boolean = false;
  loadingassignment: boolean = false;
  assignmentError: boolean = false;
  tryagain: boolean = false;
  resumeQuiz: boolean = false;

  formatDueDate = 'd MMM y';

  childQuestionAnswer: any = [];
  childDataForAPI: any = [];

  // Quiz and Assignment
  score: string = '';
  mark: string = '';
  totalmark: string = '';
  questioncount: string = '';
  attempt: string = '';
  resultid: string = '';
  assignmentResult: AssignmentResult = {
    filename: '',
    fileurl: '',
    iconurl: '',
    grade: '',
    totalgrade: '',
    gradestatus: '',
    feedback: '',
    date: '',
    time: '',
  };

  overdeadline: any = false;
  deadlinedate: string = '';
  deadlinetime: string = '';

  //Grade
  gettingGrade = false;
  studentGrade = 0;
  alreadyLoadedGrade = false;
  grade: any[] = [];

  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '5rem',
    minHeight: '5rem',
    placeholder: 'Enter text here...',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    toolbarHiddenButtons: [
      [
        'undo',
        'redo',
        // 'underline',
        'strikeThrough',
        // 'subscript',
        // 'superscript',
        'justifyLeft',
        'justifyCenter',
        'justifyRight',
        'justifyFull',
        'indent',
        'outdent',
        'heading',
        'fontName',
      ],
      [
        'fontSize',
        'textColor',
        'backgroundColor',
        'customClasses',
        'insertImage',
        'insertVideo',
        'insertHorizontalRule',
        'removeFormat',
        'toggleEditorMode',
      ],
    ],
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText',
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
    // upload: (file: File): Observable<any> => {
    //   return this.uploadImage(file);
    // },
  };

  //Discussion List
  firstreply: boolean = false;
  gettingdiscussion: boolean = false;
  firstreplying: boolean = false;
  firstreplyconent: any = '';
  firstreplycount: number = 5;
  firstreplycurrentcount: number = 5;

  username: string = '';
  userid: string = '';

  assignment_label: string = this.allinOneService.assignment_label;

  constructor(
    private _seoService: SEOService,
    private courseService: CourseService,
    // getCourseForPreview
    private instructorCourseService: InstructorCourseService,
    public allinOneService: AllInOneService,
    private instructorService: InstructorService,
    private location: Location,
    private route: ActivatedRoute,
    private router: Router,
    public domSanitizer: DomSanitizer,
    private renderer: Renderer2,
    private messageService: MessageService,
    private loadingService: LoadingService,
    private changeDetectorRef: ChangeDetectorRef,
    private dialog: MatDialog,
    private datepipe: DatePipe,
    private sanitizer: DomSanitizer,
    private activatedRoute: ActivatedRoute,
  ) {
    this.username = this.allinOneService.getUsername();
    this.userid = this.allinOneService.getKunyekUserId();
    this.activatedRoute.data.subscribe((data) => {
      this.isFromInstructor = data.isFromInstructor;
      this.isPublished = data.isPublished;
    });
    console.log('isFrom Instructor');
    console.log(this.isFromInstructor);
    console.log('preview');

    console.log(this.isPublished);

  }

  ngOnDestroy() {
    this.assignmentSubscription && this.assignmentSubscription.unsubscribe();
    clearInterval(this.timeAnalyticInterval);
    if (this.autoid && this.timeAnalyticInterval) {
      this.clearTimeAInterval();
    }
    console.log('1');

    this.getCurrentTimeForVideoPlayer();
    this.saveStudyTimeInCookie();
    clearInterval(this.reducequiztime);

    if (this.isFromInstructor) {
      this.instructorService.hideNavbar = false;
    }
  }

  get truncatedDescription(): string {
    const maxLength = 200; // Maximum length for truncated text
    if (this.course.description.length <= maxLength) {
      return this.course.description;
    }
    return this.course.description.substring(0, maxLength) + '...'; 
  }

  toggleShow(): void {
    this.show = !this.show;
  }

  openDropbox() {
    const dialog = this.dialog.open(DropboxComponent, {
      width: '80vw',
      height: '80vh',
      closeOnNavigation: true,
      data: {
        popup: true,
        classid: this.course.classid,
        courseid: this.course.courseid,
        sections: this.sectionsForDropbox,
        units: this.assignmentUnits,
      },
    });
  }

  playedvideo() {
    this.playedVid = true;
    console.log('ok');
  }

  @ViewChild('videoPlayer') videoPlayer: Player | undefined;

  async setCurrentTime() {
    if (this.videotype == 'vimeo') {
      let myvimeoplayer = new vimeoPlayer(this.vimeoPlayer.nativeElement);
      // add current time change event listener
      const ctime = Number(this.lecture.currenttime);

      myvimeoplayer.getDuration().then(async (duration: any) => {
        if (!this.lecture.checked) {
          myvimeoplayer.on('timeupdate', (data: any) => {
            let isCompleted = this.checkCompleteVideo(data.seconds, duration);
            if (isCompleted) {
              myvimeoplayer.off('timeupdate');
            }
          });
        }
        if (ctime > 0 && ctime <= duration) {
          await myvimeoplayer.setCurrentTime(Number(this.lecture.currenttime));
        }
      });
    } else {
      setTimeout(() => {
        this.videoPlayer!.pause();
      }, 1000);
    }
  }

  // videoDuration: number | undefined;

  // ngAfterViewInit() {
  //   this.videoDuration = this.videoPlayer.duration;
  //   console.log('ans');
  //   console.log(this.videoDuration);

  // }

  // test(event : any) {
  //   console.log('d event');
  //   console.log(event);
  //   console.log(event.detail);
  //   console.log(event.timeStamp);
  //   this.endTime = event.timeStamp
  // }

  // test2() {
  //   var url = 'https://www.youtube.com/watch?v=TNTVzpL7-bY';
  //   var a = url.split("v=")
  //   console.log(a);

  //   var b = a[1].includes('&');
  //   console.log('b' + b);

  //   // if(b) {
  //   //   var c = b.split("&")
  //   // }

  // }

  // getYou

  openAssignmentSubmissionBox(unit: any) {
    const dialog = this.dialog.open(AssignmentComponent, {
      width: '600px',
      maxWidth: '95vw',
      height: 'auto',
      closeOnNavigation: true,
      data: {
        classid: this.course.classid,
        courseid: this.course.courseid,
        unitid: unit.unitid,
        unitname: unit.unitname,
        sectionid: unit.sectionid,
      },
    });
    dialog.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) {
        this.getAssignmentResult(unit.unitid);
        // Update complete unit here
        const check = this.completedunitlist.find(
          (el: any) => el == unit.unitid
        );
        //undefined - checked = true
        if (!check) {
          this.completedunitlist.push(unit.unitid);
          if (this.totalunitlength == this.completedunitlist.length) {
            this.completed = true;
          }
          this.course.sections.map((section: any) => {
            section.subsections.map((subsection: any) => {
              subsection.units.map((unit: any) => {
                if (unit.unitid == this.lecture.unitid) {
                  unit.checked = true;
                }
              });
            });
          });
        }

        if (this.coursestatus == '002' && !this.isFromInstructor) {
          this.courseService
            .updateCompletedUnits(this.completedunitlist, this.course.pkey)
            .subscribe(
              (res: any) => {
                if (res.returncode == '300') {
                } else {
                  if (res.returncode == '210') {
                    setTimeout(() => {
                      this.router.navigateByUrl('login');
                      this.allinOneService.logout();
                    }, 2000);
                  } else {
                    this.messageService.openSnackBar(
                      res.status || res.message,
                      'warn'
                    );
                  }
                }
              },
              (err) => { }
            );
        }
      }
    });
  }

  //   startTimer(duration : any, display : any) {
  //     var timer : any = duration, minutes, seconds;
  //     setInterval(function () {
  //         minutes = parseInt(timer / 60, 10);
  //         seconds = parseInt(timer % 60, 10);

  //         minutes = minutes < 10 ? "0" + minutes : minutes;
  //         seconds = seconds < 10 ? "0" + seconds : seconds;

  //         display.textContent = minutes + ":" + seconds;

  //         if (--timer < 0) {
  //             timer = duration;
  //         }
  //     }, 1000);
  // }

  downloadFile(lecture: any) {
    if (lecture.libraryid) {
      window.open(lecture.downloadurl, '_blank');
    } else {
      window.open(this.allinOneService.docurl + lecture.content, '_blank');
    }
    if (!lecture.checked) {
      this.course.sections.map((section: any) => {
        section.subsections.map((subsection: any) => {
          subsection.units.map((unit: any) => {
            if (unit.unitid == lecture.unitid) {
              unit.checked = true;
            }
          });
        });
      });
      this.updateCompletedUnits(lecture);
    }
    // this.courseService.downloadFile(url).subscribe((response: any) => {
    //   saveAs(response, name);
    // }),
    //   (error: any) => {
    //     this.messageService.openSnackBar('Download failed.', 'warn');
    //   };
  }

  downloadAssignmentFile(url: any) {
    if (url == '') {
      return;
    }
    window.open(url, '_blank');
  }

  nextLecture() {
    if (!this.isLast) {
      if (this.lecture.quizview && this.lecture.resultid == 'start' && !this.isFromInstructor) {

        // answerlist: this.childQuestionAnswer,
        var al = this.childQuestionAnswer;
        console.log('here');
        console.log(al);

        var haveAns = true;
        for (var i = 0; i < al.length; i++) {
          if (Array.isArray(al[i].answerid)) {
            if (al[i].answerid.length == 0) {
              haveAns = false;
            } else {
              al[i].answerid.filter(
                (ans: any) => {
                  if (ans == null || ans == 'null' || ans == -1 ) {
                    haveAns = false;
                  }
                  else if (ans.answerid == '') {
                    haveAns = false;
                  }
                }
              )
            }

          } else {
            if (al[i].answerid == '-1' || al[i].answerid == '') {
              haveAns = false;
            }
          }
        }

        if (!haveAns && !this.isFromInstructor) {
          let dialogRefA = this.dialog.open(ConfirmDialogTwoComponent, {
            minWidth: '300px',

            data: {
              message: 'You have not answered all the questions.',
            },
            scrollStrategy: new NoopScrollStrategy(),
          });
          dialogRefA.afterClosed().subscribe((result) => { });
        } else {
          let dialogRef = this.dialog.open(ConfirmDialogComponent, {
            minWidth: '300px',
            data: {
              // message: 'Your progress will be lost. Are you sure?',
              message: 'Your answer will be submit. Are you sure?',
            },
            scrollStrategy: new NoopScrollStrategy(),
          });

          dialogRef.afterClosed().subscribe((result) => {
            if (result == true) {
              this.submitQuizFromParent('next');

              this.loadingService.hide();

              // this.confirmedNextLecture();
            } else {
              return;
            }
            this.loadingService.hide();
          });
        }
      } else {
        this.confirmedNextLecture();
      }
    }
  }

  async confirmedNextLecture() {
    console.log('2');
    // await this.getCurrentTimeForVideoPlayer();

    const index = this.lecturelist.findIndex(
      (unit: any) => unit.unitid === this.lecture.unitid
    );
    if (
      !this.lecture.checked &&
      this.lecture.contenttype != 1 &&
      this.lecture.contenttype != 6 &&
      this.lecture.contenttype != 7 &&
      this.lecture.contenttype != 8
    ) {
      this.updateCompletedUnits(this.lecturelist[index]);
      this.lecturelist[index].checked = true;
      this.course.sections.map((section: any) => {
        section.subsections.map((subsection: any) => {
          subsection.units.map((unit: any) => {
            if (unit.unitid == this.lecture.unitid) {
              unit.checked = true;
            }
          });
        });
      });
    }

    // this.isLast = this.lecturelist.length - 1 == index + 1;
    for (let i = index + 1; i < this.lecturelist.length; i++) {
      if (this.lecturelist[i].unlock) {
        this.viewLecture(this.lecturelist[i]);
        break;
      }
    }
  }

  previousLecture() {
    if (!this.isFirst) {
      if (this.lecture.quizview && this.lecture.resultid == 'start' && !this.isFromInstructor) {

        var al = this.childQuestionAnswer;
        var haveAns = true;
        for (var i = 0; i < al.length; i++) {
          if (Array.isArray(al[i].answerid)) {
            if (al[i].answerid.length == 0) {
              haveAns = false;
            } else {
              al[i].answerid.filter(
                (ans: any) => {
                  if (ans == null || ans == 'null' || ans == -1 ) {
                    haveAns = false;
                  }
                  else if (ans.answerid == '') {
                    haveAns = false;
                  }
                }
              )
            }

          } else {
            if (al[i].answerid == '-1' || al[i].answerid == '') {
              haveAns = false;
            }
          }
        }

        if (!haveAns && !this.isFromInstructor) {
          let dialogRefA = this.dialog.open(ConfirmDialogTwoComponent, {
            minWidth: '300px',

            data: {
              message: 'You have not answered all the questions.',
            },
            scrollStrategy: new NoopScrollStrategy(),
          });
          dialogRefA.afterClosed().subscribe((result) => { });
        } else {

          let dialogRef = this.dialog.open(ConfirmDialogComponent, {
            minWidth: '300px',
            data: {
              // message: 'Your progress will be lost. Are you sure?',
              message: 'Your answer will be submit. Are you sure?',
            },
            scrollStrategy: new NoopScrollStrategy(),
          });

          dialogRef.afterClosed().subscribe((result) => {
            if (result == true) {
              this.submitQuizFromParent('previous');
              this.loadingService.hide();

              this.confirmedPrevLecture();
            } else {
              return;
            }
            this.loadingService.hide();
          });
        }
      } else {
        this.confirmedPrevLecture();
      }
    }
  }

  async confirmedPrevLecture() {
    console.log('3');
    // await this.getCurrentTimeForVideoPlayer();
    const index = this.lecturelist.findIndex(
      (unit: any) => unit.unitid === this.lecture.unitid
    );
    // this.isFirst = index - 1 == 0;
    for (let i = index - 1; i >= 0; i--) {
      if (this.lecturelist[i].unlock) {
        this.viewLecture(this.lecturelist[i]);
        break;
      }
    }
  }

  /**
   *
   * @param status
   * @param sectionid
   * @param subsectionid
   * @param unitid
   */
  setTime(
    status: string,
    sectionid: string,
    subsectionid: string,
    unitid: string
  ) {
    // return;
    const data = {
      id: this.autoid,
      courseid: this.courseid,
      userid: this.allinOneService.getKunyekUserId(),
      channelid: this.course.t2,
      status: status,
      unitid: unitid,
      sectionid: sectionid,
      subsectionid: subsectionid,
      classid: this.course.classid,
    };
    this.courseService.timeAnalytic(data).subscribe((res: any) => {
      if (res.returncode == '300') {
        if (this.autoid == '') {
          this.autoid = res.id;
        }
      }
    });
  }

  async ngOnInit(): Promise<any> {
    // this.init();
    if (this.isFromInstructor) {
      this.instructorService.hideNavbar = true;
    }
    
    this.route.params.subscribe((params) => {
      this.courseid = params['courseid'];
      this.unitid = params['lectureid'];
    });
    if(!this.isFromInstructor) {
      await this.getGrades();
    }
    const awaitGetcourse = await this.getCourse();
    if (this.unitid == 'grades') {
      this.viewGrade();
    }
    if (this.unitid == 'certificate') {
      this.viewCertificate();
    }
    if (this.unitid == 'feedback') {
      console.log('ON FEEDBACK >>>')
      this.viewFeedback();
    }
    this.isLoading = false;



  }

  clearTimeAInterval() {
    clearInterval(this.timeAnalyticInterval);
    this.setTime('2', '', '', '');
  }

  // The API calls this function when the player's state changes.
  onPlayerStateChange(event: any) {
    console.log('onPlayerStateChange');
    console.log(event.data);
  }

  // The API will call this function when the video player is ready
  onPlayerReady(event: any) {
    console.log(event);

    const videoId = this.getVideo();
    event.target.cueVideoById({
      videoId: videoId,
    });
    event.target.playVideo();
  }

  getVideo() {
    return '60ItHLz5WEA';
  }

  navigateBack() {
    this.location.back();
  }

  getCourse() {
    return new Promise((resolve) => {
      var fun = this.isFromInstructor ? this.instructorCourseService.getPreviewCourses(this.courseid, this.isPublished ? '001' : '002') : this.courseService.getCourseByCourseID(this.courseid)
      // this.courseService.getCourseByCourseID(this.courseid).subscribe(
      fun.subscribe(
        async (res: any) => {
          if (res.returncode == '300') {
            if (res.coursestatus == '001') {
              this.router.navigateByUrl('/home');
              return;
            }
            this.coursestatus = res.coursestatus;
            if(res.data?.template?.length) {
              if(res.data?.classid) {
                if (res.data?.classstatus === '003') {
                  this.classTemplate = res.data.template.find((i: any) => i.classid == res.data.classid)
                } else {
                  this.classTemplate = null;
                }
              }  else {
                this.classTemplate = res.data.template.find((i: any) => i.classid == "")
              }
            } else {
              this.classTemplate = {
                classid: res.data?.classid,
                templateid: res.data.templateid,
              }
            }

            this.course = res.data;
            this.course.grading = this.course.grading ?? '002'
            var tmpunitlist = [];

            this._seoService.updateTitle('- ' + this.course.coursename);
            this._seoService.updateOgUrl(window.location.href);
            this._seoService.updateDescription(
              this.course.coursename + 'This is for testing purpose'
            );
            this._seoService.updateImg(
              this.allinOneService.imgurl + this.course.courseimage
            );

            this.course.sections.map((section: any) => {
              // Dropbox Sections
              if (section.unlock) {
                this.sectionsForDropbox.push({
                  sectionname: section.sectionname,
                  sectionid: section.sectionid,
                });
              }
              section.subsections.map((subsection: any) => {
                subsection.units.map((unit: any) => {
                  tmpunitlist.push(unit);
                  if (unit.contenttype == '1') {
                    if (
                      unit.content.toString().startsWith('https://youtu.be/') ||
                      unit.content
                        .toString()
                        .startsWith('https://www.youtube.com')
                    ) {
                      unit.icon = 'youtube';
                    } else {
                      unit.icon = 'vimeo';
                    }
                  } else if (unit.contenttype == '2') {
                    const filetype = unit.content.split('.').pop();
                    if (filetype == 'pdf') {
                      unit.icon = 'pdf';
                    } else if (filetype == 'docx') {
                      unit.icon = 'word';
                    } else if (filetype == 'pptx') {
                      unit.icon = 'powerpoint';
                    } else if (filetype == 'xlsx') {
                      unit.icon = 'excel';
                    }
                  } else if (unit.contenttype == '7') {
                    unit.icon = 'assignment';
                  } else {
                    unit = { ...unit, icon: '' };
                  }
                  var tempunit = {
                    ...unit,
                    subsectionid: subsection.subsectionid,
                    sectionid: section.sectionid,
                    checked: false,
                    unlock: section.unlock ? section.unlock : false,
                    replylist: [],
                    gettingreply: false, // getting all replies
                  };
                  // unit.checked = false;
                  // unit.unlock = section.unlock ? section.unlock : false;
                  if (tempunit.contenttype == 7) {
                    this.assignmentUnits.push({
                      unitid: tempunit.unitid,
                      unitname: tempunit.unitname,
                      sectionid: tempunit.sectionid,
                    });
                  }
                  this.lecturelist.push({
                    ...tempunit,
                    downloadurl: unit.downloadurl
                      ? unit.downloadurl
                      : this.allinOneService.docurl + unit.content,
                  });
                });
              });
            });
            this.totalunitlength = tmpunitlist.length;

            if (this.coursestatus == '002' && !this.isFromInstructor) {
              const awaitProgressList = await this.getUnitProgress();
              var tempcomplete: any[] = [];
              this.completedunitlist.map((unitid: string) => {
                var temp = this.lecturelist.find(
                  (unit: any) => unit.unitid == unitid
                );
                if (temp) {
                  tempcomplete.push(unitid);
                }
              });
              this.completedunitlist = tempcomplete;
            }

            this.completedunitlist.map((unitid: any) => {
              this.course.sections.map((section: any) => {
                section.subsections.map((subsection: any) => {
                  subsection.units
                    .filter((unit: any) => unit.unitid == unitid)
                    .map((unitval: any) => {
                      unitval.checked = true;
                    });
                });
              });
            });
            if (
              this.unitid != 'grades' &&
              this.unitid != 'certificate' &&
              this.unitid != 'feedback'
            ) {
              var currentUnit = this.lecturelist.find(
                (x: any) => x.unitid == this.unitid
              );
              if (currentUnit && currentUnit.unlock) {
                this.viewLecture(currentUnit);
              } else {
                if (Object.keys(this.lecture).length === 0) {
                  var temp = this.lecturelist.find(
                    (item: any) => item.unlock == true
                  );
                  if (temp) {
                    this.viewLecture(temp);
                  }
                }
              }
            }
            if (Object.keys(this.lecture).length === 0) {
              if (window.innerWidth < this.mobileViewWidth) {
                this.isMobileView = true;
                this.tabClick(1);
              }
            } else {
              if (window.innerWidth < this.mobileViewWidth) {
                this.isMobileView = true;
                this.tabClick(3);
              }
            }
            resolve('success');
          } else {
            if (res.returncode == '240') {
              this.router.navigateByUrl('home');
            } else if (res.returncode == "403") {
              this.messageService.openSnackBar(
                res?.message,
                'warn'
              );
              this.router.navigateByUrl('home');
            } else if (res.returncode == '210') {
              setTimeout(() => {
                this.router.navigateByUrl('login');
                this.allinOneService.logout();
              }, 2000);
            } else {
              this.messageService.openSnackBar(
                res.status || res.message,
                'warn'
              );
              if (res.statuscode == '200') {
                setTimeout(() => {
                  this.router.navigateByUrl(`course/${this.courseid}`);
                }, 3000);
              }
            }
            this.isError = true;
            setTimeout(() => {
              if (res.returncode == '404') {
                this.location.back();
              }
            }, 3000);
            resolve('error');
          }
        },
        (err) => {
          this.isError = true;

          resolve('error');
        }
      );
    });
  }

  getGrades() {
    return new Promise((resolve) => {
      this.gettingGrade = true;
      this.courseService.getGrades(this.courseid).subscribe(
        async (res: any) => {
          if (res.returncode == '300') {
            this.studentGrade = Number(res.totalpercentage);
          } else {
            this.messageService.openSnackBar(
              res.message || res.status || 'Server Error',
              'warn'
            );
          }
          this.gettingGrade = false;
          resolve("success");
        },
        (err) => {
          this.gettingGrade = false;
          resolve("error");
        }
      );
    });
  }

  getIsGradePassed() {
    if(this.classTemplate?.grade) {
      // console.log('studentGrade >>', Number(this.studentGrade));
      // console.log('certificate grade >>', Number(this.classTemplate?.certificategrade));
      if (this.studentGrade > 0 && this.classTemplate?.certificategrade) {
        if (this.studentGrade < Number(this.classTemplate?.certificategrade)) {
          return false;
        }
      } else {
        return false;
      }
    }
    return true;
  }

  getInstructor() {
    this.courseService.getInstructor(this.course.instructorid).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.instructor = res;
          this.isLoading = false;

          this._seoService.updateDescription(this.instructor.username);
        } else {
          this.messageService.openSnackBar(res.status || res.message, 'warn');
          setTimeout(() => {
            if (res.returncode == '404') {
              this.location.back();
            }
          }, 3000);
        }
      },
      (err) => {
        this.isLoading = false;
      }
    );
  }
  getUnitProgress() {
    return new Promise((resolve) => {
      this.courseService.getCompletedUnits(this.course.pkey).subscribe(
        (res: any) => {
          if (res.returncode == '300') {
            this.completedunitlist = res.unitlist == '' ? [] : res.unitlist;
            this.completed = res.completed;
            resolve('success');
          } else {
            if (res.returncode == '210') {
              setTimeout(() => {
                this.router.navigateByUrl('login');
                this.allinOneService.logout();
              }, 2000);
            } else {
              this.messageService.openSnackBar(
                res.status || res.message,
                'warn'
              );
            }
            resolve('error');
          }
        },
        (err) => {
          resolve('error');
        }
      );
    });
  }

  updateCompletedUnits(unit: any, disabled: boolean = false) {
    if (!this.isFromInstructor) {
      if (disabled) {
        return;
      }
      if (
        unit.contenttype == 6 ||
        unit.contenttype == 7 ||
        unit.contenttype == 8
      ) {
        return;
      }

      const check = this.completedunitlist.find((el: any) => el == unit.unitid);

      //undefined - checked = true
      if (!check) {
        this.completedunitlist.push(unit.unitid);
      }
      if (this.totalunitlength == this.completedunitlist.length) {
        this.completed = true;
      }

      //true - checked = false
      if (unit.checked && check) {
        this.completedunitlist.map((val: any, index: any) => {
          if (val == unit.unitid) {
            this.completedunitlist.splice(index, 1);
          }
        });
      }

      if (this.coursestatus == '002' && !this.isFromInstructor) {
        this.courseService
          .updateCompletedUnits(this.completedunitlist, this.course.pkey)
          .subscribe(
            (res: any) => {
              if (res.returncode == '300') {
              } else {
                if (res.returncode == '210') {
                  setTimeout(() => {
                    this.router.navigateByUrl('login');
                    this.allinOneService.logout();
                  }, 2000);
                } else {
                  this.messageService.openSnackBar(
                    res.status || res.message,
                    'warn'
                  );
                }
              }
            },
            (err) => { }
          );
      }
    }
  }

  ngAfterViewInit(): void {
    // this.videoDuration = this.videoPlayer.duration;
    // console.log('ans');
    // console.log(this.videoDuration);
  }

  // ngAfterViewInit() {
  // const element = document.getElementById('viewer');
  // WebViewer({
  //   path: '../../assets/public',
  //   initialDoc:'https://bucketelearning.s3.ap-southeast-1.amazonaws.com/references/10_Sales_12_Process_Order2Cash_1626926093573.pptx'
  //   }, this.viewerRef.nativeElement).then(instance => {
  //   console.log("SUCCES>>>>>>>");
  // });
  // }

  reload() {
    window.location.reload();
  }

  viewGrade() {
    if (this.coursestatus == '002' && this.course.gradepage) {
      this.lecture = {};
      this.isGrade = true;
      this.isCertificate = false;
      this.isFeedback = false;
      if (!this.isFromInstructor) {
        this.location.replaceState(`courses/${this.courseid}/lectures/grades`);
      }

    }
  }

  viewCertificate() {
    if (this.coursestatus == '002' && this.course.certificatepage) {
      this.lecture = {};
      this.isCertificate = true;
      this.isGrade = false;
      this.isFeedback = false;
      if (!this.isFromInstructor) {
        this.location.replaceState(
          `courses/${this.courseid}/lectures/certificate`
        );
      }

    }
  }

  viewFeedback() {
    if (this.coursestatus == '002' && this.course.feedbackpage) {
      this.lecture = {};
      this.isCertificate = false;
      this.isGrade = false;
      this.isFeedback = true;
      if (!this.isFromInstructor) {
        this.location.replaceState(`courses/${this.courseid}/lectures/feedback`);
      }

    }
  }

  saveStudyTimeInCookie() {
    // var tempSTList = this.cookieService.get('courseunitstudytime');
    var tempSTList = this.allinOneService.getUnitStudyTime();
    this.studyTimeList = tempSTList ? JSON.parse(tempSTList) : [];
    if (this.havestudytime && this.studytimer != '00:00') {
      var temp = {
        unitid: this.studytimeunitid,
        studytime: this.studytimer,
      };
      var i = this.studyTimeList.findIndex((x: any) => x.unitid == temp.unitid);
      if (i >= 0) {
        this.studyTimeList.splice(i, 1);
      }
      this.studyTimeList.push(temp);
      // this.cookieService.set(
      //   'courseunitstudytime',
      //   JSON.stringify(this.studyTimeList)
      // );
      this.allinOneService.setCookie(
        'courseunitstudytime',
        JSON.stringify(this.studyTimeList)
      );
      clearInterval(this.reducetime);
    }
  }

  // now1
  async viewLecture(lecture: any, click?: boolean, unlock?: boolean) {
    if (click && !unlock) {
      return;
    }

    this.saveStudyTimeInCookie();

    if (lecture.contenttype == 2 || lecture.contenttype == 3) {
      this.studytimer = '00:00';
      this.havestudytime = false;
      this.studytimeunitid = '';
      clearInterval(this.reducetime);
      this.gettingstudytimer = true;
    }

    var al = this.childQuestionAnswer;
    var haveAns = true;
    for (var i = 0; i < al.length; i++) {
      if (Array.isArray(al[i].answerid)) {
        if (al[i].answerid.length == 0) {
          haveAns = false;
        } else {
          al[i].answerid.filter(
            (ans: any) => {
              if (ans == null || ans == 'null' || ans == -1 ) {
                haveAns = false;
              }
              else if (ans.answerid == '') {
                haveAns = false;
              }
            }
          )
        }
      } else {
        if (al[i].answerid == '-1' || al[i].answerid == '') {
          haveAns = false;
        }
      }
    }


    if (click && this.lecture.quizview && this.lecture.resultid == 'start' && !haveAns && !this.isFromInstructor) {
      let dialogRefA = this.dialog.open(ConfirmDialogTwoComponent, {
        minWidth: '300px',

        data: {
          message: 'You have not answered all the questions.',
        },
        scrollStrategy: new NoopScrollStrategy(),
      });
      dialogRefA.afterClosed().subscribe((result) => { });
      return;
    }


    if (click && this.lecture.quizview && this.lecture.resultid == 'start' && !this.isFromInstructor) {
      let dialogRef = this.dialog.open(ConfirmDialogComponent, {
        minWidth: '300px',
        data: {
          // message: 'Your progress will be lost. Are you sure?',
          message: 'Your answers will be submitted. Are you sure?',
        },
        scrollStrategy: new NoopScrollStrategy(),
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result == true) {
          this.submitQuizFromParent('course');
          this.loadingService.hide();
          // this.confirmedViewLecture(lecture, click, unlock);
        } else {
          return;
        }
        this.loadingService.hide();
      });
    } else {
      this.confirmedViewLecture(lecture, click, unlock);
    }
  }

  async confirmedViewLecture(lecture: any, click?: boolean, unlock?: boolean) {
    console.log('4');
    console.log(lecture);

    await this.getCurrentTimeForVideoPlayer();
    if (this.isFromInstructor) {
      if (this.isPublished) {
        this.location.replaceState(
          `preview/courses/${this.courseid}/lectures/${lecture.unitid}/published`
        );
      } else {
        this.location.replaceState(
          `preview/courses/${this.courseid}/lectures/${lecture.unitid}/unpublished`
        );
      }
    }
    else {
      this.location.replaceState(
        `courses/${this.courseid}/lectures/${lecture.unitid}`
      );
    }
    this.isGrade = false;
    this.isCertificate = false;
    this.isFeedback = false;
    this.playedVid = false;

    if (lecture.unitid != this.lecture.unitid) {
      this.firstreply = false;
      this.firstreplyconent = '';
    }

    this.course.sections.map((section: any) => {
      section.show = '';
      section.active = '';
      section.subsections.map((subsection: any) => {
        subsection.show = '';
        section.active = '';
        subsection.units
          .filter((unit: any) => unit.unitid == lecture.unitid)
          .map((unit: any) => {
            console.log(unit);
            this.lecture = JSON.parse(JSON.stringify(unit));
            this.lecture.active = unit.unitid;
            this.lecture.subsectionname = subsection?.subsectionname;
            this.lecture.sectionid = section.sectionid;
            section.show = 'show';
            subsection.show = 'show';
            section.active = 'active-arrow';
            subsection.active = 'active-arrow';
          });
      });
    });
    if (this.autoid) {
      this.clearTimeAInterval();
      this.autoid = '';
    }

    const index = this.lecturelist.findIndex(
      (unit: any) => unit.unitid === this.lecture.unitid
    );
    this.currentIndex = index + 1;
    this.isLast = this.lecturelist.length - 1 == index;
    if (!this.isLast) {
      this.isLast = true;
      for (let i = index + 1; i < this.lecturelist.length; i++) {
        if (this.lecturelist[i].unlock) {
          this.isLast = false;
          break;
        }
      }
    }
    this.isFirst = index == 0;
    if (!this.isFirst) {
      this.isFirst = true;
      for (let i = index - 1; i >= 0; i--) {
        if (this.lecturelist[i].unlock) {
          this.isFirst = false;
          break;
        }
      }
    }
    // this.lecture = lecture;

    console.log('lecture =====> ');
    console.log(this.lecture);
    if (this.lecture.contenttype == 1) {
      //youtube
      if (this.lecture.content.toString().startsWith('https://youtu.be/')) {
        this.lecture.content =
          'https://www.youtube.com/embed/' +
          this.lecture.content.split('/').pop().replace('t=', 'start=');
        this.videoid = this.lecture.content.split('/').pop();
        this.videotype = 'youtube';
        if (
          this.lecture.content.includes('start=') &&
          this.lecture.content.endsWith('s')
        ) {
          this.lecture.content = this.lecture.content.substring(
            0,
            this.lecture.content.length - 1
          );
        }
      } else if (
        this.lecture.content.toString().startsWith('https://www.youtube.com')
      ) {
        this.lecture.content =
          'https://www.youtube.com/embed/' +
          this.lecture.content
            .split('v=')
            .pop()
            ?.replace('&', '?')
            .replace('t=', 'start=');
        this.videoid = this.lecture.content.split('/').pop();
        this.videotype = 'youtube';
        if (
          this.lecture.content.includes('start=') &&
          this.lecture.content.endsWith('s')
        ) {
          this.lecture.content = this.lecture.content.substring(
            0,
            this.lecture.content.length - 1
          );
        }
      }
      //vimeo
      else if (
        this.lecture.content.toString().startsWith('https://vimeo.com/')
      ) {
        //vimeo
        this.lecture.content =
          'https://player.vimeo.com/video/' +
          this.lecture.content.split('/').pop();
        this.videoid = this.lecture.content.split('/').pop();
        this.videotype = 'vimeo';
      } else {
        this.videotype = 'library';
      }

      setTimeout(() => {
        this.setCurrentTime();
      }, 500);
    }
    // console.log('conttype');
    // console.log(this.lecture.contenttype);
    if (this.lecture.contenttype == 6 && !this.isFromInstructor) {
      if (this.coursestatus == '002') {
        this.loadingquizz = true;
        this.courseService
          .getQuizresult(lecture.unitid, this.courseid)
          .subscribe(
            (res: any) => {
              if (res.returncode == '300') {
                console.log(res);
                this.score = res.score;
                this.mark = res.mark;
                this.totalmark = res.totalmark;
                this.loadingquizz = false;
                this.tryagain = res.tryagain;
                this.questioncount = res.questioncount;
                this.attempt = res.attempt;
                this.resultid = res.resultid;
                this.resumeQuiz = res.resume;
              } else {
                this.messageService.openSnackBar(
                  res.status || res.message,
                  'warn'
                );
                this.loadingquizz = false;
              }
            },
            (err) => {
              this.loadingquizz = false;
            }
          );
      }
    }

    if (this.lecture.contenttype == 7) {
      //get assignment result
      this.getAssignmentResult(lecture.unitid);
    }
    if (this.lecture.contenttype == 8) {
      var iscomplete = this.completedunitlist.find(
        (x: any) => x == this.lecture.unitid
      );
      this.firstreply = false;
      this.gettingdiscussion = false;
      this.firstreplying = false;
      this.firstreplyconent = '';
      this.firstreplycount = 5;
      this.firstreplycurrentcount = 5;
      this.lecture.replylist = [];
      this.lecture.nodiscussionreply = true;
      // Check the user has already replied or not
      if (iscomplete && this.coursestatus == '002') {
        //Get Discussion
        this.lecture.nodiscussionreply = false;
        this.getDiscussion();
      }
    }
    if (this.lecture.contenttype == 1) {
      this.loadingVIMEplayer = true;
      setTimeout(() => {
        this.loadingVIMEplayer = false;
      }, 100);
    }

    if (this.lecture.contenttype == 2) {
      this.loadingpdf = true;
      setTimeout(() => {
        this.loadingpdf = false;
      }, 100);
    } else {
      this.webviewer = '';
      this.webviewercanvas = '';
    }
    // if (click) {
    // lecture.contenttype != 6 &&
    // lecture.contenttype != 7 &&
    // lecture.contenttype != 8
    if (lecture.contenttype == 4) {
      if (!lecture.checked) {
        this.course.sections.map((section: any) => {
          section.subsections.map((subsection: any) => {
            subsection.units.map((unit: any) => {
              if (unit.unitid == lecture.unitid) {
                unit.checked = true;
              }
            });
          });
        });
        this.updateCompletedUnits(lecture);
      }
    }

    // count down time for pdf and write up
    // now2
    if (lecture.contenttype == 2 || lecture.contenttype == 3) {
      if (!lecture.checked) {
        if (lecture.studytime == 0) {
          if (!lecture.checked) {
            this.course.sections.map((section: any) => {
              section.subsections.map((subsection: any) => {
                subsection.units.map((unit: any) => {
                  if (unit.unitid == lecture.unitid) {
                    unit.checked = true;
                  }
                });
              });
            });
            this.removeStudyTimeFromCookie(lecture);
            this.updateCompletedUnits(lecture);
          }
        } else {
          // var tempSTList = this.cookieService.get('courseunitstudytime');
          var tempSTList = this.allinOneService.getUnitStudyTime();
          this.studyTimeList = tempSTList ? JSON.parse(tempSTList) : [];

          // console.log(this.studyTimeList);
          var i = this.studyTimeList.findIndex(
            (x: any) => x.unitid == lecture.unitid
          );
          if (i >= 0) {
            var cookietime = this.studyTimeList[i].studytime.split(':');
            var cookiemin = parseInt(cookietime[0]);
            var cookiesec = parseInt(cookietime[1]);
            if (parseInt(lecture.studytime) < cookiemin) {
              this.havestudytime = true;
              this.studytimeunitid = lecture.unitid;
              var min: number = lecture.studytime - 1;
              var sec: number = 60;
              this.reducetime = setInterval(() => {
                // min--;
                sec--;

                if (sec == 0) {
                  min--;
                  sec = 60;

                  if (min < 0) {
                    clearInterval(this.reducetime);
                    min = 0;
                    sec = 0;
                    if (!lecture.checked) {
                      this.course.sections.map((section: any) => {
                        section.subsections.map((subsection: any) => {
                          subsection.units.map((unit: any) => {
                            if (unit.unitid == lecture.unitid) {
                              unit.checked = true;
                            }
                          });
                        });
                      });
                      this.removeStudyTimeFromCookie(lecture);
                      this.updateCompletedUnits(lecture);
                    }
                  }
                }
                var fmin = min.toString().padStart(2, '0');
                var fsec = sec.toString().padStart(2, '0');
                this.studytimer = fmin + ':' + fsec;
              }, 1000);
            } else {
              this.havestudytime = true;
              this.studytimeunitid = lecture.unitid;
              var min: number = cookiemin;
              var sec: number = cookiesec;
              this.reducetime = setInterval(() => {
                // min--;
                sec--;

                if (sec == 0) {
                  min--;
                  sec = 60;

                  if (min < 0) {
                    clearInterval(this.reducetime);
                    min = 0;
                    sec = 0;
                    if (!lecture.checked) {
                      this.course.sections.map((section: any) => {
                        section.subsections.map((subsection: any) => {
                          subsection.units.map((unit: any) => {
                            if (unit.unitid == lecture.unitid) {
                              unit.checked = true;
                            }
                          });
                        });
                      });
                      this.removeStudyTimeFromCookie(lecture);
                      this.updateCompletedUnits(lecture);
                    }
                  }
                }
                var fmin = min.toString().padStart(2, '0');
                var fsec = sec.toString().padStart(2, '0');
                this.studytimer = fmin + ':' + fsec;
              }, 1000);
            }
          } else {
            // console.log('else con');
            this.havestudytime = true;
            this.studytimeunitid = lecture.unitid;
            var min: number = lecture.studytime - 1;
            var sec: number = 60;
            this.reducetime = setInterval(() => {
              // min--;
              sec--;

              if (sec == 0) {
                min--;
                sec = 60;

                if (min < 0) {
                  clearInterval(this.reducetime);
                  min = 0;
                  sec = 0;
                  if (!lecture.checked) {
                    this.course.sections.map((section: any) => {
                      section.subsections.map((subsection: any) => {
                        subsection.units.map((unit: any) => {
                          if (unit.unitid == lecture.unitid) {
                            unit.checked = true;
                          }
                        });
                      });
                    });
                    this.removeStudyTimeFromCookie(lecture);
                    this.updateCompletedUnits(lecture);
                  }
                }
              }
              var fmin = min.toString().padStart(2, '0');
              var fsec = sec.toString().padStart(2, '0');
              this.studytimer = fmin + ':' + fsec;
            }, 1000);
          }
        }
      }
      this.gettingstudytimer = false;
    }
    // end

    if (this.isMobileView) {
      this.tabClick(3);
    }
    // }

    // Start recording analytic 5 sec after user view the lecture
    if (this.coursestatus == '002' || this.coursestatus == '003') {
      setTimeout(() => {
        if (this.timeAnalyticInterval) {
          clearInterval(this.timeAnalyticInterval);
        }
        this.setTime(
          '1',
          this.lecture.sectionid,
          this.lecture.subsectionid,
          this.lecture.unitid
        );
        this.timeAnalyticInterval = setInterval(() => {
          this.setTime(
            '1',
            this.lecture.sectionid,
            this.lecture.subsectionid,
            this.lecture.unitid
          );
        }, 60 * 1000);
      }, 5000);
    }
  }

  test() {
    // var tempSTList = this.allinOneService.getQuizTimeLimit();
    var tempSTList = localStorage.getItem('quizcachelist');
    var abc = tempSTList ? JSON.parse(tempSTList) : [];
    console.log(abc);
  }

  submitQuizFromParent(paginate: string = '') {
    this.submitQuiz.submit(paginate, false, false, false);
    clearInterval(this.reducequiztime);
    this.havequiztimer = false;
    this.quiztimer = '00:00';
  }

  removeStudyTimeFromCookie(lecture: any) {
    // var tempSTList = this.cookieService.get('courseunitstudytime');
    var tempSTList = this.allinOneService.getUnitStudyTime();
    this.studyTimeList = tempSTList ? JSON.parse(tempSTList) : [];
    var tempi = this.studyTimeList.findIndex(
      (x: any) => x.unitid == lecture.unitid
    );

    if (tempi >= 0) {
      this.studyTimeList.splice(tempi, 1);
    }

    // this.cookieService.set(
    //   'courseunitstudytime',
    //   JSON.stringify(this.studyTimeList)
    // );
    this.allinOneService.setCookie(
      'courseunitstudytime',
      JSON.stringify(this.studyTimeList)
    );
  }

  openFirstReply() {
    if (this.coursestatus == '002') {
      this.firstreply = !this.firstreply;
    }
  }
  getDiscussion(isgettingmore?: boolean) {
    this.lecture.gettingreply = true;
    if (!isgettingmore) {
      this.lecture.replylist = [];
    } else {
      this.firstreplycurrentcount += this.firstreplycount;
    }
    console.log(this.lecture.replylist);
    const endtime = isgettingmore
      ? this.lecture.replylist[this.lecture.replylist.length - 1].sortkey
      : '';
    this.courseService
      .getDiscussion(
        this.lecture.unitid,
        this.courseid,
        endtime,
        this.firstreplycount,
        ''
      )
      .subscribe(
        (res: any) => {
          if (res.returncode == '300') {
            res.datalist.map((data: any) => {
              var temp: Discussion = {
                sortkey: data.sort,
                syskey: data.syskey,
                username: data.username,
                userid: data.userid,
                profileimage: data.profileimageurl,
                institute: data.institute,
                message: data.message,
                date: this.allinOneService.formatDateForDiscussionDatePipe(
                  data.date
                ),
                time: data.time,
                open: false,
                totalreplies: data.childcount,
                reply: false,
                gettingreply: false,
                replying: false,
                replycontent: '',
                replylist: [],
                currentcount: 0,
                instructor: data.instructor,
              };
              this.lecture.replylist.push(temp);
            });
          } else {
            this.messageService.openSnackBar(
              res.message || res.status || 'Server Error',
              'warn'
            );
          }
          this.lecture.gettingreply = false;
        },
        (err) => {
          this.lecture.gettingreply = false;
        }
      );
  }

  reply(discussion?: Discussion) {
    const currentDate = this.datepipe.transform(new Date(), 'yyyy/MM/dd');
    const currentDateForKy = this.datepipe.transform(new Date(), 'yyyyMMdd');
    const currentTime = this.datepipe.transform(new Date(), 'hh:mm:ss aa');
    const currentTimeForKy = this.datepipe.transform(new Date(), 'hh:mm aa');
    var data = {
      domain: '',
      domainid: '',
      contact: '',
      channelname: '',
      date: currentDateForKy?.toString(),
      time: currentTimeForKy?.toString(),
      message: '',
      imagename: '',
      imagedata: '',
      imagelist: [],
      tmpimage: '',
      channelid: this.lecture.kunyekchannelid,
      name: '',
      mention: [],
      eventid: '',
      replysyskey: '',
      filename: '',
      notimessage: '',
      instructor: false,
      profileid: '',
    };
    if (discussion) {
      discussion.replying = true;
      data.message = discussion.replycontent;
      data.replysyskey = discussion.syskey;
      this.courseService.replyDiscussion(data).subscribe(
        (res: any) => {
          if (res.returncode == '300') {
            discussion.totalreplies++;
            discussion.replylist.unshift({
              username: this.username,
              userid: this.userid,
              profileimage: '',
              message: data.message,
              date: currentDate?.toString(),
              time: currentTime?.toString(),
              sortkey: res.list[0].sortkey,
              institute: '',
              instructor: false,
            });
            discussion.replycontent = '';
            discussion.reply = false;
            discussion.open = true;
          } else {
            this.messageService.openSnackBar(
              res.message || res.status || 'Server Error',
              'warn'
            );
          }
          discussion.replying = false;
        },
        (err) => {
          discussion.replying = false;
        }
      );
    } else {
      this.firstreplying = true;
      data.message = this.firstreplyconent;
      this.courseService.replyDiscussion(data).subscribe(
        (res: any) => {
          if (res.returncode == '300') {
            if (this.lecture.nodiscussionreply) {
              // Update complete unit here
              const check = this.completedunitlist.find(
                (el: any) => el == this.lecture.unitid
              );
              //undefined - checked = true
              if (!check) {
                this.completedunitlist.push(this.lecture.unitid);
                if (this.totalunitlength == this.completedunitlist.length) {
                  this.completed = true;
                }
                this.course.sections.map((section: any) => {
                  section.subsections.map((subsection: any) => {
                    subsection.units.map((unit: any) => {
                      if (unit.unitid == this.lecture.unitid) {
                        unit.checked = true;
                      }
                    });
                  });
                });
                if (this.coursestatus == '002' && !this.isFromInstructor) {
                  this.courseService
                    .updateCompletedUnits(
                      this.completedunitlist,
                      this.course.pkey
                    )
                    .subscribe(
                      (res: any) => {
                        if (res.returncode == '300') {
                        } else {
                          if (res.returncode == '210') {
                            setTimeout(() => {
                              this.router.navigateByUrl('login');
                              this.allinOneService.logout();
                            }, 2000);
                          } else {
                            this.messageService.openSnackBar(
                              res.status || res.message,
                              'warn'
                            );
                          }
                        }
                      },
                      (err) => { }
                    );
                }
              }
              this.getDiscussion();
            } else {
              this.lecture.replylist.unshift({
                syskey: res.list[0].syskey,
                username: this.username,
                userid: this.userid,
                profileimage: '',
                message: this.firstreplyconent,
                date: currentDate?.toString(),
                time: currentTime?.toString(),
                open: false,
                totalreplies: 0,
                reply: false,
                gettingreply: false,
                replycontent: '',
                replylist: [],
                currentcount: 0,
                instructor: false,
              });
            }
            this.firstreply = false;
            this.firstreplying = false;
            this.firstreplyconent = '';
          } else {
            this.messageService.openSnackBar(
              res.message || res.status || 'Server Error',
              'warn'
            );
            this.firstreplying = false;
          }
        },
        (err) => {
          this.firstreplying = false;
        }
      );
    }
  }

  openReplies(discussion: Discussion) {
    if (discussion.open) {
      discussion.open = false;
      return;
    }
    discussion.open = true;
    if (discussion.replylist.length == 0) {
      this.getDiscussionReplies(discussion);
    }
  }

  getDiscussionReplies(discussion: Discussion, isgettingmore?: boolean) {
    discussion.gettingreply = true;
    discussion.currentcount = discussion.currentcount + 5;
    const endtime = isgettingmore
      ? discussion.replylist[discussion.replylist.length - 1].sortkey
      : '';
    this.courseService
      .getDiscussion(
        this.lecture.unitid,
        this.courseid,
        endtime || '',
        discussion.currentcount,
        discussion.syskey
      )
      .subscribe(
        (res: any) => {
          if (res.returncode == '300') {
            res.datalist.map((data: any) => {
              var temp: Reply = {
                username: data.username,
                userid: data.userid,
                profileimage: data.profileimageurl,
                institute: data.institute,
                message: data.message,
                date: this.allinOneService.formatDateForDiscussionDatePipe(
                  data.date
                ),
                time: data.time,
                sortkey: data.sort,
                instructor: data.instructor,
              };
              discussion.replylist.push(temp);
            });
            discussion.open = true;
            if (discussion.replylist.length == 0) {
              discussion.open = false;
            }
          } else {
            this.messageService.openSnackBar(
              res.message || res.status || 'Server Error',
              'warn'
            );
          }
          discussion.gettingreply = false;
        },
        (err) => {
          discussion.gettingreply = false;
        }
      );
  }

  getAssignmentResult(unitid: string) {
    console.log('this fun');

    if (!this.isFromInstructor) {
      if (this.coursestatus != '002') {
        return;
      }
      this.loadingassignment = true;
      this.assignmentSubscription && this.assignmentSubscription.unsubscribe();
      this.assignmentSubscription = this.courseService
        .getAssignmentResult(this.courseid, unitid)
        .subscribe(
          (res: any) => {
            if (res.returncode == '300') {
              this.assignmentResult = {
                filename: res.data.filename,
                fileurl: res.data.fileurl,
                iconurl: res.data.iconurl,

                grade: res.data.grade,
                totalgrade: res.data.totalgrade,
                gradestatus: res.data.gradestatus,
                feedback: res.data.feedback,
                date: res.data.date,
                time: res.data.time,
              };
              this.deadlinedate = '';
              if (res.data.deadlinedate != '') {
                var x = this.allinOneService.formatDBToShowInEdit(
                  res.data.deadlinedate
                );
                this.deadlinedate = formatDate(
                  new Date(x),
                  this.formatDueDate,
                  'en-US'
                ).toString();
              }

              this.deadlinetime = res.data.deadlinetime;
              this.overdeadline = res.data.overdeadline;
              // this.overdeadline = true
              console.log('Your ans');

              console.log(this.deadlinedate);
              console.log(this.deadlinetime);
              console.log(this.overdeadline);
            } else {
              this.assignmentError = true;
            }
            this.loadingassignment = false;
          },
          (err) => {
            this.assignmentError = true;
            this.loadingassignment = false;
          }
        );
      console.log('l c');
      console.log(this.loadingassignment);
    }
  }

  viewLectureFromGrade(unitid: any) {
    this.playedVid = false;
    const unit = this.lecturelist.find((x: any) => {
      return x.unitid == unitid && x.unlock;
    });
    if (unit) {
      this.viewLecture(unit, true, true);
    } else {
      this.messageService.openSnackBar('This unit is locked.', 'warn');
    }
  }

  openCanvas() {
    return;
    if (this.lecture.contenttype == 2) {
      setTimeout(() => {
        const element = document.getElementById('viewercanvas') as HTMLElement;
        if (element && !this.webviewercanvas) {
          WebViewer(
            {
              path: '../../assets/public',
              initialDoc: this.allinOneService.docurl + this.lecture.content,
              fullAPI: true,
            },
            element
          ).then((instance) => {
            this.webviewercanvas = instance;
            instance.UI.disableElements(['toolbarGroup-Annotate']);
            instance.UI.disableElements(['toolbarGroup-Shapes']);
            instance.UI.disableElements(['toolbarGroup-FillAndSign']);
            instance.UI.disableElements(['toolbarGroup-Forms']);
            instance.UI.disableElements(['toolbarGroup-Edit']);
            instance.UI.disableElements(['toolbarGroup-Insert']);
          });
        } else {
          this.webviewercanvas.UI.loadDocument(
            this.allinOneService.docurl + this.lecture.content
          );
        }
      }, 500);
    }
  }

  tabClick(tab: number) {
    this.tab_active = tab;
  }
  played(event: any) {
    // console.log(event.target.type);
    // this.plyrevent = event;
    // var logger = document.querySelector('#log');
    // if (logger) {
    //   if (typeof this.plyrevent == 'object') {
    //     logger.innerHTML += JSON.stringify(this.plyrevent) + '<br/>';
    //   } else {
    //     // logger.innerHTML += this.plyrevent + '<br />';
    //   }
    // }
  }
  plyrError(event: Plyr.PlyrEvent) {
    console.log('plyrError: ', event);
    this.plyrevent = JSON.stringify(event);
    var logger = document.querySelector('#log');
    if (logger) {
      if (typeof this.plyrevent == 'object') {
        logger.innerHTML += JSON.stringify(this.plyrevent) + '<br/>';
      } else {
        // logger.innerHTML += this.plyrevent + '<br />';
      }
    }
  }

  documentLoaded() {
    // this.isDocumentLoading = false;
    // const element = document.getElementById("progressBar") as HTMLElement;
    // const doc = document.getElementById("doc") as HTMLElement;
    // element.style.display = "none";
    // if (doc) {
    //   doc.style.display = "block";
    // }
    // document.getElementById("progressBar").display = "none";
  }

  openCollapse(idx: any) {
    for (let a = 0; a < this.course.sections[idx].subsections.length; a++) {
      this.course.sections[idx].subsections[a].show = true;
    }
    this.course.sections[idx].show = !this.course.sections[idx].show;
  }
  closeCollpased(setidx: any, subidx: any) {
    this.course.sections[setidx].subsections[subidx].show =
      !this.course.sections[setidx].subsections[subidx].show;
  }

  closeCourseContent() {
    const content = document.querySelector('.course-content');
    this.renderer.setStyle(
      content,
      'width',
      '0px',
      RendererStyleFlags2.Important
    );

    const learn = document.querySelector('.learn-content');
    this.renderer.setStyle(
      learn,
      'width',
      '100%',
      RendererStyleFlags2.Important
    );
    this.renderer.setStyle(
      learn,
      'margin-left',
      '0px',
      RendererStyleFlags2.Important
    );
  }
  openCourseContent() {
    const content = document.querySelector('.course-content');
    this.renderer.setStyle(
      content,
      'width',
      '25%',
      RendererStyleFlags2.Important
    );

    const learn = document.querySelector('.learn-content');
    this.renderer.setStyle(
      learn,
      'width',
      '75%',
      RendererStyleFlags2.Important
    );
    this.renderer.setStyle(
      learn,
      'margin-left',
      '26%',
      RendererStyleFlags2.Important
    );
  }

  enroll() {
    this.enrolling = true;
    this.courseService
      .enrollCourse(this.course.courseid, this.completedunitlist)
      .subscribe(
        (res: any) => {
          if (res.returncode == '300') {
            this.enrolling = false;
            let currentUrl =
              'courses/' +
              this.course.courseid +
              '/lectures/' +
              this.lecture.unitid;
            console.log(currentUrl);
            this.router
              .navigateByUrl('/', { skipLocationChange: true })
              .then(() => {
                this.router.navigateByUrl(currentUrl);
              });
          } else {
            this.enrolling = false;
            this.messageService.openSnackBar(res.status || res.message, 'warn');
          }
        },
        (err) => {
          this.enrolling = false;
        }
      );
  }

  login() {
    const dialog = this.dialog.open(AnonymousLearningLoginComponent, {
      maxWidth: '600px',
      closeOnNavigation: true,
    });

    dialog.afterClosed().subscribe((dialogResult) => {
      if (dialogResult.enroll) {
        const temp = {
          courseid: this.course.courseid,
          completedunits: this.completedunitlist,
        };
        sessionStorage.setItem('course', JSON.stringify(temp));
        this.router.navigateByUrl(
          `/login?next=courses,${this.courseid},lectures,${this.lecture.unitid}`
        );
      } else if (dialogResult.enroll == false) {
        this.router.navigateByUrl(
          `/login?next=courses,${this.courseid},lectures,${this.lecture.unitid}`
        );
      }
    });
  }

  openDestopPage(view: any) {
    if (view == '0') {
      this.pageview = '0';
    } else if (view == '1') {
      this.pageview = '1';
    } else {
      this.pageview = '2';
    }
    // this.pageview = !this.pageview;
  }
  openMobile() {
    this.showPageView = !this.showPageView;

    if (!this.showPageView) {
      this.pageview = '0';
    }
    // console.log('l>>>>');

    // console.log(this.tab_active);
    // if (this.showPageView == true) {
    //   this.tab_active = 5;
    // } else {
    //   this.tab_active = 1;
    // }
    // console.log(this.tab_active);
  }

  startQuiz(l: any) {
    this.clearTimeAInterval();
    const start = 'start';
    console.log("isMobileView", this.isMobileView)
    if (this.isMobileView) {
      if (this.isFromInstructor) {
        if (this.isPublished) {
          this.router.navigateByUrl(
            `preview/courses/${this.courseid}/lectures/${this.lecture.sectionid}/${this.lecture.subsectionid}/${this.lecture.unitid}/quiz/${start}/published`
          );
        } else {
          this.router.navigateByUrl(
            `preview/courses/${this.courseid}/lectures/${this.lecture.sectionid}/${this.lecture.subsectionid}/${this.lecture.unitid}/quiz/${start}/unpublished`
          );
        }

      } else {
        this.router.navigateByUrl(
          `courses/${this.courseid}/lectures/${this.lecture.sectionid}/${this.lecture.subsectionid}/${this.lecture.unitid}/quiz/${start}`
        );
      }
    } else {
      if (this.lecture.bygroup) {
        if (this.isFromInstructor) {
          if (this.isPublished) {
            this.router.navigateByUrl(
              `preview/courses/${this.courseid}/lectures/${this.lecture.sectionid}/${this.lecture.subsectionid}/${this.lecture.unitid}/quiz/${start}/published`
            );
          } else {
            this.router.navigateByUrl(
              `preview/courses/${this.courseid}/lectures/${this.lecture.sectionid}/${this.lecture.subsectionid}/${this.lecture.unitid}/quiz/${start}/unpublished`
            );
          }

        } else {
          this.router.navigateByUrl( 
            `courses/${this.courseid}/lectures/${this.lecture.sectionid}/${this.lecture.subsectionid}/${this.lecture.unitid}/quiz/${start}/${this.course.grading}`
          );
        }
      } else {
        this.lecture.quizview = true;
        this.lecture.resultid = 'start';
      }
    }
  }

  startQuizTimer(data: any) {
    console.log('passed data time');
    console.log(data);

    if (this.lecture.studytime == 0) {
      this.havequiztimer = false;
    } else {
      this.havequiztimer = true;

      // var tempList = this.cookieService.get('quizcachelist');
      // var tempList = this.allinOneService.getQuizTimeLimit();
      if (data != '') {
      }
      var tempList = localStorage.getItem('quizcachelist');
      this.quizTimeLimitList = tempList ? JSON.parse(tempList) : [];

      var tempi = this.quizTimeLimitList.findIndex(
        (x: any) => x.unitid == this.lecture.unitid
      );

      if (data != '') {
        var cookietime = data;
        var cookiemin = Math.floor(cookietime / 60);
        var cookiesec = cookietime % 60;
        var min: number = cookiemin;
        var sec: number = cookiesec;
      } else if (tempi >= 0) {
        // var cookietime = this.quizTimeLimitList[tempi].time.split(':');
        // var cookiemin = parseInt(cookietime[0]);
        // var cookiesec = parseInt(cookietime[1]);
        // var min: number = cookiemin;
        // var sec: number = cookiesec;
        var cookietime = this.quizTimeLimitList[tempi].time;
        var cookiemin = Math.floor(cookietime / 60);
        var cookiesec = cookietime % 60;
        var min: number = cookiemin;
        var sec: number = cookiesec;
      } else {
        var min: number = this.lecture.studytime - 1;
        var sec: number = 60;
      }

      this.reducequiztime = setInterval(() => {
        // min--;
        sec--;

        if (sec == 0) {
          min--;
          sec = 60;

          if (min < 0) {
            this.submitQuizFromParent();

            // remove time from cookie
            // var temp = this.cookieService.get('quizcachelist');
            // var temp = this.allinOneService.getQuizTimeLimit();
            // code start
            // var temp = localStorage.getItem('quizcachelist');
            // this.quizTimeLimitList = temp ? JSON.parse(temp) : [];
            // var i = this.quizTimeLimitList.findIndex(
            //   (x: any) => x.unitid == this.lecture.unitid
            // );
            // if (i >= 0) {
            //   this.quizTimeLimitList.splice(i, 1);
            // }
            // code end
            // this.cookieService.set('quizcachelist', JSON.stringify(this.quizTimeLimitList));
            // this.allinOneService.setCookie('quizcachelist', JSON.stringify(this.quizTimeLimitList));
            // localStorage.setItem(
            //   'quizcachelist',
            //   JSON.stringify(this.quizTimeLimitList)
            // );

            min = 0;
            sec = 0;
          }
        }
        var fmin = min.toString().padStart(2, '0');
        var fsec = sec.toString().padStart(2, '0');
        this.quiztimer = fmin + ':' + fsec;

        var tempStorage = min * 60 + sec;

        // save in cookie
        if (this.quiztimer != '00:00') {
          // var temp = localStorage.getItem('quizcachelist');
          // this.quizTimeLimitList = temp ? JSON.parse(temp) : [];
          // var tempData = {
          //   unitid: this.lecture.unitid,
          //   time: tempStorage,
          //   answerlist: this.childQuestionAnswer,
          // };
          // var i = this.quizTimeLimitList.findIndex(
          //   (x: any) => x.unitid == tempData.unitid
          // );
          // if (i >= 0) {
          //   this.quizTimeLimitList.splice(i, 1);
          // }
          // this.quizTimeLimitList.push(tempData);
          // localStorage.setItem(
          //   'quizcachelist',
          //   JSON.stringify(this.quizTimeLimitList)
          // );
        }
      }, 1000);
    }
  }

  stopQuizTimer() {
    clearInterval(this.reducequiztime);
    this.havequiztimer = false;
    this.quiztimer = '00:00';
  }

  getQuisAnsFromChild(data: any) {
    this.childQuestionAnswer = data;
  }

  callQuizCacheAPI() {
    console.log('call api form parent');
    if (!this.isFromInstructor) {
      this.addQuizCache(false);
    }
  }

  addQuizCache(onClose: boolean = false) {
    const usersyskey = this.allinOneService.getUserId();
    console.log('your con no gorup');
    console.log(this.quiztimer);

    var t = this.quiztimer.split(':');
    var min = parseInt(t[0]);
    var sec = parseInt(t[1]);
    var temptime = min * 60 + sec;
    var data = {
      answerlist: this.childQuestionAnswer,
      unitid: this.lecture.unitid,
      courseid: this.courseid,
      currentpage: '0',
      usersyskey: usersyskey,
      time: temptime.toString(),
    };

    if (!onClose) {
      this.quizCacheSubscription && this.quizCacheSubscription.unsubscribe();
      this.quizCacheSubscription = this.courseService
        .addQuizCache(data)
        .subscribe(
          (res: any) => {
            if (res.returncode == 300) {
            } else {
              this.saveTimeInCookie(data);
            }
          },
          (err: any) => {
            this.saveTimeInCookie(data);
          }
        );
    } else {
      this.courseService.addQuizCacheOnClose(data);
    }
  }

  saveTimeInCookie(data: any) {
    console.log('call save fun');
    this.quizTimeLimitList = [];
    // var temp = this.cookieService.get('quizcachelist');

    // var temp = this.allinOneService.getQuizTimeLimit();
    var temp = localStorage.getItem('quizcachelist');
    this.quizTimeLimitList = temp ? JSON.parse(temp) : [];
    var i = this.quizTimeLimitList.findIndex(
      (x: any) => x.unitid == data.unitid
    );
    if (i >= 0) {
      this.quizTimeLimitList.splice(i, 1);
    }
    this.quizTimeLimitList.push(data);
    // this.cookieService.delete()
    // this.cookieService.set('quizcachelist', JSON.stringify(this.quizTimeLimitList));

    // this.allinOneService.setCookie(
    //   'quizcachelist',
    //   JSON.stringify(this.quizTimeLimitList)
    // );
    localStorage.setItem(
      'quizcachelist',
      JSON.stringify(this.quizTimeLimitList)
    );
  }

  // startQuiz() {
  //   if (this.isMobileView) {
  //     const start = 'start';
  //     this.router.navigateByUrl(
  //       `courses/${this.courseid}/lectures/${this.lecture.unitid}/quiz/${start}`
  //     );
  //   } else {
  //     this.lecture.quizview = true;
  //     this.lecture.resultid = 'start';
  //   }
  // }

  ViewQuizFeedBack() {
    if (this.isMobileView) {
      const start = this.resultid;
      // this.router.navigateByUrl(
      //   `courses/${this.courseid}/lectures/${this.lecture.unitid}/quiz/${start}/${this.course.grading}`
      // );
      this.router.navigateByUrl( 
        `courses/${this.courseid}/lectures/${this.lecture.sectionid}/${this.lecture.subsectionid}/${this.lecture.unitid}/quiz/${start}/${this.course.grading}`
      );
    } else {
      this.lecture.quizview = true;
      this.lecture.resultid = this.resultid;
    }
  }

  getAnswerListFromChild(data: any) {
    console.log('from p fun');
    console.log(data);
  }

  completeQuiz(courseView: any) {
    console.log('pc');

    this.lecture.quizview = false;
    this.loadingquizz = true;
    // Update complete unit here
    const check = this.completedunitlist.find(
      (el: any) => el == this.lecture.unitid
    );
    //undefined - checked = true
    if (!check) {
      this.completedunitlist.push(this.lecture.unitid);
      if (this.totalunitlength == this.completedunitlist.length) {
        this.completed = true;
      }
      this.course.sections.map((section: any) => {
        section.subsections.map((subsection: any) => {
          subsection.units.map((unit: any) => {
            if (unit.unitid == this.lecture.unitid) {
              unit.checked = true;
            }
          });
        });
      });
    }

    if (this.totalunitlength == this.completedunitlist.length) {
      this.completed = true;
    }
    if (courseView == 'next') {
      this.confirmedNextLecture();
    } else if (courseView == 'previous') {
      this.confirmedPrevLecture();
    } else if (courseView == 'course') {
      this.confirmedViewLecture(this.lecture, true, true);
    } else {
      if (!this.isFromInstructor) {
        this.courseService
          .getQuizresult(this.lecture.unitid, this.courseid)
          .subscribe(
            (res: any) => {
              if (res.returncode == '300') {
                console.log(res);
                this.score = res.score;
                this.mark = res.mark;
                this.totalmark = res.totalmark;
                this.loadingquizz = false;
                this.tryagain = res.tryagain;
                this.questioncount = res.questioncount;
                this.attempt = res.attempt;
                this.resultid = res.resultid;
                this.resumeQuiz = res.resume;
              } else {
                this.messageService.openSnackBar(
                  res.status || res.message,
                  'warn'
                );
                this.loadingquizz = false;
              }
            },
            (err) => {
              this.loadingquizz = false;
            }
          );
      }
    }
  }

  cancelQuiz() {
    this.lecture.quizview = false;
  }

  handleImgError(ev: any) {
    let source = ev.srcElement;
    let imgSrc =
      'https://bucketelearning.s3.ap-southeast-1.amazonaws.com/essential/defaultimage.jpg';
    source.src = imgSrc;
  }

  mycourse() {
    this.router.navigateByUrl('/home/courses');
  }

  goToContents() {
    this.router.navigateByUrl(`/instructor/courses/${this.courseid}/curriculum`);
  }

  changeLayoutView() {
    if (this.pageview == '0') {
      this.pageview = '1';
    } else if (this.pageview == '1') {
      if (this.lecture.quizview && this.lecture.resultid == 'start') {
        this.pageview = '0';
      } else {
        this.pageview = '2';
      }
    } else {
      this.pageview = '0';
    }
  }

  async getThumbnailForVideo(videoUrl: any) {
    const video: any = document.createElement('video');
    const canvas: any = document.createElement('canvas');
    video.style.display = 'none';
    canvas.style.display = 'none';

    // Trigger video load
    await new Promise<void>((resolve, reject) => {
      video.addEventListener('loadedmetadata', () => {
        video.width = video.videoWidth;
        video.height = video.videoHeight;
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        // Seek the video to 25%
        video.currentTime = video.duration * 0.21;
      });
      video.addEventListener('seeked', () => resolve());
      video.src = videoUrl;
    });

    // Draw the thumbnailz
    canvas
      .getContext('2d')
      .drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
    const imageUrl = canvas.toDataURL('image/png');
    return imageUrl;
  }

  async getCurrentTimeForVideoPlayer() {
    console.log('call current time fun');

    if (this.lecture.contenttype == 1) {
      var seconds = 0;
      console.log('video type');

      console.log(this.videotype);

      if (this.videotype != 'vimeo') {
        seconds = this.videoPlayer!.currentTime;
        this.changeCurrentTimeFormat(seconds);
      } else {
        let myvimeoplayer = new vimeoPlayer(this.vimeoPlayer.nativeElement);
        await myvimeoplayer.getCurrentTime().then((seconds) => {
          this.changeCurrentTimeFormat(seconds);
        });
        let duration = await myvimeoplayer.getDuration().then((duration) => {
          return duration;
        });
        console.log(duration);
      }
    }
  }

  changeCurrentTimeFormat(seconds: number) {
    // // current time in MM:SS format
    // var minutes = Math.floor(seconds / 60);
    // var seconds = Math.floor(seconds % 60);
    // // add padding 0 for single digit using padStart
    // var strminutes = minutes.toString().padStart(2, '0');
    // var strseconds = seconds.toString().padStart(2, '0');
    // var currentTime = strminutes + ':' + strseconds;
    // this.currentTime = currentTime;
    // console.log(this.currentTime);

    var currentTime = Math.floor(seconds).toString();
    this.currentTime = currentTime;

    if (currentTime != '0') {
      this.addCurrentVideoTime(currentTime);
    }
  }

  addCurrentVideoTime(time: string) {
    if (!this.isFromInstructor) {
      this.course.sections.map((section: any) => {
        section.subsections.map((subsection: any) => {
          subsection.units
            .filter((unit: any) => unit.unitid == this.lecture.unitid)
            .map((unit: any) => {
              unit.currenttime = time;
              return unit;
            });
        });
      });

      const usersyskey = this.allinOneService.getUserId();
      var data = {
        unitid: this.lecture.unitid,
        usersyskey: usersyskey,
        courseid: this.courseid,
        currenttime: time,
      };
      if (!this.isFromInstructor) {
        this.courseService.addCurrentVideoTime(data).subscribe(
          (res: any) => { },
          (err: any) => { }
        );
      }
    }
  }

  onVideoPlay() {
    console.log('player started.');
    if (!this.lecture.resumed) {
      this.videoPlayer!.currentTime = this.lecture.currenttime;
      this.lecture.resumed = true;
    }
  }

  onVMCurrentTimeChange(event: any) {
    if (!this.lecture.checked) {
      let currentTime = this.videoPlayer!.currentTime;
      let duration = this.videoPlayer!.duration;
      this.checkCompleteVideo(currentTime, duration);
    }
  }

  checkCompleteVideo(currentTime: Number, duration: Number) {
    let percentage = (Number(currentTime) / Number(duration)) * 100;
    console.log(`percentage ${percentage}`);
    if (percentage >= 90) {
      this.course.sections.map((section: any) => {
        section.subsections.map((subsection: any) => {
          subsection.units.map((unit: any) => {
            if (unit.unitid == this.lecture.unitid) {
              unit.checked = true;
            }
          });
        });
      });
      this.updateCompletedUnits(this.lecture);
      return true;
    }
    return false;
  }

  getSafeHtml(html: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }

  height: string = '100vh';
  onPagesLoaded(event: any) {
    const h = event.source.viewer.clientHeight;
    console.log(h);
    this.height = h + 35 + 'px';
  }

  viewAttachmentFile(filename: string, url: string) {
    const filetype = filename.split('.').pop()?.toLowerCase();
    if (filetype == 'pdf' && !this.lecture.allowdownload) {
      let dialog = this.dialog.open(PdfViewerComponent, {
        width: '900px',
        maxWidth: '95vw',
        height: '700px',
        maxHeight: '95vh',
        closeOnNavigation: true,
        data: {
          filename: filename,
          url: url,
        },
      });
    }
    else {
      window.open(url, '_blank');
    }

  }
}
