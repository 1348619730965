import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'appFilter' })
export class FilterPipe implements PipeTransform {

  /**
   * Transform
   *
   * @param {any[]} items
   * @param {string} searchText
   * @returns {any[]}
   */
  transform(items: any[], searchText: string): any[] {
    if (!items) {
      return [];
    }
    if (!searchText) {
      return items;
    }
    searchText = searchText.toLocaleLowerCase();


    var returnItems: any[], returnItemsByKeyword;

    //search by coursename
    returnItems = items.filter(it => {
   
      return it.coursename.toString().toLocaleLowerCase().includes(searchText) || 
      it.instructorname.toString().toLocaleLowerCase().includes(searchText) ;
    });
    console.log("name>", items);

    //search by keywords
    returnItemsByKeyword = items.filter(it => {
      if (it.keywords != "") {
        const a = it.keywords.filter((k: any) => k.toString().toLocaleLowerCase().includes(searchText));
        if (a.length == 0) {
          return;
        }
        else {
          return it;
        }
      }

    })

    // assign array  =>   coursename array
    returnItemsByKeyword.map(el => {
      const check = returnItems.find((item: any) => item.courseid == el.courseid);
      if (!check) {
        returnItems.push(el);
      }
    })


    return returnItems;
  }
}