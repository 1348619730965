import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AllInOneService } from '../../shared/services/all-in-one.service';
import { CourseService } from '../../shared/services/course.service';
import { MessageService } from '../../shared/services/message.service';

@Component({
  selector: 'app-instructor-courses',
  templateUrl: './instructor-courses.component.html',
  styleUrls: ['./instructor-courses.component.scss'],
})
export class InstructorCoursesComponent implements OnInit {
  isLoading: boolean = false;
  instructor: string = 'Instructor';
  instructorid: string = '';
  profileid: string = '';
  gettingMoreCourses: boolean = false;
  end: string = '';
  isError: boolean = false;
  courses: any = [];
  isAll: boolean = false;

  subscription!: Subscription;

  constructor(
    private route: ActivatedRoute,
    private courseService: CourseService,
    private router: Router,
    public allinOneService: AllInOneService,
    private messageService: MessageService
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.instructorid = params['instructorid'];
      this.profileid = params['profileid'] == 'Instructor' ? '' : params['profileid'];
    });
    this.getCourses();
  }

  getCourses(): void {
    if (!this.gettingMoreCourses) {
      this.isLoading = true;
    }
    this.subscription = this.courseService
      .searchCourses('', this.end, this.instructorid, this.profileid)
      .subscribe(
        (res: any) => {
          if (res.returncode == '300') {
            if (!this.gettingMoreCourses) {
              this.instructor = res.instructorname;
            }
            this.isError = false;
            this.courses.push.apply(this.courses, res.data);
            this.end = res.end;

            if (this.end == '' || res.data.length < 16) {
              this.isAll = true;
            }
          } else {
            if (res.returncode == '210') {
              setTimeout(() => {
                this.router.navigateByUrl('login');
                this.allinOneService.logout();
              }, 2000);
            } else {
              this.messageService.openSnackBar(res.status, 'warn');
              this.isError = true;
            }
          }
          this.isLoading = false;
          this.gettingMoreCourses = false;
        },
        (err) => {
          this.isLoading = false;
          this.isError = true;
          this.gettingMoreCourses = false;
        }
      );
  }

  goToRegisterForm(courseid: string) {
    this.router.navigateByUrl(`course/home/${courseid}`);
  }

  scrolledDown() {
    if (!this.isAll && !this.gettingMoreCourses) {
      this.gettingMoreCourses = true;
      this.getCourses();
    }
  }
}
