import { DatePipe } from '@angular/common';
import { Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AllInOneService } from '../../shared/services/all-in-one.service';
import { CourseService } from '../../shared/services/course.service';
import { LoadingService } from '../../shared/services/loading.service';
import { MessageService } from '../../shared/services/message.service';

@Component({
  selector: 'app-assignment',
  templateUrl: './assignment.component.html',
  styleUrls: ['./assignment.component.scss'],
})
export class AssignmentComponent implements OnInit {
  @ViewChild('choosefile') choosefile: any;

  newSubmission: boolean = false;
  submitted: boolean = false;
  fileSelected: any = '';
  filename: string = '';
  filetype: string = '';
  classid: string = '';
  courseid: string = '';
  unitid: string = '';

  wordCount = this.allinoneService.assignmentWordCount;

  isEditing: boolean = false;
  isLoading: boolean = false;
  isSaving: boolean = false;
  form = this.formBuider.group({
    title: ['', Validators.required],
    unitid: ['', Validators.required],
    remark: [''],
    sectionid: [''],
    type : ['001'],
    text : ['']
  });

  get formControl() {
    return this.form.controls;
  }

  allowedFiletype = ['txt', 'docx', 'xlsx', 'pptx', 'pdf', 'zip', 'rar'];

  list: FileList[] = [];
  filteredList: FileList[] = [];

  constructor(
    public dialogRef: MatDialogRef<AssignmentComponent>,
    private formBuider: FormBuilder,
    public allinoneService: AllInOneService,
    private messageService: MessageService,
    private courseService: CourseService,
    public datepipe: DatePipe,
    private loadingService: LoadingService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.classid = data.classid;
    this.courseid = data.courseid;
    this.unitid = data.unitid;
    this.form.patchValue({
      sectionid: data.sectionid
    });
  }

  ngOnInit(): void {}

  uploadFile(event: any) {
    var filetype = event[0].name.split('.').pop();
    if (this.allowedFiletype.includes(filetype)) {
      var filesize = event[0].size / (1024 * 1024);
      if(filesize > this.allinoneService.assignmentFileSize){
        this.messageService.openSnackBar(`Maximum file size is ${this.allinoneService.assignmentFileSize} MB.`, 'warn');
        return;
      }
      this.fileSelected = event[0];
      this.filename = this.fileSelected.name;
      this.filetype = filetype;
    } else {
      this.messageService.openSnackBar(
        'This file type is not allowed.',
        'warn'
      );
    }
    this.choosefile.nativeElement.value = '';
  }

  onFileSelected(event: any) {
    var filetype = event.target.files[0].name.split('.').pop();
    if (this.allowedFiletype.includes(filetype)) {
      var filesize = event.target.files[0].size / (1024 * 1024);
      if(filesize > this.allinoneService.assignmentFileSize){
        this.messageService.openSnackBar(`Maximum file size is ${this.allinoneService.assignmentFileSize} MB.`, 'warn');
        return;
      }
      this.fileSelected = event.target.files[0];
      this.filename = this.fileSelected.name;
      this.filetype = filetype;
    } else {
      this.messageService.openSnackBar(
        'This file type is not allowed.',
        'warn'
      );
    }
    this.choosefile.nativeElement.value = '';
  }

  sumbitForm() {

    
    
    var filename = this.filename
      .substring(0, this.filename.lastIndexOf('.'))
      .replace(/\s/g, '');
    var date = new Date();
    var millisecond = date.getMilliseconds();
    var time = this.datepipe.transform(date, 'yyyyMMddhhmmss' + millisecond);
    filename = filename + '_' + time + '.' + this.filetype;
    var title = this.formControl.title.value.toString().trim();
    var remark = this.formControl.remark.value.toString().trim();
    var sectionid = this.formControl.sectionid.value.toString().trim();
    var path = 'dropbox/' + this.courseid + '/' + this.classid;
    var type = this.formControl.type.value;
    var text = this.formControl.text.value.toString().trim();

    this.submitted = true;
    if ((!this.fileSelected && type == '001') || this.formControl.title.invalid) {
      return;
    }

    if(type == '002') {
      if(text == ''){ 
        return this.messageService.openSnackBar('Please enter your submission.', 'warn');
      }
      else if(this.getWordCount() > this.wordCount ){
        return this.messageService.openSnackBar('Maximum word count is 1000.', 'warn');
      }
    }

    
    
    this.isSaving = true;

    if(type == '001') {
      this.allinoneService
      .fileUploadToS3WithPathForImage(this.fileSelected, filename, path)
      .then(
        () => {
          //Success
          this.courseService
            .studentFileUpload(
              this.courseid,
              this.classid,
              sectionid,
              this.unitid,
              remark,
              title,
              filename,
              this.filetype,
              ''
            )
            .subscribe(
              (res: any) => {
                if (res.returncode == '300') {
                  this.isSaving = false;
                  this.dialogRef.close(true);
                } else {
                  this.isSaving = false;
                  this.messageService.openSnackBar(
                    res.message || res.status || 'Server Error',
                    'warn'
                  );
                }
              },
              (err) => {
                this.isSaving = false;
              }
            );
        },
        () => {
          //Fail
          this.isSaving = false;
          this.messageService.openSnackBar(
            // 'Error while uploading your file.',
            'Please Try Again.',
            'warn'
          );
        }
      );
    } else if(type == '002') {
      this.courseService
      .studentFileUpload(
        this.courseid,
        this.classid,
        sectionid,
        this.unitid,
        remark,
        title,
        '',
        '',
        text
      )
      .subscribe(
        (res: any) => {
          if (res.returncode == '300') {
            this.isSaving = false;
            this.dialogRef.close(true);
          } else {
            this.isSaving = false;
            this.messageService.openSnackBar(
              res.message || res.status || 'Server Error',
              'warn'
            );
          }
        },
        (err) => {
          this.isSaving = false;
        }
      ); 
    }
    
  }

  cancel() {
    this.dialogRef.close();
  }

  getWordCount(){
    return this.formControl.text.value.trim() == "" ? 0 : this.formControl.text.value.trim().split(/\s+/).length;
  }
}
