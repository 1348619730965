import { Component, OnInit, ViewChild, HostListener } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CourseService } from '../../shared/services/course.service';
import { MessageService } from '../../shared/services/message.service';
import { CookieService } from 'ngx-cookie-service';
import { AllInOneService } from '../../shared/services/all-in-one.service';
import { FormBuilder, Validators } from '@angular/forms';
import { SEOService } from '../../shared/services/seo.service';

@Component({
  selector: 'app-verify',
  templateUrl: './verify.component.html',
  styleUrls: ['./verify.component.scss']
})

export class VerifyComponent implements OnInit {

  otp: string = "";
  verified: boolean = false;
  existsuser: boolean = false;
  showOtpComponent: boolean = true;
  isStillVerifying: boolean = false;
  isStillCreateUser: boolean = false;
  isStillResend: boolean = false;
  userFormSubmitted: boolean = false;
  isuserCreated: boolean = false;

  userForm = this.formBuider.group({
    name: ["", Validators.required,],
  });


  @ViewChild("ngOtpInput", { static: false }) ngOtpInput: any;
  config = { allowNumbersOnly: true, length: 6, isPasswordInput: false, disableAutoFocus: false, placeholder: "" };

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event.keyCode === 13) {
      this.otpFillNext();
    }
  }

  constructor(private router: Router,
    private courseService: CourseService,
    private messageService: MessageService,
    public allinOneService: AllInOneService,
    private route: ActivatedRoute,
    private formBuider: FormBuilder,
    private cookieService: CookieService,
    private _seoService: SEOService
    ) { }

  ngOnInit(): void {
    this._seoService.updateTitle('- Sign In');
    const existsuser = sessionStorage.getItem('existsuser');
    if (existsuser) {
      if (existsuser == "1") {
        this.existsuser = true;
      }
      else {
        this.existsuser = false;
      }
    }
  }
  onOtpChange(otp: any) {
    this.otp = otp;
  }

  async otpFillNext() {

    if (this.otp.length == 6) {
      this.isStillVerifying = true;

      if (this.existsuser) {
        const kunyekusername = localStorage.getItem('kunyekusername') || '';
        this.userForm.value.name = kunyekusername;

        const awaitVerify = await this.verify(kunyekusername);
        if (awaitVerify == "success") {
          this.createUser();
        }
        else {
          this.isStillVerifying = false;
        }
      }
      else {
        this.isStillVerifying = true;
        this.createUserNew();
        // this.verified = true;
      }
    }
  }


  verify(username: string) {
    return new Promise(resolve => {
      this.courseService.verify(username, this.otp).subscribe((res: any) => {
        if (res.returncode == "300") {
          this.allinOneService.setCookie("atoken", res.atoken);
          resolve('success');
        }
        else {
          if (res.returncode == "202") {
            this.messageService.openSnackBar('Invalid OTP. Please try again.', 'warn');
          }
          else {
            this.messageService.openSnackBar(res.message, 'warn');
          }
          resolve('error');
        }

      }, err => {
        resolve('error');
      });
    })

  }


  async createUserNew() {
    const awaitVerify = await this.verify(sessionStorage.getItem('username') || '');

      if (awaitVerify == "success") {
        this.createUser();
      }
      else {
        this.isStillVerifying = false;
      }
    // this.userFormSubmitted = true;

    // if (this.userForm.invalid) {
    //   return;
    // }
    // else {
    //   const awaitVerify = await this.verify(this.userForm.value.name);

    //   if (awaitVerify == "success") {
    //     this.createUser();
    //   }
    //   else {
    //     this.isStillVerifying = false;
    //   }
    // }

  }


  get userFormControl() { return this.userForm.controls; }

  /**
 * @param existsuser - check kunyekuser
 */
  async createUser() {
    const username = sessionStorage.getItem('username') || '';
    this.isStillCreateUser = true;
    this.courseService.checkUser().subscribe(async (res: any): Promise<any> => {
      if (res.returncode == "300") {
        this.isStillVerifying = false;
        //user does not exits
        if (res.status == 0) {
          this.courseService.createUser(username).subscribe((learn_res: any) => {
            if (learn_res.returncode == "300") {
              this.allinOneService.setCookie('username', username);
              this.allinOneService.setCookie("userid", learn_res.data.usersyskey);
              this.isuserCreated = true;
              this.isStillCreateUser = false;
              this.userFormSubmitted = false;
              this.goToPage();
            }
            else {
              this.messageService.openSnackBar(res.status, 'warn');
            }
          }, err => {
            this.isStillVerifying = false;
            this.userFormSubmitted = false;
          });
        }
        else {
          this.isuserCreated = true;
          this.allinOneService.setCookie('userid', res.usersyskey);
          this.goToPage();
        }
        this.verified = true;
      }
      else {
        this.messageService.openSnackBar(res.status || res.message || 'Server Error', 'warn');
        this.isStillVerifying = false;
        this.isStillCreateUser = false;
        if (res.returncode == "210") {
          setTimeout(() => {
            this.router.navigateByUrl('login');
            this.allinOneService.logout();
          }, 2000);
        }
      }

    }, err => {
      this.isStillCreateUser = false;
    });

  }

  goToPage() {
    // setTimeout(() => {
      const nex_route: any = this.route.snapshot.queryParamMap.get('next');
      var route = "";

      if (nex_route) {
        route = nex_route.toString().replaceAll(',', '/');
        this.router.navigateByUrl(route);
      }
      else {
        route = '/home';
        this.router.navigateByUrl(route);
      }

      setTimeout(() => {
        window.location.reload();
      }, 1000);
    // }, 3000);
  }

  resend() {
    if (!this.isStillResend) {
      this.isStillResend = true;
      const userid = this.allinOneService.getKunyekUserId();
      const password = this.allinOneService.getKunyekUserId();

      this.courseService.signIn(userid, password).subscribe((res: any) => {
        if (res.returncode == "300") {
          sessionStorage.setItem("session", res.session);
          this.isStillResend = false;
        }
        else {
          this.isStillResend = false;
          this.messageService.openSnackBar(res.message, 'warn');
        }
      }, err => {
        this.isStillResend = false;
      })
    }
  }
}
