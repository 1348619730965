import { Component, OnInit } from '@angular/core';
import { CourseService } from '../../shared/services/course.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AllInOneService } from '../../shared/services/all-in-one.service';
import { MessageService } from '../../shared/services/message.service';

@Component({
  selector: 'app-add-to-class',
  templateUrl: './add-to-class.component.html',
  styleUrls: ['./add-to-class.component.scss'],
})
export class AddToClassComponent implements OnInit {
  qrvalue: string = '';
  isLoading: boolean = false;
  invalid: boolean = false;
  errorMessage: string = '';

  constructor(
    private route: ActivatedRoute,
    private courseService: CourseService,
    private router: Router,
    public allinOneService: AllInOneService,
    private messageService: MessageService
  ) {
    this.route.params.subscribe((params) => {
      this.qrvalue = params['qrvalue'];
    });
  }

  ngOnInit(): void {
    try {
      this.qrvalue = decodeURIComponent(this.qrvalue);
      if (this.qrvalue) {
        this.addToClass();
      } else {
        this.invalid = true;
      }
    } catch {
      this.invalid = true;
    }
  }

  addToClass() {
    this.isLoading = true;
    this.courseService.addToClass(this.qrvalue).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          let courseid = res.courseid;
          let channelid = res.channelid;
          let channels = this.allinOneService.channels;
          if (this.allinOneService.currentDomainType == '001') {
            let checkChannel = channels.filter((channel: any) => channel.channelid === channelid);
            if (checkChannel.length > 0) {
              this.allinOneService.channelid = channelid;
              this.allinOneService.setCookie('channelid', channelid);
              window.location.replace('/course/home/' + courseid);
            }
            else{
              this.router.navigateByUrl('/home');
            }
          }
          else{
            this.router.navigateByUrl('/course/home/' + courseid);
          }
          this.isLoading = false;
        } else {
          this.isLoading = false;
          this.invalid = true;
          this.messageService.openSnackBar(
            res.message || res.status || 'Server Error',
            'warn'
          );
          this.errorMessage = res.message || res.status;
        }
      },
      (err) => {
        this.invalid = true;
        this.isLoading = false;
      }
    );
  }
}
