import { Component, OnInit } from '@angular/core';
import { CourseService } from '../../shared/services/course.service';
import { AllInOneService } from '../../shared/services/all-in-one.service';
import { MessageService } from '../../shared/services/message.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { SEOService } from '../../shared/services/seo.service';

@Component({
  selector: 'app-learningpath-courses',
  templateUrl: './learningpath-courses.component.html',
  styleUrls: ['./learningpath-courses.component.scss'],
})
export class LearningpathCoursesComponent implements OnInit {
  isLoading: boolean = false;

  learningPathName: string = 'Learning Path';
  learningpathid: string = '';

  courses: any[] = [];

  constructor(
    private courseService: CourseService,
    public allinOneService: AllInOneService,
    private messageService: MessageService,
    private route: ActivatedRoute,
    public deviceService: DeviceDetectorService,
    private _seoService: SEOService,
    private router: Router
  ) {
    this.route.params.subscribe((params) => {
      this.learningpathid = params['learningpathid'];
    });
  }

  ngOnInit(): void {
    this.getCourses();
  }

  getCourses() {
    this.isLoading = true;
    const data = {
      usersyskey: this.allinOneService.getUserId(),
      learningpathid: this.learningpathid,
      channelid: this.allinOneService.channelid,
    };
    this.courseService.getLearningPathCourses(data).subscribe((res: any)=> {
      if(res.returncode == '300'){
        this.courses = res.data;
        this.learningPathName=res.name;
      }
      else{
        this.messageService.openSnackBar(res.message || res.status || 'Server Error', 'warn');
      }
      this.isLoading = false;
    }, err => {
      this.isLoading = false;
    })
  }

  goToRegisterForm(courseid: string) {
    this.router.navigateByUrl(`/course/home/${courseid}`);
  }

  goToLearningPath(){
    this.router.navigateByUrl(`/home/learningpath`);
  }

  goToLearning(courseid: string, event: any){
    event.stopPropagation();
    this.router.navigateByUrl(`/courses/${courseid}/lectures/`);
  }
  
}
