import { DeviceDetectorService } from 'ngx-device-detector';
import { Component, Inject, OnInit } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { AllInOneService } from '../../services/all-in-one.service';
import { CourseService } from '../../services/course.service';
import { MessageService } from '../../services/message.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-refine-dialog',
  templateUrl: './refine-dialog.component.html',
  styleUrls: ['./refine-dialog.component.scss'],
})
export class RefineDialogComponent {
  animal: string = '';
  name: string = '';
  channels: any[] = [];
  categories: any[] = [];
  chldata: string = '';
  catedata: string = '';
  statusid: string = '';

  channelid: string = 'all';
  categoryid: string = '';

  statusList = [
    {
      id: '1',
      name: 'In Progress',
    },
    {
      id: '2',
      name: 'Completed',
    },
    {
      id: '3',
      name: 'Not Started',
    },
  ];

  constructor(
    public allinOneService: AllInOneService,
    private messageService: MessageService,
    private courseService: CourseService,
    public deviceService: DeviceDetectorService,
    private router: Router,
    public dialog: MatDialogRef<RefineDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.channelid = 'all';
    this.channels = this.data.chl;
    this.categories = this.data.cat;
    this.channelid = this.data.chldata;
    this.categoryid = this.data.catedata;
    this.catedata = this.data.catedata;
    this.statusid = this.data.status;
    if (this.channels.length == 0) {
      this.channels = this.allinOneService.getChannels();
    }
    if (this.categories.length == 0) {
      this.categories = this.allinOneService.getCategory();
    }
  }

  onConfirm(): void {
    const data = [
      {
        status: true,
        categoryid: this.categoryid,
        channelid: this.channelid,
        statusid: this.statusid
      },
    ];

    this.dialog.close(data);
  }

  reset(): void {
    this.categoryid = '';
    this.channelid = 'all';
  }

  close() {
    const data = [
      {
        status: false,
      },
    ];
    this.dialog.close(data);
  }
}
