import { Injectable } from '@angular/core';

import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, share } from 'rxjs/operators';
import { MessageService } from './message.service';
import { AllInOneService } from './all-in-one.service';
import { CookieService } from 'ngx-cookie-service';
import { Router, Params } from '@angular/router';
import { Answer } from '../models/Quiz';
import { type } from 'os';

@Injectable({
  providedIn: 'root',
})
export class CourseService {
  constructor(
    private http: HttpClient,
    private messageService: MessageService,
    private allinOneService: AllInOneService,
    private cookieService: CookieService,
    private router: Router
  ) {}

  private handleError = (error: HttpErrorResponse) => {
    console.error(error);
    if (error.status == 0) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.message);
      this.messageService.openSnackBar('Network Error', 'warn');
    } else {
      if (error.status.toString().startsWith('5')) {
        if (error.status != 504) {
          this.messageService.openSnackBar('Server Error', 'warn');
        } else {
          this.messageService.openSnackBar(
            'Request Timeout, please try again',
            'warn'
          );
        }
      } else if (error.status.toString().startsWith('4')) {
        if (error.status.toString() == '401') {
          this.messageService.openSnackBar('Unauthorized Access.', 'warn');
        } else {
          this.messageService.openSnackBar('Client Error', 'warn');
        }
      }
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
    }

    return throwError('Something bad happened; please try again later.');
  };

  httpRequestForLogin(url: string, param: any) {
    const options = {
      headers: new HttpHeaders({
        Accept: 'text/html,application/json',
        'Content-Type': 'application/json',
      }),
    };

    return this.http
      .post(url, param, options)
      .pipe(catchError(this.handleError));
  }

  httpRequest(url: string, param: any) {
    const appid = this.allinOneService.getAppId();
    var userid = this.allinOneService.getKunyekUserId();
    const atoken = this.allinOneService.getAtoken();

    const options = {
      headers: new HttpHeaders({
        Accept: 'text/html,application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${atoken}`,
      }),
    };

    const body = Object.assign(param, {
      appid: appid,
      userid: userid,
      atoken: atoken,
    });

    return this.http
      .post(url, body, options)
      .pipe(catchError(this.handleError));
  }

  downloadFile(url: string): any {
    return this.http.get(url, { responseType: 'blob' });
  }

  //user
  // kunyek user to create a new one if not in elearning. [Elearning Server]
  channelGet() {
    const url = this.allinOneService.channelUrl + 'get';
    const param = {
      channelid: '',
      status: '',
    };
    return this.httpRequest(url, param);
  }

  checkApprovedinstructor() {
    const url = this.allinOneService.domain + 'checkapprovedinstrutor';
    const param = {};
    return this.httpRequest(url, param);
  }

  approverUSer(usersyskey: any, approved: any) {
    const url = this.allinOneService.userUrl + 'approveuser';
    const param = {
      usersyskey: usersyskey,
      approved: approved,
    };
    return this.httpRequest(url, param);
  }

  getMychannel(data: any) {
    const url = this.allinOneService.channelUrl + 'getmyinstructorchannel';
    return this.httpRequest(url, data);
  }

  channelGetId(channelid: string) {
    const url = this.allinOneService.channelUrl + 'get';
    const param = {
      channelid: channelid,
      status: '',
    };
    return this.httpRequest(url, param);
  }
  /**
   * @param courseid
   * @param classid
   * @param syskey
   */
  getStudentDropboxList(courseid: string, classid: string, syskey: string) {
    const url = this.allinOneService.courseUrl + 'getstudentdropboxlist';
    const param = {
      courseid: courseid,
      classid: classid,
      studentid: syskey,
    };
    return this.httpRequest(url, param);
  }
  updateChannel(data: any) {
    const url = this.allinOneService.channelUrl + 'edit';
    return this.httpRequest(url, data);
  }

  getAnalyticsData(role: string, channelid: string, daterange: any) {
    const url = this.allinOneService.domain + 'getchannelanalystics';
    const param = {
      role: role,
      channelid: channelid,
      daterange: daterange,
    };
    return this.httpRequest(url, param);
  }

  /**
   *
   * @param channelid
   * @param searchuser
   * @param startdate
   * @param enddate
   * @param categoryid
   * @returns
   */
  getAnalyticsCourse(
    channelid: string,
    searchuser: string,
    startdate: string,
    enddate: string,
    categoryid: string,
    daterange: any,
    connectionid: string = '',
    fromltuser: boolean = false,
    ltid: string = ''
  ) {
    const url = this.allinOneService.domain + 'getcourseanalytics';
    var param = {};
    if (fromltuser) {
      param = {
        channelid: channelid,
        searchuser: searchuser,
        startdate: startdate,
        enddate: enddate,
        categoryid: categoryid,
        connectionid: searchuser ? connectionid : '',
        learningpathid: ltid,
        daterange: daterange,
      };
    } else {
      param = {
        channelid: channelid,
        searchuser: searchuser,
        startdate: startdate,
        enddate: enddate,
        categoryid: categoryid,
        connectionid: searchuser ? connectionid : '',
        daterange: daterange,
      };
    }
    return this.httpRequest(url, param);
  }

  getCourseReport(data: any) {
    const url = this.allinOneService.reportUrl + 'getcoursereport';
    return this.httpRequest(url, data);
  }

  getUserReport(data: any) {
    const url = this.allinOneService.reportUrl + 'getuserreport';
    return this.httpRequest(url, data);
  }

  getAttendanceReport(data: any) {
    const url = this.allinOneService.reportUrl + 'getclassattendance';
    return this.httpRequest(url, data);
  }

  getClassReport(data: any) {
    const url = this.allinOneService.reportUrl + 'getclassreport';
    return this.httpRequest(url, data);
  }

  getClassDetailReport(data: any) {
    const url = this.allinOneService.reportUrl + 'getclassreportdetails';
    return this.httpRequest(url, data);
  }

  getClassStudentDetailReport(data: any) {
    const url = this.allinOneService.reportUrl + 'getstudentclassreport';
    return this.httpRequest(url, data);
  }

  /**
   *
   * @param channelid
   * @param courseid
   * @param searchuser
   * @returns
   */
  getUserDetailAnalytics(
    channelid: string,
    courseid: string,
    searchuser: string
  ) {
    const url = this.allinOneService.domain + 'getuserdetailsanalytic';
    const param = {
      channelid: channelid,
      courseid: courseid,
      searchuser: searchuser,
    };
    return this.httpRequest(url, param);
  }

  /**
   *
   * @param channelid
   * @param courseid
   * @param classid
   * @param startdate
   * @param enddate
   * @returns
   */
  getUserAnalytics(
    channelid: string,
    courseid: string,
    classid: string,
    startdate: string,
    enddate: string
  ) {
    const url = this.allinOneService.courseUrl + 'getuseranalytics';
    const param = {
      channelid: channelid,
      courseid: courseid,
      classid: classid,
      startdate: startdate,
      enddate: enddate,
    };
    return this.httpRequest(url, param);
  }

  getCourseDetail(courseid: string) {
    const url = this.allinOneService.courseUrl + 'getcourse';
    const userid = this.allinOneService.getUserId();
    const param = {
      courseid: courseid,
      status: 1,
      usersyskey: userid,
    };
    return this.httpRequest(url, param);
  }

  getInstructor(instructorid: string) {
    const url = this.allinOneService.userUrl + 'getinstructor';

    const param = {
      usersyskey: instructorid,
    };

    return this.httpRequestForLogin(url, param);
  }

  createChannel(data: any) {
    const url = this.allinOneService.channelUrl + 'create';
    return this.httpRequest(url, data);
  }

  createKhubUser(data: any) {
    const url =
      'https://ut809vr7a7.execute-api.ap-southeast-1.amazonaws.com/kunyek/mhktest/khubcreateuser';
    return this.httpRequest(url, data);
  }

  addmember(data: any) {
    const url = this.allinOneService.channelUrl + 'addmember';
    return this.httpRequest(url, data);
  }

  delemember(data: any) {
    const url = this.allinOneService.channelUrl + 'deletemember';
    return this.httpRequest(url, data);
  }

  getMember(data: any) {
    const url = this.allinOneService.channelUrl + 'getmember';
    return this.httpRequest(url, data);
  }
  updataMember(data: any) {
    const url = this.allinOneService.channelUrl + 'editmember';
    return this.httpRequest(url, data);
  }

  checkUser() {
    const url = this.allinOneService.userUrl + 'checkuser';
    const param = {};

    return this.httpRequest(url, param);
  }

  exchangeToken(data: any) {
    const url = this.allinOneService.iamdomain + 'auth/exchangetoken';
    const options = {
      headers: new HttpHeaders({
        Accept: 'text/html,application/json',
        'Content-Type': 'application/json',
      }),
    };
    return this.http
      .post(url, data, options)
      .pipe(catchError(this.handleError));
  }

  createUser(username: string, institute: string) {
    const url = this.allinOneService.userUrl + 'createuser';

    var userid = this.allinOneService.getKunyekUserId();

    const param = {
      userid: userid,
      username: username,
      userrole: 1,
      institute: institute,
    };
    return this.httpRequestForLogin(url, param);
  }

  updateUser(
    username: string,
    institute: string,
    profileimage: string,
    biography: string,
    type?: string
  ) {
    const url = this.allinOneService.userUrl + 'updateuser';
    const userid = this.allinOneService.getUserId();

    const param = {
      usersyskey: userid,
      username: username,
      institute: institute,
      sociallinks: [],
      biography: biography,
      changeimage: profileimage == '' ? 0 : 1,
      profileimage: profileimage,
      type: type ? type : 'profile',
    };
    return this.httpRequestForLogin(url, param);
  }
  getUser() {
    const url = this.allinOneService.userUrl + 'getuser';
    const userid = this.allinOneService.getUserId();

    const param = {
      usersyskey: userid,
      channelid: '',
    };
    return this.httpRequest(url, param);
  }

  signIn(userid: string, password?: string) {
    const appid = this.allinOneService.getAppId();
    const rememberme = sessionStorage.getItem('rememberme') || 'FALSE';

    const url = this.allinOneService.iamdomain + 'auth/signin';
    const param = {
      userid: userid,
      password: password || '',
      uuid: '',
      recaptcha: '',
      appid: appid,
      rememberme: rememberme,
    };

    return this.httpRequestForLogin(url, param);
  }

  verify(username: string, otp: string) {
    var userid = this.allinOneService.getKunyekUserId();
    var password = this.allinOneService.getKunyekPassword();

    const session = this.allinOneService.getSession();
    const appid = this.allinOneService.getAppId();

    const url = this.allinOneService.iamdomain + 'auth/verifyuser';
    const param = {
      userid: userid,
      username: username,
      uuid: '',
      otp: otp,
      session: session,
      password: password,
      appid: appid,
    };

    return this.httpRequestForLogin(url, param);
  }
  forgotpassword(password: string) {
    const url = this.allinOneService.iamdomain + 'auth/forgotpassword';
    const param = {
      password: password,
    };
    return this.httpRequest(url, param);
  }
  getCategoryList(data?: any) {
    const url = this.allinOneService.categoryUrl + 'getcategory';
    const param = data ? data : {};
    return this.httpRequest(url, param);
  }

  getCategoryMainList(data?: any) {
    const url = this.allinOneService.categoryUrl + 'getcategories';
    const param = data ? data : {};
    return this.httpRequest(url, param);
  }

  addSubcategory(data: any) {
    const url = this.allinOneService.categoryUrl + 'addcategory';
    return this.httpRequest(url, data);
  }

  editSubcategory(data: any) {
    const url = this.allinOneService.categoryUrl + 'updatecategory';
    return this.httpRequest(url, data);
  }

  /**
   *
   * @param categoryid
   * @param categoryname
   * @returns
   */
  deleteSubcategory(
    categoryid: string,
    categoryname: string,
    channelid: string
  ) {
    const url = this.allinOneService.categoryUrl + 'deletecategory';
    const param = {
      categoryid: categoryid,
      categoryname: categoryname,
      channelid: channelid,
    };
    return this.httpRequest(url, param);
  }

  /**
   * @param coursename - name of the course
   * @param instructorid - id of the instructor
   */

  createCourse(course: any) {
    const url = this.allinOneService.courseUrl + 'addcourse';
    return this.httpRequest(url, course);
  }

  /**
   * @param coursename - name of the course
   * @param instructorid - id of the instructor
   */

  updateCourse(course: any) {
    const url = this.allinOneService.courseUrl + 'updatecourse';
    const userid = this.allinOneService.getUserId();

    var param = {};
    var paymenttypelist: any = [];
    if (course.channelid != '' && course.paymenttype == '002') {
      paymenttypelist = [
        {
          id: course.memberspaymentid,
          paymenttype: course.memberspaymenttype,
          amount: course.memberspaymenttype == '002' ? course.membersfee : 0,
          currency: course.memberscurrency,
        },
      ];
    } else {
      paymenttypelist = [];
    }

    if (course.courseimagefiletype == 'base64') {
      param = {
        access:
          course.type == '002' || course.paymenttype == '002'
            ? '001'
            : course.anonymousLearning,
        courseid: course.courseid,
        coursename: course.coursename,
        coursesubname: course.coursesubname,
        category: course.category,
        subcategory: course.subcategory,
        instructorid: userid,
        description: course.description,
        requirements: course.requirements,
        changeimage: 1,
        keywords: course.keywords,
        courseimage: course.courseimage,
        coursetype: course.type,
        paymenttype: course.paymenttype, // 001 - free , 002 - paid
        grading: course.grading,
        amount:
          course.paymenttype == '001'
            ? '0'
            : course.fee
            ? course.fee.toString()
            : '0',
        currency: course.currency,
        // gradepage: course.gradepage,
        // certificatepage: course.certificatepage,
        // feedbackpage: course.feedbackpage,
        // templateid: course.certificatepage ? course.templateid : '',
        // mandatory: course.mandatorytype == '003' ? false : true,
        mandatory: false,
        paymenttypelist: paymenttypelist,
      };
    } else if (course.courseimagefiletype == 'url') {
      param = {
        access:
          course.type == '002' || course.paymenttype == '002'
            ? '001'
            : course.anonymousLearning,
        courseid: course.courseid,
        coursename: course.coursename,
        coursesubname: course.coursesubname,
        category: course.category,
        subcategory: course.subcategory,
        instructorid: userid,
        description: course.description,
        requirements: course.requirements,
        keywords: course.keywords,
        changeimage: 0,
        coursetype: course.type,
        paymenttype: course.paymenttype, // 001 - free , 002 - paid
        grading: course.grading,
        amount:
          course.paymenttype == '001'
            ? '0'
            : course.fee
            ? course.fee.toString()
            : '0',
        currency: course.currency,
        // gradepage: course.gradepage,
        // certificatepage: course.certificatepage,
        // feedbackpage: course.feedbackpage,
        // templateid: course.certificatepage ? course.templateid : '',
        // mandatory: course.mandatorytype == '003' ? false : true,
        mandatory: false,
        paymenttypelist: paymenttypelist,
      };
    } else {
      param = {
        access:
          course.type == '002' || course.paymenttype == '002'
            ? '001'
            : course.anonymousLearning,
        courseid: course.courseid,
        coursename: course.coursename,
        coursesubname: course.coursesubname,
        category: course.category,
        subcategory: course.subcategory,
        instructorid: userid,
        description: course.description,
        requirements: course.requirements,
        changeimage: 1,
        keywords: course.keywords,
        courseimage: '',
        coursetype: course.type,
        paymenttype: course.paymenttype, // 001 - free , 002 - paid
        grading: course.grading,
        amount:
          course.paymenttype == '001'
            ? '0'
            : course.fee
            ? course.fee.toString()
            : '0',
        currency: course.currency,
        // gradepage: course.gradepage,
        // certificatepage: course.certificatepage,
        // feedbackpage: course.feedbackpage,
        // templateid: course.certificatepage ? course.templateid : '',
        // mandatory: course.mandatorytype == '003' ? false : true,
        mandatory: false,
        paymenttypelist: paymenttypelist,
      };
    }

    // return;
    return this.httpRequest(url, param);
  }

  updateCourseCustomPage(course: any) {
    const url = this.allinOneService.courseUrl + 'updatecustompage';
    // var param = {
    //   gradepage: course.gradepage,
    //   certificatepage: course.certificatepage,
    //   feedbackpage: course.feedbackpage,
    //   templateid: course.certificatepage ? course.templateid : '',
    //   feedbacktemplateid: course.feedbackpage ? course.feedbacktemplateid : '',
    //   courseid: course.courseid,
    //   certificategrade: course.grade
    //     ? course.certificategrade.toString().trim()
    //     : '',
    //   certificatefeedback: course.,
    // };
    // const url = "https://rwm99kh7ta.execute-api.ap-southeast-1.amazonaws.com/Stage/test";
    var param = {
      gradepage: course.gradepage,
      certificatepage: course.certificatepage,
      feedbackpage: course.feedbackpage,
      feedbacktemplateid: course.feedbackpage ? course.feedbacktemplateid : '',
      template: course.template,
      courseid: course.courseid,
    };
    console.log('your params');
    console.log(param);

    return this.httpRequest(url, param);
  }

  /**
   * @param coursename - name of the course
   * @param instructorid - id of the instructor
   */

  updateCourseStatus(courseid: string, coursestatus: any) {
    const url = this.allinOneService.courseUrl + 'updatestatus';
    const param = {
      courseid: courseid,
      coursestatus: coursestatus,
    };

    return this.httpRequest(url, param);
  }

  /**
   * @param instructorid - id of the instructor
   */

  getAllCourseByInstructor(selectedChannel: string) {
    const url = this.allinOneService.courseUrl + 'getcoursebyinstructorid';
    const userid = this.allinOneService.getUserId();

    const param = {
      instructorid: userid,
      channelid: selectedChannel,
    };

    return this.httpRequest(url, param);
  }

  /**
   * @param courseid - id of the course
   */

  getCourseByCourseID(courseid: string) {
    const url = this.allinOneService.courseUrl + 'getinstructorcourse';
    const userid = this.allinOneService.getUserId() || '';
    const param = {
      courseid: courseid,
      usersyskey: userid,
    };
    return this.httpRequest(url, param);
  }

  /**
   * @param sortkey - index of section
   */
  addSection(courseid: string, sectionname: string, sortkey: number) {
    const url = this.allinOneService.sectionUrl + 'addsection';
    const param = {
      courseid: courseid,
      sectionname: sectionname,
      sortkey: sortkey,
    };

    return this.httpRequest(url, param);
  }

  /**
   */
  updateSection(sectionid: string, sectionname: string) {
    const url = this.allinOneService.sectionUrl + 'updatesection';

    const param = {
      sectionid: sectionid,
      sectionname: sectionname,
    };

    return this.httpRequest(url, param);
  }

  /**
   */
  deleteSection(sectionid: string) {
    const url = this.allinOneService.sectionUrl + 'deletesection';

    const param = {
      sectionid: sectionid,
    };

    return this.httpRequest(url, param);
  }

  /**
   * @param sortkey - index of sub section
   */

  addSubSection(
    sectionid: string,
    subsectionname: string,
    sortkey: number,
    courseid: string
  ) {
    const url = this.allinOneService.subsectionUrl + 'addsubsection';
    const param = {
      subsectionname: subsectionname,
      sectionid: sectionid,
      sortkey: sortkey,
      courseid: courseid,
    };
    return this.httpRequest(url, param);
  }

  /**
   */

  updateSubsection(subsectionid: string, subsectionname: string) {
    const url = this.allinOneService.subsectionUrl + 'updatesubsection';

    const param = {
      subsectionid: subsectionid,
      subsectionname: subsectionname,
    };
    return this.httpRequest(url, param);
  }

  /**
   */
  deleteSubsection(subsectionid: string) {
    const url = this.allinOneService.subsectionUrl + 'deletesubsection';
    const param = {
      subsectionid: subsectionid,
    };

    return this.httpRequest(url, param);
  }

  /**
   *
   * @param unitname
   * @param subsectionid
   * @param sortkey
   * @param contenttype
   * @param content
   * @param filename
   * @param filetype
   * @param imageList
   * @param FileList
   * @param courseid
   * @param filepath
   * @param grade
   * @param studytime
   * @returns
   */
  addUnit(
    unitname: string,
    subsectionid: string,
    sortkey: number,
    contenttype: number,
    content: string,
    filename: string,
    filetype: string,
    imageList: any,
    FileList: any,
    courseid: string,
    libraryid: string,
    maxattept: string,
    quizconfig: string,
    grade: string,
    studytime: string,
    deadlinedate: string,
    deadlinetime: string,
    attachment: string,
    showfeedback: boolean,
    allowdownload: boolean,
    autonumbering: boolean,
    bygroup: boolean,
    groupquestionscount: string,
    grouptime: boolean,
    attachmentDataList: any,
    randomassignment: boolean,
    randomassignmentcount: number,
    multifileupload: boolean,
    multifileuploadcount: number
  ) {
    const url = this.allinOneService.unitUrl + 'addunit';
    var param = {};
    if (contenttype == 1) {
      param = {
        unitname: unitname,
        contenttype: contenttype,
        subsectionid: subsectionid,
        content: content,
        sortkey: sortkey,
        courseid: courseid,
        grade: grade,
        studytime: studytime,
        libraryid: libraryid,
        // path: filepath,
      };
    } else if (contenttype == 2) {
      param = {
        unitname: unitname,
        contenttype: contenttype,
        subsectionid: subsectionid,
        content: content,
        filelist: FileList,
        sortkey: sortkey,
        courseid: courseid,
        libraryid: libraryid,
        grade: grade,
        studytime: studytime,
        allowdownload: allowdownload,
      };
    } else if (contenttype == 10) {
      param = {
        unitname: unitname,
        contenttype: contenttype,
        subsectionid: subsectionid,
        content: content,
        filelist: FileList,
        sortkey: sortkey,
        courseid: courseid,
        libraryid: libraryid,
        grade: grade,
        studytime: studytime,
      };
    } else if (contenttype == 6) {
      param = {
        unitname: unitname,
        contenttype: contenttype,
        subsectionid: subsectionid,
        content: content,
        imagelist: imageList,
        sortkey: sortkey,
        courseid: courseid,
        libraryid: libraryid,
        maxattempt: maxattept,
        quizconfig: quizconfig,
        grade: grade,
        studytime: studytime,
        showfeedback: showfeedback,
        autonumbering: autonumbering,
        bygroup: bygroup,
        groupquestionscount: groupquestionscount,
        grouptime: grouptime,
      };
    } else if (contenttype == 7) {
      param = {
        unitname: unitname,
        contenttype: contenttype,
        subsectionid: subsectionid,
        content: content,
        imagelist: imageList,
        sortkey: sortkey,
        courseid: courseid,
        libraryid: libraryid,
        maxattempt: maxattept,
        quizconfig: quizconfig,
        grade: grade,
        studytime: studytime,
        deadlinedate: deadlinedate,
        deadlinetime: deadlinetime,
        attachment: attachment,
        allowdownload: allowdownload,
        attachmentlist: attachmentDataList,
        randomassignment: randomassignment,
        randomassignmentcount: randomassignmentcount,
        multifileupload: multifileupload,
        multifileuploadcount: multifileuploadcount,
      };
    } else if (contenttype == 8) {
      param = {
        unitname: unitname,
        contenttype: contenttype,
        subsectionid: subsectionid,
        content: content,
        imagelist: imageList,
        sortkey: sortkey,
        courseid: courseid,
        libraryid: libraryid,
        maxattempt: maxattept,
        quizconfig: quizconfig,
        grade: grade,
        studytime: studytime,
        attachment: attachment,
        showfeedback: showfeedback,
        // allowdownload: allowdownload,
      };
    } else if (contenttype == 5) {
      param = {
        unitname: unitname,
        contenttype: contenttype,
        subsectionid: subsectionid,
        content: content,
        imagelist: imageList,
        sortkey: sortkey,
        courseid: courseid,
        libraryid: libraryid,
        maxattempt: maxattept,
        quizconfig: quizconfig,
        grade: grade,
        studytime: studytime,
        showfeedback: showfeedback,
        // allowdownload: allowdownload,
      };
    } else {
      param = {
        unitname: unitname,
        contenttype: contenttype,
        subsectionid: subsectionid,
        content: content,
        imagelist: imageList,
        sortkey: sortkey,
        courseid: courseid,
        libraryid: libraryid,
        maxattempt: maxattept,
        quizconfig: quizconfig,
        grade: grade,
        studytime: studytime,
        showfeedback: showfeedback,
      };
    }

    console.log(param);
    return this.httpRequest(url, param);
  }

  /**
   */

  renameUnit(unitid: string, unitname: string) {
    const url = this.allinOneService.unitUrl + 'updateunitname';

    const param = {
      unitid: unitid,
      unitname: unitname,
    };
    return this.httpRequest(url, param);
  }

  /**
   *
   * @param unitid
   * @param unitname
   * @param subsectionid
   * @param sortkey
   * @param contenttype
   * @param content
   * @param filename
   * @param filetype
   * @param filelist
   * @param imageList
   * @param filepath
   * @param libraryid
   * @param maxattept
   * @param quizconfig
   * @param grade
   * @param studytime
   * @returns
   */
  updateUnit(
    courseid: string,
    unitid: string,
    unitname: string,
    subsectionid: string,
    sortkey: number,
    contenttype: number,
    content: string,
    filename: string,
    filetype: string,
    filelist: number,
    imageList: any,
    filepath: string,
    libraryid: string,
    maxattept: string,
    quizconfig: string,
    grade: string,
    studytime: string,
    deadlinedate: string,
    deadlinetime: string,
    attachment: string,
    showfeedback: boolean,
    allowdownload: boolean,
    autonumbering: boolean,
    bygroup: boolean,
    groupquestionscount: string,
    grouptime: boolean,
    attachmentDataList: any,
    randomassignment: boolean,
    randomassignmentcount: number,
    multifileupload: boolean,
    multifileuploadcount: number
  ) {
    const url = this.allinOneService.unitUrl + 'updateunit';
    var param = {};
    if (contenttype == 1) {
      param = {
        courseid: courseid,
        unitid: unitid,
        unitname: unitname,
        contenttype: contenttype,
        content: content,
        // path: filepath,
        libraryid: libraryid,
        grade: grade,
        studytime: studytime,
      };
    } else if (contenttype == 2) {
      param = {
        courseid: courseid,
        unitid: unitid,
        unitname: unitname,
        contenttype: contenttype,
        content: content,
        filelist: filelist,
        libraryid: libraryid,
        grade: grade,
        studytime: studytime,
        allowdownload: allowdownload,
      };
    } else if (contenttype == 10) {
      param = {
        courseid: courseid,
        unitid: unitid,
        unitname: unitname,
        contenttype: contenttype,
        content: content,
        filelist: filelist,
        libraryid: libraryid,
        grade: grade,
        studytime: studytime,
      };
    } else if (contenttype == 6) {
      param = {
        courseid: courseid,
        unitid: unitid,
        unitname: unitname,
        contenttype: contenttype,
        content: content,
        imagelist: imageList,
        sortkey: sortkey,
        libraryid: libraryid,
        maxattempt: maxattept,
        quizconfig: quizconfig,
        grade: grade,
        studytime: studytime,
        showfeedback: showfeedback,
        autonumbering: autonumbering,
        bygroup: bygroup,
        groupquestionscount: groupquestionscount,
        grouptime: grouptime,
      };
    } else if (contenttype == 7) {
      param = {
        courseid: courseid,
        unitid: unitid,
        unitname: unitname,
        contenttype: contenttype,
        content: content,
        imagelist: imageList,
        sortkey: sortkey,
        libraryid: libraryid,
        maxattempt: maxattept,
        quizconfig: quizconfig,
        grade: grade,
        studytime: studytime,
        deadlinedate: deadlinedate,
        deadlinetime: deadlinetime,
        attachment: attachment,
        allowdownload: allowdownload,
        attachmentlist: attachmentDataList,
        randomassignment: randomassignment,
        randomassignmentcount: randomassignmentcount,
        multifileupload: multifileupload,
        multifileuploadcount: multifileuploadcount,
      };
    } else if (contenttype == 8) {
      param = {
        courseid: courseid,
        unitid: unitid,
        unitname: unitname,
        contenttype: contenttype,
        content: content,
        imagelist: imageList,
        sortkey: sortkey,
        libraryid: libraryid,
        maxattempt: maxattept,
        quizconfig: quizconfig,
        grade: grade,
        studytime: studytime,
        attachment: attachment,
        // allowdownload: allowdownload,
      };
    } else if (contenttype == 5) {
      param = {
        courseid: courseid,
        unitid: unitid,
        unitname: unitname,
        contenttype: contenttype,
        content: content,
        imagelist: imageList,
        sortkey: sortkey,
        libraryid: libraryid,
        maxattempt: maxattept,
        quizconfig: quizconfig,
        grade: grade,
        studytime: studytime,
        showfeedback: showfeedback,
        // allowdownload: allowdownload,
      };
    } else {
      param = {
        courseid: courseid,
        unitid: unitid,
        unitname: unitname,
        contenttype: contenttype,
        content: content,
        imagelist: imageList,
        sortkey: sortkey,
        libraryid: libraryid,
        maxattempt: maxattept,
        quizconfig: quizconfig,
        grade: grade,
        studytime: studytime,
        showfeedback: showfeedback,
      };
    }
    return this.httpRequest(url, param);
  }

  /**
   * @param courseid
   * @param unitid
   * @returns
   */
  getUnit(courseid: string, unitid: string) {
    const url = this.allinOneService.unitUrl + 'getunit';

    const userid = this.allinOneService.getUserId();
    const param = {
      courseid: courseid,
      unitid: unitid,
      usersyskey: userid,
    };
    return this.httpRequest(url, param);
  }

  /**
   */

  uploadUnitFile(filename: string, filetype: string, image: string) {
    const url = this.allinOneService.unitUrl + 'uploadfile';

    console.log(image);

    const param = {
      filetype: filetype,
      filename: filename,
      base64: image.toString().split(',')[1],
    };

    console.log(param);

    return this.httpRequest(url, param);
  }

  /**
   */

  deleteUnitUploadedFile(imageList: any) {
    const url = this.allinOneService.unitUrl + 'deletefile';

    const param = {
      filelist: imageList,
    };

    return this.httpRequest(url, param);
  }

  /**
   */
  deleteUnit(unitid: string, courseid: string) {
    const url = this.allinOneService.unitUrl + 'deleteunit';
    const param = {
      unitid: unitid,
      courseid: courseid,
    };

    return this.httpRequest(url, param);
  }

  /**
   * @param data - section id and sort key of all sections
   */

  sortSections(sections: [], courseid: any) {
    const url = this.allinOneService.sectionUrl + 'sortsections';
    const param = {
      data: sections,
      courseid: courseid,
    };
    return this.httpRequest(url, param);
  }

  /**
   * @param data - section id and sort key of all sections
   */

  sortQuestions(questions: [], courseid?: string) {
    const url = this.allinOneService.unitUrl + 'sortquestion';
    const param = {
      questionlist: questions,
      courseid: courseid ? courseid : '',
    };
    return this.httpRequest(url, param);
  }

  /**
   * @param data - subsection id and sort key of all subsections
   */

  sortSubSections(subsections: []) {
    const url = this.allinOneService.subsectionUrl + 'sortsubsections';

    const param = {
      data: subsections,
    };
    return this.httpRequest(url, param);
  }

  /**
   * @param previousSubsectionList - Previous Section container
   * @param currentSubsectionList - current section container
   * @param subsectionid - subsectionid that is dragged
   * @param secionid - sectionid that subsection is moved
   */
  subSectionDragAndDrop(
    previousSubsectionList: any[],
    currentSubsectionList: any[],
    subsectionid: string,
    sectionid: string,
    courseid: string
  ) {
    const url = this.allinOneService.unitUrl + 'updatedrag';
    const param = {
      oldsubsection: previousSubsectionList,
      newsubsection: currentSubsectionList,
      type: '1',
      sectionid: sectionid,
      subsectionid: subsectionid,
      courseid: courseid,
    };
    return this.httpRequest(url, param);
  }

  /**
   * @param previousUnitList - Previous Section container
   * @param currentUnitList - current section container
   * @param unitid - subsectionid that is dragged
   * @param subsectionid - sectionid that subsection is moved
   */
  unitDragAndDrop(
    previousUnitList: any[],
    currentUnitList: any[],
    unitid: string,
    subsectionid: string,
    courseid: string
  ) {
    const url = this.allinOneService.unitUrl + 'updatedrag';
    const param = {
      oldunit: previousUnitList,
      newunit: currentUnitList,
      type: '2',
      unitid: unitid,
      subsectionid: subsectionid,
      courseid: courseid,
    };
    return this.httpRequest(url, param);
  }

  /**
   * @param data - subsection id and sort key of all subsections
   */

  sortUnit(units: [], courseid: string) {
    const url = this.allinOneService.unitUrl + 'sortunits';

    const param = {
      data: units,
      courseid: courseid,
    };
    return this.httpRequest(url, param);
  }

  requestQR(requesturl: any) {
    const url = this.allinOneService.iamdomain + 'auth/requestqr';
    const param = {
      // "requesturl": requesturl,
      appid: 'kunyek',
      type: '0',
    };
    return this.httpRequestForLogin(url, param);
  }

  checkQR(requestqr: any) {
    const url = this.allinOneService.iamdomain + 'auth/checkqr';
    const param = {
      qr: requestqr,
      // "appid": "kunyek"
    };
    return this.httpRequestForLogin(url, param);
  }

  /**
   * @param courseid - Course id
   * @param instructorid - Insctructor
   */
  addCoInstructor(courseid: string, instructorid: string) {
    const url = this.allinOneService.courseUrl + 'addinstructor';
    const param = {
      courseid: courseid,
      instructorid: instructorid,
    };

    return this.httpRequest(url, param);
  }

  /**
   * @param courseid - Course id
   */
  getInstructors(courseid: string) {
    const url = this.allinOneService.courseUrl + 'getinstructors';
    const param = {
      courseid: courseid,
    };

    return this.httpRequest(url, param);
  }

  /**
   * @param id - primary key to be deleted
   */
  removeCoinstructor(id: string) {
    const url = this.allinOneService.courseUrl + 'deleteinstructor';
    const param = {
      id: id,
    };

    return this.httpRequest(url, param);
  }

  /**
   *
   * @param courseid
   * @param instructorid - primary instructor id
   * @param coinstructorpkey - co instructor primary key
   * @returns
   */
  transferCourseOwnership(
    courseid: string,
    instructorid: string,
    coinstructorpkey: string
  ) {
    const url = this.allinOneService.courseUrl + 'ownerchange';
    const param = {
      courseid: courseid,
      instructorid: instructorid,
      coinstructorpkey: coinstructorpkey,
    };

    return this.httpRequest(url, param);
  }

  /**
   * @param courseid - Course id
   */
  getAllClass(courseid: any) {
    const url = this.allinOneService.courseUrl + 'getclasscode';
    const param = {
      courseid: courseid,
    };
    return this.httpRequest(url, param);
  }

  /**
   * @param courseid - Course id
   */
  getPaidStudents(courseid: any) {
    const url = this.allinOneService.courseUrl + 'getstudentlist';
    const param = {
      courseid: courseid,
    };
    return this.httpRequest(url, param);
  }

  /**
   *
   * @param id
   * @param feedback
   * @returns
   */
  giveOverallFeedbacks(id: String, feedback: string, adduser: string) {
    const url = this.allinOneService.courseUrl + 'addstudentfeedback';
    const param = {
      adduser: adduser,
      id: id,
      feedback: feedback,
    };
    return this.httpRequest(url, param);
  }

  /**
   *
   * @param classid
   * @param sections
   * @returns
   */
  updateClassSection(classid: string, sections: string[]) {
    const url = this.allinOneService.courseUrl + 'updateclasssection';
    const param = {
      classid: classid,
      sections: sections,
    };
    return this.httpRequest(url, param);
  }

  updateStudentSection(courseid: string, sections: string[]) {
    // const url = this.allinOneService.courseUrl + 'updatestudentsection';
    const url = this.allinOneService.domain + 'test';
    const param = {
      courseid: courseid,
      sections: sections,
    };
    return this.httpRequest(url, param);
  }

  /**
   * @param classcode - Class Code
   * @param classname - Class Name
   * @param remark - Remark
   * @param status - Active or inactive
   * @param courseid - Course id for class to be created
   * @param kunyekchannelid
   */
  createClass(
    classcode: string,
    classname: string,
    remark: string,
    status: string,
    courseid: string,
    startdate: string,
    hidesections: boolean
    // kunyekchannelid: string
  ) {
    const url = this.allinOneService.courseUrl + 'addclasscode';
    const param = {
      classcode: classcode,
      classname: classname,
      remark: remark,
      classstatus: status,
      courseid: courseid,
      startdate: startdate,
      // kunyekchannelid: kunyekchannelid,
      kunyekchannelid: '',
      hidesections: hidesections,
    };

    return this.httpRequest(url, param);
  }

  /**
   * @param classid - class's autoid
   * @param classcode - Class Code
   * @param classname - Class Name
   * @param remark - Remark
   * @param status - Active or inactive
   * @param kunyekchannelid
   */
  updateClass(
    classid: string,
    classcode: string,
    classname: string,
    remark: string,
    status: string,
    startdate: string,
    hidesections: boolean
    // kunyekchannelid: string
  ) {
    const url = this.allinOneService.courseUrl + 'updateclasscode';
    const param = {
      classid: classid,
      classcode: classcode,
      classname: classname,
      remark: remark,
      classstatus: status,
      startdate: startdate,
      // kunyekchannelid: kunyekchannelid,
      kunyekchannelid: '',
      hidesections: hidesections,
    };

    return this.httpRequest(url, param);
  }

  /**
   *
   * @param classid
   * @returns
   */
  getClassSections(classid: string, courseid: string) {
    const url = this.allinOneService.courseUrl + 'getclasssection';
    const param = {
      classid: classid,
      courseid: courseid,
    };
    return this.httpRequest(url, param);
  }

  getStudentSections(courseid: string) {
    // const url = this.allinOneService.courseUrl + 'getstudentsection';
    const url = this.allinOneService.domain + 'test3';
    const param = {
      courseid: courseid,
    };
    return this.httpRequest(url, param);
  }

  /**
   * @param courseid - Class Code
   * @param userid - User id
   * @param classid - Class id
   * @param studentcode - Student ID that's added by user
   * @param studentname - Student name that's added by user
   * @param remark - Remark
   */

  createStudent(
    courseid: string,
    adduser: string,
    classid: string,
    studentcode: string,
    studentname: string,
    remark: string
  ) {
    const url = this.allinOneService.courseUrl + 'addstudentclass';

    const param = {
      courseid: courseid,
      adduser: adduser,
      classid: classid,
      studentcode: studentcode,
      studentname: studentname,
      remark: remark,
      status: '2',
    };
    return this.httpRequest(url, param);
  }

  createImportStudent(courseid: string, classid: string, data: any) {
    const url = this.allinOneService.courseUrl + 'addstudentclass';
    const param = {
      courseid: courseid,
      adduser: '',
      classid: classid,
      remark: '',
      studentcode: '',
      studentname: '',
      status: '1',
      userlist: data,
    };
    return this.httpRequest(url, param);
  }

  /**
   * @param studentid - User id
   * @param remark - Remark
   * @param remove - Remove
   * @param studentcode - Student ID that's added by user
   * @param studentname - Student name that's added by user
   */

  updateStudent(
    adduser: string,
    studentid: string,
    remove: any,
    studentcode: string,
    studentname: string,
    remark: string
  ) {
    const url = this.allinOneService.courseUrl + 'updatestudentclass';
    const param = {
      adduser: adduser,
      id: studentid,
      remove: remove,
      studentcode: studentcode,
      studentname: studentname,
      remark: remark,
    };
    return this.httpRequest(url, param);
  }
  /**
   * @param classid - Class id
   */
  getstudentclass(classid: string, courseid: any) {
    const url = this.allinOneService.courseUrl + 'getstudentclass';
    const param = {
      classid: classid,
      courseid: courseid,
    };
    return this.httpRequest(url, param);
  }

  /**
* @param classid - Class id
* @param courseid - Course id

*/

  getInstructorDropboxList(classid: any, courseid: any) {
    const url = this.allinOneService.courseUrl + 'getinstructordropboxlist';
    const param = {
      courseid: courseid,
      classid: classid,
    };
    return this.httpRequest(url, param);
  }

  gradeAssignment(classdbid: string, grade: string, feedback: string) {
    const url = this.allinOneService.courseUrl + 'updategradeassignment';
    const param = {
      classdbid: classdbid,
      grade: grade,
      feedback: feedback,
    };
    return this.httpRequest(url, param);
  }

  /**
   *
   * @param type
   * @param id
   * @param score
   * @returns
   */
  updateGradeResult(type: number, id: string, score: string) {
    const url = this.allinOneService.courseUrl + 'updatescore';
    const param = {
      type: type,
      id: id,
      score: score,
    };
    return this.httpRequest(url, param);
  }

  getSection(courseid: any) {
    const url = this.allinOneService.courseUrl + 'getsections';
    const param = {
      courseid: courseid,
    };
    return this.httpRequest(url, param);
  }

  checkToken() {
    const url = this.allinOneService.iamdomain + 'auth/checktoken';
    const atoken = this.allinOneService.getAtoken();
    const appid = this.allinOneService.getAppId();
    const kunyek_userid = this.allinOneService.getKunyekUserId();

    const param = {
      userid: kunyek_userid,
      appid: appid,
      atoken: atoken,
    };

    // {
    //   "returncode": "3" // 0 = valid, 1 = invalid, 3 = error
    //  }

    return this.httpRequestForLogin(url, param);
  }

  renewToken() {
    const url = this.allinOneService.iamdomain + 'auth/renewtoken';
    const param = {};
    return this.httpRequest(url, param);
  }

  /**
   *
   * @param courseid
   * @param userlist
   * @returns
   */
  checkStudentClasList(courseid: any, userlist: any, classid: string) {
    const url = this.allinOneService.courseUrl + 'checkstudentclasslist';
    const param = {
      courseid: courseid,
      userlist: userlist,
      classid: classid,
    };
    return this.httpRequest(url, param);
  }

  /**
   *
   * @param filepath - File path of the current folder
   * @param fileid
   */
  getLibraryFile(filepath: string, fileid: string) {
    const url = this.allinOneService.userUrl + 'getlibrary';
    const usersyskey = this.allinOneService.getUserId();
    const param = {
      filepath: filepath,
      fileid: fileid,
      usersyskey: usersyskey,
    };
    return this.httpRequest(url, param);
  }

  /**
   * @param filepath
   * @param autoid
   * @returns
   */
  getSharedFile(filepath: string, autoid: string) {
    const url = this.allinOneService.userUrl + 'getsharedlibrary';
    const usersyskey = this.allinOneService.getUserId();
    const param = {
      filepath: filepath,
      autoid: autoid,
      // fileid : fileid,
      usersyskey: usersyskey,
    };
    return this.httpRequest(url, param);
  }

  /**
   *
   * @param sharesyskey - Usersyskey of the shared user
   * @param path - shared file path
   * @param type - 0 - owner, 1 - shared
   */
  addLibrary(sharesyskey: string, path: string, type: string) {
    const url = this.allinOneService.userUrl + 'addlibrary';
    const usersyskey = this.allinOneService.getUserId();
    const param = {
      usersyskey: usersyskey,
      path: path,
      type: type,
      sharesyskey: sharesyskey,
    };
    return this.httpRequest(url, param);
  }

  /**
   * @param filepatharray - Array of file name list
   * @returns
   */
  deleteLibraryFile(id: any) {
    const url = this.allinOneService.userUrl + 'deletelibraryfile';
    const param = {
      id: id,
    };
    return this.httpRequest(url, param);
  }

  /**
   *
   * @param fileid
   * @param filename
   * @param uploadfilename
   * @param filedescription
   * @param filepath
   * @param filesize
   * @param filetype
   * @returns
   */
  addFileToLibrary(
    fileid: string,
    filename: string,
    uploadfilename: string,
    filedescription: string,
    filepath: string,
    filesize: number,
    filetype: string
  ) {
    const url = this.allinOneService.userUrl + 'addlibrary';
    const usersyskey = this.allinOneService.getUserId();
    const param = {
      fileid: fileid,
      filename: filename,
      uploadfilename: uploadfilename,
      filedescription: filedescription,
      filepath: filepath,
      filesize: filesize,
      filetype: filetype,
      ownersyskey: usersyskey,
      usersyskey: usersyskey,
      sharetype: '0',
    };
    return this.httpRequest(url, param);
  }

  /**
   *
   * @param id
   * @param fileid
   * @param filename
   * @param uploadfilename
   * @param filedescription
   * @param filepath
   * @param filesize
   * @param filetype
   * @returns
   */
  editLibraryFile(
    id: string,
    fileid: string,
    filename: string,
    uploadfilename: string,
    filedescription: string,
    filepath: string,
    filesize: number,
    filetype: string
  ) {
    const url = this.allinOneService.userUrl + 'editlibrary';
    const usersyskey = this.allinOneService.getUserId();
    const param = {
      id: id,
      fileid: fileid,
      filename: filename,
      uploadfilename: uploadfilename,
      filedescription: filedescription,
      filepath: filepath,
      filesize: filesize,
      filetype: filetype,
      // ownersyskey: usersyskey,
      // usersyskey: usersyskey,
      // sharetype: '0',
    };
    return this.httpRequest(url, param);
  }

  /**
   *
   * @param id

   */

  getShareUser(id: any) {
    const url = this.allinOneService.userUrl + 'getshareduser';
    const usersyskey = this.allinOneService.getUserId();
    const param = {
      id: id,
      usersyskey: usersyskey,
    };
    return this.httpRequest(url, param);
  }
  /**
   *
   * @param id
   * @param shareuserid
   */

  shareLibrary(data: any) {
    const url = this.allinOneService.userUrl + 'sharelibrary';
    const param = {
      id: data.id,
      shareuserid: data.shareuserid,
    };
    return this.httpRequest(url, param);
  }

  /**
   *
   * @param id
   */

  getShareInfo(id: any) {
    const url = this.allinOneService.userUrl + 'getsharedinfo';

    const param = {
      id: id,
    };
    return this.httpRequest(url, param);
  }

  /**
   *
   * @param id
   * @param shareduserid
   */

  deleteShareUser(id: any, shareduserid: any) {
    const url = this.allinOneService.userUrl + 'deleteshareduser';

    const param = {
      id: id,
      shareduserid: shareduserid,
    };

    return this.httpRequest(url, param);
  }

  /**
   * @param courseid
   * @param unitid
   * @returns
   */
  getQuestionsByUnit(courseid: string, unitid: string) {
    const url = this.allinOneService.unitUrl + 'getallquestions';
    const param = {
      courseid: courseid,
      unitid: unitid,
    };
    return this.httpRequest(url, param);
  }

  /**
   * @param courseid
   * @param unitid
   * @param templateid
   * @returns
   */
  getQuestionsByTemplate(courseid: string, unitid: string, templateid: string) {
    const url = this.allinOneService.unitUrl + 'getallquestions';
    const param = {
      courseid: courseid,
      unitid: unitid,
      templateid: templateid,
    };
    return this.httpRequest(url, param);
  }

  /**
   *
   * @param unitid
   * @param courseid
   * @param question
   * @param answerlist
   * @param type
   * @param sortkey
   * @param casesensitive
   * @returns
   */
  addQuestion(
    unitid: string,
    courseid: string,
    question: string,
    answerlist: any[],
    correctanswer: string[],
    type: string,
    sortkey: string,
    casesensitive: string,
    feedback: boolean = false,
    imagelist: any[] = [],
    countbyquantity: boolean = false,
    fixedsequence: boolean = false,
    mark: number = 1
  ) {
    const url = this.allinOneService.unitUrl + 'addquestion';
    const param =
      type == '004'
        ? {
            unitid: feedback ? '' : unitid,
            templateid: feedback ? unitid : '',
            courseid: courseid,
            question: question,
            answerlist: answerlist,
            type: type,
            sortkey: sortkey,
            correctanswer: correctanswer,
            casesensitive: casesensitive,
            imagelist: imagelist,
            countbyquantity: countbyquantity,
            fixedsequence: false,
            mark: mark,
          }
        : {
            unitid: feedback ? '' : unitid,
            templateid: feedback ? unitid : '',
            courseid: courseid,
            question: question,
            answerlist: answerlist,
            type: type,
            sortkey: sortkey,
            correctanswer: correctanswer,
            casesensitive: casesensitive,
            imagelist: imagelist,
            fixedsequence: fixedsequence,
            mark: mark,
          };
    console.log(param);
    return this.httpRequest(url, param);
  }

  /**
   *
   * @param quizid
   * @param question
   * @param answerlist
   * @param correctanswer
   * @param type
   * @param sortkey
   * @param casesensitive
   * @returns
   */
  updateQuestion(
    quizid: string,
    question: string,
    answerlist: any[],
    correctanswer: string[],
    type: string,
    sortkey: string,
    casesensitive: string,
    courseid: string,
    imagelist: any = [],
    removeimagelist: any = [],
    countbyquantity: boolean = false,
    fixedsequence: boolean = false,
    mark: number = 1
  ) {
    const url = this.allinOneService.unitUrl + 'updatequestion';
    const param =
      type == '004'
        ? {
            quizid: quizid,
            question: question,
            answerlist: answerlist,
            type: type,
            sortkey: sortkey,
            correctanswer: correctanswer,
            casesensitive: casesensitive,
            courseid: courseid,
            imagelist: imagelist,
            removeimagelist: removeimagelist,
            countbyquantity: countbyquantity,
            fixedsequence: false,
            mark: mark,
          }
        : {
            quizid: quizid,
            question: question,
            answerlist: answerlist,
            type: type,
            sortkey: sortkey,
            correctanswer: correctanswer,
            casesensitive: casesensitive,
            courseid: courseid,
            imagelist: imagelist,
            removeimagelist: removeimagelist,
            fixedsequence: fixedsequence,
            mark: mark,
          };
    return this.httpRequest(url, param);
  }

  /**
   *
   * @param quizid
   */
  deleteQuestion(quizid: string, courseid: string) {
    const url = this.allinOneService.unitUrl + 'deletequestion';
    const param = {
      quizid: quizid,
      courseid: courseid,
    };
    return this.httpRequest(url, param);
  }

  getInstructorProfiles() {
    const url = this.allinOneService.userUrl + 'getinstructorprofile';
    const userid = this.allinOneService.getUserId();
    const param = {
      usersyskey: userid,
    };
    return this.httpRequest(url, param);
  }

  /**
   *
   * @param name
   * @param institute
   * @param description
   * @param profileimage
   * @returns
   */
  createInstructorProfile(
    name: string,
    institute: string,
    description: string,
    profileimage: string
  ) {
    const url = this.allinOneService.userUrl + 'addinstructorprofile';
    const userid = this.allinOneService.getUserId();
    const param = {
      usersyskey: userid,
      name: name,
      institute: institute,
      description: description,
      profileimage: profileimage,
    };
    return this.httpRequest(url, param);
  }

  /**
   *
   * @param profileid
   * @param name
   * @param institute
   * @param description
   * @param profileimage
   * @returns
   */
  updateInstructorProfile(
    profileid: string,
    name: string,
    institute: string,
    description: string,
    profileimage: string
  ) {
    const url = this.allinOneService.userUrl + 'updateinstructorprofile';
    const userid = this.allinOneService.getUserId();
    const param = {
      profileid: profileid,
      usersyskey: userid,
      name: name,
      institute: institute,
      description: description,
      profileimage: profileimage,
    };
    return this.httpRequest(url, param);
  }

  /**
   *
   * @param profileid
   * @param courseid
   * @returns
   */
  changeCourseProfile(profileid: string, courseid: string) {
    const url = this.allinOneService.courseUrl + 'addcourseprofile';
    const param = {
      profileid: profileid,
      courseid: courseid,
    };
    return this.httpRequest(url, param);
  }

  getPrerequities(courseid: string) {
    const url = this.allinOneService.courseUrl + 'getprerequisites';
    const param = {
      courseid: courseid,
    };
    return this.httpRequest(url, param);
  }

  replyDiscussion(data: any) {
    const url = this.allinOneService.kunyekdomain + 'message/sent';
    return this.httpRequest(url, data);
  }

  /**
   *
   * @param unitid
   * @param courseid
   * @param end Sort Key/date time of last message
   * @param count Number of discssuion to get
   * @param parentsyskey Parent discussion syskey
   * @returns
   */
  getDiscussion(
    unitid: string,
    courseid: string,
    end: string,
    count: number,
    parentsyskey: string
  ) {
    const url = this.allinOneService.unitUrl + 'getdiscussion';
    const param = {
      unitid: unitid,
      courseid: courseid,
      end: end,
      count: count,
      parentsyskey: parentsyskey,
    };
    return this.httpRequest(url, param);
  }

  /**
   *
   * @param courseid Course ID
   * @param unitid Unit ID of discussion unit
   * @param msgsyskey Reply message syskey
   * @returns
   */
  deleteDiscussionReply(courseid: string, unitid: string, msgsyskey: string) {
    const url = this.allinOneService.unitUrl + 'deletediscussionmsg';
    const param = {
      courseid,
      unitid,
      msgsyskey,
    };
    return this.httpRequest(url, param);
  }

  /**
   *
   * @param courseid
   * @param type
   * @returns
   */
  getGradebook(courseid: string, type: string, classid: string = '') {
    const url = this.allinOneService.courseUrl + 'getgradebook';
    const param = {
      courseid: courseid,
      type: type,
      classid: classid,
    };
    return this.httpRequest(url, param);
  }

  /**
   *
   * @param channelid
   * @returns
   */
  getCategoryAnalytic(channelid: string, daterange: any) {
    const url = this.allinOneService.domain + 'getcategoryanalytics';
    const param = {
      channelid: channelid,
      daterange: daterange,
    };
    return this.httpRequest(url, param);
  }

  /**
   *
   * @param channelid
   * @param categoryid
   * @param type | 001- all coruses , 002 - courses by a particular instructor
   * @returns
   */
  getCoursesByChannel(
    channelid: string,
    categoryid: string,
    type: string = '001'
  ) {
    const url = this.allinOneService.courseUrl + 'getcoursebycategory';
    const userid = this.allinOneService.getUserId();
    const param = {
      channelid: channelid,
      categoryid: categoryid,
      type: type,
      usersyskey: userid,
    };
    return this.httpRequest(url, param);
  }

  /**
   *
   * @param courseid
   * @param startdate
   * @param enddate
   * @param starttime
   * @param endtime
   * @returns
   */
  updateSchedule(
    courseid: string,
    startdate: string,
    enddate: string,
    starttime: string,
    endtime: string
  ) {
    const url = this.allinOneService.courseUrl + 'updateschedule';
    const param = {
      courseid: courseid,
      startdate: startdate,
      enddate: enddate,
      starttime: starttime,
      endtime: endtime,
    };
    return this.httpRequest(url, param);
  }

  /**
   *
   * @param channelid
   * @returns
   */
  getInsctructorledCourse(channelid: string) {
    const url = this.allinOneService.courseUrl + 'getinstructorledcourse';
    const param = {
      channelid: channelid,
    };
    return this.httpRequest(url, param);
  }

  /**
   *
   * @param channelid
   * @returns
   */
  getPrerequisiteCourses(channelid: string) {
    const url = this.allinOneService.courseUrl + 'getprerequisitecourse';
    const param = {
      channelid: channelid,
    };
    return this.httpRequest(url, param);
  }

  /**
   *
   * @param courseid
   * @param prerequisites
   * @returns
   */
  addPrerequisiteCourses(courseid: string, prerequisites: string[]) {
    const url = this.allinOneService.courseUrl + 'addprerequisites';
    const param = {
      courseid: courseid,
      prerequisites: prerequisites,
    };
    return this.httpRequest(url, param);
  }

  getTemplates(channelid: string) {
    const url = this.allinOneService.adminUrl + 'gettemplate';
    const param = {
      channelid: channelid,
    };
    return this.httpRequest(url, param);
  }

  getTemplatesbyInstructor(channelid: string) {
    const url = this.allinOneService.courseUrl + 'gettemplatebyinstructor';
    const param = {
      channelid: channelid,
    };
    return this.httpRequest(url, param);
  }

  /**
   *
   * @param rawjson
   * @param backgroundimage
   * @param padding
   * @param previewimage
   */
  addTemplate(
    rawjson: any[],
    backgroundimage: string,
    padding: string,
    previewimage: string,
    channelid: string
  ) {
    const url = this.allinOneService.adminUrl + 'addtemplate';
    const param = {
      rawjson: rawjson,
      backgroundimage: backgroundimage,
      padding: padding,
      previewimage: previewimage,
      channelid: channelid,
    };
    return this.httpRequest(url, param);
  }

  /**
   *
   * @param templateid
   * @param rawjson
   * @param backgroundimage
   * @param padding
   * @param previewimage
   * @returns
   */
  editTemplate(
    templateid: string,
    rawjson: any[],
    backgroundimage: string,
    padding: string,
    previewimage: string,
    channelid: string
  ) {
    const url = this.allinOneService.adminUrl + 'updatetemplate';
    const param = {
      templateid: templateid,
      rawjson: rawjson,
      backgroundimage: backgroundimage,
      padding: padding,
      previewimage: previewimage,
      channelid: channelid,
    };
    return this.httpRequest(url, param);
  }

  getOrganizations() {
    const url = this.allinOneService.adminUrl + 'getorganization';
    return this.httpRequest(url, {});
  }

  getPolicy() {
    const url = this.allinOneService.domain + 'getpolicy';
    return this.httpRequest(url, {});
  }

  checkDomain() {
    const url = this.allinOneService.domain + 'checkdomain';
    return this.httpRequest(url, {});
  }

  /**
   *
   * @param channelid
   * @returns
   */
  getType(channelid: string) {
    const url = this.allinOneService.channelUrl + 'gettype';
    const param = {
      channelid: channelid,
    };
    return this.httpRequest(url, param);
  }

  /**
   *
   * @param channelid
   */
  getOrgMember(channelid: string) {
    const url = this.allinOneService.channelUrl + 'getorgmember';
    const param = {
      channelid: channelid,
    };
    return this.httpRequest(url, param);
  }

  /**
   *
   * @param channelid
   * @returns
   */
  getOrgMemberForAnalytic(channelid: string) {
    const url = this.allinOneService.channelUrl + 'getorgmemberbyadmin';
    const param = {
      channelid: channelid,
    };
    return this.httpRequest(url, param);
  }

  /**
   *
   * @param startdate
   * @param enddate
   * @param channelid
   * @param courseid
   * @returns
   */
  getInstructorAnalytic(
    startdate: string,
    enddate: string,
    channelid: string,
    courseid: string
  ) {
    const url = this.allinOneService.courseUrl + 'getanalyticswithinstructor';
    const param = {
      startdate: startdate,
      enddate: enddate,
      channelid: channelid,
      courseid: courseid,
    };
    return this.httpRequest(url, param);
  }

  /**
   *
   * @param courseid
   * @param type
   * @param mandatorydata
   * @returns
   */
  updateMandatoryInfo(courseid: string, type: string, mandatorydata: string[]) {
    const url = this.allinOneService.courseUrl + 'addmandatoryinfo';
    const param = {
      courseid: courseid,
      type: type,
      mendatorydata: mandatorydata,
    };
    return this.httpRequest(url, param);
  }

  /**
   *
   * @param courseid
   * @returns
   */
  getMandatoryInfo(courseid: string) {
    const url = this.allinOneService.courseUrl + 'getmandatoryinfo';
    const param = {
      courseid: courseid,
    };
    return this.httpRequest(url, param);
  }

  /**
   *
   * @param courseid
   * @returns
   */
  getFeedbackData(courseid: string) {
    const url = this.allinOneService.courseUrl + 'getfeedbackresult';
    const param = {
      courseid: courseid,
    };
    return this.httpRequest(url, param);
  }

  /**
   *
   * @param channelid
   * @param department
   * @param post
   * @return
   */
  getCourseAnalytic(channelid: string, department: string, post: string) {
    const url = this.allinOneService.channelUrl + 'getorganalytics';
    const param = {
      channelid: channelid,
      categoryid: '',
      division: '',
      department: department,
      branch: '',
      post: post,
    };
    return this.httpRequest(url, param);
  }

  /**
   *
   * @param courseid
   * @returns
   */
  getKunyekChannels(courseid: string) {
    const url = this.allinOneService.courseUrl + 'getkunyekchannel';
    const param = {
      courseid: courseid,
    };
    return this.httpRequest(url, param);
  }

  /**
   *
   * @param channelid
   * @returns
   */
  getFeedbackTemplate(channelid: string) {
    const url = this.allinOneService.adminUrl + 'getfeedbacktemplate';
    const param = {
      channelid: channelid,
    };
    return this.httpRequest(url, param);
  }

  /**
   *
   * @param name
   * @param description
   * @param channelid
   * @returns
   */
  createFeedbackTemplate(name: string, description: string, channelid: string) {
    const url = this.allinOneService.adminUrl + 'addfeedbacktemplate';
    const param = {
      name: name,
      description: description,
      channelid: channelid,
    };
    return this.httpRequest(url, param);
  }

  /**
   *
   * @param templateid
   * @param name
   * @param description
   * @param channelid
   * @returns
   */
  editFeedbackTemplate(
    templateid: string,
    name: string,
    description: string,
    channelid: string
  ) {
    const url = this.allinOneService.adminUrl + 'updatefeedbacktemplate';
    const param = {
      fbtmpid: templateid,
      name: name,
      description: description,
      channelid: channelid,
    };
    return this.httpRequest(url, param);
  }

  /**
   *
   * @param templateid
   * @returns
   */
  deleteFeedbackTemplate(templateid: string) {
    const url = this.allinOneService.adminUrl + 'deletefeedbacktemplate';
    const param = {
      fbtmpid: templateid,
    };
    return this.httpRequest(url, param);
  }

  /**
   *
   * @param templateid
   * @param searchuser
   * @returns
   */
  getFeedbackAnalyticsDetails(courseid: string, searchuser: string) {
    const url = this.allinOneService.channelUrl + 'getfeedbackanalyticsdetails';
    const param = {
      courseid: courseid,
      channelid: '',
      searchuser: searchuser,
    };
    return this.httpRequest(url, param);
  }

  /**
   *
   * @param channelid
   * @returns
   */
  getFeedbackAnalytics(channelid: string) {
    const url = this.allinOneService.channelUrl + 'getfeedbackanalytics';
    const param = {
      channelid: channelid,
    };
    return this.httpRequest(url, param);
  }

  /**
   *
   * @param channelid
   * @returns
   */
  getCustomSchedule(channelid: string) {
    const url = this.allinOneService.channelUrl + 'customschedule/get';
    const param = {
      channelid: channelid,
    };
    return this.httpRequest(url, param);
  }

  /**
   *
   * @param channelid
   * @param scheduleid
   * @returns
   */
  deleteCustomSchedule(channelid: string, scheduleid: string) {
    const url = this.allinOneService.channelUrl + 'customschedule/delete';
    const param = {
      channelid: channelid,
      scheduleid: scheduleid,
    };
    return this.httpRequest(url, param);
  }

  addCustomSchedule(data: any) {
    const url = this.allinOneService.channelUrl + 'customschedule/add';
    return this.httpRequest(url, data);
  }

  updateCustomSchedule(data: any) {
    const url = this.allinOneService.channelUrl + 'customschedule/update';
    return this.httpRequest(url, data);
  }

  getMembertype(data: any) {
    const url = this.allinOneService.channelUrl + 'getmembertype';
    return this.httpRequest(url, data);
  }

  addmembertype(data: any) {
    const url = this.allinOneService.channelUrl + 'addmembertype';
    return this.httpRequest(url, data);
  }

  updatemembertype(data: any) {
    const url = this.allinOneService.channelUrl + 'updatemembertype';
    return this.httpRequest(url, data);
  }

  // Member Excel Import
  getImportStatus(data: any) {
    const url = this.allinOneService.domain + 'getimportstatus';
    return this.httpRequest(url, data);
  }

  addMemberImport(data: any) {
    // const url = this.allinOneService.domain + 'checkimportchannelmember' ;
    const url = this.allinOneService.channelUrl + 'checkimportchannelmember';
    return this.httpRequest(url, data);
  }

  updateMemberImport(data: any) {
    const url = this.allinOneService.domain + 'updateimportstatus';
    return this.httpRequest(url, data);
  }

  importquestions(data: any) {
    const url = this.allinOneService.domain + 'unit/importquestions';
    return this.httpRequest(url, data);
  }

  getStudentForAnalytic(channelid: string) {
    const url = this.allinOneService.domain + 'getstudentsbypublicchannel';
    const param = {
      channelid: channelid,
    };
    return this.httpRequest(url, param);
  }

  deleteFeedBack(courseid: any, userid: any) {
    const url = this.allinOneService.courseUrl + 'deletestudentfeedback';
    const param = {
      courseid: courseid,
      studentuserid: userid,
    };
    return this.httpRequest(url, param);
  }

  // grade book total details
  getGrades(courseid: string, userid: string) {
    const url = this.allinOneService.courseUrl + 'getgrade';
    const param = {
      courseid: courseid,
      searchuser: userid,
      isFromInstructor: true,
    };
    return this.httpRequest(url, param);
  }

  publicChanges(data: any) {
    const url = this.allinOneService.courseUrl + 'publishchanges';
    return this.httpRequest(url, data);
  }

  getClassQr(data: any) {
    const url = this.allinOneService.domain + 'class/getqr';
    return this.httpRequest(url, data);
  }

  addAssignmentDeadline(data: any) {
    const url = this.allinOneService.courseUrl + 'adddeadline';
    return this.httpRequest(url, data);
  }

  updateAssignmentDeadline(data: any) {
    const url = this.allinOneService.courseUrl + 'updatedeadline';
    return this.httpRequest(url, data);
  }

  deleteAssignmentDeadline(data: any) {
    const url = this.allinOneService.courseUrl + 'deletedeadline';
    return this.httpRequest(url, data);
  }

  getAssignmentDeadline(data: any) {
    const url = this.allinOneService.courseUrl + 'getdeadlines';
    return this.httpRequest(url, data);
  }

  getNotification(data: any) {
    const url = this.allinOneService.domain + 'class/getnotifications';
    return this.httpRequest(url, data);
  }

  addNotification(data: any) {
    const url = this.allinOneService.domain + 'class/addnotification';
    return this.httpRequest(url, data);
  }

  // Dropbox import
  assignmentImport(data: any) {
    const url = this.allinOneService.domain + 'class/assignmentimport';
    return this.httpRequest(url, data);
  }

  // Learning path user import
  learningPathUserImport(data: any) {
    // https://api1.khub.omnicloudapi.com/learningpath/checkimportuser
    const url = this.allinOneService.domain + 'learningpath/checkimportuser';
    return this.httpRequest(url, data);
  }

  quizQuestionImport(data: any) {
    const url = this.allinOneService.domain + 'unit/checkimportquiz';
    return this.httpRequest(url, data);
  }

  downloadAssignment(data: any) {
    const url = this.allinOneService.domain + 'class/downloadassignment';
    return this.httpRequest(url, data);
  }

  // Video tracking
  getVideoTracking(data: any) {
    const url = this.allinOneService.unitUrl + 'getvideotracking';
    return this.httpRequest(url, data);
  }

  // socket connection update
  updateConnectionID(data: any) {
    const url = 'https://api1.khub.omnicloudapi.com/updateconnectionid';
    return this.httpRequest(url, data);
  }

  // Duplicate Course
  duplicatCourse(data: any) {
    const url = this.allinOneService.courseUrl + 'duplicatecourse';
    return this.httpRequest(url, data);
  }

  // Duplicate Content
  duplicatCourseContent(data: any) {
    const url = this.allinOneService.courseUrl + 'duplicatecoursecontent';
    return this.httpRequest(url, data);
  }

  // Course section schedule
  addCourseSectionSchedule(data: any) {
    const url = this.allinOneService.domain + 'class/createscheduler';
    return this.httpRequest(url, data);
  }

  // Learning Track
  addLearningTrack(data: any) {
    const url = this.allinOneService.domain + 'addlearningpath';
    return this.httpRequest(url, data);
  }

  updateLearningTrack(data: any) {
    const url = this.allinOneService.domain + 'updatelearningpath';
    return this.httpRequest(url, data);
  }

  getLearningTrack(data: any) {
    const url = this.allinOneService.domain + 'getlearningpath';
    return this.httpRequest(url, data);
  }

  deleteLT(data: any) {
    const url = this.allinOneService.domain + 'learningpath/delete';
    return this.httpRequest(url, data);
  }

  getLTUser(data: any) {
    const url = this.allinOneService.domain + 'learningpath/getuser';
    return this.httpRequest(url, data);
  }

  addLTUser(data: any) {
    const url = this.allinOneService.domain + 'learningpath/adduser';
    return this.httpRequest(url, data);
  }

  updateLTUser(data: any) {
    const url = this.allinOneService.domain + 'learningpath/updateuser';
    return this.httpRequest(url, data);
  }

  deleteLTUser(data: any) {
    const url = this.allinOneService.domain + 'learningpath/deleteuser';
    return this.httpRequest(url, data);
  }

  gradebookResetQuiz(data: any) {
    const url = this.allinOneService.unitUrl + 'deletequizresult';
    return this.httpRequest(url, data);
  }

  getAttendanceDetails(data: any) {
    const url = this.allinOneService.domain + 'class/getattendancedetails';
    return this.httpRequest(url, data);
  }

  getCategoryListWithCounts(data: any) {
    const url = this.allinOneService.categoryUrl + 'getcategorywithcounts';
    return this.httpRequest(url, data);
  }

  // Student API------------------------------------------------------------------------------------------------------------------
  getPreviewCourses(courseid: string, preview?: string) {
    const url = this.allinOneService.courseUrl + 'getcoursedata';
    const usersyskey = this.allinOneService.getUserId();
    const param = {
      courseid: courseid,
      usersyskey: usersyskey,
      status: '0',
      preview: preview ? preview : '',
    };

    return this.httpRequest(url, param);
  }

  getQuizQuestion(
    unit_id: any,
    resultid: any,
    courseid: string,
    sectionid: string,
    subsectionid: string
  ) {
    // const url = this.allinOneService.unitUrl + 'getquizquestion';
    const url = this.allinOneService.unitUrl + 'getquizquestions';
    const usersyskey = this.allinOneService.getUserId();
    const param = {
      unitid: unit_id,
      usersyskey: usersyskey,
      resultid: resultid,
      courseid: courseid,
      sectionid: sectionid,
      subsectionid: subsectionid,
    };
    return this.httpRequest(url, param);
  }

  getQuizresult(unit_id: any, courseid: string) {
    const url = this.allinOneService.unitUrl + 'getquizresult';
    const usersyskey = this.allinOneService.getUserId();
    const param = {
      unitid: unit_id,
      usersyskey: usersyskey,
      courseid: courseid,
    };
    return this.httpRequest(url, param);
  }

  getFeedback(
    unit_id: any,
    resultid: any,
    courseid: string,
    studentid: string
  ) {
    const url = this.allinOneService.unitUrl + 'getquizfeedback';
    // const url = 'https://rwm99kh7ta.execute-api.ap-southeast-1.amazonaws.com/Stage/test2';
    const usersyskey = this.allinOneService.getUserId();
    const param = {
      unitid: unit_id,
      studentid: studentid,
      usersyskey: usersyskey,
      resultid: resultid,
      courseid: courseid,
    };
    return this.httpRequest(url, param);
  }

  submitQuiz(
    unit_id: any,
    courseid: any,
    answerlist: any[],
    templateid: string
  ) {
    const url = this.allinOneService.unitUrl + 'addquizresult';
    // const url = 'https://rwm99kh7ta.execute-api.ap-southeast-1.amazonaws.com/Stage/test2';

    const usersyskey = this.allinOneService.getUserId();
    const param = {
      unitid: unit_id,
      courseid: courseid,
      usersyskey: usersyskey,
      answerlist: answerlist,
      templateid: templateid,
    };
    return this.httpRequest(url, param);
  }
  addQuizCache(data: any) {
    const url = this.allinOneService.unitUrl + 'addquizcache';
    return this.httpRequest(url, data);
  }
  fetchHttpRequest(url: string, param: any) {
    const appid = this.allinOneService.getAppId();
    var userid = this.allinOneService.getKunyekUserId();
    const atoken = this.allinOneService.getAtoken();

    const options = {
      headers: new HttpHeaders({
        Accept: 'text/html,application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${atoken}`,
      }),
    };

    const body = Object.assign(param, {
      appid: appid,
      userid: userid,
      atoken: atoken,
    });

    fetch(url, {
      keepalive: true,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${atoken}`,
      },
      body: JSON.stringify(body),
    });
  }
  addQuizCacheOnClose(data: any) {
    const url = this.allinOneService.unitUrl + 'addquizcache';
    this.fetchHttpRequest(url, data);
  }

  // Student API End------------------------------------------------------------------------------------------------------------------

  convertFileToBase64(file: File): Promise<string | ArrayBuffer | null> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result); // Resolve the promise with the Base64 string
      };
      reader.onerror = (error) => {
        reject('Error converting file to Base64: ' + error); // Reject the promise on error
      };
      reader.readAsDataURL(file); // Start reading the file as Base64 string
    });
  }
  maxFileSize = 5 * 1024 * 1024;
  uploadFile(file: File, name: string, path: string): Observable<any> {
    const url =
      'https://rwm99kh7ta.execute-api.ap-southeast-1.amazonaws.com/Stage/test2';

    return new Observable((observer) => {
      if (file.size > this.maxFileSize) {
        this.messageService.openSnackBar(
          'File size exceeds the 5 MB limit.',
          'warn'
        );
      } else {
        this.convertFileToBase64(file)
          .then((base64) => {
            const param = {
              base64: base64,
              filename: name,
              filetype: file.type,
              filepath: path,
              buckname: 'elearningbucket',
            };

            this.http.post(url, param).subscribe(
              (res: any) => {
                if (res.returncode == '200') {
                  this.messageService.openSnackBar(
                    'Error while uploading your file [Server Error]',
                    'warn'
                  ); // Handle error
                } else {
                  observer.next(res); // Emit response to subscriber
                  observer.complete();
                }
                // Complete the observable
              },
              (error) => {
                observer.error(error); // Emit error to subscriber
              }
            );
          })
          .catch((error) => {
            observer.error(error); // Emit error to subscriber
          });
      }
    });
  }
}
