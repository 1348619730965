import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class MessageService {

  constructor(private _snackBar: MatSnackBar) { }

  /**
   * @param type - type of message used in class ["success", 'warn]
   */
  openSnackBar(message: string, type: string) {
    this._snackBar.open(message, '', {
      horizontalPosition: 'center',
      verticalPosition: 'top',
      panelClass: ['snackbar', 'snackbar-' + type],
      duration: 5000
    });
  }

  openSnackBarWithAction(message: string, type: string, action: string) {
    this._snackBar.open(message, action, {
      horizontalPosition: 'center',
      verticalPosition: 'top',
      panelClass: ['snackbar', 'snackbar-' + type],
    });
  }
}
