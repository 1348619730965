import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { setTime } from 'ngx-bootstrap/chronos/utils/date-setters';

@Component({
  selector: 'app-pdf-viewer',
  templateUrl: './pdf-viewer.component.html',
  styleUrls: ['./pdf-viewer.component.scss'],
})
export class PdfViewerComponent implements OnInit {
  filename: string = '';
  url: string = '';
  isLoading: boolean = true;
  

  constructor(
    public dialogRef: MatDialogRef<PdfViewerComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.url = data.url;
    this.filename = data.filename;
    
  }

  ngOnInit(): void {
    console.log(this.url);
    setTimeout(() => {
      this.isLoading = false;
    }, 1000);
  }

  close(){
    this.dialogRef.close();
  }
}
