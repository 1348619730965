<div class="d-flex">
    <h3 class="me-auto">Filters</h3>

    <h3 style="cursor: pointer;" (click)="close()"><img src="assets/images/refine-close.png" style="height: 20px;width:20px" alt=""></h3>
</div>

<hr>

<!-- <div class="form-group  chl">
    <label for="channels" class="mb-2">Channels</label>
    <select class="form-select custom-dropdown" id="channels" name="channels" [(ngModel)]="channelid"> 
        <option value="all">All</option>
        <option *ngFor = "let chls of channels" [value] = "chls.channelid"  [selected]="channelid ==  chls.channelid">{{chls.name}}</option>
    </select>
</div> -->
<div class="form-group  cat">
    <label for="categories" class="mb-2">Categories</label>
    <select class="form-select custom-dropdown" id="categories" name="categories" [(ngModel)]="categoryid">
        <option value = "">All</option>
        <option *ngFor = "let category of categories" [value] = "category.categoryid"  [selected]="categoryid ==  category.categoryid">{{category.categoryname}}</option>
    </select>
</div>

<div class="form-group cat">
  <label for="categories" class="mb-2">Status</label>
  <select class="form-select custom-dropdown" id="categories" name="categories"
    [(ngModel)]="statusid">
    <option value="">All</option>
    <option *ngFor="let status of statusList" [value]="status.id" [selected]="statusid ==  status.id">
      {{status.name}}</option>
  </select>
</div>

<div class="d-flex apply">
    <button class="reset btn " color="black" (click)="reset()">Reset</button>
    <button class="apply" mat-stroked-button (click)="onConfirm()" color="primary">Apply</button>
</div>