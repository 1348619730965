import { Component, OnInit, HostListener, ViewEncapsulation } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { AllInOneService } from '../../shared/services/all-in-one.service';

@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss'],
})
export class TabsComponent implements OnInit {
  selectedIndex: number = 0;
  width: any;
  isiPhone: boolean = false;
  isAuthenticate: boolean = false;
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.width = event.target.innerWidth;
    if(this.width < 915 && !this.allinoneService.getAtoken() || (this.allinoneService.currentDomainType == '003')){
      this.isAuthenticate = false;
      this.router.navigateByUrl('/login');
    }
    else{
      this.isAuthenticate = true;
    }
  }

  constructor(
    private location: Location,
    private router: Router,
    private deviceService: DeviceDetectorService,
    private allinoneService: AllInOneService
  ) {
    this.width = window.innerWidth;
    if(this.width < 915 && !this.allinoneService.getAtoken()){
      this.isAuthenticate = false;
      this.router.navigateByUrl('/login');
    }
    else{
      this.isAuthenticate = true;
    }
  }

  ngOnInit(): void {
    const deviceInfo = this.deviceService.getDeviceInfo();
    if (deviceInfo.device == 'iPhone') {
      this.isiPhone = true;
    }

    // this.width = window.screen.width;

    const url = this.router.url;
    if (url == '/schedule') {
      this.selectedIndex = 1;
    }
    else if (url == '/search') {
      this.selectedIndex = 2;
    } else if (url == '/my-learning') {
      this.selectedIndex = 3;
    }
  }
  onTabChanged(ev: any) {
    if (this.selectedIndex == 0) {
      this.location.replaceState('/home');
    } else if (this.selectedIndex == 1) {
      this.location.replaceState('/schedule');
    } else if (this.selectedIndex == 2) {
      this.location.replaceState('/search');
    }
     else {
      this.location.replaceState('/my-learning');
    }
  }
}
