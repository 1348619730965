import { DatePipe } from '@angular/common';
import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import html2canvas from 'html2canvas';
import { base64ToFile } from 'ngx-image-cropper';
import { AllInOneService } from '../../shared/services/all-in-one.service';
import { CourseService } from '../../shared/services/course.service';
import { MessageService } from '../../shared/services/message.service';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { Observable, Observer } from 'rxjs';
import * as saveAs from 'file-saver';

@Component({
  selector: 'app-certificate',
  templateUrl: './certificate.component.html',
  styleUrls: ['./certificate.component.scss'],
})
export class CertificateComponent implements OnInit {
  @Input('coursename') coursename: string = '';
  @Input('templateid') templateid: string = '';
  @Input('isFromInstructor') isFromInstructor: boolean = false;

  isImgGenerated: boolean = false;
  isUploadedCertificate: boolean = false;
  isLoading: boolean = false;
  showShareButton: boolean = true;

  certificateSrc: any;
  certificateImg: any;

  certificatePdf: any;

  courseid: string = '';
  certificateid: string = '';
  certificateUrl: string = '';

  base64Image: any;

  showmessage: boolean = false;
  message: any = '';

  messagedata = [
    {
      message: 'Feedback',
      eligible: true,
    },
    {
      message: 'Grade',
      eligible: false,
    },
  ];

  template: any;
  constructor(
    private datePipe: DatePipe,
    private allinoneService: AllInOneService,
    private route: ActivatedRoute,
    private courseService: CourseService,
    private messageService: MessageService
  ) {
    this.route.params.subscribe((params) => {
      this.courseid = params['courseid'];
    });
  }

  renderTemplate(): void {
    const app = document.getElementById('template-bg-preview');
    console.log('Your app');

    console.log(app);

    app!.style.fontSize = (app!.clientWidth / 50).toString() + 'px';
    if (this.template.backgroundimageurl) {
      const img = document.createElement('img');
      img.onload = async () => {
        this.certificateImg = await this.generateImg();
        this.uploadCertificate();
      };
      img.id = 'bg-img';
      img.crossOrigin = '*';
      img.src = this.template.backgroundimageurl;
      img.style.width = '100%';
      img.style.height = 'auto';
      img.style.objectFit = 'cover';
      app?.appendChild(img);
    }
    this.template.rawjson.map((item: any, index: number) => {
      const div = document.createElement('div');
      div.id = index.toString();
      div.textContent = item.content
        .toString()
        .replaceAll('[[name]]', this.allinoneService.user.username)
        .replaceAll('[[course]]', this.coursename)
        .replaceAll(
          '[[date]]',
          this.datePipe.transform(new Date(), 'dd MMMM, yyyy')
        );
      div.style.position = 'absolute';
      div.style.fontSize = item.fontSize + 'em';
      div.style.top = item.top + '%';
      div.style.fontWeight = item.fontWeight;
      div.style.fontFamily = item.fontFamily;
      div.style.color = item.color;
      div.style.paddingLeft = this.template.padding + 'em';
      div.style.paddingRight = this.template.padding + 'em';
      div.style.lineHeight = item.lineHeight ? item.lineHeight : '1';
      if (item.isCenter) {
        div.style.width = '100%';
        div.style.textAlign = 'center';
      } else {
        div.style.left = item.left + '%';
      }

      app?.appendChild(div);
    });
  }

  ngOnInit(): void {
    if (!this.isFromInstructor) {
      this.getCertificate();
    }
  }

  // images = [
  //   {
  //     name: 'Image 1',
  //     url: this.certificateUrl
  //   }
  // ];

  images = [
    {
      name: 'Image 1',
      url: 'https://images.pexels.com/photos/9667784/pexels-photo-9667784.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260',
    },
    {
      name: 'Image 1',
      url: 'https://4.bp.blogspot.com/-OuIrYzKE1lM/WJ1nqskJ5pI/AAAAAAAAOww/v9JfD7Hb_Fwe_K1svBN7gz2A_BUKxbqGwCLcB/s400/mindblowing-awasome-wallpapers-imgs.jpg',
    },
    {
      name: 'Image 2',
      url: 'https://akm-img-a-in.tosshub.com/indiatoday/559_102017023826.jpg?TZlWXro5W8Rj4VbO.MpENgo1F2MX93j',
    },
  ];

  download() {
    const img = new Image();
    img.src = this.certificateUrl;

    var imageUrl = this.certificateUrl;

    img.onload = function () {
      const imgWidth = img.width;
      const imgHeight = img.height;

      // const doc = new jsPDF({
      //   unit: 'px',
      //   format : 'a4'
      //   // format: [imgWidth, imgHeight],
      // });

      const doc = new jsPDF({
        orientation: 'l',
        unit: 'px',
        // format: 'a4'
        format: [imgWidth, imgHeight],
      });

      console.log('img width');
      console.log(imgWidth);
      // 1568

      console.log('img height');
      console.log(imgHeight);
      // 1176

      doc.addImage(imageUrl, 'JPEG', 0, 0, imgWidth, imgHeight);
      doc.save('certificate.pdf');
    };
  }

  generateImg() {
    return new Promise((resolve) => {
      var node: any = document.getElementById('template-bg-preview');
      html2canvas(node, { scale: 5, allowTaint: true, useCORS: true }).then(
        (canvas: any) => {
          const b64 = canvas.toDataURL();
          this.certificateSrc = b64;
          this.isImgGenerated = true;
          const blob = base64ToFile(b64);
          const imageFile: File = new File([blob], 'certificate.png', {
            type: blob.type,
          });
          resolve(imageFile);
        }
      );
    });
  }

  uploadCertificate() {
    // return;
    const filename = this.allinoneService.generateFileName(this.certificateImg);

    // this.courseService.uploadFile(this.certificateImg, filename, "certificates").subscribe(
    //   response => {
    //     console.log('File uploaded successfully:', response);
    //     this.courseService
    //     .addCertificate(this.courseid, filename, this.templateid)
    //     .subscribe((res: any) => {
    //       if (res.returncode == '300') {
    //         this.isUploadedCertificate = true;
    //         this.certificateid = res.pkey;
    //         this.certificateUrl = res.imageurl;
    //       } else {
    //       }
    //     });
    //   },
    //   error => {
    //     console.error('Error while uploading your file:', error);
    //     this.messageService.openSnackBar('Error while uploading your file.', 'warn');  // Handle error

    //   }
    // );

    this.allinoneService
      .fileUploadToS3WithPathForImage(
        this.certificateImg,
        filename,
        'certificates'
      )
      .then(
        () => {
          this.courseService
            .addCertificate(this.courseid, filename, this.templateid)
            .subscribe((res: any) => {
              if (res.returncode == '300') {
                this.isUploadedCertificate = true;
                this.certificateid = res.pkey;
                this.certificateUrl = res.imageurl;
              } else {
              }
            });
        },
        () => {}
      );
  }

  getCertificate() {
    this.isLoading = true;
    this.courseService.getCertificate(this.courseid, this.templateid).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.showShareButton = res.sharecertificate;
          if (res.type == '1') {
            this.certificateSrc = res.data.imageurl;
            this.certificateUrl = res.data.imageurl;
            this.certificateid = res.data.pkey;
            this.isImgGenerated = true;
          } else if (res.type == '3') {
            this.showmessage = true;
            this.message = res.data;
          } else {
            // this.templateid = res.data.templateid;
            this.template = res.data;
            this.isLoading = false;
            setTimeout(() => {
              this.renderTemplate();
            }, 100);
          }
        } else {
          this.messageService.openSnackBar(
            res.message || res.status || 'Server Error',
            'warn'
          );
        }
        this.isLoading = false;
      },
      (err) => {
        this.isLoading = false;
      }
    );
  }

  shareCertificate() {
    const hostname = window.location.host;
    const url =
      'https://' + hostname + '/certificate/verify/' + this.certificateid;
    window.open(url, '_blank');
  }

  // downloadCertificate(){
  //   window.open(this.certificateUrl, '_blank', 'download')
  //   // window.open(this.certificateUrl, '_blank', 'download=test.png')
  // }
}
