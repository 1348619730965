<section class="billboard  p-3 pt-3   pt-lg-3 pt-sm-3  user-section  text-sm-start">

  <div class="row">
    <div class="col-12 col-lg-6 col-sm-6 left">
      <div class="signup-wrapper">
        <div class="khub-sign">
          <img src="assets/images/khub.png" alt="" *ngIf="allinoneService.currentDomainType == '001'">
          <img [src]="allinoneService.channeldata.url" alt="" *ngIf="allinoneService.currentDomainType != '001'">
          <div class="line"></div>
          <h3 style="font-weight: 600;" class="">Start learning with <strong class="text-warning">
            {{allinoneService.currentDomainType == '001'? 'KHub' : allinoneService.channeldata.name}}</strong></h3>
        </div>
        <ul class="custom-list">
          <li class="custom-li-item">Easy to learn</li>
          <li class="custom-li-item">Learners can access content anywhere and anytime</li>
          <li class="custom-li-item">Topics include design,business,photography, and more</li>
        </ul>
      </div>
    </div>
    <div class="col-12 col-lg-6 col-sm-6 right">

      <div class="signup-wrapper">

        <div class="input-err-container" *ngIf="showPassInput">
          <h3>Password must contain the following:</h3>

          <p id="letter" class="invalid">A <b>lowercase</b> letter</p>
          <p id="capital" class="invalid">A <b>capital (uppercase)</b> letter</p>
          <p id="number" class="invalid">A <b>number</b></p>
          <p id="number" class="invalid">At least <b>8</b> characters</p>

        </div>



        <div>
          <h3 style="font-weight: 600;" class="mb-3">Sign Up</h3>
        </div>

        <form [formGroup]="signupForm" (ngSubmit)="signUp()">

          <div class="form-group my-2">
            <input type="text" class="form-control" autofocus placeholder="Full Name" formControlName="username"
              [ngClass]="{ 'is-invalid': signupFormSubmitted && signupFormControl.username.errors }">
            <div *ngIf="signupFormSubmitted && signupFormControl.username.errors" class="invalid-feedback">
              <div *ngIf="signupFormControl.username.errors?.required">
                {{allinoneService.input_err_msg}}
              </div>
            </div>

          </div>

          <div class="form-group my-2">
            <input type="text" class="form-control" autofocus placeholder="Email" formControlName="userid"
              [ngClass]="{ 'is-invalid': signupFormSubmitted && signupFormControl.userid.errors }">
            <div *ngIf="signupFormSubmitted && signupFormControl.userid.errors" class="invalid-feedback">
              <div *ngIf="signupFormControl.userid.errors?.required">
                {{allinoneService.input_err_msg}}
              </div>
            </div>

          </div>

          <div class="form-group my-2">

            <div class="input-group">
              <input [type]="fieldTextType ? 'text' : 'password'" class="form-control" autofocus placeholder="Password"
                formControlName="password"
                [ngClass]="{ 'is-invalid': signupFormSubmitted && signupFormControl.password.errors }">
              <div class="input-group-append">
                <span class="input-group-text">
                  <i class="fa" [ngClass]="{'fa-eye-slash': !fieldTextType,
                                                       'fa-eye': fieldTextType}" (click)="toggleFieldTextType()"></i>
                </span>
              </div>


              <div *ngIf="signupFormSubmitted && signupFormControl.password.errors" class="invalid-feedback">
                <div *ngIf="signupFormControl.password.errors?.required">
                  {{allinoneService.input_err_msg}}
                </div>
              </div>

            </div>


            <app-password-strength [passwordToCheck]="signupForm.value.password"
              (passwordStrength)="passwordValid($event)"></app-password-strength>
          </div>

          <!-- <div class="form-group my-2">
                <input type="text" class="form-control" autofocus placeholder="Full Name" formControlName="username"
                    [ngClass]="{ 'is-invalid': signupFormSubmitted && signupFormControl.username.errors }">
                <div *ngIf="signupFormSubmitted && signupFormControl.username.errors" class="invalid-feedback">
                    <div *ngIf="signupFormControl.username.errors?.required">
                        {{allinoneService.input_err_msg}}
                    </div>
                </div>

            </div>

            <div class="form-group my-2">
                <input type="text" class="form-control" autofocus placeholder="Institute" formControlName="institute"
                    [ngClass]="{ 'is-invalid': signupFormSubmitted && signupFormControl.institute.errors }">
                <div *ngIf="signupFormSubmitted && signupFormControl.institute.errors" class="invalid-feedback">
                    <div *ngIf="signupFormControl.institute.errors?.required">
                        {{allinoneService.input_err_msg}}
                    </div>
                </div>

            </div> -->

          <app-button-loader [isStillupload]="isStillSigningIn" [button_text]="'Sign Up'" [width]="'100'"
            [margin_left]="'0'"></app-button-loader>
        </form>

        <div class="ask-login-wrapper my-3">
          <h4>Already have an account? <strong class="login main-color" routerLink="/login">Sign In</strong>
          </h4>
        </div>

      </div>
    </div>
  </div>
</section>