<div class="d-flex">

  <h2 class="me-auto">
    Feedback Form
  </h2>
</div>

<ng-container *ngIf="isFromInstructor; else feedbackTemplate">
  <div class="d-flex align-items-center justify-content-center">
    No preview available for Feedback.
  </div>
</ng-container>

<ng-template #feedbackTemplate>

  <ng-container *ngIf="!feedbackGiven; else elseTemplate">
    <div *ngIf="isGivingFeedback">
      <div *ngIf="isLoading" class="nodata">
        <mat-spinner [strokeWidth]="3" [diameter]="30"></mat-spinner>
      </div>
      <div *ngIf="!isLoading" class="question-container">
        <div class="question-list mt-3" *ngFor="let ques of question;let i = index;">

          <ul class="questino-radio">

            <li class="question-li">
              <div class="d-flex w-100">
                <div>
                  {{i+1}}.&nbsp;
                </div>
                <div class="d-flex flex-column w-100">
                  <div class="d-flex justify-content-between w-100">
                    <div>
                      <span [innerHtml]="ques.question">
                      </span>
                    </div>
                  </div>
                  <div>

                    <ol class="questino-radio" *ngIf="ques.type=='001' || ques.type == '002'">
                      <li *ngFor="let ans of ques.answerlist;let j = index;">
                        <div class="form-check ">

                          <input *ngIf="ques.type=='001'" class="form-check-input" type="radio" [name]="'question'+i"
                            [id]="'question'+i+j" [checked]="j == ques.selected" (change)="radio($event,i,j)">

                          <input *ngIf="ques.type=='002'" class="form-check-input" type="checkbox" [name]="'question'+i"
                            [id]="'question'+i+j" [checked]="ques.checkboxans.includes(ans.answerid.toString())"
                            (change)="checkbox($event,i,j)">

                          <!-- <input *ngIf="ques.type=='003'" class="form-check-input" type="text" [name]="'question'+i" [id]="'question'+i+j"  [disabled]="isfeedback"> -->

                          <label class="form-check-label w-100" [for]="'question'+i+j">
                            <span [innerHtml]="ans.answer"></span>
                          </label>
                        </div>
                      </li>
                    </ol>

                    <div *ngIf="ques.type == '003'" class="mt-3">
                      <textarea rows="3" class="form-control" [(ngModel)]="ques.selected" [id]="'question'+i">
                    </textarea>
                    </div>
                    <div class="rate" *ngIf="ques.type == '004'">
                      <input type="radio" [id]="'question' + i + '1'" [name]="'question' + i" value="5"
                        [(ngModel)]="ques.selected" />
                      <label [for]="'question' + i + '1'" title="text"><mat-icon
                          class="star-icon">star</mat-icon></label>
                      <input type="radio" [id]="'question' + i + '2'" [name]="'question' + i" value="4"
                        [(ngModel)]="ques.selected" />
                      <label [for]="'question' + i + '2'" title="text"><mat-icon
                          class="star-icon">star</mat-icon></label>
                      <input type="radio" [id]="'question' + i + '3'" [name]="'question' + i" value="3"
                        [(ngModel)]="ques.selected" />
                      <label [for]="'question' + i + '3'" title="text"><mat-icon
                          class="star-icon">star</mat-icon></label>
                      <input type="radio" [id]="'question' + i + '4'" [name]="'question' + i" value="2"
                        [(ngModel)]="ques.selected" />
                      <label [for]="'question' + i + '4'" title="text"><mat-icon
                          class="star-icon">star</mat-icon></label>
                      <input type="radio" [id]="'question' + i + '5'" [name]="'question' + i" value="1"
                        [(ngModel)]="ques.selected" />
                      <label [for]="'question' + i + '5'" title="text"><mat-icon
                          class="star-icon">star</mat-icon></label>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>

        </div>
        <div class="d-flex justify-content-end">
          <button [disabled]="isSubmitting" mat-flat-button color="primary" class="mt-2" style="height: 40px;"
            (click)="cancel()">
            Cancel
          </button>

          <button [disabled]="isSubmitting" mat-flat-button color="primary" class="mt-2 " type="submit"
            style="height: 40px;" (click)="submit()">
            <span style="padding: 0;margin: 0;" *ngIf="isSubmitting"
              class="d-flex align-items-center justify-content-center p-2">
              <mat-spinner [strokeWidth]="2" [diameter]="20"></mat-spinner>

            </span>
            <span *ngIf="!isSubmitting">Submit </span>
          </button>

        </div>
      </div>
    </div>
    <div *ngIf="!isGivingFeedback">
      <div class="notsubmitted-feedback-container">
        <div class="cardform">
          <h2>Can we ask you something?</h2>
          <span>Let us know what we are getting right and what we can improve.</span>
          <button mat-flat-button color="primary" class="ms-0" (click)="giveFeedback()">
            Give Feedback
          </button>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-template #elseTemplate>
    <div class="notsubmitted-feedback-container">
      <div class="cardform">
        <div class="icon">
          <mat-icon class="success">check_circle</mat-icon>
        </div>
        <div class="text">
          You have already provided feedback for this course.
        </div>
      </div>
    </div>
  </ng-template>
</ng-template>