import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LoadingService } from '../../services/loading.service';

@Component({
  selector: 'app-confirm-dialog-two',
  templateUrl: './confirm-dialog-two.component.html',
  styleUrls: ['./confirm-dialog-two.component.scss']
})
export class ConfirmDialogTwoComponent implements OnInit {

  title?: string;
    message?: string;

    constructor(public dialogRef: MatDialogRef<ConfirmDialogTwoComponent>,
        private loadingService: LoadingService,
        @Inject(MAT_DIALOG_DATA) public data: any) {
        if (!data) {
            this.message = "Are you sure?";
        }
        else{
            this.message = data.message
        }
    }

    ngOnInit() {

    }


    onConfirm(): void {
        this.dialogRef.close(true);
    }

    onDismiss(): void {
        this.dialogRef.close(false);
    }

}
