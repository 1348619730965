<ng-container *ngIf="!isLoading && !isError && !notFound; else elseTemplate">
  <div class="content row mx-auto">
    <div class="col-lg-8 col-md-8 col-sm-12 col-12">
      <img [src]="certificateurl" class="certificate-img">
    </div>
    <div class="col-lg-4 col-md-4 col-sm-12 col-12">
      <div class="ps-3" *ngIf="!isMobileView">
        <div class="d-flex flex-column course-info" (click)="goToRegisterForm()">
          <div class="coursename mb-3">
            About this course:
          </div>

          <div class="mb-3">
            <img width="100%" height="auto" [src]="course.courseimageurl" alt="KHub"
              (error)="allinoneService.handleImgError($event)" style="object-fit: cover;">
          </div>


          <div class="course-header-title mb-2">
            <font> {{course.coursename}} </font>
          </div>
          <span class="course-header-instructor">{{course.instructorname}}</span>
          <span class="course-header-category">{{course.categoryname}}</span>
          <span class="course-fee" *ngIf="course.paymenttype == '001'">Free</span>
          <span class="course-fee" *ngIf="course.paymenttype == '002'">{{course.currency}}&nbsp;{{course.amount |
            number:
            '1.'}}</span>
        </div>
        <div>
          <button mat-flat-button color="primary" class="ms-0 mt-3" (click)="goToRegisterForm()">View Course</button>
        </div>
      </div>
      <div *ngIf="isMobileView">
        <div class="d-flex flex-column course-info" (click)="goToRegisterForm()">
          <div class="coursename my-3">
            About this course:
          </div>

          <div class="d-flex">
            <div class="me-4">
              <img width="100px" height="auto" [src]="course.courseimageurl" alt="Photo of a"
                (error)="allinoneService.handleImgError($event)" style="object-fit: cover;">
            </div>
  
  
            <div class="d-flex flex-column">
              <div class="course-header-title mb-2">
                <font> {{course.coursename}} </font>
              </div>
              <span class="course-header-instructor">{{course.instructorname}}</span>
              <span class="course-header-category">{{course.categoryname}}</span>
              <span class="course-fee" *ngIf="course.paymenttype == '001'">Free</span>
              <span class="course-fee" *ngIf="course.paymenttype == '002'">{{course.currency}}&nbsp;{{course.amount |
                number:
                '1.'}}</span>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>



</ng-container>

<ng-template #elseTemplate>
  <div *ngIf="isLoading" class="center">
    <mat-spinner [strokeWidth]="3" [diameter]="30"></mat-spinner>
  </div>
  <div *ngIf="isError" class="center">
    <div class="check-error" *ngIf="isError">
      <h3 style="margin: 0;">Something went wrong! Please reload.</h3><br>
      <button type="button" (click)="reload()" class="btn btn-outline-secondary">
        Reload
      </button>
    </div>
  </div>
  <div *ngIf="notFound" class="center">
    <h3 class="mb-0">Certificate not found.</h3><br>
  </div>
</ng-template>