import { Component, Input, OnInit } from '@angular/core';
import {
  ActivatedRoute,
  ActivationEnd,
  NavigationEnd,
  Router,
  RoutesRecognized,
} from '@angular/router';
import { AllInOneService } from './shared/services/all-in-one.service';
import { CourseService } from './shared/services/course.service';
import { MessageService } from './shared/services/message.service';
import { AngularFaviconService } from 'angular-favicon';
import { error } from 'console';
import { SEOService } from './shared/services/seo.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'E-Learning';
  hideTopbar: boolean = false;
  authenticate: boolean = false;
  retry: boolean = false;
  isLoading: boolean = true;
  width: number = 1024;
  nextroute: string = '/home';
  isMobileview: boolean = false;
  isFromDomain: boolean = false;
  isQueryChannel: boolean = false;
  queryChannel: string = '';
  channelSeo: { title: string; description: string } | null = null;
  queryChannelList: any = {
    yueco: 'yueco.knowledgehub.edu.mm',
    sayaraung: 'sayaraung.knowledgehub.edu.mm',
    miod: 'miod.khub.cloud',
  };
  channelSeoList: { [key: string]: { title: string; description: string } } = {
    'www.khub.cloud': {
      title: 'KHub',
      description:
        'KHub is an online learning platform where you can find everything things you need.',
    },
    'yueco.knowledgehub.edu.mm': {
      title: 'Yueco',
      description:
        'Yueco is an online learning platform where you can find everything things you need.',
    },
    'miod.khub.cloud': {
      title: 'MIoD',
      description:
        'MIoD is an online learning platform where you can find everything things you need.',
    },
    'sayaraung.knowledgehub.edu.mm': {
      title: 'Sayar-Aung',
      description:
        'Sayar-Aung is an online learning platform where you can find everything things you need.',
    },
  };

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private courseService: CourseService,
    private messageService: MessageService,
    public allinoneService: AllInOneService,
    private ngxFavicon: AngularFaviconService,
    private _seoService: SEOService
  ) {
    this.width = window.screen.width;
    if (this.width > 1024) {
      this.nextroute = '/home/courses';
    }
    if (window.innerWidth <= 500) {
      this.isMobileview = true;
    }
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.onActivate();
      }
    });
  }
  async ngOnInit(): Promise<void> {
    let hostname = window.location.hostname;
    if (hostname === 'localhost') hostname = 'www.khub.cloud';
    let queryParams = new URLSearchParams(window.location.search);
    this.queryChannel =
      queryParams.get('channel') || this.allinoneService.getQueryChannel();
    this.isQueryChannel = this.queryChannel in this.queryChannelList;

    this.allinoneService.currentDomain = hostname;
    this.allinoneService.currentPaymentConfig =
      this.allinoneService.paymentConfig.find(
        (x: any) => x.hostname == hostname
      );
    this.allinoneService.channelid = this.allinoneService.getChannelid();
    if (
      this.allinoneService.currentDomain != 'www.khub.cloud' ||
      this.isQueryChannel
    ) {
      this.isFromDomain = true;
      const checkdomain = await this.checkDomain();
      if (this.allinoneService.currentDomainType != '001') {
        this.ngxFavicon.setFavicon(this.allinoneService.channeldata.url);
      }
      this.channelSeo =
        this.channelSeoList[this.queryChannelList[this.queryChannel]];
    } else {
      this.channelSeo = this.channelSeoList[hostname];
    }

    if (this.channelSeo) {
      this._seoService.updateTitle(this.channelSeo.title);
      this._seoService.updateDescription(this.channelSeo.description);
    }

    if (this.allinoneService.currentDomainType != '003') {
      const temp = await this.user();
    }
  }

  onActivate() {
    window.scroll(0, 0);

    if (
      this.router.url.includes('/courses/') &&
      this.router.url.includes('/lectures')
    ) {
      this.hideTopbar = true;
    } else {
      this.hideTopbar = false;
    }

    console.log('here');
    //or document.body.scrollTop = 0;
    //or document.querySelector('body').scrollTo(0,0)
  }

  checkDomain() {
    console.log(this.allinoneService.currentDomain);
    return new Promise(async (resolve) => {
      let queryParams = new URLSearchParams(window.location.search);

      const appid = queryParams.get('appid') || 'kunyek';
      const kunyek_userid = queryParams.get('userid') || '';
      const domain = queryParams.get('domain') || '';
      const atoken = queryParams.get('atoken') || '';

      if (kunyek_userid) {
        //check here
        this.courseService
          .checkDomain(
            appid,
            this.allinoneService.checkUserId(kunyek_userid),
            atoken,
            this.allinoneService.currentDomain,
            this.isQueryChannel
              ? this.queryChannelList[this.queryChannel]
              : null
          )
          .subscribe((res: any) => {
            if (res.returncode == '300') {
              this.allinoneService.currentDomainType = res.type;
              if (res.type == '002') {
                this.allinoneService.channeldata = res.data;
                this.allinoneService.selectedChannelName = res.data.name;
                this.allinoneService.channelid = res.data.channelid;
                this.allinoneService.setCookie(
                  'channelid',
                  this.allinoneService.channelid
                );
                this.allinoneService.setCookie(
                  'queryChannel',
                  this.queryChannel
                );
                this.allinoneService.showSignUpButton = res.data.allowsignup;
                this.allinoneService.showStudioLink = res.data.studiolink;
                // this.allinoneService.privatecourse = res.data.privatecourse;
                this.allinoneService.login_page_description = res.data.webdesc;
                this.allinoneService.showQrSignin = res.data.showqrsignin;
                this.allinoneService.assignment_label =
                  res.data.assignmentlabel ||
                  this.allinoneService.assignment_label;
              }
            } else {
              if (res.returncode == '205') {
                this.allinoneService.showSignUpButton = res.data.allowsignup;
                this.allinoneService.channeldata = res.data;
                this.allinoneService.login_page_description = res.data.webdesc;
                this.allinoneService.currentDomainType = '003';
                this.allinoneService.logoutWithoutReload();
                this.router.navigateByUrl('/login');
                this.isLoading = false;
              }
            }
            resolve(true);
          });
      } else {
        //check here
        this.courseService
          .checkDomain(
            this.allinoneService.getAppId(),
            this.allinoneService.getKunyekUserId(),
            this.allinoneService.getAtoken(),
            this.allinoneService.currentDomain,
            this.isQueryChannel
              ? this.queryChannelList[this.queryChannel]
              : null
            // 'com.nirvasoft.app059'
          )
          .subscribe((res: any) => {
            if (res.returncode == '300') {
              this.allinoneService.currentDomainType = res.type;
              if (res.type == '002') {
                this.allinoneService.channeldata = res.data;
                this.allinoneService.selectedChannelName = res.data.name;
                this.allinoneService.channelid = res.data.channelid;
                this.allinoneService.setCookie(
                  'channelid',
                  this.allinoneService.channelid
                );
                this.allinoneService.setCookie(
                  'queryChannel',
                  this.queryChannel
                );
                this.allinoneService.showSignUpButton = res.data.allowsignup;
                this.allinoneService.showStudioLink = res.data.studiolink;
                // this.allinoneService.privatecourse = res.data.privatecourse;
                this.allinoneService.login_page_description = res.data.webdesc;
                this.allinoneService.showQrSignin = res.data.showqrsignin;
                this.allinoneService.assignment_label =
                  res.data.assignmentlabel ||
                  this.allinoneService.assignment_label;
              }
            } else {
              if (res.returncode == '205') {
                this.allinoneService.showSignUpButton = res.data.allowsignup;
                this.allinoneService.channeldata = res.data;
                this.allinoneService.currentDomainType = '003';
                this.allinoneService.login_page_description = res.data.webdesc;
                this.allinoneService.logoutWithoutReload();
                this.router.navigateByUrl('/login');
                this.isLoading = false;
                console.log(this.allinoneService.showQrSignin);
              }
            }
            resolve(true);
          });
      }
    });
  }

  user() {
    return new Promise(async (resolve) => {
      let queryParams = new URLSearchParams(window.location.search);

      const appid = queryParams.get('appid') || 'kunyek';
      const kunyek_userid = queryParams.get('userid') || '';
      const domain = queryParams.get('domain') || '';
      const atoken = queryParams.get('atoken');
      const username = queryParams.get('username') || '';
      const channelid = queryParams.get('channelid') || '';
      sessionStorage.setItem('kunyekusername', username);

      if (appid == '026' && kunyek_userid && domain && !atoken) {
        this.allinoneService.channelid = channelid;
        // Here Call API
        //if success
        //Check user, create new if not exist
        const data = {
          appid: appid,
          userid: this.allinoneService.checkUserId(kunyek_userid),
          domain: domain,
        };
        this.courseService.hcmLogin(data).subscribe(
          (res: any) => {
            if (res.returncode == '300') {
              if (!this.isFromDomain) {
                this.allinoneService.setCookie('channelid', channelid);
              }

              this.allinoneService.setCookie('appid', res.appid || '');

              this.allinoneService.setCookie(
                'kunyek_userid',
                this.allinoneService.checkUserId(res.userid) || ''
              );
              this.allinoneService.setCookie('atoken', res.atoken || '');
              this.courseService.checkUser().subscribe(
                async (res: any) => {
                  if (res.returncode == '300') {
                    if (res.status == 0) {
                      //user does not exits
                      this.courseService.createUser(username).subscribe(
                        async (res: any) => {
                          if (res.returncode == '300') {
                            this.allinoneService.setCookie(
                              'userid',
                              res.data.usersyskey
                            );
                            this.allinoneService.setCookie(
                              'username',
                              username
                            );
                            if (
                              this.allinoneService.currentDomainType == '001'
                            ) {
                              const channels = await this.getMyChannel();
                            }
                            const categorieswithcounts =
                              await this.getCategoryListWithCount();
                            // const categories = await this.getCategoryList();
                            const user = await this.getUser();
                            this.isLoading = false;
                            this.router.navigateByUrl(this.nextroute);
                          } else {
                            this.isLoading = false;
                            this.retry = true;

                            if (res.returncode == '210') {
                              setTimeout(() => {
                                this.router.navigateByUrl('login');
                                this.allinoneService.clearAllcookies();
                              }, 2000);
                            } else {
                              this.messageService.openSnackBar(
                                res.status,
                                'warn'
                              );
                            }
                          }
                        },
                        (err) => {
                          this.isLoading = false;
                          this.retry = true;
                        }
                      );
                    } else if (res.status == 1) {
                      //user exits
                      this.allinoneService.setCookie('username', res.username);
                      this.allinoneService.setCookie('userid', res.usersyskey);
                      if (this.allinoneService.currentDomainType == '001') {
                        const channels = await this.getMyChannel();
                      }
                      const categorieswithcounts =
                        await this.getCategoryListWithCount();
                      // const categories = await this.getCategoryList();
                      const user = await this.getUser();
                      this.isLoading = false;
                      this.router.navigateByUrl(this.nextroute);
                    }
                  } else {
                    this.isLoading = false;
                    this.retry = true;
                    if (res.returncode == '210') {
                      setTimeout(() => {
                        this.router.navigateByUrl('login');
                        this.allinoneService.clearAllcookies();
                      }, 2000);
                    } else {
                      this.messageService.openSnackBar(res.status, 'warn');
                    }
                  }
                },
                (err) => {
                  this.isLoading = false;
                  this.retry = true;
                }
              );
            } else {
              this.isLoading = false;
              this.router.navigateByUrl('login');
              this.allinoneService.clearAllcookies();
            }
          },
          (err) => {
            this.isLoading = false;
            this.retry = true;
          }
        );
      } else if (appid && kunyek_userid && atoken) {
        if (!this.isFromDomain) {
          this.allinoneService.channelid = channelid;
          this.allinoneService.setCookie('channelid', channelid);
        }
        this.allinoneService.setCookie('appid', appid || '');

        this.allinoneService.setCookie(
          'kunyek_userid',
          this.allinoneService.checkUserId(kunyek_userid) || ''
        );

        this.allinoneService.setCookie('atoken', atoken || '');

        this.courseService.checkUser().subscribe(
          async (res: any) => {
            if (res.returncode == '300') {
              if (res.status == 0) {
                //user does not exits
                this.courseService.createUser(username).subscribe(
                  async (res: any) => {
                    if (res.returncode == '300') {
                      this.allinoneService.setCookie('username', username);
                      this.allinoneService.setCookie(
                        'userid',
                        res.data.usersyskey
                      );
                      if (this.allinoneService.currentDomainType == '001') {
                        const channels = await this.getMyChannel();
                      }
                      // const categorieswithcounts =
                      //   await this.getCategoryListWithCount();
                      // // const categories = await this.getCategoryList();
                      const user = await this.getUser();
                      this.isLoading = false;
                      this.router.navigateByUrl(this.nextroute);
                      const categorieswithcounts =
                        await this.getCategoryListWithCount();
                    } else {
                      this.isLoading = false;
                      this.retry = true;

                      if (res.returncode == '210') {
                        setTimeout(() => {
                          this.router.navigateByUrl('login');
                          this.allinoneService.clearAllcookies();
                        }, 2000);
                      } else {
                        this.messageService.openSnackBar(res.status, 'warn');
                      }
                    }
                  },
                  (err) => {
                    this.isLoading = false;
                    this.retry = true;
                  }
                );
              } else if (res.status == 1) {
                //user exits
                this.allinoneService.setCookie('username', res.username);
                this.allinoneService.setCookie('userid', res.usersyskey);
                if (this.allinoneService.currentDomainType == '001') {
                  const channels = await this.getMyChannel();
                }
                // const categorieswithcounts =
                //   await this.getCategoryListWithCount();
                // // const categories = await this.getCategoryList();
                const user = await this.getUser();
                this.isLoading = false;
                this.router.navigateByUrl(this.nextroute);

                await this.getCategoryListWithCount();
                // const categories = await this.getCategoryList();
                // const user = await this.getUser();
                resolve(true);
              }
            } else {
              this.isLoading = false;
              this.retry = true;
              if (res.returncode == '210') {
                setTimeout(() => {
                  this.router.navigateByUrl('login');
                  this.allinoneService.clearAllcookies();
                }, 2000);
              } else {
                this.messageService.openSnackBar(res.status, 'warn');
              }
            }
          },
          (err) => {
            this.isLoading = false;
            this.retry = true;
          }
        );

        //Check Token
        // this.courseService.checkToken().subscribe(
        //   (res: any) => {
        //     if (res.returncode == '1' || res.returncode == '3') {
        //       setTimeout(() => {
        //         this.isLoading = false;
        //         this.router.navigateByUrl('login');
        //         this.allinoneService.clearAllcookies();
        //       }, 2000);
        //     } else {
        //       //user
        //       // kunyek user to create a new one if not in elearning. [Elearning Server]
        //       this.courseService.checkUser().subscribe(
        //         async (res: any) => {
        //           if (res.returncode == '300') {
        //             if (res.status == 0) {
        //               //user does not exits
        //               this.courseService.createUser(username).subscribe(
        //                 async (res: any) => {
        //                   if (res.returncode == '300') {
        //                     this.allinoneService.setCookie(
        //                       'username',
        //                       username
        //                     );
        //                     this.allinoneService.setCookie(
        //                       'userid',
        //                       res.data.usersyskey
        //                     );
        //                     if (
        //                       this.allinoneService.currentDomainType == '001'
        //                     ) {
        //                       const channels = await this.getMyChannel();
        //                     }
        //                     const categorieswithcounts =
        //                       await this.getCategoryListWithCount();
        //                     // const categories = await this.getCategoryList();
        //                     const user = await this.getUser();
        //                     this.isLoading = false;
        //                     this.router.navigateByUrl(this.nextroute);
        //                   } else {
        //                     this.isLoading = false;
        //                     this.retry = true;

        //                     if (res.returncode == '210') {
        //                       setTimeout(() => {
        //                         this.router.navigateByUrl('login');
        //                         this.allinoneService.clearAllcookies();
        //                       }, 2000);
        //                     } else {
        //                       this.messageService.openSnackBar(
        //                         res.status,
        //                         'warn'
        //                       );
        //                     }
        //                   }
        //                 },
        //                 (err) => {
        //                   this.isLoading = false;
        //                   this.retry = true;
        //                 }
        //               );
        //             } else if (res.status == 1) {
        //               //user exits
        //               this.allinoneService.setCookie('username', res.username);
        //               this.allinoneService.setCookie('userid', res.usersyskey);
        //               if (this.allinoneService.currentDomainType == '001') {
        //                 const channels = await this.getMyChannel();
        //               }
        //               const categorieswithcounts =
        //                 await this.getCategoryListWithCount();
        //               // const categories = await this.getCategoryList();
        //               const user = await this.getUser();
        //               this.isLoading = false;
        //               this.router.navigateByUrl(this.nextroute);
        //               resolve(true);
        //             }
        //           } else {
        //             this.isLoading = false;
        //             this.retry = true;
        //             if (res.returncode == '210') {
        //               setTimeout(() => {
        //                 this.router.navigateByUrl('login');
        //                 this.allinoneService.clearAllcookies();
        //               }, 2000);
        //             } else {
        //               this.messageService.openSnackBar(res.status, 'warn');
        //             }
        //           }
        //         },
        //         (err) => {
        //           this.isLoading = false;
        //           this.retry = true;
        //         }
        //       );
        //     }
        //   },
        //   (err) => {
        //     this.isLoading = false;
        //     this.retry = true;
        //   }
        // );
      } else {
        const atoken = this.allinoneService.getAtoken();
        if (atoken) {
          //Check Token
          this.courseService.checkToken().subscribe(
            async (res: any) => {
              if (res.returncode == '1' || res.returncode == '3') {
                const appid = this.allinoneService.getAppId();
                var userid = this.allinoneService.getKunyekUserId();
                if (appid == '' || userid == '' || atoken == '') {
                  setTimeout(() => {
                    this.isLoading = false;
                    this.router.navigateByUrl('login');
                    this.allinoneService.clearAllcookies();
                  }, 2000);
                } else {
                  this.courseService.renewToken().subscribe(
                    async (res: any) => {
                      if (res.returncode == '300') {
                        this.allinoneService.setCookie('atoken', res.atoken);
                        if (this.allinoneService.currentDomainType == '001') {
                          const channels = await this.getMyChannel();
                        }
                        const user = await this.getUser();
                        const categorieswithcounts =
                          await this.getCategoryListWithCount();
                        // const categories = await this.getCategoryList();
                        this.isLoading = false;
                        resolve(true);
                      } else {
                        setTimeout(() => {
                          this.isLoading = false;
                          this.router.navigateByUrl('login');
                          this.allinoneService.clearAllcookies();
                        }, 2000);
                      }
                    },
                    (error) => {
                      this.isLoading = false;
                      this.retry = true;
                    }
                  );
                }
              } else {
                if (this.allinoneService.currentDomainType == '001') {
                  const channels = await this.getMyChannel();
                }
                const user = await this.getUser();
                const categorieswithcounts =
                  await this.getCategoryListWithCount();
                // const categories = await this.getCategoryList();
                this.isLoading = false;
                resolve(true);
              }
            },
            (err) => {
              this.isLoading = false;
              this.retry = true;
            }
          );
        } else {
          const categorieswithcounts = await this.getCategoryListWithCount();
          // const categories = await this.getCategoryList();
          this.isLoading = false;
          resolve(true);
        }
      }
    });
  }

  getCategoryList() {
    return new Promise((resolve) => {
      this.courseService.getCategoryList().subscribe(
        (res: any) => {
          if (res.returncode == '300') {
            this.allinoneService.setCookie(
              'categories',
              JSON.stringify(res.data) || 'undefined'
            );
            this.allinoneService.categories = res.data;
            resolve(true);
          } else {
            this.isLoading = false;
            this.retry = true;
            this.messageService.openSnackBar(
              res.message || res.error || res.status,
              'warn'
            );

            resolve(false);
          }
        },
        (err) => {
          this.isLoading = false;
          this.retry = true;
          resolve(false);
        }
      );
    });
  }

  getCategoryListWithCount() {
    return new Promise((resolve) => {
      this.courseService.getCategoryListWithCounts().subscribe(
        (res: any) => {
          if (res.returncode == '300') {
            this.allinoneService.setCookie(
              'categorieswithcounts',
              JSON.stringify(res.datalist) || 'undefined'
            );
            this.allinoneService.categorieswithcounts = res.datalist;
            this.allinoneService.setCookie(
              'categories',
              JSON.stringify(res.datalist) || 'undefined'
            );
            this.allinoneService.categories = res.datalist;
            resolve(true);
          } else {
            this.isLoading = false;
            this.retry = true;
            this.messageService.openSnackBar(
              res.message || res.error || res.status,
              'warn'
            );
            resolve(false);
          }
        },
        (err) => {
          this.isLoading = false;
          this.retry = true;
          resolve(false);
        }
      );
    });
  }

  getMyChannel() {
    return new Promise((resolve) => {
      const searchuserid = this.allinoneService.getKunyekUserId();
      if (searchuserid != '') {
        const data = {
          role: '0',
          searchuserid: searchuserid.toString(),
        };
        this.courseService.getMychannel(data).subscribe(
          (res: any) => {
            if (res.returncode == '300') {
              this.allinoneService.channels = res.datalist;
              if (
                this.allinoneService.channels.length == 1 &&
                this.allinoneService.channels[0].channelid == ''
              ) {
                this.allinoneService.channelid = '';
                this.allinoneService.setCookie('channelid', '');
              } else {
                var temp = this.allinoneService.channels.find((x: any) => {
                  return x.channelid == this.allinoneService.channelid;
                });

                if (!temp) {
                  this.allinoneService.channelid = '';
                  this.allinoneService.setCookie('channelid', '');
                } else {
                  this.allinoneService.selectedChannelName = temp.name;
                  this.allinoneService.channeldata = temp;
                  // this.allinoneService.privatecourse = temp.privatecourse;
                }
              }

              if (this.allinoneService.channels.length > 0) {
                this.allinoneService.setCookie(
                  'channels',
                  JSON.stringify(this.allinoneService.channels) || 'undefined'
                );
              }

              // get learning track
              if (this.allinoneService.channelid != '') {
                const data = {
                  searchuser: searchuserid.toString(),
                  channelid: this.allinoneService.channelid,
                };
                this.courseService.getLearningPath(data).subscribe(
                  (res: any) => {
                    if (res.returncode == '300') {
                      this.allinoneService.learningPathList = res.datalist;
                      if (res.datalist.length > 0) {
                        this.allinoneService.showLearningPath = true;
                      }
                    } else {
                      this.allinoneService.showLearningPath = false;
                    }
                    resolve(true);
                  },
                  (err) => {
                    this.allinoneService.showLearningPath = false;
                    resolve(true);
                  }
                );
              } else {
                resolve(true);
              }
            } else {
              this.isLoading = false;
              if (res.returncode == '210') {
                setTimeout(() => {
                  this.router.navigateByUrl('login');
                  this.allinoneService.clearAllcookies();
                }, 1000);
              } else {
                this.messageService.openSnackBar(res.status, 'warn');
                this.retry = true;
              }
              resolve(false);
            }
          },
          (err) => {
            this.isLoading = false;
            this.retry = true;
            resolve(false);
          }
        );
      }
    });
  }

  getUser() {
    return new Promise((resolve) => {
      this.courseService.getUser().subscribe(
        async (res: any) => {
          if (res.returncode == '300') {
            // let keys = atob(res.wak);
            // [this.allinoneService.akid, this.allinoneService.skid] =
            //   keys.split(',');
            this.allinoneService.user = {
              userid: res.data.userid,
              username: res.data.username,
              profileimage: res.data.profileimage,
              department: res.data.department,
              type: res.data.type,
              typeid: res.data.typeid,
            };
          } else {
            this.messageService.openSnackBar(res.status, 'warn');
            if (res.returncode == '210') {
              setTimeout(() => {
                this.router.navigateByUrl('login');
                this.allinoneService.logout();
              }, 2000);
            }
          }
          this.isLoading = false;
          resolve(true);
        },
        (err) => {
          this.isLoading = false;
          setTimeout(() => {
            this.router.navigateByUrl('login');
            this.allinoneService.logout();
          }, 2000);
          resolve(true);
        }
      );
    });
  }

  reload() {
    window.location.reload();
  }

  signin() {
    this.allinoneService.clearAllcookies();
    window.location.replace('/login');
  }
}
