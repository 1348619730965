import { Injectable, ComponentFactory } from '@angular/core';
import { OverlayRef, Overlay } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { SpinnerOverlayComponent } from '../components/spinner-overlay/spinner-overlay.component';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {
  private overlayRef?: OverlayRef;

  constructor(private overlay: Overlay) { }

  show(): void {
    Promise.resolve(null).then(() => {
      this.overlayRef = this.overlay.create({
        positionStrategy: this.overlay
          .position()
          .global()
          .centerHorizontally()
          .centerVertically(),
        hasBackdrop: true
      });

      this.overlayRef.attach(new ComponentPortal(SpinnerOverlayComponent));
    })
  }

  hide(): void {
    this.overlayRef?.detach();
    this.overlayRef = undefined;
  }
}
