import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Grade } from '../../shared/models/Grade';
import { AllInOneService } from '../../shared/services/all-in-one.service';
import { CourseService } from '../../shared/services/course.service';
import { MessageService } from '../../shared/services/message.service';

@Component({
  selector: 'app-grades',
  templateUrl: './grades.component.html',
  styleUrls: ['./grades.component.scss'],
})
export class GradesComponent implements OnInit {
  @Input('isMobileView') isMobileView: boolean = false;
  @Input('isFromInstructor') isFromInstructor: boolean = false;

  @Output('viewLecture')
  lecture: EventEmitter<any> = new EventEmitter<any>();

  grades: any[] = [];
  mobileviewgrades: any[] = [];
  gettingGrade: boolean = false;

  courseid: string = '';
  user: string = '';
  totalPercentage: string = '';

  constructor(
    private courseService: CourseService,
    private allinOneService: AllInOneService,
    private messageService: MessageService,
    private route: ActivatedRoute
  ) {
    this.route.params.subscribe((params) => {
      this.courseid = params['courseid'];
    });
  }

  ngOnInit(): void {
    if (!this.isFromInstructor) {
      this.getGrades();
    }
  }

  getGrades() {
    this.gettingGrade = true;
    this.courseService.getGrades(this.courseid).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.mobileviewgrades = res.datalist;
          this.grades = res.datalist;

          var temp = {
            isTotal: true,
            totalPercentage: res.totalpercentage,
          };
          this.grades.push(temp);

          this.user = res.username ? res.username : res.userid;
          this.totalPercentage = res.totalpercentage;
        } else {
          this.messageService.openSnackBar(
            res.message || res.status || 'Server Error',
            'warn'
          );
        }
        this.gettingGrade = false;
      },
      (err) => {
        this.gettingGrade = false;
      }
    );
  }

  viewlecture(grade: any) {
    if (grade.isTotal) {
      return;
    } else {
      this.lecture.emit(grade.unitid);
    }
  }
}
