import { Component, HostListener, Input, OnInit } from '@angular/core';
import { AllInOneService } from '../../shared/services/all-in-one.service';
import { ActivatedRoute, Router } from '@angular/router';

import { Course } from '../../shared/models/Course';
import { CourseService } from '../../shared/services/course.service';
import { MessageService } from '../../shared/services/message.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { RefineDialogComponent } from '../../shared/components/refine-dialog/refine-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { SEOService } from '../../shared/services/seo.service';

@Component({
  selector: 'app-course-dashboard',
  templateUrl: './course-dashboard.component.html',
  styleUrls: ['./course-dashboard.component.scss'],
})
export class CourseDashboardComponent implements OnInit {
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    if (
      window.innerWidth > this.mobileViewWidth &&
      window.innerWidth < this.tabletViewWidth
    ) {
      this.isTabletView = true;
      this.isMobileView = false;
    } else if (
      window.innerWidth < this.mobileViewWidth &&
      window.innerWidth < this.tabletViewWidth
    ) {
      this.isMobileView = true;
      this.isTabletView = false;
    } else {
      this.isTabletView = false;
      this.isMobileView = false;
    }
  }

  isMobileView: boolean = false;
  isTabletView: boolean = false;

  mobileViewWidth: number = 600;
  tabletViewWidth: number = 992;

  courses: any = [];
  channels: any = [];
  categories: any = [];
  isLoading: boolean = true;
  isError: boolean = false;
  skeletons: any = [1, 2, 3, 4, 5, 6, 7, 8];
  p: number = 1;
  perpage: number = 8;
  total_courses: number = 0;
  total_pages: any = [];
  course_subscribe: any;
  selectedTabIndex: number = 0;

  searchText: string = '';
  // type 1 - Search by Category and Channel
  // Type 2 - Search by Text and Keywords
  filterType = 1;

  filterForm = {
    sortby: '1',
    categoryid: '',
    channelid: this.allinOneService.channelid,
    status: ''
  };

  statusList = [
    {
      id: '1',
      name: 'In Progress'
    },
    {
      id: '2',
      name: 'Completed'
    },
    {
      id: '3',
      name: 'Not Started'
    },
  ]

  constructor(
    public allinOneService: AllInOneService,
    private messageService: MessageService,
    private courseService: CourseService,
    public deviceService: DeviceDetectorService,
    private router: Router,
    public dialog: MatDialog,
    private _seoService: SEOService,
    private route: ActivatedRoute
  ) {
    this.route.params.subscribe((params) => {
      var type = params['type'];
      if (type == 'courses'){
        this.selectedTabIndex = 0; 
      }
      else if(type == 'learningpath'){
        if(!this.allinOneService.showLearningPath) {
          this.router.navigateByUrl('home/courses')
        }
        else{
          this.selectedTabIndex = 1;
        }
      }
    });
  }



  ngOnInit(): void {
    this._seoService.updateTitle('- My Courses');
    if (
      window.innerWidth > this.mobileViewWidth &&
      window.innerWidth < this.tabletViewWidth
    ) {
      this.isTabletView = true;
      this.isMobileView = false;
    } else if (
      window.innerWidth < this.mobileViewWidth &&
      window.innerWidth < this.tabletViewWidth
    ) {
      this.isMobileView = true;
      this.isTabletView = false;
    }
    this.channels = this.allinOneService.getChannels();
    this.categories = this.allinOneService.getCategory();
    this.getCourses(0, this.perpage, this.filterType);

  }

  refine(): void {
    let dialogRef = this.dialog.open(RefineDialogComponent, {
      panelClass: 'refin-box',
      maxWidth: '100vw',
      data: {
        chl: this.channels,
        cat: this.categories,
        chldata: this.filterForm.channelid,
        catedata: this.filterForm.categoryid,
        status: this.filterForm.status
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result[0].status == true) {
        this.filterForm = {
          sortby: this.filterForm.sortby,
          categoryid: result[0].categoryid,
          channelid: this.filterForm.channelid,
          status: result[0].statusid,
        };
        this.p = 1;
        this.getCourses(0, this.perpage, this.filterType)
      }
    });
  }

  getCourses(start: number, end: number, searchtype: number): void {
    this.isLoading = true;
    const userid = this.allinOneService.getUserId();
    const data = {
      studentid: userid,
      start: start,
      end: end,
      categoryid: this.filterForm.categoryid,
      channelid: this.filterForm.channelid,
      sortby: this.filterForm.sortby,
      searchtext: this.searchText,
      searchtype: searchtype.toString(),
      progressstatus: this.filterForm.status
    };
    this.course_subscribe = this.courseService
      .getRegisteredCourses(data)
      .subscribe(
        (res: any) => {
          if (res.returncode == '300') {
            this.total_courses = res.totallength;
            console.log(">>>");
            console.log(res);
            this.courses = [];
            this.total_pages = [];
            this.generatePagination();

            this.isError = false;
            res.data.map((course: any) => {
              this.courses.push({
                completedpercentage: course.completedpercentage,
                pkey: course.pkey,
                courseid: course.courseid,
                coursename: course.coursename,
                coursesubname: course.coursesubname,
                courseimage: course.courseimage,
                description: course.description,
                instructorid: course.instructorid,
                instructorname: course.instructorname,
                classcode:course.classcode,
                classname:course.classname,
                institute: course.institute
              });

            });
            console.log(this.courses);
          } else {
            if (res.returncode == '210') {
              setTimeout(() => {
                this.router.navigateByUrl('login');
                this.allinOneService.logout();
              }, 2000);
            } else {
              this.messageService.openSnackBar(res.status, 'warn');
              this.isError = true;
            }
          }
          this.isLoading = false;
        },
        (err) => {
          this.isLoading = false;
          this.isError = true;
        }
      );
  }
  generatePagination() {
    for (let i = 0; i < Math.ceil(this.total_courses / this.perpage); i++) {
      this.total_pages.push(i + 1);
    }
  }
  pageChange(p: any) {
    if (this.p != p) {
      this.p = p;
      var endpage = this.p * this.perpage;
      if (this.course_subscribe) {
        this.course_subscribe.unsubscribe();
      }
      this.getCourses(endpage - 8, endpage, this.filterType);
    }
  }
  previousPage() {
    if (this.p != 1) {
      this.pageChange(this.p - 1);
    }
  }
  nextPage() {
    if (this.p != this.total_pages.length) {
      this.pageChange(this.p + 1);
    }
  }
  reload() {
    window.location.reload();
  }

  handleImgError(ev: any) {
    let source = ev.srcElement;
    let imgSrc = 'https://assets.khub.cloud/essential/defaultimage.jpg';
    source.src = imgSrc;
  }

  goToLearning(course: any) {
    this.router.navigateByUrl(`/courses/${course.courseid}/lectures/`);
  }

  unEnrollCourse(pkey: string, courseindex: number) {
    this.courseService.unEnrollCourse(pkey).subscribe((res: any) => {
      if (res.returncode == '300') {
        this.courses.splice(courseindex, 1);
        this.total_courses = this.total_courses - 1;
        this.total_pages = [];
        this.generatePagination();

        if (this.p != 1 && this.courses.length == 0) {
          this.pageChange(this.p - 1);
        }
      } else {
        if (res.returncode == '210') {
          setTimeout(() => {
            this.router.navigateByUrl('login');
            this.allinOneService.logout();
          }, 2000);
        } else {
          this.messageService.openSnackBar(res.status, 'warn');
        }
      }
    });
  }

  search() {
    this.filterType = 2;
    this.p = 1;
    this.getCourses(0, this.perpage, this.filterType);
  }

  clearSearch() {
    this.filterType = 1;
    this.searchText = '';
    this.p = 1;
    this.getCourses(0, this.perpage, this.filterType);
  }

  sortChanged(event: any) {
    this.p = 1;
    this.filterForm.sortby = event.target.value;
    this.getCourses(0, this.perpage, this.filterType);
  }

  filterChanged(event: any, type: number) {
    if (type == 1) {
      this.filterForm.channelid = event.target.value;
    } else if(type == 2){
      this.filterForm.categoryid = event.target.value;
    }
    else{
      this.filterForm.status = event.target.value;
    }
    this.p = 1;
    this.getCourses(0, this.perpage, this.filterType);
  }

  resetFilter() {
    if (
      this.filterForm.sortby == '1' &&
      this.filterForm.categoryid == '' &&
      this.filterForm.channelid == 'all' &&
      this.filterForm.status == ''
    ) {
      return;
    } else {
      this.filterForm = {
        sortby: '1',
        categoryid: '',
        channelid: this.allinOneService.channelid,
        status: '',
      };
      this.p = 1;
      this.getCourses(0, this.perpage, this.filterType);
    }
  }

  tabChange(event: any){
    console.log(event);
    if(event == 0){
      this.router.navigateByUrl(`home/courses`)
    }
    else{
      this.router.navigateByUrl(`home/learningpath`)
    }
    
  }
}
