import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'projects/e-learning/src/app/shared/services/message.service';
import { Component, OnInit } from '@angular/core';
import { AllInOneService } from '../../shared/services/all-in-one.service';
import { Course } from '../../shared/models/Course';
import { CourseService } from '../../shared/services/course.service';
import { SEOService } from '../../shared/services/seo.service';

@Component({
  selector: 'app-course-by-channel',
  templateUrl: './course-by-channel.component.html',
  styleUrls: ['./course-by-channel.component.scss']
})
export class CourseByChannelComponent implements OnInit {

  courses: Course[] = [];
  channelid: string = "";
  isLoading: boolean = true;

  skeletons: any = [1, 2, 3, 4, 5, 6, 7, 8];
  p: number = 1;
  isError: boolean = false;

  channel = {
    name : "",
    description : "",
    image: ""
  }

  constructor(private courseService: CourseService,
    public allinOneService: AllInOneService,
    private messageService: MessageService,
    private router: Router,
    private route: ActivatedRoute,
    private _seoService: SEOService
    ) { }


  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.channelid = params['channelid'];
    })
    this.getCourses();
  }
  reload() {
    window.location.reload();
  }
  getCourses(): void {
    this.courseService.getCoursesByChannel(this.channelid).subscribe((res: any) => {
      if (res.returncode == "300") {
        this.isError = false;
        this.channel.name = res.channelname
        this.channel.description = res.channeldescription
        this.channel.image = res.channelurl
        this._seoService.updateTitle('- '+ this.channel.name);
        res.data.map((course: any) => {
          this.courses.push({
            pkey: course.pkey,
            courseid: course.courseid,
            coursename: course.coursename,
            coursesubname: course.coursesubname,
            courseimage: course.courseimage,
            description: course.description,
            instructorid: course.instructorid,
            instructorname: course.instructorname,
            enroll:course.enroll,
            categoryname: course.categoryname,
            paymenttype: course.paymenttype,
            amount: course.amount,
            currency: course.currency,
            institute: course.institute,
            studentcounts: course.studentcounts || 0,
          })
        });
      }
      else {
        this.isError = true;
        if (res.returncode == "210") {
          setTimeout(() => {
            this.router.navigateByUrl('login');
            this.allinOneService.logout();
          }, 2000);
        }
        else {
          this.messageService.openSnackBar(res.status, 'warn');
        }
      }
      this.isLoading = false;
    }, (_err:any) => {
      this.isLoading = false;
      this.isError = true;

    })
  }

  handleImgError(ev: any) {
    let source = ev.srcElement;
    let imgSrc = "https://bucketelearning.s3.ap-southeast-1.amazonaws.com/essential/defaultimage.jpg";
    source.src = imgSrc;
  }
  goToRegisterForm(course: any) {
    this.router.navigateByUrl(`course/channel/${course.courseid}`);
  }
  imgLoaded(ev: any) {
    let source = ev.srcElement;

  }
}
