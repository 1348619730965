import { Component, Input, OnInit } from '@angular/core';
import { AllInOneService } from '../../shared/services/all-in-one.service';
import { Router } from '@angular/router';
import { Course } from '../../shared/models/Course';
import { CourseService } from '../../shared/services/course.service';
import { MessageService } from '../../shared/services/message.service';
import { SEOService } from '../../shared/services/seo.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  @Input() isMobileView: boolean = false;

  courses: Course[] = [];
  isLoading: boolean = true;
  withChannel: any[] = [];
  nochannelId: any[] = [];
  mandatoryCourses: any[] = [];
  recentCourses: any[] = [];
  channels: any[] = [];
  customHomepageCourses: any[] = [];

  skeletons: any = [1, 2, 3, 4, 5, 6, 7, 8];
  p: number = 1;
  q: number = 1;
  isError: boolean = false;
  login: boolean = false;
  playedVid: boolean = false;
  isAll: boolean = false;
  gettingMoreCourses: boolean = false;
  customHomepage: boolean = false;
  channeldata: any = '';
  end: string = '';
  // itemsPerPage: number = 16;

  privateCourse : boolean = false;

  constructor(
    private courseService: CourseService,
    public allinOneService: AllInOneService,
    private messageService: MessageService,
    private _seoService: SEOService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this._seoService.updateTitle(' E-Learning');
    this.channeldata = this.allinOneService.channeldata
      ? this.allinOneService.channeldata
      : '';
    console.log(this.channeldata);
    if (this.channeldata != '' && this.channeldata.homepage) {
      console.log('heeeeee----------->')
      this.customHomepage = true;
      this.getCoursesWithChannel();
    } else {
      this.getCoursesWithChannel();
      this.getCoursesWithChannel(true);
    }
    this.checkAnalytics();
    console.log('home data');
    console.log(this.allinOneService.channeldata);
    console.log(this.allinOneService.channeldata.privatecourse);
    

    this.privateCourse = this.allinOneService.channeldata.privatecourse ? this.allinOneService.channeldata.privatecourse : false;
    
    
  }
  reload() {
    window.location.reload();
  }

  checkAnalytics() {
    this.courseService.checkAnalytics().subscribe();
  }

  getCourses(): void {
    // Channel Check
    const searchuserid = this.allinOneService.getKunyekUserId();
    if (searchuserid != '') {
      this.allinOneService.gettingChannel = true;
      const data = {
        role: '0',
        searchuserid: searchuserid.toString(),
      };
      this.courseService.getMychannel(data).subscribe(
        (res: any) => {
          if (res.returncode == '300') {
            this.channels = res.datalist;
            if (this.channels.length == 1 && this.channels[0].channelid == '') {
              this.allinOneService.channelid = '';
              this.allinOneService.setCookie('channelid', '');
            } else {
              var temp = this.channels.find((x: any) => {
                return x.channelid == this.allinOneService.channelid;
              });
              if (!temp) {
                this.allinOneService.channelid = '';
                this.allinOneService.setCookie('channelid', '');
              } else {
                this.allinOneService.selectedChannelName = temp.name;
                // this.allinOneService.privatecourse = temp.privatecourse;
              }
            }

            if (this.channels.length > 0) {
              this.allinOneService.setCookie(
                'channels',
                JSON.stringify(this.channels) || 'undefined'
              );
              this.allinOneService.channels = this.channels;
            }
            this.getCategoryListWithCount();
            this.getCoursesWithChannel();
            this.getCoursesWithChannel(true);
          } else {
            // this.messageService.openSnackBar(res.status, 'warn');

            if (res.returncode == '210') {
              setTimeout(() => {
                this.router.navigateByUrl('login');
                this.allinOneService.logout();
              }, 2000);
            }
          }
          this.allinOneService.gettingChannel = false;
        },
        (err) => {
          this.allinOneService.gettingChannel = false;
        }
      );
    } else {
      this.getCoursesWithChannel();
      return;
    }
    // --------
  }

  getCategoryListWithCount() {
    this.courseService.getCategoryListWithCounts().subscribe((res: any) => {
      if (res.returncode == '300') {
        this.allinOneService.setCookie(
          'categorieswithcounts',
          JSON.stringify(res.datalist) || 'undefined'
        );
        this.allinOneService.categorieswithcounts = res.datalist;
      }
    });
  }

  getCoursesWithChannel(recentCourse: boolean = false): void {
    this.gettingMoreCourses = true;
    console.log('blah blah');
    console.log(this.customHomepage);
    this.courseService.getCoursesWithChannel(this.end, recentCourse).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.isError = false;
          if (this.customHomepage) {
            this.customHomepageCourses = res.data;
            console.log('customHomepageCourses', this.customHomepage);
          } else {
            if (recentCourse) {
              this.recentCourses = res.data || [];
              // get only first 4
              this.recentCourses = this.recentCourses.slice(0, 4);
            } else {
              this.nochannelId.push.apply(this.nochannelId, res.data);
              this.mandatoryCourses = res.mandatorycourses || [];
              this.end = res.end;
              if (res.end == '' || res.data.length < 16) {
                this.isAll = true;
              }
            }
          }
        } else {
          this.isError = true;

          if (res.returncode == '210') {
            setTimeout(() => {
              this.router.navigateByUrl('login');
              this.allinOneService.logout();
            }, 2000);
          } else {
            this.messageService.openSnackBar(
              res.status || res.message || 'Server Error',
              'warn'
            );
          }
        }
        this.isLoading = false;
        this.gettingMoreCourses = false;
      },
      (err) => {
        this.isLoading = false;
        this.gettingMoreCourses = false;
        this.isError = true;
      }
    );
  }

  goChannelPage(channel: any) {
    // console.log(channelid);
    this.router.navigateByUrl(`channels/${channel}/courses`);
  }

  goCourse(course: any) {
    this.router.navigateByUrl(`course/channel/${course.courseid}`);
  }

  handleImgError(ev: any) {
    let source = ev.srcElement;
    let imgSrc = 'https://assets.khub.cloud/essential/defaultimage.jpg';
    source.src = imgSrc;
  }

  playedvideo() {
    this.playedVid = true;

    console.log('ok');
  }

  goToRegisterForm(course: any) {
    this.router.navigateByUrl(`course/home/${course.courseid}`);
  }
  goToLearning(event: any, course: any) {
    event.stopPropagation();
    this.router.navigateByUrl(`/courses/${course.courseid}/lectures/`);
  }
  imgLoaded(ev: any) {
    let source = ev.srcElement;
  }
  checkVideoUrl(url: string) {
    //youtube
    var videoUrlPreview = '';
    if (url.toString().startsWith('https://youtu.be/')) {
      videoUrlPreview =
        'https://www.youtube.com/embed/' +
        url.split('/').pop()?.replace('&', '?').replace('t=', 'start=');
      if (videoUrlPreview.includes('start=') && videoUrlPreview.endsWith('s')) {
        videoUrlPreview = videoUrlPreview.substring(
          0,
          videoUrlPreview.length - 1
        );
      }
    } else if (url.toString().startsWith('https://www.youtube.com')) {
      videoUrlPreview =
        'https://www.youtube.com/embed/' +
        url.split('v=').pop()?.replace('&', '?').replace('t=', 'start=');
      if (videoUrlPreview.includes('start=') && videoUrlPreview.endsWith('s')) {
        videoUrlPreview = videoUrlPreview.substring(
          0,
          videoUrlPreview.length - 1
        );
      }
    } else {
      //vimeo
      // https://vimeo.com/568270540
      // https://player.vimeo.com/video/568270540
      videoUrlPreview =
        'https://player.vimeo.com/video/' + url.split('/').pop();
    }

    return videoUrlPreview;
  }

  scrolledDown() {
    if (!this.isAll && !this.gettingMoreCourses && !this.customHomepage) {
      this.gettingMoreCourses = true;
      this.getCoursesWithChannel();
    }
  }
}
