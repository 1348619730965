import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getVideoId',
  pure: false
})
export class GetVideoIdPipe implements PipeTransform {
  transform(value: string, type: string): string {
    if (value) {
      if (type == 'youtube') {
        // get video id from youtube embed url . video url may contain other parameters
        let videoId = value.split('embed/')[1].split('?')[0];
        return videoId;
      } else if (type == 'vimeo') {
        // get video id from video url https://player.vimeo.com/video/, video url may contain other parameters
        let videoId = value.split('video/')[1].split('?')[0];
        return videoId;
      }
      else{
        return '';
      }
    } else {
      return '';
    }
  }
}
