<ng-container *ngIf="!allinOneService.showLearningPath">
  <app-breadcrumb *ngIf=" deviceService.isDesktop() && filterType == 1" [s_breadcrumb]="'My Courses'"></app-breadcrumb>
  <app-breadcrumb *ngIf=" deviceService.isDesktop() && filterType == 2" [s_breadcrumb]="'My Courses'"
    [a_breadcrumb]="'Search'" (emit)="clearSearch()"></app-breadcrumb>
</ng-container>

<section class="p-3 p-sm-0 pt-sm-2 mb-2" [ngClass]="{'pt-lg-4' : !allinOneService.showLearningPath}">
  <div class="container" style="margin-bottom: 1rem;">
    <mat-tab-group mat-align-tabs="start" *ngIf="allinOneService.showLearningPath"
      (selectedIndexChange)="tabChange($event)" [selectedIndex]="selectedTabIndex">
      <mat-tab>
        <ng-template mat-tab-label class="lable-tab">
          My Courses
        </ng-template>

        <div class="m-3">
          <ng-container *ngTemplateOutlet="mycourses"></ng-container>
        </div>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label class="lable-tab">
          Learning Track
        </ng-template>
        <div>
          <app-learningpath-list></app-learningpath-list>
        </div>
      </mat-tab>
    </mat-tab-group>
    <ng-container *ngIf="!allinOneService.showLearningPath">
      <ng-container *ngTemplateOutlet="mycourses"></ng-container>
    </ng-container>
  </div>
</section>



<ng-template #mycourses>
  <div *ngIf="!isError">
    <div class="mb-4" (click)="clearSearch()" *ngIf="filterType == 2 && !deviceService.isDesktop()">
      <span style="font-size: 18px; font-weight: 500;">My Courses</span>
    </div>
    <div *ngIf="isMobileView" class="my-3">
      <div class="input-group">
        <input type="text" class="form-control" id="Search" placeholder="Search" aria-describedby="Search" required
          [(ngModel)]="searchText">
        <!-- <div class="input-group-prepend"> -->
        <button class="btn btn-custom" (click)="search()" [disabled]="!searchText">
          <i class="fa fa-search"></i>
        </button>
        <!-- </div> -->
      </div>
    </div>
    <div class="mb-3 d-flex justify-content-between align-items-end">
      <div class="d-flex align-items-end" [ngClass]="{'w-100' : isMobileView || isTabletView}">
        <div class="form-group"
          [ngClass]="{'me-3' : filterType == 1 || !isMobileView,'w-50' : (isMobileView || isTabletView) && filterType == 1, 'w-100' : (isMobileView || isTabletView) && filterType == 2}">
          <label for="sortby" class="mb-2">Sort By</label>
          <select class="form-select custom-dropdown" id="sortby" name="sortby" (change)="sortChanged($event)"
            [(ngModel)]="filterForm.sortby">
            <option value="1">Recently Accessed</option>
            <option value="2">Title: A-Z</option>
            <option value="3">Title: Z-A</option>
          </select>
        </div>
        <!-- <div class="form-group me-3" *ngIf="filterType == 1 && !isMobileView && !isTabletView">
          <label for="channels" class="mb-2">Channels</label>
          <select class="form-select custom-dropdown" id="channels" name="channels"
            (change)="filterChanged($event, 1)" [(ngModel)]="filterForm.channelid">
            <option value="all">All</option>
            <option *ngFor="let chl of channels" [value]="chl.channelid">{{chl.name}}</option>
          </select>
        </div> -->
        <div class="form-group me-3" *ngIf="filterType == 1 && !isMobileView && !isTabletView">
          <label for="categories" class="mb-2">Categories</label>
          <select class="form-select custom-dropdown" id="categories" name="categories"
            (change)="filterChanged($event, 2)" [(ngModel)]="filterForm.categoryid">
            <option value="">All</option>
            <option *ngFor="let category of categories" [value]="category.categoryid">
              {{category.categoryname}}</option>
          </select>
        </div>
        <div class="form-group me-3" *ngIf="filterType == 1 && !isMobileView && !isTabletView">
          <label for="categories" class="mb-2">Status</label>
          <select class="form-select custom-dropdown" id="categories" name="categories"
            (change)="filterChanged($event, 3)" [(ngModel)]="filterForm.status">
            <option value="">All</option>
            <option *ngFor="let status of statusList" [value]="status.id">
              {{status.name}}</option>
          </select>
        </div>
        <div class="d-flex align-items-center me-3" style="height: 38px; cursor: pointer; color: var(--main-color);"
          (click)="resetFilter()" *ngIf="!isMobileView && !isTabletView && filterType == 1"
          [ngClass]="{'reset-disabled' : filterForm.categoryid == '' && filterForm.channelid == 'all' && filterForm.sortby == '1'}">
          Reset
        </div>
        <div *ngIf="(isMobileView || isTabletView) && filterType == 1"
          [ngClass]="{'w-50' : isMobileView || isTabletView, 'me-3' : isTabletView}">
          <button class="btn btn-custom" style="width: 100%" (click)="refine()">Refine</button>
        </div>
      </div>
      <div *ngIf="!isMobileView">
        <div class="input-group">
          <input type="text" class="form-control" id="Search" placeholder="Search" aria-describedby="Search" required
            [(ngModel)]="searchText">
          <!-- <div class="input-group-prepend">
            <button class="btn btn-custom" (click)="search()" [disabled]="!searchText">
              <i class="fa fa-search"></i>
            </button>
          </div> -->
          <button class="btn btn-custom" (click)="search()" [disabled]="!searchText">
            <i class="fa fa-search"></i>
          </button>
        </div>
      </div>
    </div>
  </div>

  <ng-container *ngIf="!isLoading; else elseTemplate;">
    <div *ngIf="!isError">
      <div class="row" *ngIf="courses.length > 0">
        <div class="col-12 col-lg-3 col-sm-6 mb-3 course-card" *ngFor="let course of courses ;let c= index;">
          <mat-card (click)="goToLearning(course)">
            <div class="img-container">

              <img width="270" height="175" [src]="allinOneService.imgurl + course.courseimage"
                alt="Photo of a Shiba Inu" (error)="handleImgError($event)">
              <span class="badge bg-warning p-2 text-dark status"
                *ngIf="course.classcode != '' && course.classname != ''"
                style="text-overflow: ellipsis;white-space: nowrap;width: auto;overflow: hidden;max-width: 120px;">{{course.classcode}}</span>
              <!-- *ngIf="course.completedpercentage > 0" -->
            </div>

            <mat-card-content>
              <div class="course-header-title mb-2">
                <font> {{course.coursename}}</font>
              </div>
              <!-- <span class="course-header-subtitle">{{course.coursesubname}}</span> -->
              <span class="course-header-instructor">{{course.instructorname}}</span>

              <div class="unit-progress" *ngIf="course.completedpercentage > 0">
                <div class="progress">
                  <div class="progress-bar progress-bar-striped" role="progressbar"
                    [ngStyle]="{'width': course.completedpercentage + '%'}" aria-valuenow="25" aria-valuemin="0"
                    aria-valuemax="100"></div>
                </div>
                <div class="progress-per">
                  <strong>{{course.completedpercentage}}%</strong> complete
                </div>
              </div>

              <div class="unit-progress mt-2" *ngIf="course.completedpercentage == 0">
                <div class="progress my-2">

                  <div class="progress-bar progress-bar-striped" role="progressbar"
                    [ngStyle]="{'width': course.completedpercentage + '%'}" aria-valuenow="25" aria-valuemin="0"
                    aria-valuemax="100"></div>
                </div>
                Open
              </div>
            </mat-card-content>
            <!-- <mat-card-actions>
                      <button mat-button color="primary">View Detail -></button>
                  </mat-card-actions> -->
          </mat-card>
          <!-- <div class="setting" *ngIf="!course.classcode && !course.classname">
                          <button mat-icon-button [matMenuTriggerFor]="menu">
                              <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
                          </button>
                          <mat-menu #menu="matMenu">
                              <button mat-menu-item (click)="unEnrollCourse(course.pkey,c)">Unregister</button>
                          </mat-menu>
                      </div> -->
        </div>



      </div>
      <div class="loading-center" *ngIf="courses.length == 0">
        No course found
      </div>
    </div>

    <div class="check-error" *ngIf="isError">
      <h3 style="margin: 0;">Something went wrong!, please reload</h3><br>
      <button type="button" (click)="reload()" class="btn btn-outline-secondary">
        Reload
      </button>
    </div>
  </ng-container>

  <!-- custom pagination -->

  <nav aria-label="Page navigation example" *ngIf="total_courses > 8 && !isError">
    <ul class="pagination">
      <li class="page-item" [ngClass]="{'disabled': p == 1}" (click)="previousPage()">
        <a class="page-link" aria-label="Previous">
          <span aria-hidden="true">&laquo;</span>
          <span class="sr-only">Previous</span>
        </a>
      </li>
      <li class="page-item" *ngFor="let pn of total_pages" (click)="pageChange(pn)"
        [ngClass]="{'page-active': p == pn}"> <a class="page-link">{{pn}}</a></li>
      <li class="page-item" (click)="nextPage()" [ngClass]="{'disabled': p == total_pages.length}">
        <a class="page-link" aria-label="Next">
          <span aria-hidden="true">&raquo;</span>
          <span class="sr-only">Next</span>
        </a>
      </li>
    </ul>
  </nav>


  <!-- Loading Skeleton -->
  <ng-template #elseTemplate>
    <div class="row">
      <div class="col-12 col-lg-3 col-sm-6 mb-3 course-card" *ngFor="let skeleton of skeletons;">
        <div class="img-container">
          <ngx-skeleton-loader [theme]="{ height: '160px',width: '100%'}">
          </ngx-skeleton-loader>
        </div>

        <div class="d-flex flex-column">
          <ngx-skeleton-loader [theme]="{ height: '20px',width: '70%'}"></ngx-skeleton-loader>
          <ngx-skeleton-loader [theme]="{ height: '20px',width: '50%'}"></ngx-skeleton-loader>
        </div>
      </div>
    </div>
  </ng-template>
</ng-template>