import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmDialogComponent } from '../../shared/components/confirm-dialog/confirm-dialog.componet';
import { AllInOneService } from '../../shared/services/all-in-one.service';
import { CourseService } from '../../shared/services/course.service';
import { MessageService } from '../../shared/services/message.service';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss'],
})
export class FeedbackComponent implements OnInit {
  @Input('feedbackGiven') feedbackGiven: boolean = false;
  @Input('templateid') templateid: string = '';
  @Input('isFromInstructor') isFromInstructor: boolean = false;

  @Output('emit')
  feedbackGave: EventEmitter<any> = new EventEmitter<any>();

  isLoading: boolean = false;
  isSubmitting: boolean = false;
  isGivingFeedback: boolean = false;

  // templateid: string = '9345893047'; // Hardcoded
  courseid: string = '';

  answerList: any[] = [];
  question: any[] = [];

  constructor(
    private courseService: CourseService,
    public allinOneService: AllInOneService,
    private route: ActivatedRoute,
    private router: Router,
    private messageService: MessageService,
    private dialog: MatDialog
  ) {
    this.route.params.subscribe((params) => {
      this.courseid = params['courseid'];
    });
  }

  ngOnInit(): void { }

  getFeedbackForm() {
    this.isLoading = true;
    this.courseService.getFeedbackForm(this.templateid).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.question = res.datalist;
          for (let i = 0; i < this.question.length; i++) {
            this.question[i].checkboxans = [];
            if (this.question[i].type != '003') {
              this.question[i].selected = '-1';
            } else {
              this.question[i].selected = '';
            }
          }
        } else {
          this.messageService.openSnackBar(
            res.message || res.status || 'Server Error',
            'warn'
          );
        }
        this.isLoading = false;
      },
      (err) => {
        this.isLoading = false;
      }
    );
  }

  radio(event: any, indexquestion: any, index: any) {
    this.question[indexquestion].selected = index;
    console.log(this.question);
  }

  checkbox(event: any, indexquestion: any, index: any) {
    console.log(event.currentTarget.checked);
    var checked = event.currentTarget.checked;
    if (checked) {
      if (!this.question[indexquestion].checkboxans) {
        this.question[indexquestion].checkboxans = [
          this.question[indexquestion].answerlist[index].answerid,
        ];
      } else {
        this.question[indexquestion].checkboxans.push(
          this.question[indexquestion].answerlist[index].answerid
        );
      }
    } else {
      const id = this.question[indexquestion].checkboxans.indexOf(
        this.question[indexquestion].answerlist[index].answerid
      ); // 2
      const removedDrink = this.question[indexquestion].checkboxans.splice(
        id,
        1
      );
    }

    console.log(this.question[indexquestion].checkboxans);
  }

  giveFeedback() {
    this.isGivingFeedback = true;
    this.getFeedbackForm();
  }

  cancel() {
    this.isGivingFeedback = false;
  }

  submit() {
    this.answerList = [];
    for (let i = 0; i < this.question.length; i++) {
      if (this.question[i].type == '001') {
        this.answerList.push({
          quizid: this.question[i].quizid,
          answerid:
            this.question[i].selected == '-1'
              ? '-1'
              : this.question[i].answerlist[this.question[i].selected].answerid,
        });
      } else if (this.question[i].type == '003' || this.question[i].type == '004') {
        this.answerList.push({
          quizid: this.question[i].quizid,
          answerid: this.question[i].selected.toString().trim(),
        });
      } else {
        this.answerList.push({
          quizid: this.question[i].quizid,
          answerid: this.question[i].checkboxans
            ? this.question[i].checkboxans
            : [],
        });
      }
    }

    var checkNotAnswer = this.answerList.filter(
      (ans: any) => ans.answerid == '-1' || ans.answerid.length == 0
    );
    if (checkNotAnswer.length > 0) {
      let dialogRef = this.dialog.open(ConfirmDialogComponent, {
        minWidth: '300px',
        data: {
          message:
            'You haven\'t answered all the questions. Are you sure to submit your feedback?',
        },
        scrollStrategy: new NoopScrollStrategy(),
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.isSubmitting = true;
          this.courseService
            .submitQuiz('', this.courseid, this.answerList, this.templateid)
            .subscribe(
              (res: any) => {
                if (res.returncode == '300') {
                  this.isSubmitting = false;
                  this.feedbackGiven = true;
                  this.isGivingFeedback = false;
                  this.feedbackGave.emit();
                  this.messageService.openSnackBar(
                    'Submitted successfully.',
                    'success'
                  );
                } else {
                  this.messageService.openSnackBar(
                    res.status || res.message || res.error,
                    'warn'
                  );
                  this.isSubmitting = false;
                }
              },
              (err) => {
                this.isSubmitting = false;
              }
            );
        }
      });
    } else {
      this.isSubmitting = true;
      this.courseService
        .submitQuiz('', this.courseid, this.answerList, this.templateid)
        .subscribe(
          (res: any) => {
            if (res.returncode == '300') {
              this.isSubmitting = false;
              this.feedbackGiven = true;
              this.isGivingFeedback = false;
              this.feedbackGave.emit();
              this.messageService.openSnackBar(
                'Submitted successfully.',
                'success'
              );
            } else {
              this.messageService.openSnackBar(
                res.status || res.message || res.error,
                'warn'
              );
              this.isSubmitting = false;
            }
          },
          (err) => {
            this.isSubmitting = false;
          }
        );
    }
  }

  deleteFeedback() {
    let dialogRef = this.dialog.open(ConfirmDialogComponent, {
      minWidth: '300px',
      data: {
        message:
          'Are you sure?',
      },
      scrollStrategy: new NoopScrollStrategy(),
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.courseService
          .deleteFeedBack(this.courseid)
          .subscribe(
            (res: any) => {
              if (res.returncode == '300') {
                this.isGivingFeedback = false;
                this.feedbackGiven = false;

                // this.messageService.openSnackBar(
                //   'Deleted successfully.',
                //   'success'
                // );
              } else {
                this.messageService.openSnackBar(
                  res.status || res.message || res.error,
                  'warn'
                );
                this.isSubmitting = false;
              }
            },
            (err) => {
              this.isSubmitting = false;
            }
          );
      }
    });

  }
}
