
<ng-container *ngIf="isFromInstructor; else feedbackTemplate">
  <div class="d-flex align-items-center justify-content-between mb-3">
    <div>
      <h2 class="mb-0">
        Certificate of Completion
      </h2>
    </div>
    <div class="d-flex align-items-center justify-content-center">
      No preview available for Certificate.
    </div>
  </div>

</ng-container>

<ng-template #feedbackTemplate>
<div class="d-flex align-items-center justify-content-between mb-3">
  <div *ngIf="!showmessage && !isLoading">
    <h2 class="mb-0">
      Certificate of Completion
    </h2>
  </div>
  <div *ngIf="!showmessage">
    <div class="d-flex align-items-center" *ngIf="!isLoading">
      <!-- <div class="layout-container" (click)="downloadCertificate()"> -->
      <!-- <a [href]="certificateSrc" download="test"> -->
      <div (click)="download()">
        <!-- <a download href="https://images.unsplash.com/photo-1682686581580-d99b0230064e?ixlib=rb-4.0.3&ixid=M3wxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1470&q=80" download> -->
        <div class="layout-container">
          <div class="d-flex justify-content-center">
            <img src="assets/images/download.png" alt="Page View" download class="layout-view-logo">
          </div>
          <span class="layout-label" for="flexRadioDefault1" style="cursor: pointer;">
            Download
          </span>
        </div>
      </div>
      <div class="layout-container" (click)="shareCertificate()" *ngIf="showShareButton">
        <div class="d-flex justify-content-center">
          <img src="assets/images/share.png" alt="Page View" class="layout-view-logo">
        </div>
        <span class="layout-label" for="flexRadioDefault1" style="cursor: pointer;">
          Share
        </span>
      </div>
    </div>
  </div>
</div>
<div *ngIf="!showmessage">
  <ng-container *ngIf="!isLoading; else loadingTemplate">
    <!-- <div class="d-flex justify-content-center"> -->
    <div class="template-bg" id="template-bg-preview" *ngIf="!isImgGenerated">
    </div>
    <div class="template-bg">
      <img id="yc" [src]="certificateSrc" *ngIf="isImgGenerated" style="width: 100%;">
    </div>
    <!-- </div> -->
  </ng-container>
</div>
<div *ngIf="showmessage">
  <div class="notsubmitted-feedback-container">
    <div class="cardform">
      <h2 class="mb-3">Eligibility for Certificate</h2>
      <div *ngFor="let x of message" class="d-flex align-items-center mb-2">
        <!-- <p>{{ x.eligible ?  }}</p> -->
        <mat-icon class="me-3 mb-1" [ngClass]="x.eligible ? 'success' : 'error' ">{{ x.eligible ? 'check_circle' :
          'cancel' }}</mat-icon>
        <div class="m-0 mb-1">{{x.message}}</div>
      </div>
    </div>
  </div>
</div>



<ng-template #loadingTemplate>
  <div class="nodata">
    <mat-spinner [strokeWidth]="3" [diameter]="30"></mat-spinner>
  </div>
</ng-template>

</ng-template>  