import {
  Component,
  OnInit,
  OnDestroy,
  HostListener,
  Input,
} from '@angular/core';
import { AllInOneService } from '../../shared/services/all-in-one.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Course } from '../../shared/models/Course';
import { CourseService } from '../../shared/services/course.service';
import { MessageService } from '../../shared/services/message.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-course-search-mobile',
  templateUrl: './course-search-mobile.component.html',
  styleUrls: ['./course-search-mobile.component.scss'],
})
export class CourseSearchMobileComponent implements OnInit, OnDestroy {
  @Input() isMobileView: boolean = false;

  width: any;
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.width = event.target.innerWidth;
  }

  isLoading: boolean = false;
  courses: any = [];
  searchText = '';
  category: string = '';
  end: string = '';
  p: number = 1;
  isError: boolean = false;
  channels: any = [];
  isChannel: boolean = false;
  isAll: boolean = false;
  gettingMoreCourses: boolean = false;

  mytimeout: any;

  subscription!: Subscription;

  privateCourse : boolean = false;

  constructor(
    private courseService: CourseService,
    public allinOneService: AllInOneService,
    private messageService: MessageService,
    private route: ActivatedRoute,
    public deviceService: DeviceDetectorService,
    private router: Router
  ) {
    this.searchText = this.route.snapshot.queryParamMap.get('q') || '';
    this.width = window.innerWidth;
  }

  ngOnInit(): void {
    if (!this.isMobileView) {
      this.getCourses();
    } else {
      this.isLoading = false;
    }
    this.privateCourse = this.allinOneService.channeldata.privatecourse ? this.allinOneService.channeldata.privatecourse : false;
  }

  ngOnDestroy(): void {}

  reload() {
    window.location.reload();
  }

  getCourses(): void {
    if (!this.gettingMoreCourses) {
      this.isLoading = true;
    }
    this.subscription = this.courseService
      .searchCourses(this.searchText, this.end, '', '')
      .subscribe(
        (res: any) => {
          if (res.returncode == '300') {
            this.isError = false;
            this.courses.push.apply(this.courses, res.data);
            this.end = res.end;

            if (this.end == '' || res.data.length < 16) {
              this.isAll = true;
            }
          } else {
            if (res.returncode == '210') {
              setTimeout(() => {
                this.router.navigateByUrl('login');
                this.allinOneService.logout();
              }, 2000);
            } else {
              this.messageService.openSnackBar(res.status, 'warn');
              this.isError = true;
            }
          }
          this.isLoading = false;
          this.gettingMoreCourses = false;
        },
        (err) => {
          this.isLoading = false;
          this.isError = true;
          this.gettingMoreCourses = false;
        }
      );
  }

  goToChannel(channel: any) {
    this.router
      .navigateByUrl(`channels/${channel.channelid}/courses`)
      .then(() => {
        window.location.reload();
      });
  }

  handleImgError(ev: any) {
    let source = ev.srcElement;
    let imgSrc =
      'https://bucketelearning.s3.ap-southeast-1.amazonaws.com/essential/defaultimage.jpg';
    source.src = imgSrc;
  }
  goToRegisterForm(courseid: string) {
    this.router.navigateByUrl(`course/home/${courseid}`);
  }
  goToCourseByCategoryPage(category: any) {
    if (category == 'all') {
      this.router.navigateByUrl(`courses/${category}`);
    } else {
      this.router.navigateByUrl(`courses/${category.categoryid}`);
    }
  }

  scrolledDown() {
    console.log('here');
    if (!this.isAll && !this.gettingMoreCourses) {
      this.gettingMoreCourses = true;
      this.getCourses();
    }
  }

  searchCourse() {
    clearTimeout(this.mytimeout);
    this.mytimeout = setTimeout(() => {
      this.end = '';
      this.courses = [];
      this.gettingMoreCourses = false;
      this.subscription && this.subscription.unsubscribe();
      if (this.searchText != '') {
        this.getCourses();
      }
    }, 500);
  }
}
