<div class="loading-container">
    <ng-container *ngIf="!invalid; else invalidTemplate">
        <span class="loader"></span>
    </ng-container>
    
    <ng-template #invalidTemplate>
        <div class="d-flex flex-wrap align-items-center justify-content-center">
            <div class="err-img-container">
                <img src="./../../../assets/images/error.jpg">
            </div>
            <div class="text">
                <h1>Oops!</h1>
                <p>{{errorMessage ? errorMessage: 'Something went wrong. Please double check your url and refresh the page.'}}</p>
            </div>
        </div>
    </ng-template>
</div>
