<section *ngIf="pageno == '1'">
    <div class="signup-wrapper otp-wrapper">
        <div class="mb-3">
            <!-- <img src="assets/images/khub.png" alt="" class="navbar-brand-logo"> -->

            <h4 style="font-weight: 600;" class="mb-1">Enter your Email or Phone Number</h4>
            <span>to receive a verification code.</span>
        </div>
        <form [formGroup]="forgotForm" (ngSubmit)="forgotPasswrodSubmit()">
            <div class="form-group my-2">
                <input type="text" class="form-control" autofocus placeholder="Email or Phone Number"
                    formControlName="userid"
                    [ngClass]="{ 'is-invalid': forgotFormSubmitted && forgotFormControl.userid.errors }">
                <div *ngIf="forgotFormSubmitted && forgotFormControl.userid.errors" class="invalid-feedback">
                    <div *ngIf="forgotFormControl.userid.errors?.required">
                        {{allinoneService.input_err_msg}}
                    </div>
                </div>

            </div>
            <app-button-loader [button_text]="'Forgot Password'" [width]="'100'"
                [isStillupload]="isStillUserCheckingForForgot" [margin_left]="'0'">
            </app-button-loader>
        </form>


    </div>
</section>


<section *ngIf="pageno == '2'">
    <div class="signup-wrapper otp-wrapper">
        <div class="mb-3">
            <!-- <img src="assets/images/khub.png" alt="" class="navbar-brand-logo"> -->

            <h3 style="font-weight: 600;" class="mb-3">Verification
                <span style="    font-size: 0.7rem;
                opacity: 0.8;">( {{forgotForm.value.userid}} )</span></h3>
            <span>Enter verification code</span>
        </div>
        <ng-otp-input class="mb-1" (inputStyles)="{'width': '20px'}" #ngOtpInput (onInputChange)="onOtpChange($event)"
            *ngIf="showOtpComponent" [config]="config">
        </ng-otp-input>

        <app-button-loader (click)="verify()" [button_text]="'Verify'" [width]="'100'"
            [isStillupload]="isStillVerifying" [margin_left]="'0'">
        </app-button-loader>
    </div>
</section>

<section *ngIf="pageno == '3'">
    <div class="signup-wrapper otp-wrapper">
        <div class="input-err-container" *ngIf="showPassInput">
            <h3>Password must contain the following:</h3>

            <p id="letter" class="invalid">A <b>lowercase</b> letter</p>
            <p id="capital" class="invalid">A <b>capital (uppercase)</b> letter</p>
            <p id="number" class="invalid">A <b>number</b></p>
            <p id="number" class="invalid">At least <b>8</b> characters</p>
        </div>
        <div class="mb-3">
            <!-- <img src="assets/images/khub.png" alt="" class="navbar-brand-logo"> -->

            <h4 style="font-weight: 600;" class="mb-1">Reset Password</h4>
        </div>
        <form [formGroup]="resetForm" (ngSubmit)="resetPassword()">
            <div class="form-group my-2">
                <input type="password" class="form-control" autofocus placeholder="Password" formControlName="password"
                    [ngClass]="{ 'is-invalid': resetFormSubmitted && resetFormControl.password.errors }">
                <div *ngIf="resetFormSubmitted && resetFormControl.password.errors" class="invalid-feedback">
                    <div *ngIf="resetFormControl.password.errors?.required">
                        {{allinoneService.input_err_msg}}
                    </div>
                </div>
                <app-password-strength [passwordToCheck]="resetForm.value.password"
                    (passwordStrength)="passwordValid($event)"></app-password-strength>
            </div>

            <div class="form-group my-2">
                <input type="password" class="form-control" autofocus placeholder="Confirm Password"
                    formControlName="co_password"
                    [ngClass]="{ 'is-invalid': resetFormSubmitted && resetFormControl.co_password.errors }">
                <div *ngIf="resetFormSubmitted && resetFormControl.co_password.errors" class="invalid-feedback">
                    <div *ngIf="resetFormControl.co_password.errors?.required">
                        {{allinoneService.input_err_msg}}
                    </div>
                </div>

            </div>
            <app-button-loader [button_text]="'Submit'" [width]="'100'" [isStillupload]="isStillReset"
                [margin_left]="'0'">
            </app-button-loader>
        </form>


    </div>
</section>