import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AllInOneService } from '../../shared/services/all-in-one.service';
import { CourseService } from '../../shared/services/course.service';
import { MessageService } from '../../shared/services/message.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-authenticate',
  templateUrl: './authenticate.component.html',
  styleUrls: ['./authenticate.component.scss']
})
export class AuthenticateComponent implements OnInit, OnDestroy {

  // isLoading: boolean = false;
  retry: boolean = false;
  width: number = 1024;
  nextroute: string = '/home';

  constructor(
    private router: Router,
    public allinoneService: AllInOneService,
    private courseService: CourseService,
    private messageService: MessageService,
    private route: ActivatedRoute,
    private location: Location,
  ) { 
    this.width = window.screen.width;
    if (this.width > 1024) {
      this.nextroute = '/home/courses';
    }
  }
  

  ngOnDestroy(): void {
    this.allinoneService.hideNavBar = false;
  }

  ngOnInit(): void {
    if(this.allinoneService.getAtoken()){
      
      this.router.navigateByUrl(this.nextroute);
    }
    else{
      this.router.navigateByUrl('/home');
    }
  }



}
