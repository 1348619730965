import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AllInOneService } from '../../shared/services/all-in-one.service';
import { CourseService } from '../../shared/services/course.service';
import { MessageService } from '../../shared/services/message.service';

@Component({
  selector: 'app-submission-details',
  templateUrl: './submission-details.component.html',
  styleUrls: ['./submission-details.component.scss']
})
export class SubmissionDetailsComponent implements OnInit {

  assignment: any;

  constructor(
    public dialogRef: MatDialogRef<SubmissionDetailsComponent>,
    public allinoneService: AllInOneService,
    private messageService: MessageService,
    private courseService: CourseService,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { 
    this.assignment = data.assignment;
  }

  ngOnInit(): void {
  }

  dismiss(){
    this.dialogRef.close();
  }


  downloadFile(){
    window.open(this.assignment.fileurl, '_blank');
  }

}
