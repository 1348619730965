<ng-container *ngIf="(!isLoading && !error);else elseTemplate">
  <div class="container">
    <!-- <h1 style=" font-weight: 700;" class = "px-3">{{coursename}}</h1> -->
    <div class="d-flex flex-column card-body">
      <div class="p-3">
        <h2>
          <strong>Payment Success</strong> <i class="fas fa-check-circle ms-2"></i>
        </h2>
        <div class="d-flex flex-column">
          <div class="mb-3 d-flex justify-content-between">
            <div class="label">
              Order Number
            </div>
            <div>
              {{orderno}}
            </div>
          </div>
          <div class="mb-3 d-flex justify-content-between">
            <div class="label">
              Transaction Number
            </div>
            <div>
              {{transactiono}}
            </div>
          </div>
          <div class="mb-3 d-flex justify-content-between">
            <div class="label">
              Amount
            </div>
            <div>
              {{amount}}&nbsp;{{currency}}
            </div>
          </div>
          <div class="mb-3 d-flex justify-content-between">
            <div class="label">
              Payment Type
            </div>
            <div>
              {{paymenttype}}
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex flex-column p-3">
        <h2 style=" font-weight: 700;">{{coursename}}</h2>
        <img class="img-fluid rounded" (error)="allinoneService.handleImgError($event)"
          [src]="allinoneService.imgurl + courseimage" alt="" style="width: 500px;height: auto;" />

        <div class="flex-column text-sm-start my-2" *ngIf="coursetype != '002'">
          <button mat-flat-button color="primary" class="mx-0 py-1" (click)="openCourse()">
            Open Course
          </button>

          <button mat-stroked-button color="primary" class="mx-2 py-1" (click)="home()">
            Maybe Later
          </button>
        </div>
        <div class="flex-column text-sm-start my-2" *ngIf="coursetype == '002'">
          <div>
            <small><i class ="fas fa-info-circle me-2"></i>This course is instructor-led. Instructor will add you to the class. Please contact instructor for more details.</small>
          </div>
          <button mat-stroked-button color="primary" class="my-2 py-1" (click)="home()">
            Go to Home
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-container>
<ng-template #elseTemplate>
  <div class="loading-container">
    <span class="loader" *ngIf="isLoading"></span>
    <span *ngIf="error">
      <h3 style="margin: 0;">Something went wrong!</h3><br>
      <button type="button" (click)="home()" class="btn btn-outline-secondary">
        Go to Home
      </button>
    </span>
  </div>
</ng-template>