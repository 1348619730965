<app-breadcrumb [s_breadcrumb]="'Schedule'"></app-breadcrumb>

<div class="container pb-5">


  <ng-container *ngIf="!isLoading;else elseTemplate">

    <!-- <ng-container>
      <mat-accordion>
        <mat-expansion-panel class="exp-pan" [expanded]="panelOpenState" (opened)="panelOpenState = true"
          (closed)="panelOpenState = false">
          <mat-expansion-panel-header>
            <mat-panel-title class="pan-title">
              My Schedule
            </mat-panel-title>
          </mat-expansion-panel-header>

        </mat-expansion-panel>
      </mat-accordion>
    </ng-container>
  </ng-container> -->


    <ng-container>
      <mat-accordion>
        <mat-expansion-panel class="exp-pan" [expanded]="panelOpenState1" (opened)="panelOpenState1 = true"
          (closed)="panelOpenState1 = false">
          <mat-expansion-panel-header style="background-color: white;">
            <mat-panel-title class="pan-title">
              My Schedule
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="table-responsive" *ngIf="!isMobileView">
            <table class="table">
              <thead class="table-light">
                <tr>
                  <th>Course Name</th>
                  <th>Class Name</th>
                  <th>Class Code</th>
                  <th>Instructor</th>
                  <th>Start Date</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody *ngIf="myschedule.length == 0">
                <tr>
                  <td colspan="6">
                    <span class="nodata">
                      Empty
                    </span>
                  </td>
                </tr>
              </tbody>
              <tbody *ngIf="myschedule.length > 0">
                <tr *ngFor="let class of myschedule">
                  <td>{{class.course.coursename}}</td>
                  <td>{{class.classname}}</td>
                  <td>{{class.classcode}}</td>
                  <td>{{class.course.instructorname}}</td>
                  <td>{{allinOneService.formatDBToShow(class.startdate)}}</td>
                  <td>
                    <span class="badge bg-orange" *ngIf="class.upcoming">
                      Upcoming Training
                    </span>
                    <span class="badge bg-main" *ngIf="!class.upcoming && class.classstatus == '001'">
                      Ongoing Training
                    </span>
                    <span class="badge bg-danger" *ngIf="!class.upcoming && class.classstatus == '002'">
                      Inactive Tranining
                    </span>
                    <span class="badge bg-success" *ngIf="!class.upcoming && class.classstatus == '003'">
                      Completed Tranining
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div *ngIf="isMobileView" class="myschedule">

            <div *ngIf="myschedule.length == 0">
              <span class="nodata">
                Empty
              </span>
            </div>
            <div class="card card-body p-2 flex-column mb-2" *ngFor="let class of myschedule">
              <div class="mb-2 coursename">
                {{class.course.coursename}}
              </div>
              <div class="d-flex flex-row justify-content-between mb-1">
                <div>
                  {{class.classcode}}
                </div>
                <div>
                  {{class.course.instructorname}}
                </div>
              </div>
              <div class="d-flex flex-row justify-content-between">
                <div>
                  {{allinOneService.formatDBToShow(class.startdate)}}
                </div>
                <div>
                  <span class="badge bg-orange" *ngIf="class.upcoming">
                    Upcoming Training
                  </span>
                  <span class="badge bg-main" *ngIf="!class.upcoming && class.classstatus == '001'">
                    Ongoing Training
                  </span>
                  <span class="badge bg-danger" *ngIf="!class.upcoming && class.classstatus == '002'">
                    Inactive Tranining
                  </span>
                  <span class="badge bg-success" *ngIf="!class.upcoming && class.classstatus == '003'">
                    Completed Tranining
                  </span>
                </div>
              </div>
            </div>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </ng-container>

    <!-- <ng-container>
      <div class="category-title d-flex">
        My Schedule
      </div>
      <div class="table-responsive" *ngIf="!isMobileView">
        <table class="table">
          <thead class="table-light">
            <tr>
              <th>Course Name</th>
              <th>Class Name</th>
              <th>Class Code</th>
              <th>Instructor</th>
              <th>Start Date</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody *ngIf="myschedule.length == 0">
            <tr>
              <td colspan="6">
                <span class="nodata">
                  Empty
                </span>
              </td>
            </tr>
          </tbody>
          <tbody *ngIf="myschedule.length > 0">
            <tr *ngFor="let class of myschedule">
              <td>{{class.course.coursename}}</td>
              <td>{{class.classname}}</td>
              <td>{{class.classcode}}</td>
              <td>{{class.course.instructorname}}</td>
              <td>{{allinOneService.formatDBToShow(class.startdate)}}</td>
              <td>
                <span class="badge bg-orange" *ngIf="class.upcoming">
                  Upcoming Training
                </span>
                <span class="badge bg-main" *ngIf="!class.upcoming && class.classstatus == '001'">
                  Ongoing Training
                </span>
                <span class="badge bg-danger" *ngIf="!class.upcoming && class.classstatus == '002'">
                  Inactive Tranining
                </span>
                <span class="badge bg-success" *ngIf="!class.upcoming && class.classstatus == '003'">
                  Completed Tranining
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div *ngIf="isMobileView" class="myschedule">
        <div class="card card-body p-2 flex-column mb-2" *ngFor="let class of myschedule">
          <div class="mb-2 coursename">
            {{class.course.coursename}}
          </div>
          <div class = "d-flex flex-row justify-content-between mb-1">
            <div>
              {{class.classcode}}
            </div>
            <div>
              {{class.course.instructorname}}
            </div>
          </div>
          <div class="d-flex flex-row justify-content-between">
            <div>
              {{allinOneService.formatDBToShow(class.startdate)}}
            </div>
            <div>
              <span class="badge bg-orange" *ngIf="class.upcoming">
                Upcoming Training
              </span>
              <span class="badge bg-main" *ngIf="!class.upcoming && class.classstatus == '001'">
                Ongoing Training
              </span>
              <span class="badge bg-danger" *ngIf="!class.upcoming && class.classstatus == '002'">
                Inactive Tranining
              </span>
              <span class="badge bg-success" *ngIf="!class.upcoming && class.classstatus == '003'">
                Completed Tranining
              </span>
            </div>
          </div>

        </div>
      </div>
    </ng-container> -->
    <!-- <div class="category-title d-flex" *ngIf="currentCourses.length > 0">
    Ongoing Courses
  </div> -->
    <ng-container *ngIf="currentCourses.length > 0 && channelid != '' ">
      <mat-accordion>
        <mat-expansion-panel class="exp-pan" [expanded]="panelOpenState2" (opened)="panelOpenState2 = true"
          (closed)="panelOpenState2 = false">
          <mat-expansion-panel-header>
            <mat-panel-title class="pan-title">
              Ongoing Courses
            </mat-panel-title>
          </mat-expansion-panel-header>

          <div class="row" *ngIf="currentCourses.length > 0">
            <div class="col-12 col-lg-3 col-sm-12 mb-3 course-card " *ngFor="let course of currentCourses ">
              <!-- <mat-card (click)="goToRegisterForm(course.courseid)"> -->
              <mat-card [class.course-container]="course.courseid" (click)="goToRegisterForm(course.courseid)">
                <div class="img-container">
                  <img width="270" height="175" [src]="course.imageurl || course.courseimage" alt="Photo of the course"
                    (error)="allinOneService.handleImgError($event)">
                    <div *ngIf="course.courseid" class="view-details">
                      <span class="badge bg-primary badge-open">View Details</span>
                    </div>
                </div>

                <mat-card-content>
                  <div class="course-header-title mb-2">
                    <font> {{course.name}} </font>
                  </div>
                  <!-- <span class="course-header-instructor">{{course.instructorname}}</span> -->
                  <!-- <span class="course-header-category">{{course.categoryname}}</span> -->
                  <span class="course-header-category">
                    {{allinOneService.formatDBToShow(course.startdate)}} -
                    {{allinOneService.formatDBToShow(course.enddate)}}
                  </span>
                  <span class="course-fee" *ngIf="course.paymenttype == '001'">Free</span>
                  <span class="course-fee" *ngIf="course.paymenttype == '002'">{{course.currency}}&nbsp;{{course.amount
                    |
                    number:
                    '1.'}}</span>
                </mat-card-content>
              </mat-card>

              <div class="setting" *ngIf="course.enroll == 'true'">
                <span class="badge bg-primary">Enrolled</span>
              </div>

              <div class="borderstype"></div>
              
            </div>
            <div *ngIf="currentCourses.length > 0 && !isOngoingAll && !loadingMoreOngoingCourses">
              <div class="d-flex align-items-center justify-content-center loadmore" (click)="loadMore('002')">
                Load more
              </div>
            </div>

            <ng-container *ngIf="loadingMoreOngoingCourses && !isMobileView">
              <div class="col-12 col-lg-3 col-sm-6 mb-3 course-card" *ngFor="let skeleton of [1,2,3,4];">
                <div class="img-container">
                  <ngx-skeleton-loader [theme]="{ height: '160px',width: '100%'}">
                  </ngx-skeleton-loader>
                </div>

                <div class="d-flex flex-column">
                  <ngx-skeleton-loader [theme]="{ height: '20px',width: '70%'}"></ngx-skeleton-loader>
                  <ngx-skeleton-loader [theme]="{ height: '20px',width: '50%'}"></ngx-skeleton-loader>
                </div>
              </div>
            </ng-container>

            <ng-container *ngIf="loadingMoreOngoingCourses && isMobileView">
              <div class="col-12 col-lg-6 col-sm-12 mb-3 course-card" *ngFor="let skeleton of [1,2];">
                <ngx-skeleton-loader [theme]="{ height: '20px',width: '100%'}"></ngx-skeleton-loader>
                <ngx-skeleton-loader [theme]="{ height: '20px',width: '80%'}"></ngx-skeleton-loader>
              </div>
            </ng-container>

          </div>
          <div class="d-flex justify-content-center align-items-center p-5" style="height: 300px;"
            *ngIf="currentCourses.length == 0">
            No course found
          </div>

        </mat-expansion-panel>
      </mat-accordion>
    </ng-container>

    <!-- <div class="category-title d-flex">
    Upcoming Courses
  </div> -->
    <ng-container *ngIf="channelid != ''">
      <mat-accordion>
        <mat-expansion-panel class="exp-pan" [expanded]="panelOpenState3" (opened)="panelOpenState3 = true"
          (closed)="panelOpenState3 = false">
          <mat-expansion-panel-header>
            <mat-panel-title class="pan-title">
              Upcoming Courses
            </mat-panel-title>
          </mat-expansion-panel-header>

          <div class="row" *ngIf="!isLoading">
            <div class="col-12 col-lg-3 col-sm-12 mb-3 course-card " *ngFor="let course of upcomingCourses ">
              <!-- <mat-card (click)="goToRegisterForm(course.courseid)"> -->
              <mat-card [class.course-container]="course.courseid" [class.no-click]="!course.courseid" (click)="goToRegisterForm(course.courseid)">
                <div class="img-container">
                  <img width="270" height="175" [src]="course.imageurl || course.courseimage" alt="Photo of the course"
                    (error)="allinOneService.handleImgError($event)">
                    <div *ngIf="course.courseid" class="view-details">
                      <span class="badge bg-primary badge-open">View Details</span>
                    </div>
                </div>

                <mat-card-content>
                  <div class="course-header-title mb-2">
                    <font> {{course.name}} </font>
                  </div>
                  <!-- <span class="course-header-instructor">{{course.instructorname}}</span>

                  <span class="course-header-category">{{course.categoryname}}</span> -->
                  <span class="course-header-category">
                    {{allinOneService.formatDBToShow(course.startdate)}} -
                    {{allinOneService.formatDBToShow(course.enddate)}}
                  </span>
                  <!-- <span class="course-fee" *ngIf="course.paymenttype == '001'">Free</span>
                  <span class="course-fee" *ngIf="course.paymenttype == '002'">{{course.currency}}&nbsp;{{course.amount
                    |
                    number:
                    '1.'}}</span> -->

                </mat-card-content>
              </mat-card>
              <!-- <div class="setting" *ngIf="course.enroll == 'true'">
          <span class="badge bg-primary">Enrolled</span>
        </div> -->
              <div class="borderstype"></div>
            </div>
            <div *ngIf="upcomingCourses.length > 0 && !isUpcomingAll && !loadingMoreUpcomingCourses">
              <div class="d-flex align-items-center justify-content-center loadmore" (click)="loadMore('003')">
                Load more
              </div>
            </div>

            <ng-container *ngIf="loadingMoreUpcomingCourses && !isMobileView">
              <div class="col-12 col-lg-3 col-sm-6 mb-3 course-card" *ngFor="let skeleton of [1,2,3,4];">
                <div class="img-container">
                  <ngx-skeleton-loader [theme]="{ height: '160px',width: '100%'}">
                  </ngx-skeleton-loader>
                </div>

                <div class="d-flex flex-column">
                  <ngx-skeleton-loader [theme]="{ height: '20px',width: '70%'}"></ngx-skeleton-loader>
                  <ngx-skeleton-loader [theme]="{ height: '20px',width: '50%'}"></ngx-skeleton-loader>
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="loadingMoreUpcomingCourses && isMobileView">
              <div class="col-12 col-lg-6 col-sm-12 mb-3 course-card" *ngFor="let skeleton of [1,2];">
                <ngx-skeleton-loader [theme]="{ height: '20px',width: '100%'}"></ngx-skeleton-loader>
                <ngx-skeleton-loader [theme]="{ height: '20px',width: '80%'}"></ngx-skeleton-loader>
              </div>
            </ng-container>


            <div class="d-flex justify-content-center align-items-center p-5" style="height: 300px;"
              *ngIf="upcomingCourses.length == 0">
              No course found
            </div>
          </div>

        </mat-expansion-panel>
      </mat-accordion>
    </ng-container>
  </ng-container>

  <!-- Loading Skeleton -->
  <ng-template #elseTemplate>
    <article class="article d-flex" *ngFor="let n of [0,1,2,3,4,5,6]">
      <div class="left-article">
        <div>
          <ngx-skeleton-loader [theme]="{ height: '20px',width: '100%'}"></ngx-skeleton-loader>
          <ngx-skeleton-loader [theme]="{ height: '20px',width: '80%'}"></ngx-skeleton-loader>
        </div>
      </div>
    </article>
  </ng-template>
</div>