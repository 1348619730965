import { Component, OnInit, Renderer2, RendererStyleFlags2, ViewChild, OnDestroy } from '@angular/core';
import { AllInOneService } from '../../shared/services/all-in-one.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { PlyrComponent } from 'ngx-plyr';
import * as Plyr from 'plyr';
import { CourseService } from '../../shared/services/course.service';
import { MessageService } from '../../shared/services/message.service';
import { LoadingService } from '../../shared/services/loading.service';
import { SEOService } from '../../shared/services/seo.service';


@Component({
  selector: 'app-course-learn-preview',
  templateUrl: './course-learn-preview.component.html',
  styleUrls: ['../course-learn/course-learn.component.scss']
})
export class CourseLearnPreviewComponent implements OnInit {


  course: any = {};  //Student side used as "lecture" and Teacher side used as "unit"
  instructor: any;
  courseid: string = "";
  unitid: string = "";
  lecture: any = {};

  isLoading: boolean = true;
  isDocumentLoading: boolean = false;
  timeIntervalFrame: any;

  tab_active: number = 1;

  @ViewChild(PlyrComponent)
  plyr!: PlyrComponent;
  videoid: string = "";
  videotype: Plyr.Provider = "vimeo";

  // or get it from plyrInit event
  player!: Plyr;

  videoSources: Plyr.Source[] = [
    {
      src: this.videoid,
      provider: this.videotype,
    },
  ];
  show: boolean = false;

  plyrevent: any;

  completedunitlist: any = []

  isError: boolean = false;

  YT: any;
  video: any;


  constructor(private courseService: CourseService,
    private _seoService : SEOService,
    public allinOneService: AllInOneService,
    private location: Location,
    private route: ActivatedRoute,
    private router: Router,
    public domSanitizer: DomSanitizer,
    private renderer: Renderer2,
    private messageService: MessageService,
    private loadingService: LoadingService,
  ) {
  }

  // ngAfterViewInit() {
  //   const doc = (<any>window).document;
  //   const playerApiScript = doc.createElement('script');
  //   playerApiScript.type = 'text/javascript';
  //   playerApiScript.src = 'https://www.youtube.com/iframe_api';
  //   doc.body.appendChild(playerApiScript);
  // }

  // init() {
  //   (<any>window).onYouTubeIframeAPIReady = () => {
  //     this.player = new (<any>window).YT.Player('player', {
  //       height: '100%',
  //       width: '100%',
  //       // videoId: this.getVideo(),
  //       events: {
  //         'onReady': (event: any) => { this.onPlayerReady(event); },
  //         'onStateChange': (event: any) => { this.onPlayerStateChange(event); }
  //       },
  //       playerVars: {
  //         autoplay: 1,
  //         controls: 0,
  //         modestbranding: 1,
  //         // playlist: 'UG3sfZKtCQI,ALZHF5UqnU4,x9ZkC3OgI78',
  //         rel: 0,
  //         showInfo: 0
  //       }
  //     });
  //   };
  // }
  ngOnDestroy() {
    // alert();
    // this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    // this.router.onSameUrlNavigation = 'reload';
  }

  async ngOnInit(): Promise<any> {
    // this.init();


    this.route.params.subscribe(params => {
      this.courseid = params['courseid'];
      this.unitid = params['lectureid'];
    });
    const awaitGetcourse = await this.getCourse();
    if (awaitGetcourse == "success") {
      this.getInstructor();
    }
    else {
      this.isLoading = false;
    }
  }

  // The API calls this function when the player's state changes.
  onPlayerStateChange(event: any) {
    console.log('onPlayerStateChange');
    console.log(event.data);
  }

  // The API will call this function when the video player is ready
  onPlayerReady(event: any) {
    console.log(event);

    const videoId = this.getVideo();
    event.target.cueVideoById({
      'videoId': videoId
    });
    event.target.playVideo();
  }

  getVideo() {
    return '60ItHLz5WEA';
  }

  navigateBack() {
    this.location.back();
  }

  getCourse() {
    return new Promise(resolve => {
      this.courseService.getCourseByCourseIDForPreview(this.courseid).subscribe(async (res: any) => {
        if (res.returncode == "300") {
          this.course = res.data;

          this._seoService.updateTitle(this.course.coursename);

          this._seoService.updateImg(this.allinOneService.imgurl + this.course.courseimage);

          if (this.course.sections.length > 0) {
            this.course.sections[0].show = "show";
            if (this.course.sections[0].subsections.length > 0) {
              this.course.sections[0].subsections[0].show = "show";
              if (this.course.sections[0].subsections[0].units.length > 0) {
                // this.location.replaceState(`courses/${this.courseid}/lectures/${this.course.sections[0].subsections[0].units[0].unitid}`)
                var unit = {};
                if (this.unitid) {
                  unit = { unitid: this.unitid };
                }
                else {
                  unit = { unitid: this.course.sections[0].subsections[0].units[0].unitid };
                }

                this.viewLecture(unit);
              }
            }
          }

          console.log(this.course);
          resolve('success');
        }
        else {
          if (res.returncode == "210") {
            setTimeout(() => {
              this.router.navigateByUrl('login');
              this.allinOneService.logout();
            }, 2000);
          }
          else{
          this.messageService.openSnackBar(res.status, 'warn');

          }
          this.isError = true;
          setTimeout(() => {
            if (res.returncode == "404") {
              this.location.back();
            }
          }, 3000);
          resolve('error');
        }
      }, err => {
        this.isError = true;

        resolve('error');
      })

    });
  }


  getInstructor() {
    this.courseService.getInstructor(this.course.instructorid).subscribe((res: any) => {
      if (res.returncode == "300") {
        this.instructor = res;
        this.isLoading = false;

        this._seoService.updateDescription(this.instructor.username);
      }
      else {
        this.messageService.openSnackBar(res.status, 'warn');
        setTimeout(() => {
          if (res.returncode == "404") {
            this.location.back();
          }
        }, 3000);
      }
    }, err => {
      this.isLoading = false;
    });
  }
  getUnitProgress() {
    return new Promise(resolve => {
      this.courseService.getCompletedUnits(this.course.pkey).subscribe((res: any) => {
        if (res.returncode == "300") {
          this.completedunitlist = res.unitlist == "" ? [] : res.unitlist;
          resolve('success');
        }
        else {
          if (res.returncode == "210") {
            setTimeout(() => {
              this.router.navigateByUrl('login');
              this.allinOneService.logout();
            }, 2000);
          }
          else{
          this.messageService.openSnackBar(res.status, "warn");
          }
          resolve('error');
        }
      }, err => {
        resolve('error');
      });

    })
  }


  updateCompletedUnits(unit: any) {
    this.messageService.openSnackBar("This course is in preview mode.", 'warn');
  }
  reload() {
    window.location.reload();
  }
  viewLecture(lecture: any) {
    // this.location.replaceState(`courses/${this.courseid}/lectures/${lecture.unitid}`)
    //fina a lecture [matching with url]
    this.location.replaceState(`courses/preview/${this.courseid}/lectures/${lecture.unitid}`);


    console.log(this.course);

    this.course.sections.map((section: any) => {
      section.subsections.map((subsection: any) => {
        subsection.units.filter((unit: any) => unit.unitid == lecture.unitid).map((unit: any) => {
          console.log(unit);
          this.lecture = unit;
          lecture.active = unit.unitid;
        })
      })
    });

    // this.lecture = lecture;
    console.log(this.lecture);
    if (this.lecture.contenttype == 1) {
      //youtube
      if (this.lecture.content.toString().startsWith('https://youtu.be/')) {
        this.lecture.content = 'https://www.youtube.com/embed/' + this.lecture.content.split('/').pop();
        this.videoid = this.lecture.content.split('/').pop();
        this.videotype = "youtube";


      }
      //vimeo
      else if (this.lecture.content.toString().startsWith('https://vimeo.com/')) { //vimeo
        this.lecture.content = 'https://player.vimeo.com/video/' + this.lecture.content.split('/').pop();
        this.videoid = this.lecture.content.split('/').pop();
        this.videotype = "vimeo";
      }

      this.videoSources = [
        {
          src: this.videoid,
          provider: this.videotype
        }
      ]
    }

    if (this.lecture.contenttype == 2) {
      this.isDocumentLoading = true;
      const doc = document.getElementById("doc");
      console.log(doc);

      if (doc) {
        doc.style.height = "0px !important";
        doc.style.width = "0px !important";
      }
      setTimeout(() => {
        this.isDocumentLoading = false;
        if (doc) {
          doc.style.height = "auto !important";
          doc.style.width = "auto !important";
        }
      }, 5000);
    }

    //   const element = document.getElementById("progressBar") as HTMLElement;
    //   const doc = document.getElementById("doc") as HTMLElement;

    //   console.log(element);
    //   if (element) {
    //     element.style.display = "block";
    //   }
    //   if (doc) {
    //     doc.style.display = "none";
    //   }
    // }
  }


  tabClick(tab: number) {

    this.tab_active = tab;
    // var myOffcanvas = document.getElementById('offcanvasTop')
    // var bsOffcanvas = new bootstrap.Offcanvas(myOffcanvas);

    // bsOffcanvas.show();
  }
  played(event: any) {
    // console.log(event.target.type);
    // this.plyrevent = event;
    // var logger = document.querySelector('#log');
    // if (logger) {
    //   if (typeof this.plyrevent == 'object') {
    //     logger.innerHTML += JSON.stringify(this.plyrevent) + '<br/>';
    //   } else {
    //     // logger.innerHTML += this.plyrevent + '<br />';
    //   }
    // }

  }
  plyrError(event: Plyr.PlyrEvent) {
    console.log("plyrError: ", event);
    this.plyrevent = JSON.stringify(event);
    var logger = document.querySelector('#log');
    if (logger) {
      if (typeof this.plyrevent == 'object') {
        logger.innerHTML += JSON.stringify(this.plyrevent) + '<br/>';
      } else {
        // logger.innerHTML += this.plyrevent + '<br />';
      }
    }
  }
  play(): void {
    this.plyr.player.muted = true;
    this.plyr.player.muted = false;
    this.plyr.player.play();
  }

  plyrEnded(event: Plyr.PlyrEvent): void {
    var unit: any = [];
    this.course.sections.map((section: any) => {
      section.subsections.map((subsection: any) => {
        unit = subsection.units.filter((el: any) => el.unitid == this.unitid);
        if (unit.length == 1) {
          this.updateCompletedUnits(unit[0])
          unit[0].checked = true;

          this.videoSources = [
            {
              src: this.videoid,
              provider: this.videotype
            }
          ]
        }
      });
    })


    // this.player.currentTime = 20;
    // this.player.muted = false;
    // this.player.play();
  }
  documentLoaded() {
    // this.isDocumentLoading = false;
    // const element = document.getElementById("progressBar") as HTMLElement;
    // const doc = document.getElementById("doc") as HTMLElement;

    // element.style.display = "none";
    // if (doc) {
    //   doc.style.display = "block";
    // }

    // document.getElementById("progressBar").display = "none";
  }

  closeCourseContent() {
    const content = document.querySelector('.course-content');
    this.renderer.setStyle(content, 'width', "0px", RendererStyleFlags2.Important);

    const learn = document.querySelector('.learn-content');
    this.renderer.setStyle(learn, 'width', '100%', RendererStyleFlags2.Important);
    this.renderer.setStyle(learn, 'margin-left', '0px', RendererStyleFlags2.Important);
  }
  openCourseContent() {
    const content = document.querySelector('.course-content');
    this.renderer.setStyle(content, 'width', "25%", RendererStyleFlags2.Important);

    const learn = document.querySelector('.learn-content');
    this.renderer.setStyle(learn, 'width', '75%', RendererStyleFlags2.Important);
    this.renderer.setStyle(learn, 'margin-left', '26%', RendererStyleFlags2.Important);
  }


  handleImgError(ev: any) {
    let source = ev.srcElement;
    let imgSrc = 'https://bucketelearning.s3.ap-southeast-1.amazonaws.com/essential/defaultimage.jpg';
    source.src = imgSrc;
  }
}
