import { Component, OnInit } from '@angular/core';
import { AllInOneService } from '../../shared/services/all-in-one.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-learningpath-list',
  templateUrl: './learningpath-list.component.html',
  styleUrls: ['./learningpath-list.component.scss']
})
export class LearningpathListComponent implements OnInit {

  pathList = this.allinOneService.learningPathList;

  constructor(public allinOneService: AllInOneService, private router: Router, private route: ActivatedRoute) { 
    
  }

  ngOnInit(): void {
    
  }

  goToRegisterForm(learningpathid: string){
    this.router.navigateByUrl(`home/learningpath/${learningpathid}`);
  }

}
