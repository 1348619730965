<div class="m-4">
  <div *ngFor="let section of course.sections;let s = index;" [ngClass]="{'section-lock': !section.unlock}">
    <div>
      <div [id]="'heading' + s" class="section position-relative">
        <span class="index-label">
          {{s+1}}.0
        </span>
        <span class="me-auto">
          {{section.sectionname}}
        </span>
        <span>
          <i class="fa fa-lock me-3" *ngIf="!section.unlock"></i>
        </span>
      </div>
      <div *ngFor="let subsection of section.subsections;let ss = index;">
        <div class="subsection position-relative"
          *ngIf="((subsection.units.length > 1 || subsection.units.length == 0 )&& subsection.subsectionname != '') || (subsection.units.length == 1 && !(subsection.units[0].unitname == '' || subsection.subsectionname == '' || subsection.units[0].unitname == subsection.subsectionname))">
          <span *ngIf="subsection.subsectionname == '' " style="position: absolute;
                    font-size: 10px;
                    color: gray;
                    font-weight: normal;">
            {{s+1}}.{{ss+1}}
          </span>
          <span *ngIf="subsection.subsectionname != '' ">
            <span *ngIf="subsection.subsectionname == section.sectionname" style="position: absolute;
                    font-size: 10px;
                    color: gray;
                    font-weight: normal;">
              {{s+1}}.{{ss+1}}

            </span>
            <span *ngIf="subsection.subsectionname != section.sectionname">
              <span class="sub-index-label"> {{s+1}}.{{ss+1}} </span>
              <span>{{subsection.subsectionname}}</span>
            </span>
          </span>
        </div>
        <div class="unit"
          *ngIf="((subsection.units.length > 1 || subsection.units.length == 0) && subsection.subsectionname == '') || (subsection.units.length == 1 && (subsection.units[0].unitname == '' || subsection.subsectionname == '' || subsection.units[0].unitname == subsection.subsectionname))">
          <div class="mt-3">
            <ul class="unit-list">
              <li *ngFor="let unit of subsection.units;let su = index;" class="justify-content-between pad-right"
                style="position: relative;">
                <div class="mx-2 unit">
                  <div *ngIf="unit.contenttype == 1" class="d-flex align-items-center position-relative">
                    <span class="unit-index-label">
                      {{s+1}}.{{ss+1}}<span *ngIf="subsection.units.length > 1">.{{su+1}}</span>
                    </span>
                    <p>
                      <span><img [src]="'../../../assets/images/' +unit.icon+ '.png'" class="unit-icon"
                          [ngClass]="unit.icon"></span>
                      <a [href]="unit.content" target="_blank" *ngIf="section.unlock"> {{unit.unitname ? unit.unitname :
                        subsection.subsectionname ? subsection.subsectionname : unit.content}}
                      </a>
                      <span *ngIf="!section.unlock"> {{unit.unitname ? unit.unitname :
                        subsection.subsectionname ? subsection.subsectionname : ''}}
                      </span>
                    </p>
                  </div>
                  <div *ngIf="unit.contenttype == 2" class="d-flex align-items-center position-relative">
                    <span class="unit-index-label">
                      {{s+1}}.{{ss+1}}<span *ngIf="subsection.units.length > 1">.{{su+1}}</span>
                    </span>
                    <p>
                      <span><img [src]="'../../../assets/images/' +unit.icon+ '.png'" class="unit-icon"></span>

                      <a [href]="unit.libraryid ? unit.downloadurl : allinoneService.docurl + unit.content"
                        target="_blank" *ngIf="section.unlock"> {{unit.unitname ? unit.unitname :
                        subsection.subsectionname ? subsection.subsectionname :
                        unit.content}}
                      </a>

                      <span *ngIf="!section.unlock"> {{unit.unitname ? unit.unitname :
                        subsection.subsectionname ? subsection.subsectionname :
                        ''}}
                      </span>
                    </p>
                  </div>
                  <div *ngIf="unit.contenttype == 3" class="d-flex align-items-center position-relative">
                    <span class="unit-index-label">
                      {{s+1}}.{{ss+1}}<span *ngIf="subsection.units.length > 1">.{{su+1}}</span>
                    </span>
                    <p>
                      <span>
                        <i class="fa fa-file-text me-1" aria-hidden="true"></i>
                      </span>
                      <span> {{unit.unitname ? unit.unitname :
                        ''}}
                      </span>
                    </p>
                  </div>
                  <div *ngIf="unit.contenttype == 4" class="d-flex align-items-center position-relative">
                    <span class="unit-index-label">
                      {{s+1}}.{{ss+1}}<span *ngIf="subsection.units.length > 1">.{{su+1}}</span>
                    </span>
                    <p>
                      <span>
                        <i class="fas fa-link me-1" aria-hidden="true"></i>

                      </span>
                      <a [href]="unit.content" target="_blank" *ngIf="section.unlock"> {{unit.unitname ? unit.unitname :
                        subsection.subsectionname ? subsection.subsectionname : unit.content}}
                      </a>
                      <span *ngIf="!section.unlock"> {{unit.unitname ? unit.unitname :
                        subsection.subsectionname ? subsection.subsectionname : ''}}
                      </span>
                    </p>
                  </div>
                  <div *ngIf="unit.contenttype == 5" class="d-flex align-items-center position-relative">
                    <span class="unit-index-label">
                      {{s+1}}.{{ss+1}}<span *ngIf="subsection.units.length > 1">.{{su+1}}</span>
                    </span>
                    <p>
                      <span>
                        <i class="fas fa-paperclip me-1"></i>
                      </span>
                      <a [href]="unit.libraryid ? unit.downloadurl : allinoneService.docurl + unit.content"
                        target="_blank" *ngIf="section.unlock">
                        {{unit.unitname ? unit.unitname :
                        unit.content}}
                      </a>
                      <span *ngIf="!section.unlock">
                        {{unit.unitname ? unit.unitname :
                        ''}}
                      </span>

                    </p>
                  </div>
                  <div *ngIf="unit.contenttype == 6" class="d-flex align-items-center position-relative">
                    <span class="unit-index-label">
                      {{s+1}}.{{ss+1}}<span *ngIf="subsection.units.length > 1">.{{su+1}}</span>
                    </span>
                    <p>
                      <span>

                        <i class="fas fa-clipboard-list me-1"></i>
                      </span>
                      <span>
                        {{unit.unitname ? unit.unitname :
                        'Quiz'}}
                      </span>
                    </p>
                  </div>
                  <div *ngIf="unit.contenttype == 7" class="d-flex align-items-center position-relative">
                    <span class="unit-index-label">
                      {{s+1}}.{{ss+1}}<span *ngIf="subsection.units.length > 1">.{{su+1}}</span>
                    </span>
                    <p>
                      <span>
                        <img [src]="'./../../assets/images/assignment.png'" class="unit-icon">
                      </span>
                      <span> {{unit.unitname ? unit.unitname :
                        ''}}
                      </span>
                    </p>
                  </div>
                  <div *ngIf="unit.contenttype == 8" class="d-flex align-items-center position-relative">
                    <span class="unit-index-label">
                      {{s+1}}.{{ss+1}}<span *ngIf="subsection.units.length > 1">.{{su+1}}</span>
                    </span>
                    <p>
                      <span>
                        <img [src]="'./../../assets/images/discusison.png'" class="unit-icon">
                      </span>
                      <span> {{unit.unitname ? unit.unitname :
                        ''}}
                      </span>
                    </p>
                  </div>
                </div>
                <div *ngIf="(unit.contenttype == 3 || unit.contenttype == 7 || unit.contenttype == 8) && section.unlock"
                  class="writeup">
                  <div [innerHtml]="unit.content">
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div
          *ngIf="((subsection.units.length > 1 || subsection.units.length == 0) && subsection.subsectionname != '') || (subsection.units.length == 1 && !(subsection.units[0].unitname == '' || subsection.subsectionname == '' || subsection.units[0].unitname == subsection.subsectionname))">
          <div>
            <ul class="unit-list">
              <li *ngFor="let unit of subsection.units;let su = index;" class="justify-content-between pad-right"
                style="position: relative;">
                <div class="mx-2 unit">
                  <div *ngIf="unit.contenttype == 1" class="d-flex align-items-center position-relative">
                    <span class="unit-index-label">
                      {{s+1}}.{{ss+1}}.{{su+1}}
                    </span>
                    <p>
                      <span><img [src]="'../../../assets/images/' +unit.icon+ '.png'" class="unit-icon"
                          [ngClass]="unit.icon"></span>

                      <a [href]="unit.content" target="_blank" *ngIf="section.unlock"> {{unit.unitname ? unit.unitname :
                        unit.content}}
                      </a>
                      <span *ngIf="!section.unlock"> {{unit.unitname ? unit.unitname :
                        ''}}
                      </span>
                    </p>
                  </div>
                  <div *ngIf="unit.contenttype == 2" class="d-flex align-items-center position-relative">
                    <span class="unit-index-label">
                      {{s+1}}.{{ss+1}}.{{su+1}}
                    </span>
                    <p>
                      <span><img [src]="'../../../assets/images/' +unit.icon+ '.png'" class="unit-icon"></span>
                      <a [href]="unit.libraryid ? unit.downloadurl : allinoneService.docurl + unit.content"
                        target="_blank" *ngIf="section.unlock">
                        {{unit.unitname ? unit.unitname :
                        unit.content}}
                      </a>
                      <span *ngIf="!section.unlock">
                        {{unit.unitname ? unit.unitname :
                        ''}}
                      </span>


                    </p>
                  </div>
                  <div *ngIf="unit.contenttype == 3" class="d-flex align-items-center position-relative">
                    <span class="unit-index-label">
                      {{s+1}}.{{ss+1}}.{{su+1}}
                    </span>
                    <p>
                      <span>
                        <i class="fa fa-file-text me-1" aria-hidden="true"></i>
                      </span>
                      <span> {{unit.unitname ? unit.unitname :
                        ''}}
                      </span>

                    </p>
                  </div>

                  <div *ngIf="unit.contenttype == 4" class="d-flex align-items-center position-relative">
                    <span class="unit-index-label">
                      {{s+1}}.{{ss+1}}.{{su+1}}
                    </span>
                    <p>
                      <span>
                        <i class="fas fa-link me-1" aria-hidden="true"></i>

                      </span>

                      <a [href]="unit.content" target="_blank" *ngIf="section.unlock"> {{unit.unitname ? unit.unitname :
                        unit.content}}
                      </a>

                      <span *ngIf="!section.unlock"> {{unit.unitname ? unit.unitname :
                        ''}}
                      </span>
                    </p>
                  </div>
                  <div *ngIf="unit.contenttype == 5" class="d-flex align-items-center position-relative">
                    <span class="unit-index-label">
                      {{s+1}}.{{ss+1}}.{{su+1}}
                    </span>
                    <p>
                      <span>

                        <i class="fas fa-paperclip me-1"></i>
                      </span>

                      <a [href]="unit.libraryid ? unit.downloadurl : allinoneService.docurl + unit.content"
                        target="_blank" *ngIf="section.unlock">
                        {{unit.unitname ? unit.unitname :
                        unit.content}}
                      </a>
                      <span *ngIf="!section.unlock">
                        {{unit.unitname ? unit.unitname :
                        ''}}
                      </span>
                    </p>
                  </div>
                  <div *ngIf="unit.contenttype == 6" class="d-flex align-items-center position-relative">
                    <span class="unit-index-label">
                      {{s+1}}.{{ss+1}}.{{su+1}}
                    </span>
                    <p>
                      <span>

                        <i class="fas fa-clipboard-list me-1"></i>
                      </span>
                      <span>
                        {{unit.unitname ? unit.unitname :
                        'Quiz'}}
                      </span>
                    </p>
                  </div>
                  <div *ngIf="unit.contenttype == 7" class="d-flex align-items-center position-relative">
                    <span class="unit-index-label">
                      {{s+1}}.{{ss+1}}.{{su+1}}
                    </span>
                    <p>
                      <span>
                        <img [src]="'./../../assets/images/assignment.png'" class="unit-icon">
                      </span>
                      <span> {{unit.unitname ? unit.unitname :
                        ''}}
                      </span>

                    </p>
                  </div>
                  <div *ngIf="unit.contenttype == 8" class="d-flex align-items-center position-relative">
                    <span class="unit-index-label">
                      {{s+1}}.{{ss+1}}.{{su+1}}
                    </span>
                    <p>
                      <span>
                        <img [src]="'./../../assets/images/discussion.png'" class="unit-icon">
                      </span>
                      <span> {{unit.unitname ? unit.unitname :
                        ''}}
                      </span>

                    </p>
                  </div>
                </div>
                <div *ngIf="(unit.contenttype == 3 || unit.contenttype == 7 || unit.contenttype == 8) && section.unlock"
                  class="writeup">
                  <div [innerHtml]="unit.content">
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>


    </div>
  </div>
</div>