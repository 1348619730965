import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AllInOneService } from '../../shared/services/all-in-one.service';
import { CourseService } from '../../shared/services/course.service';
import { MessageService } from '../../shared/services/message.service';
import { SEOService } from '../../shared/services/seo.service';

@Component({
  selector: 'app-scheduled-courses',
  templateUrl: './scheduled-courses.component.html',
  styleUrls: ['./scheduled-courses.component.scss'],
})
export class ScheduledCoursesComponent implements OnInit {
  width: any;
  isMobileView: boolean = false;
  panelOpenState1: boolean = true;
  panelOpenState2: boolean = true;
  panelOpenState3: boolean = true;

  isOngoingAll: boolean = false;
  isUpcomingAll: boolean = false;

  loadingMoreOngoingCourses: boolean = false;
  loadingMoreUpcomingCourses: boolean = false;

  ongoingEnd: string = '';
  upcomingEnd: string = '';
  channelid: string = '';

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.width = event.target.innerWidth;
    if (this.width < 1024) {
      this.isMobileView = true;
    } else {
      this.isMobileView = false;
    }
  }

  isLoading: boolean = false;
  isScheduleLoading: boolean = false;

  currentCourses: any[] = [];
  upcomingCourses: any[] = [];
  myschedule: any[] = [];

  constructor(
    private courseService: CourseService,
    public allinOneService: AllInOneService,
    private messageService: MessageService,
    private route: ActivatedRoute,
    private _seoService: SEOService,
    private router: Router
  ) {
    if (this.allinOneService.getAtoken() == '') {
      this.router.navigateByUrl('/');
    }
    this._seoService.updateTitle('- Schedule');
  }

  ngOnInit(): void {
    this.channelid = this.allinOneService.getChannelid();
    this.width = window.innerWidth;
    if (this.width < 1024) {
      this.isMobileView = true;
    }
    if (this.channelid != '') {
      this.getCourses();
    } else {
      this.getMySchedule();
    }
  }

  getMySchedule(): void {
    this.courseService.getMySchedule().subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.myschedule = res.datalist;
        } else {
          if (res.returncode == '210') {
            setTimeout(() => {
              this.router.navigateByUrl('login');
              this.allinOneService.logout();
            }, 2000);
          } else {
            this.messageService.openSnackBar(res.status, 'warn');
          }
        }
        this.isLoading = false;
      },
      (err) => {
        this.isLoading = false;
      }
    );
  }

  getCourses(): void {
    this.isLoading = true;
    this.courseService
      .getScheduledCourse(this.ongoingEnd, this.upcomingEnd)
      .subscribe(
        (res: any) => {
          if (res.returncode == '300') {
            this.currentCourses = res.currentcourses;
            this.upcomingCourses = res.upcomingcourses;
            this.upcomingEnd = res.upcommingend;
            this.ongoingEnd = res.ongoingend;
            if (this.upcomingEnd == '' || this.upcomingCourses.length < 4) {
              this.isUpcomingAll = true;
            }
            if (this.ongoingEnd == '' || this.currentCourses.length < 4) {
              this.isOngoingAll = true;
            }
            this.getMySchedule();
          } else {
            if (res.returncode == '210') {
              setTimeout(() => {
                this.router.navigateByUrl('login');
                this.allinOneService.logout();
              }, 2000);
            } else {
              this.messageService.openSnackBar(res.status, 'warn');
            }
            this.isLoading = false;
          }
          this.loadingMoreOngoingCourses = false;
        },
        (err) => {
          this.isLoading = false;
        }
      );
  }

  goToRegisterForm(courseid: string) {
    if(courseid != ''){
      this.router.navigateByUrl(`course/schedule/${courseid}`);
    }
  }

  //002 - ongoing, 003 - upcoming
  loadMore(type: string) {
    if (
      (type == '002' && this.loadingMoreOngoingCourses) ||
      (type == '003' && this.loadingMoreUpcomingCourses)
    ) {
      return;
    }
    if (type == '002') {
      this.loadingMoreOngoingCourses = true;
    } else {
      this.loadingMoreUpcomingCourses = true;
    }

    this.courseService
      .getScheduledCourse(this.ongoingEnd, this.upcomingEnd)
      .subscribe(
        (res: any) => {
          if (res.returncode == '300') {
            if (type == '003') {
              this.upcomingEnd = res.upcommingend;
              this.upcomingCourses.push.apply(
                this.upcomingCourses,
                res.upcomingcourses
              );
              if (this.upcomingEnd == '' || this.upcomingCourses.length < 4) {
                this.isUpcomingAll = true;
              }
            } else {
              this.currentCourses.push.apply(
                this.currentCourses,
                res.currentcourses
              );
              this.ongoingEnd = res.ongoingend;
              if (this.ongoingEnd == '' || this.currentCourses.length < 4) {
                this.isOngoingAll = true;
              }
            }
          } else {
            if (res.returncode == '210') {
              setTimeout(() => {
                this.router.navigateByUrl('login');
                this.allinOneService.logout();
              }, 2000);
            } else {
              this.messageService.openSnackBar(res.status, 'warn');
            }
          }
          this.loadingMoreOngoingCourses = false;
          this.loadingMoreUpcomingCourses = false;
        },
        (err) => {
          this.loadingMoreOngoingCourses = false;
          this.loadingMoreUpcomingCourses = false;
        }
      );
  }
}
