<div class="d-flex align-items-center justify-content-between">
  <div class="mb-3">
    <h2 class="mb-0">
      Submission Details
    </h2>
  </div>
  <div class="mb-3">
    <mat-icon style="cursor: pointer;" (click)="dismiss()">close</mat-icon>
  </div>
</div>
<ul class="list-group mb-3">
  <li class="list-group-item">
    <div class="row">
      <div class="col-6">
        <div class="col-12 col-sm-12 col-md-12 col-lg-12">
          Title
        </div>
        <div class="col-12 col-sm-12 col-md-12 col-lg-12 mr-text">
          {{assignment.title || '-'}}
        </div>
      </div>
      <div class="col-6">
        <div class="col-12 col-sm-12 col-md-12 col-lg-12">
          File
        </div>
        <div class="col-12 col-sm-12 col-md-12 col-lg-12 mr-text">
          <span>
            <span class="cus-point filename" (click)="downloadFile()">{{assignment.filename}}</span>
            <i class="fa fa-download ms-2 cus-point" (click)="downloadFile()"></i>
          </span>
        </div>
      </div>
    </div>
  </li>
  <li class="list-group-item">
    <div class="row">
      <div class="col-6">
        <div class="col-12 col-sm-12 col-md-12 col-lg-12">
          Remark
        </div>
        <div class="col-12 col-sm-12 col-md-12 col-lg-12 mr-text">
          {{assignment.remark || '-'}}
        </div>
      </div>
      <div class="col-6">
        <div class="col-12 col-sm-12 col-md-12 col-lg-12">
          Submitted Date
        </div>
        <div class="col-12 col-sm-12 col-md-12 col-lg-12 mr-text">
          {{ allinoneService.formatDBToShow(assignment.date)
          }}&nbsp;{{assignment.time}}
        </div>
      </div>
    </div>
  </li>
  <li class="list-group-item">
    <div class="row">
      <div class="col-12">
        <div class="col-12 col-sm-12 col-md-12 col-lg-12">
          Grade
        </div>
        <div class="col-12 col-sm-12 col-md-12 col-lg-12 mr-text">
          <span *ngIf="assignment.grade" class="grade">
            {{assignment.unitid ? assignment.grade +"/" +assignment.totalgrade : assignment.grade}}
          </span>
          <span *ngIf="!assignment.grade">-</span>
        </div>
      </div>
    </div>
  </li>
  <li class="list-group-item">
    <div class="row">
      <div class="col-12">
        <div class="col-12 col-sm-12 col-md-12 col-lg-12">
          Feedback
        </div>
        <div class="col-12 col-sm-12 col-md-12 col-lg-12 mr-text">
          {{assignment.feedback || '-'}}
        </div>
      </div>
    </div>
  </li>

</ul>