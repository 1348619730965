import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AllInOneService } from '../../shared/services/all-in-one.service';
import { CourseService } from '../../shared/services/course.service';
import { MessageService } from '../../shared/services/message.service';

@Component({
  selector: 'app-fail',
  templateUrl: './fail.component.html',
  styleUrls: ['./fail.component.scss'],
})
export class FailComponent implements OnInit {
  orderno: string = '';
  refno: string = '';
  currency: string = '';
  amount: string = '';
  paymenttype: string = '';
  transactiono: string = '';
  courseid: string = '';
  courseimage: string = '';
  coursename : string = '';
  coursetype: string = '';
  coursestatus: string = '';

  isLoading : boolean = true;
  error: boolean = false;

  constructor(
    private courseService: CourseService,
    private messageService: MessageService,
    public allinoneService: AllInOneService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.allinoneService.hideNavBar = true;
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.orderno = params['order_no'];
      this.refno = params['ref_no'];
      this.transactiono = params['transact_no'];
      this.currency = params['currency'];
      this.paymenttype = params['payment_type'];
      this.amount = params['price'];
    });
    if (!this.orderno || !this.refno || !this.transactiono) {
      this.error = true;
      this.isLoading = false;
    }
    else{
      this.updatePayment(); 
    }

  }

  home() {
    this.router.navigateByUrl('/home');
  }

  mailto(){
    window.open('mailto:'+ this.allinoneService.adminmail+'?subject=Regarding Payment', "_blank")
  }

  updatePayment() {
    this.courseService.updatePayment(this.orderno, this.refno, this.transactiono, '002').subscribe((res: any) => {
      if(res.returncode == "300"){
        this.courseimage = res.courseimage;
        this.coursename= res.coursename;
        this.coursetype= res.coursetype;
        this.coursestatus= res.coursestatus;
        this.courseid = res.courseid;
        // this.location.replaceState('/payment/success');
        this.allinoneService.hideNavBar = false;
        this.isLoading = false;
      }
      else{
        this.isLoading = false;
        this.messageService.openSnackBar(res.message || res.error || res.status, 'warn');
        this.error = true;
      }
    }, err=> {
      this.isLoading = false;
      this.error = true;
    })
  }

  reload(){
    window.location.reload();
  }
}
