import { QuestionComponent } from './quiz/question/question.component';
import { CourseByChannelComponent } from './students/course-by-channel/course-by-channel.component';
import { NgModule } from '@angular/core';
import { RouteReuseStrategy, RouterModule, Routes } from '@angular/router';
import { CourseRegisterComponent } from './students/course-register/course-register.component';
import { CourseDashboardComponent } from './students/course-dashboard/course-dashboard.component';
import { CourseLearnComponent } from './students/course-learn/course-learn.component';
import { TabsComponent } from './students/tabs/tabs.component';
import { LoginComponent } from './students/login/login.component';
import { VerifyComponent } from './students/verify/verify.component';
import { AuthGuard } from './shared/auth/auth.guard';
import { CourseSearchMobileComponent } from './students/course-search-mobile/course-search-mobile.component';
import { CourseByCategoryComponent } from './students/course-by-category/course-by-category.component';
import { ForgotPasswordComponent } from 'projects/e-learning-instructor/src/app/instructor/forgot-password/forgot-password.component';
import { SignupComponent } from './students/signup/signup.component';
import { CourseLearnPreviewComponent } from './students/course-learn-preview/course-learn-preview.component';
import { AuthenticateComponent } from './students/authenticate/authenticate.component';
import { SuccessComponent } from './payment/success/success.component';
import { FailComponent } from './payment/fail/fail.component';
import { CancelComponent } from './payment/cancel/cancel.component';
import { ScheduledCoursesComponent } from './students/scheduled-courses/scheduled-courses.component';
import { VerifyCertificateComponent } from './custom-page/verify-certificate/verify-certificate.component';
import { StudentReportComponent } from './reporting/student-report/student-report.component';
import { InstructorCoursesComponent } from './instructor/instructor-courses/instructor-courses.component';
import { AddToClassComponent } from './class/add-to-class/add-to-class.component';
import { LearningpathCoursesComponent } from './learningpath/learningpath-courses/learningpath-courses.component';
import { CurtomRouteReuseStrategy } from './shared/services/route-reuse.service';

const routes: Routes = [
  {
    path: '',
    component: AuthenticateComponent,
  },
  // {
  //   path: '', component: window.screen.width >= 1025 ? TabsComponent : LoginComponent
  //   // > 1025 ? Desktop View : Mobile View
  // },
  {
    path: 'verify',
    component: VerifyComponent,
  },
  {
    path: 'signup',
    component: SignupComponent,
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: ':site/forgot-password/:pageno',
    component: ForgotPasswordComponent,
  },
  {
    path: ':tab',
    component: TabsComponent,
    data: {
      shouldReuseRoute: true,
      reuseRoutesFrom: '*',
    },
  },
  {
    path: 'course/:courseby/:courseid',
    component: CourseRegisterComponent,
  },
  {
    path: 'home/:type',
    canActivate: [AuthGuard],
    component: CourseDashboardComponent,
    data: {
      shouldReuseRoute: true,
      reuseRoutesFrom: '*',
    },
  },
  {
    path: 'home/courses/schedule',
    component: ScheduledCoursesComponent,
    data: {
      shouldReuseRoute: true,
      reuseRoutesFrom: '*',
    },
  },
  {
    path: 'home/user/analytics',
    component: StudentReportComponent,
  },
  {
    path: 'courses/search',
    component: CourseSearchMobileComponent,
  },
  {
    path: 'courses/:category',
    component: CourseByCategoryComponent,
    data: {
      recent: false,
    },
  },
  {
    path: 'courses/:category/:subcategory',
    component: CourseByCategoryComponent,
    data: {
      recent: false,
    },
  },
  {
    path: 'home/courses/new-release',
    component: CourseByCategoryComponent,
    data: {
      recent: true,
    },
  },
  {
    path: 'home/:type/courses',
    component: CourseByCategoryComponent,
    data: {
      recent: false,
      custom: true
    },
  },
  {
    path: 'channels/:channelid/courses',
    component: CourseByChannelComponent,
  },
  {
    path: 'courses/:courseid/lectures/:lectureid',
    component: CourseLearnComponent,
  },
  {
    path: 'courses/preview/:courseid/lectures/:lectureid',
    canActivate: [AuthGuard],
    component: CourseLearnPreviewComponent,
  },
  {
    path: 'courses/:courseid/lectures/:sectionid/:subsectionid/:lectureid/quiz/:resultid/:grading',
    component: QuestionComponent,
  },
  {
    path: 'courses/:courseid/lectures/:sectionid/:subsectionid/:lectureid/quiz/:resultid',
    component: QuestionComponent,
  },
  {
    path: 'payment/success',
    component: SuccessComponent,
    canActivate: [AuthGuard],
  },

  {
    path: 'payment/fail',
    component: FailComponent,
    canActivate: [AuthGuard],
  },

  {
    path: 'payment/cancel/:courseid/:orderno',
    component: CancelComponent,
    canActivate: [AuthGuard],
  },

  {
    path: 'certificate/verify/:certificateid',
    component: VerifyCertificateComponent,
  },

  {
    path: 'instructor/:instructorid/:profileid',
    component: InstructorCoursesComponent,
  },

  { path: 'eLearning', redirectTo: 'eLearning/dashboard' },

  {
    path: 'class/:qrvalue',
    component: AddToClassComponent,
    canActivate: [AuthGuard],
  },

  {
    path: 'home/learningpath/:learningpathid',
    canActivate: [AuthGuard],
    component: LearningpathCoursesComponent,
  },

  // otherwise redirect to home
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [
    {
      provide: RouteReuseStrategy,
      useClass: CurtomRouteReuseStrategy,
    },
  ],
})
export class AppRoutingModule {}
