import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LoadingService } from '../../services/loading.service';

@Component({
  selector: 'app-anonymous-learning-login',
  templateUrl: './anonymous-learning-login.component.html',
  styleUrls: ['./anonymous-learning-login.component.scss'],
})
export class AnonymousLearningLoginComponent implements OnInit {
  message: string = '';

  constructor(
    public dialogRef: MatDialogRef<AnonymousLearningLoginComponent>,
    private loadingService: LoadingService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (!data) {
      this.message =
        'Do you want to save your progress by enrolling this course?';
    }
  }

  ngOnInit(): void {}

  onConfirm(): void {
    this.dialogRef.close({enroll: true});
  }

  onDismiss(): void {
    this.dialogRef.close({enroll: false});
  }
}
