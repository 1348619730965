<section class="m-3">
    <div class="row">
        <div class="col-12 col-lg-3 col-sm-6 mb-3 course-card" *ngFor="let path of pathList">
            <!--  -->
            <mat-card (click)="goToRegisterForm(path.id)">
                <!-- <div class="img-container">
                    <img width="270" height="175" [src]="path.imageurl || path.courseimage" alt="Learning Track" (error)="allinOneService.handleImgError($event)">
                </div> -->
                <mat-card-content>
                    <div class="d-flex align-items-center justify-content-between mb-2">
                        
                        <div class="course-header-title">
                        
                            <font>
                                {{path.name}}
                            </font>
                        </div>
                        <!-- <div class="ms-2">
                            <img src="../../../assets/images/learningpath.png" width="30px"/>
                        </div> -->
                    </div>
                    
                    <span class="course-header-category">
                        {{path.description}}
                    </span>
                    <span class="course-header-category">
                        <div class="d-flex justify-content-between">
                            <div>
                                Courses
                            </div>
                            <div class="info">
                                {{path.coursecount}}
                            </div>
                        </div>
                    </span>
                    <span class="course-header-category">
                        <div class="d-flex justify-content-between">
                            <div>
                                Due Date
                            </div>
                            <div class="info">
                                {{allinOneService.formatDBToShow(path.enddate)}}
                            </div>
                        </div>
                    </span>
                    <!-- <span class="course-header-instructor">{{course.instructorname}}</span>
              <span class="course-header-category">{{course.categoryname}}</span>
              <span class="course-fee" *ngIf="course.paymenttype == '001'">Free</span>
              <span class="course-fee" *ngIf="course.paymenttype == '002'">{{course.currency}}&nbsp;{{course.amount |
                number:'1.'}}</span> -->
                </mat-card-content>
            </mat-card>
        </div>
    </div>
</section>