import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AllInOneService } from '../services/all-in-one.service';

@Injectable({
  providedIn: 'root'
})

export class AuthGuard implements CanActivate {
  constructor(
    private allinOneService: AllInOneService,
    private router: Router) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): any {
    const atoken = this.allinOneService.getAtoken();
    if (!atoken || this.allinOneService.currentDomainType == '003') {
      this.allinOneService.replaceLocation(route.url.toString());
      return false;
    } else {
      return true;
    }
  }

}
