<div (window:resize)="onResize($event)">

    <!-- web view -->
    <app-home class="web-view" *ngIf="width >= 916"></app-home>

    <!-- tab [Mobile View] -->
    <mat-tab-group *ngIf="width <= 916 && isAuthenticate" [(selectedIndex)]="selectedIndex" (selectedTabChange)="onTabChanged($event);"
        class="mobile-view mat-tab-group-inverted-header home-tab flex-column" [ngClass]="{'matonIos': isiPhone}">
        <mat-tab>
            <ng-template mat-tab-label>
                <mat-icon>star_border</mat-icon>
                <span>Featured </span>
            </ng-template>
            <app-home [isMobileView]="true"></app-home>
        </mat-tab>
        <mat-tab>
          <ng-template mat-tab-label>
              <mat-icon>access_time</mat-icon>
              <span>Schedule </span>
          </ng-template>
          <app-scheduled-courses></app-scheduled-courses>
      </mat-tab>
        <mat-tab>
            <ng-template mat-tab-label>
                <mat-icon>search</mat-icon>
                <span>Search </span>
            </ng-template>
            <app-course-search-mobile [isMobileView]="true"></app-course-search-mobile>
        </mat-tab>
        <mat-tab>
            <ng-template mat-tab-label>
                <mat-icon>play_circle</mat-icon>
                <span>My Learning </span>
            </ng-template>
            <app-course-dashboard></app-course-dashboard>
        </mat-tab>

    </mat-tab-group>
</div>