import { Component, Input, OnInit } from '@angular/core';
import { AllInOneService } from '../../shared/services/all-in-one.service';

@Component({
  selector: 'app-course-learn-pageview',
  templateUrl: './course-learn-pageview.component.html',
  styleUrls: ['./course-learn-pageview.component.scss']
})
export class CourseLearnPageviewComponent implements OnInit {

  @Input('course') course: any;

  constructor(
    public allinoneService: AllInOneService,
  ) { }

  ngOnInit(): void {
  }

}
