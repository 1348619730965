import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AllInOneService } from '../../shared/services/all-in-one.service';
import { CourseService } from '../../shared/services/course.service';

@Component({
  selector: 'app-cancel',
  templateUrl: './cancel.component.html',
  styleUrls: ['./cancel.component.scss'],
})
export class CancelComponent implements OnInit {
  courseid: string = '';
  orderno: string = '';

  constructor(
    private courseService: CourseService,
    private route: ActivatedRoute,
    private router: Router,
    private allinoneService: AllInOneService
  ) {
    this.allinoneService.hideNavBar = true;
  }

  async ngOnInit(): Promise<void> {
    this.courseid = this.route.snapshot.params['courseid'];
    this.orderno = this.route.snapshot.params['orderno'];
    const await_delete = await this.deletePayment();
    this.allinoneService.hideNavBar = false;
    this.router.navigateByUrl('/course/home/' + this.courseid);
  }

  deletePayment() {
    return new Promise((resolve) => {
      this.courseService.deletePayment(this.orderno).subscribe(
        (res: any) => {
          resolve(true);
        },
        (err) => {
          resolve(false);
        }
      );
    });
  }
}
