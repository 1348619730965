import { NgModule } from '@angular/core';
import { BrowserModule, HammerModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatCardModule } from '@angular/material/card';
import { MatExpansionModule } from '@angular/material/expansion';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatRippleModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { MatButtonModule } from '@angular/material/button';
import { MatTabsModule } from '@angular/material/tabs';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { OverlayModule } from '@angular/cdk/overlay';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTableModule } from '@angular/material/table';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatMenuModule } from '@angular/material/menu';
import { MatCheckboxModule } from '@angular/material/checkbox';


import { MatChipsModule } from '@angular/material/chips';
import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';
import { ImageCropperModule } from 'ngx-image-cropper';
import { SpinnerOverlayComponent } from './shared/components/spinner-overlay/spinner-overlay.component';
import { ConfirmDialogComponent } from './shared/components/confirm-dialog/confirm-dialog.componet';
import { AllInOneService } from './shared/services/all-in-one.service';
import { CourseRegisterComponent } from './students/course-register/course-register.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { CourseDashboardComponent } from './students/course-dashboard/course-dashboard.component';
import { CourseLearnComponent } from './students/course-learn/course-learn.component';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import { HomeComponent } from './students/home/home.component';
import { CourseSearchMobileComponent } from './students/course-search-mobile/course-search-mobile.component';
import { FilterPipe } from './filter.pipe';
import { PlyrModule } from 'ngx-plyr';
import { NgxJsonViewerModule } from 'ngx-json-viewer';
import { TopBarComponent } from './students/top-bar/top-bar.component';
import { TabsComponent } from './students/tabs/tabs.component';
import { LoginComponent } from './students/login/login.component';
import { NgOtpInputModule } from 'ng-otp-input';
import { VerifyComponent } from './students/verify/verify.component';
import { MessageService } from './shared/services/message.service';
import { ButtonLoaderComponent } from './shared/components/button-loader/button-loader.component';

import { CookieService } from 'ngx-cookie-service';
import { SafePipe } from './safe.pipe';
import { IframeTrackerDirective } from './iframe.directive';
import { CourseByCategoryComponent } from './students/course-by-category/course-by-category.component';
import { BreadcrumbComponent } from './shared/components/breadcrumb/breadcrumb.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { PasswordStrengthComponent } from 'projects/e-learning-instructor/src/app/shared/components/password-strength/password-strength.component';
import { ForgotPasswordComponent } from 'projects/e-learning-instructor/src/app/instructor/forgot-password/forgot-password.component';
import { SignupComponent } from './students/signup/signup.component';
import { CourseLearnPreviewComponent } from './students/course-learn-preview/course-learn-preview.component';
import { CourseByChannelComponent } from './students/course-by-channel/course-by-channel.component';
import { RefineDialogComponent } from './shared/components/refine-dialog/refine-dialog.component';
import { AnonymousLearningLoginComponent } from './shared/components/anonymous-learning-login/anonymous-learning-login.component';

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { DropboxComponent } from './class/dropbox/dropbox.component';
import { DragDropDirective } from './shared/directives/dragdrop.directive';
import { SwipeAngularListModule } from 'swipe-angular-list';
import { DatePipe } from '@angular/common';
import { MatSortModule } from '@angular/material/sort';
import { CourseLearnPageviewComponent } from './students/course-learn-pageview/course-learn-pageview.component';
import { MatRadioModule } from '@angular/material/radio';
import { AuthenticateComponent } from './students/authenticate/authenticate.component';
import { SuccessComponent } from './payment/success/success.component';
import { FailComponent } from './payment/fail/fail.component';
import { CancelComponent } from './payment/cancel/cancel.component';
import { AssignmentComponent } from './class/assignment/assignment.component';
import { SubmissionDetailsComponent } from './class/submission-details/submission-details.component';
import { ChartsModule } from 'ng2-charts';
import { MatGridListModule } from '@angular/material/grid-list';

import { TimeAgoPipe } from './shared/datetimeago/time-ago.pipe';
import { GradesComponent } from './custom-page/grades/grades.component';
import { ScheduledCoursesComponent } from './students/scheduled-courses/scheduled-courses.component';
import { CertificateComponent } from './custom-page/certificate/certificate.component';
import { VerifyCertificateComponent } from './custom-page/verify-certificate/verify-certificate.component';
import { FeedbackComponent } from './custom-page/feedback/feedback.component';
import { ScrollableDirective } from './students/course-by-category/scrollable.directive';
import { StudentReportComponent } from './reporting/student-report/student-report.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

import { VimeModule } from '@vime/angular';
import { InstructorCoursesComponent } from './instructor/instructor-courses/instructor-courses.component';
import { GetVideoIdPipe } from './shared/get-video-id/get-video-id.pipe';
import { AddToClassComponent } from './class/add-to-class/add-to-class.component';
import { LearningpathListComponent } from './learningpath/learningpath-list/learningpath-list.component';
import { LearningpathCoursesComponent } from './learningpath/learningpath-courses/learningpath-courses.component';
import { PdfViewerComponent } from './shared/components/pdf-viewer/pdf-viewer.component';
import { ConfirmDialogTwoComponent } from './shared/components/confirm-dialog-two/confirm-dialog-two.component';
import { QuestionComponent } from './quiz/question/question.component';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};


@NgModule({
  declarations: [
    AppComponent,
    TopBarComponent,
    ButtonLoaderComponent,
    ConfirmDialogComponent,
    SpinnerOverlayComponent,
    CourseRegisterComponent,
    CourseDashboardComponent,
    CourseLearnComponent,
    TabsComponent,
    HomeComponent,
    CourseSearchMobileComponent,
    FilterPipe,
    SafePipe,
    TimeAgoPipe,
    IframeTrackerDirective,
    LoginComponent,
    VerifyComponent,
    CourseByCategoryComponent,
    BreadcrumbComponent,
    PasswordStrengthComponent,
    ForgotPasswordComponent,
    SignupComponent,
    CourseLearnPreviewComponent,
    CourseByChannelComponent,
    RefineDialogComponent,
    AnonymousLearningLoginComponent,
    DropboxComponent,
    DragDropDirective,
    ScrollableDirective,
    CourseLearnPageviewComponent,
    QuestionComponent,
    AuthenticateComponent,
    SuccessComponent,
    FailComponent,
    CancelComponent,
    AssignmentComponent,
    SubmissionDetailsComponent,
    GradesComponent,
    ScheduledCoursesComponent,
    CertificateComponent,
    VerifyCertificateComponent,
    FeedbackComponent,
    StudentReportComponent,
    InstructorCoursesComponent,
    GetVideoIdPipe,
    AddToClassComponent,
    LearningpathListComponent,
    LearningpathCoursesComponent,
    PdfViewerComponent,
    ConfirmDialogTwoComponent,
  ],

  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    BrowserAnimationsModule,
    MatCardModule,
    DragDropModule,
    MatExpansionModule,
    MatRippleModule,
    MatFormFieldModule,
    MatButtonModule,
    MatTabsModule,
    MatProgressSpinnerModule,
    MatIconModule,
    MatDialogModule,
    MatListModule,
    MatGridListModule,
    MatTooltipModule,
    MatTableModule,
    MatProgressBarModule,
    MatCheckboxModule,
    ImageCropperModule,
    MatSnackBarModule,
    MatMenuModule,
    OverlayModule,
    AngularEditorModule,
    HttpClientModule,
    NgxSkeletonLoaderModule,
    NgxDocViewerModule,
    PlyrModule,
    NgxJsonViewerModule,
    NgOtpInputModule,
    NgxPaginationModule,
    NgxQRCodeModule,
    PerfectScrollbarModule,
    NgxExtendedPdfViewerModule,
    HammerModule,
    SwipeAngularListModule,
    MatSortModule,
    MatRadioModule,
    ChartsModule,
    VimeModule,
    InfiniteScrollModule,
    MatChipsModule
  ],

  providers: [CookieService, MessageService, AllInOneService,DatePipe,
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
    { provide: MatDialogRef, useValue: {} },
   { provide: MAT_DIALOG_DATA, useValue: [] }
],
  bootstrap: [AppComponent]
})

export class AppModule { }
