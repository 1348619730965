<div class="app-main-container">
  <ng-container *ngIf="!isLoading && !retry;else loadingTemplate">
    <!-- <app-top-bar *ngIf="!hideTopbar && !allinoneService.hideNavBar" class="d-none d-sm-none d-lg-block"></app-top-bar> -->
    <app-top-bar *ngIf="!hideTopbar && !allinoneService.hideNavBar" class="d-block"></app-top-bar>
  
    <div class="main-content">
      <router-outlet (activate)="onActivate()"></router-outlet>
    </div>
  </ng-container>
  <ng-template #loadingTemplate>
    <div class="loading-container" *ngIf="isLoading && !retry && !isMobileview">
      <span class="loader"></span>
    </div>
    <div class="loading-container-mobile" *ngIf="isLoading && !retry && isMobileview">
      <div class="splash-header">
        <div><img src="../assets/images/new-khub.png" alt=""></div>
        <span>Knowledge Hub</span>
      </div>
      <div class="load">
        <div class="snippet" data-title=".dot-flashing">
          <div class="stage">
            <div class="dot-flashing"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="loading-container" *ngIf="!isLoading && retry">
      <div class="retry">
        <h3 style="margin: 0;">Something went wrong! Please reload the page.</h3><br>
        <div class="d-flex align-items-center">
          <div>
            <button type="button" (click)="reload()" class="btn btn-outline-secondary">
              Reload
            </button>
          </div>
          <div class="mx-3">
            or
          </div>
          <div>
            <button type="button" (click)="signin()" class="btn btn-outline-secondary">
              Sign In
            </button>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</div>