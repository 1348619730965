import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LoadingService } from '../../services/loading.service';

@Component({
    selector: 'app-confirm-dialog',
    templateUrl: './confirm-dialog.component.html',
})
export class ConfirmDialogComponent implements OnInit {

    title?: string;
    message?: string;

    constructor(public dialogRef: MatDialogRef<ConfirmDialogComponent>,
        private loadingService: LoadingService,
        @Inject(MAT_DIALOG_DATA) public data: any) {
        if (!data) {
            this.message = "Are you sure?";
        }
        else{
            this.message = data.message
        }
    }

    ngOnInit() {

    }


    onConfirm(): void {
        this.dialogRef.close(true);
    }

    onDismiss(): void {
        this.dialogRef.close(false);
    }
}