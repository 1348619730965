<div class = "d-flex align-items-center justify-content-between  mb-3">
  <div class="title">Dropbox Files</div>
  <div *ngIf = "isPopup" class = "d-flex align-items-center">
    <mat-icon class = "cus-point" (click)="dismiss()">close</mat-icon>
  </div>
</div>

<ng-container *ngIf="!newSubmission; else newSubmissionTemplate">
  <div class="d-flex justify-content-between flex-wrap">
    <div class="mb-3">
      <button mat-flat-button color="primary" class="ms-0" (click)="openNewSubmission()">
        <i class="fa fa-plus me-1"></i> New Submission
      </button>
    </div>
    <div class="mb-3" style="width: 300px" *ngIf="isPopup">
      <select class="form-select" name="filtersection" id="filtersection" [(ngModel)]="filterSectionid"
        (change)="filterSectionChange($event)" matTooltip="Filter by Section">
        <option value="all">All</option>
        <option value="">General</option>
        <option [value]="section.sectionid" *ngFor="let section of sections">
          {{ section.sectionname }}
        </option>
      </select>
    </div>
  </div>
  <ng-container *ngIf="isPopup; else mobileTemplate">
    <div class="tableFixHead">
      <table class="table table-borderless" matSort (matSortChange)="sortData($event)">
        <thead>
          <tr>
            <th scope="col" style="width: 20px">#</th>
            <th scope="col" mat-sort-header="title" style="width: 200px;">Title</th>
            <th scope="col" mat-sort-header="remark">Remark</th>
            <th scope="col" mat-sort-header="grade">Grade</th>
            <th scope="col" mat-sort-header="feedback">Feedback</th>
            <th scope="col" mat-sort-header="date">Date</th>
            <th scope="col" class="text-center">Action</th>
          </tr>
        </thead>
        <tbody *ngIf="filteredList.length > 0 && !isLoading">
          <ng-container *ngFor="let item of filteredList; let index = index">
            <tr>
              <td (click)="openDetail(item)">
                <img [src]="'../../../assets/images/' + item.type + '.png'" />
              </td>
              <td [title]="item.title" (click)="openDetail(item)">
                <span>{{ item.title }}</span>
              </td>
              <td [title]="item.remark" (click)="openDetail(item)">
                <span>{{ item.remark }}</span>
              </td>
              <td (click)="openDetail(item)">
                <span *ngIf="!item.unitid">{{ item.grade || '-'}}</span>
                <span *ngIf="item.unitid">{{ item.grade ? item.grade +'/' + item.totalgrade : '-'}}</span>
              </td>
              <td [title]="item.feedback" (click)="openDetail(item)">
                <span>{{ item.feedback }}</span>
              </td>
              <td (click)="openDetail(item)">
                {{ allinoneService.formatDBToShow(item.date) }}&nbsp;{{
                item.time
                }}
              </td>
              <td class="text-center" style="min-width: 107px">
                <button type="button" class="btn btn-outline-primary me-2" matTooltip="Download File"
                  (click)="downloadFile(item.fileurl, item.filename)">
                  <i class="fa fa-download"></i>
                </button>
                <button type="button" class="btn btn-outline-danger" matTooltip="Delete File"
                  (click)="deleteFile(item.classdbid, index)" [disabled]="item.unitid && item.grade">
                  <i class="fa fa-trash"></i>
                </button>
              </td>
            </tr>
          </ng-container>
        </tbody>
        <tbody>
          <tr *ngIf="isLoading">
            <td colspan="7">
              <div class="loading-container">
                <div class="spinner-border text-primary" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
            </td>
          </tr>
          <tr *ngIf="!isLoading && filteredList.length == 0">
            <td colspan="7">
              <div class="loading-container">Empty</div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </ng-container>
  <ng-template #mobileTemplate>
    <div class="mb-3">
      <select class="form-select" name="filtersection" id="filtersection" [(ngModel)]="filterSectionid"
        (change)="filterSectionChange($event)">
        <option value="all">All</option>
        <option value="">General</option>
        <option [value]="section.sectionid" *ngFor="let section of sections">
          {{ section.sectionname }}
        </option>
      </select>
    </div>
    <div *ngIf="isLoading" class="mobile-loading-container">
      <div class="spinner-border text-primary" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
    <div *ngIf="!isLoading && filteredList.length == 0" class="mobile-loading-container">
      Empty
    </div>
    <div *ngIf="!isLoading && filteredList.length > 0">
      <sw-item-list *ngFor="let item of filteredList; let index = index" [inside]="item"
        [customTemplate]="customTemplateSrc" [item-class]="'list-custom'" [editTemplate]="editTemplate"
        [trashTemplate]="trashTemplate" (callback)="swipeAction($event, index)" (click)="openDetail(item)">
      </sw-item-list>
    </div>
    <ng-template #editTemplate>
      <i class="fa fa-download"></i>
    </ng-template>
    <ng-template #trashTemplate>
      <i class="far fa-trash-alt" style="color: red"></i>
    </ng-template>

    <ng-template #customTemplateSrc let-item="item" let-id="id">
      <div class="mobileview">
        <div class="d-flex flex-row align-items-center justify-content-between">
          <div class="d-flex flex-column">
            <div class="d-flex flex-row align-items-center">
              <div>
                <img [src]="'../../../assets/images/' + item.type + '.png'" />
              </div>
              <div class="mb-title">
                {{ item.title }}
              </div>
            </div>
            <div>
              <div class="my-1" *ngIf="item.grade">
                <span *ngIf="item.grade">
                  <span *ngIf="!item.unitid">
                    <span style="font-size: 14px;">Your grade - </span> <span style="color: green;font-size: 14px;">{{item.grade}}</span>
                  </span>
                  <span *ngIf="item.unitid">
                    <span style="font-size: 14px;">Your grade - </span> <span style="color: green;font-size: 14px;">{{item.grade +'/' +
                      item.totalgrade}}</span>
                  </span>
                </span>
                <span *ngIf="!item.grade">-</span>
              </div>
              <div class="date">
                {{ allinoneService.formatDBToShow(item.date) }}&nbsp;{{
                item.time
                }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </ng-template>
</ng-container>

<ng-template #newSubmissionTemplate>
  <form [formGroup]="form" (ngSubmit)="sumbitForm()">
    <div class="mb-3">
      <div class="d-flex align-items-center flex-row flex-wrap">
        <button mat-stroked-button class="ms-0" (click)="cancel()" [disabled]="isSaving">
          Cancel
        </button>
        <button type="submit" mat-flat-button class="ms-2" color="primary" [disabled]="isSaving">
          <span *ngIf="isSaving">
            <div class="spinner-border spinner-border-sm text-primary" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </span>
          <span *ngIf="!isSaving"> Save </span>
        </button>
      </div>
    </div>
    <div class="form-group mb-3">
      <label for="title" class="mb-2"> Title </label>
      <input type="text" class="form-control form-control-sm" placeholder="Assignment 1" formControlName="title"
        id="title" name="title" [ngClass]="{ 'is-invalid': submitted && formControl.title.errors }" />

      <div *ngIf="submitted && formControl.title.errors" class="invalid-feedback">
        <div *ngIf="formControl.title.errors?.required">
          {{ allinoneService.input_err_msg }}
        </div>
      </div>
    </div>

    <div class="form-group mb-3">
      <label for="section" class="mb-2">Section</label>
      <select class="form-select" name="section" id="section" formControlName="sectionid" (change)="sectionChange()">
        <option value="">General</option>
        <option [value]="section.sectionid" *ngFor="let section of sections">
          {{ section.sectionname }}
        </option>
      </select>
    </div>

    <div class="form-group mb-3">
      <label for="section" class="mb-2">Unit</label>
      <select class="form-select" name="section" id="section" formControlName="unitid">
        <option value = "">-</option>
        <option [value]="unit.unitid" *ngFor="let unit of filteredUnits">
          {{ unit.unitname }}
        </option>
      </select>
    </div>

    <div class="form-group mb-3">
      <label for="file" class="mb-2"> File </label>
      <div class="dragdrop form-control p-0" name="file" id="file"
        [ngClass]="{ 'is-invalid': submitted && !fileSelected }">
        <ng-container *ngIf="fileSelected != ''; else uploadTemplate">
          <div class="h-100 w-100 d-flex flex-row align-items-center justify-content-start ps-3" appDragDrop
            (onFileDropped)="uploadFile($event)">
            <div>
              <img [src]="'../../../assets/images/' + filetype + '.png'" width="40px" />
            </div>
            <div class="ms-2">
              <strong>{{ filename }}</strong>
            </div>
          </div>
        </ng-container>
        <ng-template #uploadTemplate>
          <div class="h-100 w-100 d-flex flex-row align-items-center justify-content-center" appDragDrop
            (onFileDropped)="uploadFile($event)">
            <div class="me-3" *ngIf="isPopup">Drag and drop file here</div>
            <div class="me-3" *ngIf="isPopup">or</div>
            <div>
              <input type="file" (change)="onFileSelected($event)" class="inputfile" #choosefile />
              <button type="button" class="ms-0" mat-flat-button color="primary" (click)="choosefile.click()">
                Choose File
              </button>
            </div>
          </div>
        </ng-template>
      </div>
      <small>Only .txt, .docx, .pptx, .xlsx, .pdf are allowed.</small>
    </div>

    <div class="form-group mb-3">
      <label for="remark" class="mb-2"> Remark </label>
      <textarea class="form-control" rows="3" placeholder="Enter Remark" formControlName="remark" id="remark"
        name="remark"></textarea>
    </div>
  </form>
</ng-template>