<ng-container *ngIf="(!isLoading && !error);else elseTemplate">
  <section class="mt-5 d-flex justify-content-center">
    <div class="card card-body m-2">
      <h1 style="font-weight: 700;">
        <strong>
          <i class="fas fa-exclamation-circle" style="color: red;"></i> Payment Failed
        </strong>
      </h1>

      <div class="d-flex flex-column">
        <div class="mb-3 d-flex justify-content-between">
          <div class="label">
            Order Number
          </div>
          <div>
            {{transactiono}}
          </div>
        </div>
        <div class="mb-3 d-flex justify-content-between">
          <div class="label">
            Amount
          </div>
          <div>
            {{amount}}&nbsp;{{currency}}
          </div>
        </div>
        <div class="mb-3 d-flex justify-content-between">
          <div class="label">
            Payment Type
          </div>
          <div>
            {{paymenttype}}
          </div>
        </div>
      </div>

      <div class="d-flex flex-column">
        <h2 style=" font-weight: 700;">{{coursename}}</h2>
        <img class="img-fluid rounded" (error)="allinoneService.handleImgError($event)"
          [src]="allinoneService.imgurl + courseimage" alt="" style="width: 500px;height: auto;" />
        </div>

      <div style="padding-bottom: 20px;">
        Please contact&nbsp;<strong class="mailto" (click)="mailto()">system admin</strong>&nbsp;for more details.
      </div>
      <button mat-stroked-button color="primary" class="my-2 py-1" (click)="home()">
        Go to Home
      </button>
    </div>
  </section>
</ng-container>
<ng-template #elseTemplate>
  <div class="loading-container">
    <span class="loader" *ngIf="isLoading"></span>
    <span *ngIf="error">
      <h3 style="margin: 0;">Something went wrong!</h3><br>
      <button type="button" (click)="home()" class="btn btn-outline-secondary">
        Go to Home
      </button>
    </span>
  </div>
</ng-template>