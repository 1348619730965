<app-breadcrumb [s_breadcrumb]="'Analytics'"></app-breadcrumb>
<section class="container-xxl my-3">


  <div class="d-flex align-items-center flex-wrap justify-content-between ">

    <div class="col-12 col-md-3 mb-3 me-md-3">
      <!-- <label for="startdate" class="form-label">Start Date</label> -->
      <input type="date" class="form-control" id="startdate" name="startdate" [(ngModel)]="reportForm.startdate"
        required #startdate="ngModel" step="1">
    </div>
    <div class="col-12 col-md-3 mb-3 me-auto">
      <!-- <label for="enddate" class="form-label">End Date</label> -->
      <input type="date" class="form-control" id="enddate" name="enddate" [(ngModel)]="reportForm.enddate" required
        #enddate="ngModel" step="1">

    </div>
    <!-- <div class="d-flex col-12 col-md-3 mb-3 me-auto">
      <div class="form-check me-3">
        <input class="form-check-input" type="radio" name="chartview" id="chartview" value="chart"
          (change)="changeView($event)" checked>
        <label class="form-check-label" for="chartview">
          Chart View
        </label>
      </div>
      <div class="form-check me-3">
        <input class="form-check-input" type="radio" name="chartview" id="tableview" value="table"
          (change)="changeView($event)">
        <label class="form-check-label" for="tableview">
          Table View
        </label>
      </div>
    </div> -->

    <div class="d-flex align-items-center">
      <!-- <button color="primary" class="me-2 mb-3" mat-flat-button>
        Change
      </button> -->
      <div class="align-items-center mb-3" [matMenuTriggerFor]="statusmenu">
        <button class="btn dropdown-toggle" type="button" id="dropdownMenuButton" aria-expanded="false"
          mdbDropdownToggle style="
              background-color: #87929d;
              border-color: #87929d;
              color: white;
              min-width: 100px;
            ">
          View as&nbsp;:&nbsp;{{isChartView? 'Chart': 'Table'}}
        </button>
      </div>
      <mat-menu #statusmenu="matMenu" id="service-menu" xPosition="before" class="calendar-menu">
        <button mat-menu-item (click)="changeView('chart')">
          Chart
        </button>
        <button mat-menu-item (click)="changeView('table')">
          Table
        </button>
      </mat-menu>

      <button color="primary" class="me-2 mb-3" mat-flat-button (click)="downloadSheet()" *ngIf="!isChartView">
        Export CSV
      </button>
      <div class="mb-3">
        <div class="line">
        </div>
      </div>
      <button mat-flat-button color="primary" class="mb-3" (click)="search()">Search</button>
    </div>

  </div>
  <div *ngIf="isChartView">
    <div *ngIf="isLoading">

      <span class="loading" style="height: 400px;">
        <div class="spinner-border" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </span>
    </div>

    <div class="d-flex row flex-wrap mb-3" *ngIf="!isLoading">
      <div class="col-12 col-sm-12 col-md-6 col-lg-6 p-2">
        <!-- <div class="card card-body">
          <div class="d-flex row flex-wrap mb-3">
            <div class="col-12 col-sm-12 col-md-6 col-lg-6 ">
              <div class="d-flex">
                <div class="card me-3 mt-2 custom-card">
                  <div class="card-header custom-card-header">
                    Total
                  </div>
                  <div class="card-body custom-card-body">
                    15
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-sm-12 col-md-6 col-lg-6">
              <div class="d-flex justify-content-center align-items-center">
                <canvas baseChart [data]="pieChartOrgData" [labels]="pieChartLabels" [chartType]="pieChartType"
                  [options]="pieChartOptions" [plugins]="pieChartPlugins" [legend]="pieChartLegend"
                  [colors]="pieChartColors">
                </canvas>
              </div>
            </div>
          </div>

        </div> -->
        <div class="card card-body">
          <h3>Courses Learning Status</h3>
          <div class="d-flex flex-wrap justify-content-center">
            <div class="card me-3 mt-2 custom-card">
              <div class="card-header custom-card-header">
                Total
              </div>
              <div class="card-body custom-card-body">
                {{courseDataList.length}}
              </div>
            </div>
            <div class="card me-3 mt-2 custom-card">
              <div class="card-header custom-card-header">
                Completed
              </div>
              <div class="card-body custom-card-body">
                {{completedCourseCount}}
              </div>
            </div>
            <div class="card me-3 mt-2 custom-card">
              <div class="card-header custom-card-header">
                In Progress
              </div>
              <div class="card-body custom-card-body">
                {{courseDataList.length - completedCourseCount}}
              </div>
            </div>

          </div>



          <div class="d-flex justify-content-center">
            <div style="height:215px;">
              <canvas baseChart [data]="pieChartOrgData" [labels]="pieChartLabels" [chartType]="pieChartType"
                [options]="pieChartOptions" [plugins]="pieChartPlugins" [legend]="pieChartLegend"
                [colors]="pieChartColors">
              </canvas>
            </div>
          </div>

        </div>



      </div>
      <div class="col-12 col-sm-12 col-md-6 col-lg-6 p-2">
        <div class="card card-body">
          <h3>Learning Hours</h3>

          <div>
            <canvas *ngIf="!isLoading" height="350px" width="350px" baseChart [datasets]="courseBarStuCount"
              [labels]="courseBarChartLabels" [options]="courseBarChartOptions" [plugins]="deptChartPlugins"
              [legend]="courseChartL" [chartType]="courseChartT">
            </canvas>
          </div>

        </div>
      </div>

    </div>
  </div>
  <div *ngIf="!isChartView">
    <div class="table-responsive">
      <table class="table" matSort (matSortChange)="sortData($event)">
        <thead class="table-light">
          <tr>
            
            <th mat-sort-header="coursename">Course</th>
            <th mat-sort-header="instructor">Instructor</th>
            <th mat-sort-header="category">Category</th>
            <th mat-sort-header="coursetype">Type</th>
            <th mat-sort-header="startdate">Start Date</th>
            <th mat-sort-header="enddate">End Date</th>
            <th mat-sort-header="timespent">Time Spent</th>
            <th mat-sort-header="completed">Completed</th>
          </tr>
        </thead>
        <tbody *ngIf="courseDataList.length == 0 && !isLoading">
          <tr>
            <td colspan="8">
              <span class="nodata">
                Empty
              </span>
            </td>
          </tr>
        </tbody>
        <tbody *ngIf="isLoading">
          <tr>
            <td colspan="8">
              <span class="loading">
                <div class="spinner-border" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              </span>

            </td>
          </tr>
        </tbody>
        <tbody *ngIf="courseDataList.length > 0 && !isLoading">
          <tr *ngFor="let course of courseDataList">
            
            <td>{{course.coursename}}</td>
            <td>{{course.instructorname}}</td>
            <td>{{course.categoryname}}</td>
            <td>{{course.coursetype == '001' ? 'Self-Paced' : course.coursetype == '002' ? 'Instructor-Led' : 'Hybrid'
            }}
            <!-- #001 - sellf-paced \ 002 - instructor-led \ 003 - hybrid -->
          </td>
            <td>{{course.startdate? ((allinoneService.formatDateForDiscussionDatePipe(course.startdate)+' '+
              course.starttime) | date:'short') : '--'}}</td>
            <td>{{course.enddate? ((allinoneService.formatDateForDiscussionDatePipe(course.enddate)+' '+ course.endtime)
              | date:'short') : '--'}}</td>
            <td>{{course.totaltime}}</td>
            
            <td>
              <span class="badge bg-success" *ngIf="course.completed">
                Completed
              </span>
              <span class="badge bg-main" *ngIf="!course.completed">
                In Progress
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</section>