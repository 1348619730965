<form [formGroup]="form" (ngSubmit)="sumbitForm()">
  <div class="form-group mb-3">
    <label for="title" class="mb-2"> Title </label>
    <input type="text" class="form-control form-control-sm" placeholder="Assignment 1" formControlName="title"
      id="title" name="title" [ngClass]="{ 'is-invalid': submitted && formControl.title.errors }" />

    <div *ngIf="submitted && formControl.title.errors" class="invalid-feedback">
      <div *ngIf="formControl.title.errors?.required">
        {{ allinoneService.input_err_msg }}
      </div>
    </div>
  </div>

  <div class="d-flex align-items-center flex-wrap">
    <div class="form-check me-5 mb-1">
      <input class="form-check-input" type="radio" name="type" id="file" value="001" formControlName="type">
      <label class="form-check-label" for="file">
        File
      </label>
    </div>
    <div class="form-check me-5 mb-1">
      <input class="form-check-input" type="radio" name="type" id="text" value="002" formControlName="type">
      <label class="form-check-label" for="text">
        Text
      </label>
    </div>
  </div>

  <div *ngIf="formControl.type.value == '001' " class="form-group mb-3">
    <!-- <label for="file" class="mb-2"> File </label> -->
    <div class="dragdrop form-control p-0" name="file" id="file"
      [ngClass]="{ 'is-invalid': submitted && !fileSelected }">
      <ng-container *ngIf="fileSelected != ''; else uploadTemplate">
        <div class="h-100 w-100 d-flex flex-row align-items-center justify-content-start ps-3" appDragDrop
          (onFileDropped)="uploadFile($event)">
          <div>
            <img [src]="'../../../assets/images/' + filetype + '.png'" width="40px" />
          </div>
          <div class="ms-2">
            <strong>{{ filename }}</strong>
          </div>
        </div>
      </ng-container>
      <ng-template #uploadTemplate>
        <div class="h-100 w-100 d-flex flex-row align-items-center justify-content-center" appDragDrop
          (onFileDropped)="uploadFile($event)">
          <div class="me-3">Drag and drop file here</div>
          <div class="me-3">or</div>
          <div>
            <input type="file" (change)="onFileSelected($event)" class="inputfile" #choosefile />
            <button type="button" class="ms-0" mat-flat-button color="primary" (click)="choosefile.click()">
              Choose File
            </button>
          </div>
        </div>
      </ng-template>
    </div>
    <small>Only .txt, .docx, .pptx, .xlsx, .pdf, .zip, .rar are allowed.</small>
  </div>

  <div *ngIf="formControl.type.value == '002' " class="form-group mb-3">
    <textarea class="form-control" rows="3" [placeholder]="'Maximum word count - ' + wordCount" formControlName="text" id="text"
      name="text"></textarea>
    <div class="mt-1 small">
      Word Count : <span [ngClass]="{'text-danger' : getWordCount() > wordCount}">{{
        getWordCount() }}</span>
    </div>
  </div>

  <div class="form-group mb-3">
    <label for="remark" class="mb-2"> Remark (Optional) </label>
    <textarea class="form-control" rows="3" placeholder="Enter Remark" formControlName="remark" id="remark"
      name="remark"></textarea>
  </div>

  <div class="d-flex align-items-center flex-row flex-wrap justify-content-end">
    <button mat-stroked-button class="ms-0" (click)="cancel()" [disabled]="isSaving">
      Cancel
    </button>
    <button type="submit" mat-flat-button class="ms-2" color="primary" [disabled]="isSaving">
      <span *ngIf="isSaving">
        <div class="spinner-border spinner-border-sm text-primary" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </span>
      <span *ngIf="!isSaving"> Submit </span>
    </button>
  </div>

</form>