import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { AllInOneService } from '../../shared/services/all-in-one.service';
import { MessageService } from '../../shared/services/message.service';
import { CourseService } from '../../shared/services/course.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {


  signupForm = this.formBuider.group({
    username: ["", Validators.required],
    userid: ["", Validators.required],
    password: ["", Validators.required],
  });

  signupFormSubmitted: boolean = false;
  isStillSigningIn: boolean = false;
  passwordIsValid: boolean = false;
  showPassInput: boolean = false;
  fieldTextType: boolean = false;

  constructor(private formBuider: FormBuilder,
    public allinoneService: AllInOneService,
    private courseService: CourseService,
    private route: ActivatedRoute,
    private router: Router,
    private messageService: MessageService) { }

  ngOnInit(): void {
  }
  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }
  passwordValid(event: boolean) {
    this.passwordIsValid = event;
  }
  get signupFormControl() { return this.signupForm.controls; }

  signUp() {
    this.signupFormSubmitted = true;
    console.log(this.passwordIsValid);


    if (this.signupForm.invalid || !this.passwordIsValid || !this.signupFormControl.username.valid) {
      if (!this.passwordIsValid) {
        this.showPassInput = true;
      }
      return;
    }
    else {
      this.showPassInput = false;
      this.isStillSigningIn = true;

      this.allinoneService.setCookie("kunyek_userid", this.allinoneService.checkUserId(this.signupForm.value.userid));
      this.allinoneService.setCookie("kunyek_password", this.signupForm.value.password);
      sessionStorage.setItem('username', this.signupForm.value.username);

      this.courseService.checkUser().subscribe(async (res: any): Promise<any> => {
        if (res.returncode == "300") {
          if (res.status != 0) {
            this.allinoneService.setCookie('userid', res.usersyskey);
            this.messageService.openSnackBar("User already exists,please login", 'warn');
            this.router.navigateByUrl('login');
          }
          else {
            //user does not exists
            const awaitIamSignin = await this.iamSignIn();
            if (awaitIamSignin == "success") {
              const next_route: any = this.route.snapshot.queryParamMap.get('next');
              if (!next_route) {
                this.router.navigateByUrl(`verify`)
              }
              else {
                this.router.navigateByUrl(`verify?=${next_route}`)
              }
            }
          }


        }

        else {
          this.messageService.openSnackBar(res.status || res.message || 'Server Error', 'warn');
          this.signupFormSubmitted = false;
          this.isStillSigningIn = false;
          if (res.returncode == "210") {
            setTimeout(() => {
              this.router.navigateByUrl('login');
              this.allinoneService.logout();
            }, 2000);
          }
        }
      }, err => {
        this.signupFormSubmitted = false;
        this.isStillSigningIn = false;
      });
    }
  }



  iamSignIn() {
    return new Promise(resolve => {
      this.courseService.signIn(this.allinoneService.checkUserId(this.signupForm.value.userid), "").subscribe((res: any) => {
        if (res.returncode == "300") {
          this.allinoneService.setCookie("kunyek_userid", this.allinoneService.checkUserId(this.signupForm.value.userid));
          this.allinoneService.setCookie("kunyek_password", (this.signupForm.value.password));
          sessionStorage.setItem("session", res.session);
          sessionStorage.setItem("existsuser", res.type);
          sessionStorage.setItem("kunyekusername", res.username);

          this.isStillSigningIn = false;
          resolve('success');
        }
        else {
          if (res.returncode == "310") {
            this.messageService.openSnackBar("User already exists,please login", 'warn');
            this.router.navigateByUrl('login');
          }
          else {
            this.isStillSigningIn = false;
            this.messageService.openSnackBar(res.message, 'warn');
          }

        }
      }, err => {
        this.isStillSigningIn = false;
        resolve('error');
      })
    })

  }

}
