<section infinite-scroll [infiniteScrollUpDistance]="2" [infiniteScrollThrottle]="150" (scrolled)="scrolledDown()"
[scrollWindow]="true">

<app-breadcrumb *ngIf="!isLoading && !subcategoryid" [s_breadcrumb]="category"></app-breadcrumb>
<app-breadcrumb *ngIf="!isLoading && subcategoryid" [s_breadcrumb]="category" [a_breadcrumb]="subcategory"
  (emit)="goToMaincategory()"></app-breadcrumb>

<div class="container pb-5" id="container">
  <!-- <div class="search">
        <i class="fa fa-search"></i>
        <input type="text" [(ngModel)]="searchText" class="form-control" id="coursesubname" placeholder="Search"
            autocomplete="off">

    </div> -->
  <div class="category-title d-flex" [ngClass]="{'pb-2' : !subcategoryid && subTitle.length > 0}"
    *ngIf="!subcategoryid">{{category}}
    <div class="d-none d-sm-none d-lg-block mx-3" *ngIf="!isAll">Courses</div>
  </div>

  <div class="category-title d-flex" *ngIf="subcategoryid != ''">{{subcategory}} Courses
  </div>

  <div class="chiprow d-flex" *ngIf="!subcategoryid && !isRecentCourses">
    <div class="d-flex align-items-center">
      <div class="scroll-button d-flex align-items-center" *ngIf="list.isOverflow" (click)="list.scroll(-1)"
        id="scroll-left">
        <i class="fa fa-chevron-left"></i>
      </div>
    </div>
    <div class="list flex-fill d-flex align-items-center mt-1" appScrollable #list="appScrollable" [scrollUnit]="300">
      <span class="sub-lbl" *ngFor="let sub of subTitle; let i = index;" [ngClass]="{'ps-0' : i == 0}"
        [routerLink]="'/courses/' +categoryid + '/' + sub.categoryname">
        {{sub.subcategoryname}}
      </span>
    </div>
    <div class="d-flex align-items-center">
      <div class="scroll-button d-flex align-items-center justify-content-end" *ngIf="list.isOverflow"
        (click)="list.scroll(1)">
        <i class="fa fa-chevron-right"></i>
      </div>
    </div>
  </div>

  <ng-container *ngIf="!isLoading;else elseTemplate">
    <div class="row">
      <div class="col-12 col-lg-3 col-sm-12 mb-3 course-card "
        *ngFor="let course of courses">
        <mat-card (click)="goToRegisterForm(course.courseid)">
          <div class="img-container">
            <img width="270" height="175" [src]="allinOneService.imgurl + course.courseimage" alt="Photo of a"
              (error)="allinOneService.handleImgError($event)">
          </div>

          <mat-card-content>
            <div class="course-header-title mb-2">
              <font> {{course.coursename}} </font>
            </div>
            <span class="course-header-instructor">{{course.instructorname}}</span>
            <span class="course-header-category">{{course.categoryname}}</span>
            <span *ngIf="!privateCourse" class="course-header-category student-count-btn">{{(course.studentcounts ? +course.studentcounts : 0) > 1 ? course.studentcounts + ' Students' : course.studentcounts + ' Student'}} </span>
            <span class="course-fee" *ngIf="course.paymenttype == '001' && !privateCourse">Free</span>
            <span class="course-fee" *ngIf="course.paymenttype == '002' && !privateCourse">{{course.currency}}&nbsp;{{course.amount |
              number:
              '1.'}}</span>
          </mat-card-content>
        </mat-card>
        <!-- *ngIf="course.enroll == 'true'" -->
        <div class="setting" *ngIf="course.enroll == 'true'">
          <span class="badge bg-primary">Enrolled</span>
        </div>

        <div class="borderstype"></div>
      </div>

    </div>

    <!-- <pagination-controls *ngIf="courses.length > 8" class="my-pagination" (pageChange)="p = $event" previousLabel=""
      nextLabel="">
    </pagination-controls> -->

    <div class="d-flex justify-content-center align-items-center p-5" style="height: 50vh;" *ngIf="courses.length == 0">
      No course found
    </div>
  </ng-container>
  <ng-container *ngIf="gettingMoreCourses">
    <div class="row">
      <div class="col-12 col-lg-3 col-sm-6 mb-3 course-card" *ngFor="let skeleton of [1,2,3,4];">
        <div class="img-container">
          <ngx-skeleton-loader [theme]="{ height: '160px',width: '100%'}">
          </ngx-skeleton-loader>
        </div>

        <div class="d-flex flex-column">
          <ngx-skeleton-loader [theme]="{ height: '20px',width: '70%'}"></ngx-skeleton-loader>
          <ngx-skeleton-loader [theme]="{ height: '20px',width: '50%'}"></ngx-skeleton-loader>
        </div>
      </div>
    </div>

  </ng-container>

  <!-- Loading Skeleton -->
  <ng-template #elseTemplate>
    <div class="row">
      <div class="col-12 col-lg-3 col-sm-6 mb-3 course-card" *ngFor="let skeleton of [1,2,3,4, 5,6,7,8];">
        <div class="img-container">
          <ngx-skeleton-loader [theme]="{ height: '160px',width: '100%'}">
          </ngx-skeleton-loader>
        </div>

        <div class="d-flex flex-column">
          <ngx-skeleton-loader [theme]="{ height: '20px',width: '70%'}"></ngx-skeleton-loader>
          <ngx-skeleton-loader [theme]="{ height: '20px',width: '50%'}"></ngx-skeleton-loader>
        </div>
      </div>
    </div>
  </ng-template>
</div>

</section>