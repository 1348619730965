import { DatePipe, formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { Color, Label, SingleDataSet } from 'ng2-charts';
import { AllInOneService } from '../../shared/services/all-in-one.service';
import { CourseService } from '../../shared/services/course.service';
import { MessageService } from '../../shared/services/message.service';

@Component({
  selector: 'app-student-report',
  templateUrl: './student-report.component.html',
  styleUrls: ['./student-report.component.scss'],
})
export class StudentReportComponent implements OnInit {
  formatToShow = 'yyyy-MM-dd';
  piedateFormat = 'dd/MM/yyyy';
  dateFormat = 'yyyyMMdd';
  timeFormat = 'hh:mm:ss';
  apitimeFormat = 'hh:mm:ss aa';

  public pieChartOrgData: SingleDataSet = [0, 0];
  public pieChartLabels: Label[] = ['Completed', 'In Progress'];
  public pieChartType: ChartType = 'pie';
  public pieChartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      position: 'right',
      labels: {
        padding: 30,
        usePointStyle: true,
      },
    },
  };
  public pieChartLegend = true;
  public pieChartPlugins = [];
  public pieChartColors = [
    {
      backgroundColor: ['rgb(75, 192, 192)','rgb(51, 145, 194, 0.3)'],
    },
  ];

  d = new Date();
  month = (this.d.getMonth() + 1).toString().padStart(2, '0');
  day = this.d.getDate().toString().padStart(2, '0');
  todayDate = '' + this.d.getFullYear() + this.month + this.day;
  //for filter date
  aweekBefore = new Date(this.d).setDate(new Date(this.d).getDate() - 7);
  aweekBeforeDate = new Date(this.aweekBefore);

  currentEndDate = formatDate(
    Date.now(),
    this.formatToShow,
    'en-US'
  ).toString();
  currentStartDate = formatDate(
    new Date(this.aweekBefore),
    this.formatToShow,
    'en-US'
  ).toString();

  isLoading: boolean = false;
  // gettingCourses: boolean = false;

  coursesTableData: any;
  completedCourseCount = 0;

  public courseChartLabels: Label[] = [];
  public courseChartType: ChartType = 'horizontalBar';
  public courseChartLegend = false;
  // public courseChartPlugins = [ChartDataLabels];
  public courseChartPlugins = [];

  public timespentChartLabels: Label[] = [];
  public timespentChartType: ChartType = 'horizontalBar';
  public timespentChartLegend = false;
  // public timespentChartPlugins = [ChartDataLabels];
  public timespentChartPlugins = [];

  public courseChartOptions: ChartOptions = {
    responsive: true,
    scales: {
      xAxes: [
        {
          scaleLabel: {
            display: true,
            labelString: 'Students',
          },
          // ticks: {
          //   callback: function (value) {
          //     return value.toString().substr(0, 20) + '...';
          //   },
          // },
          ticks: {
            beginAtZero: true,
          },
        },
      ],
      yAxes: [
        {
          scaleLabel: {
            display: true,
            labelString: 'course',
          },
          ticks: {
            callback: function (value) {
              if (value.toString().length > 20) {
                return value.toString().substr(0, 20) + '...';
              } else {
                return value.toString();
              }
            },
          },
          // ticks: {
          //   beginAtZero: true,
          // },
        },
      ],
    },
  };
  public timespentChartOptions: ChartOptions = {
    responsive: true,
    scales: {
      xAxes: [
        {
          scaleLabel: {
            display: true,
            labelString: 'Students',
          },
          ticks: {
            beginAtZero: true,
          },
          // ticks: {
          //   callback: function (value) {
          //     return value.toString().substr(0, 20) + '...';
          //   },
          // },
        },
      ],
      yAxes: [
        {
          scaleLabel: {
            display: true,
            labelString: 'Departments',
          },
          ticks: {
            callback: function (value) {
              if (value.toString().length > 20) {
                return value.toString().substr(0, 20) + '...';
              } else {
                return value.toString();
              }
            },
          },
          // ticks: {
          //   beginAtZero: true,
          // },
        },
      ],
    },
  };

  public courseStuCount: ChartDataSets[] = [{ data: [0] }];
  public timespentStuCount: ChartDataSets[] = [{ data: [0] }];

  // public barChartColors: Color[] = [{ backgroundColor:[this.get_random_hue_color()]}];
  // public barChartColors: Color[] = [{ backgroundColor:["#3391c3"]}];

  public courseChartT: ChartType = 'line';
  public courseChartL = false;
  public deptChartPlugins = [];
  public courseBarChartLabels: Label[] = [];
  public courseBarStuCount: ChartDataSets[] = [
    {
      data: [0],
      fill: true,
      borderColor: 'rgb(75, 192, 192)',
      backgroundColor: 'rgb(51, 145, 194, 0.3)',
    },
  ];

  public courseBarChartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      yAxes: [
        {
          scaleLabel: {
            display: true,
            labelString: 'Time Spent (Hours)',
          },
          ticks: {
            beginAtZero: true,
          },
          // ticks: {
          //   callback: function (value) {
          //     return value.toString().substr(0, 20) + '...';
          //   },
          // },
        },
      ],
      xAxes: [
        {
          scaleLabel: {
            display: true,
            labelString: 'Courses',
          },
          ticks: {
            callback: function (value) {
              if (value.toString().length > 10) {
                return value.toString().substr(0, 10) + '...';
              } else {
                return value.toString();
              }
            },
            maxRotation: 90,
            minRotation: 70,
            autoSkip: false,
          },
          // ticks: {
          //   beginAtZero: true,
          // },
        },
      ],
    },
  };

  isChartView: boolean = true;

  courseDataList: any[] = [];

  reportForm = {
    startdate: this.currentStartDate,
    enddate: this.currentEndDate,
  };

  constructor(
    private courseService: CourseService,
    private messageService: MessageService,
    public allinoneService: AllInOneService,
    private datepipe: DatePipe,
    private router: Router,
  ) {
    if(this.allinoneService.getAtoken() == ''){
      this.router.navigateByUrl('home');
    }
  }

  ngOnInit(): void {
    this.search();
  }

  downloadSheet() {
    if (this.courseDataList.length == 0) {
      this.messageService.openSnackBar('No data to export.', 'warn');
      return;
    }
    var startdate = formatDate(
      new Date(this.reportForm.startdate),
      'dd/MM/yyyy',
      'en'
    );
    var enddate = formatDate(
      new Date(this.reportForm.enddate),
      'dd/MM/yyyy',
      'en'
    );
    var exportdata: any = [];
    var name = '';

    name =
      'Reports '  + ' ( ' + startdate + '-' + enddate + ' ).csv';
    this.courseDataList.map((item: any) => {
      exportdata.push({
        'Course Name': item.coursename,
        'Instructor': item.instructorname,
        'Category': item.categoryname,
        'Type': item.coursetype == '001' ? 'Self-Paced' : item.coursetype == '002' ? 'Instructor-Led' : 'Hybrid',
        'Start Date': item.startdate ? this.datepipe.transform((this.allinoneService.formatDateForDiscussionDatePipe(item.startdate)+' '+item.starttime), 'dd/MM/YYYY HH:mm a') : '--',
        'End Date': item.enddate ? this.datepipe.transform((this.allinoneService.formatDateForDiscussionDatePipe(item.enddate)+' '+item.endtime), 'dd/MM/YYYY HH:mm a') : '--',
        'Time Spent': item.totaltime,
        'Status' : item.completed ? 'Completed' : 'In progress'
      });
    });
    this.allinoneService.exportCSV(exportdata, name);
  }

  search() {
    var startdate = formatDate(
      new Date(this.reportForm.startdate),
      'yyyyMMdd',
      'en'
    );
    var enddate = formatDate(
      new Date(this.reportForm.enddate),
      'yyyyMMdd',
      'en'
    );
    this.isLoading = true;
    this.courseBarChartLabels = [];
    this.courseBarStuCount[0].data = [];
    this.courseService.getStudentReport(startdate, enddate).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.courseDataList = res.datalist;
          var checkCompleted = this.courseDataList.filter(
            (x: any) => x.completed
          );
          this.completedCourseCount = checkCompleted.length;
          setTimeout(() => {
            this.pieChartOrgData = [
              this.completedCourseCount,
              this.courseDataList.length - this.completedCourseCount,
            ];
          }, 500);
          // setTimeout(() => {
          //   var tempColorArray: any[] = [];
          this.courseDataList.map((item: any) => {
            //     if (item.totalsecond > 0) {
            console.log('-----------');
            console.log(item);
            this.courseBarChartLabels.push(item.coursename);

            var val = (parseFloat(item.totalsecond) / 3600).toFixed(2);
            this.courseBarStuCount[0].data?.push(parseFloat(val));
            //       tempColorArray.push(this.get_random_hue_color());

            //       // this.barChartColors[0].backgroundColor=tempColorArray;
            //     }
          });
          // }, 500);
        } else {
          this.messageService.openSnackBar(
            res.message || res.status || 'Server Error',
            'warn'
          );
        }
        this.isLoading = false;
      },
      (err) => {
        this.isLoading = false;
      }
    );
  }
  get_random_hue_color() {
    let h = Math.floor(Math.random() * 361);
    let s = 60;
    let l = 52;
    h /= 360;
    s /= 100;
    l /= 100;

    let r, g, b;
    if (s === 0) {
      r = g = b = l;
    } else {
      const hue2rgb = function (p: any, q: any, t: any) {
        if (t < 0) t += 1;
        if (t > 1) t -= 1;
        if (t < 1 / 6) return p + (q - p) * 6 * t;
        if (t < 1 / 2) return q;
        if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
        return p;
      };
      const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
      const p = 2 * l - q;
      r = hue2rgb(p, q, h + 1 / 3);
      g = hue2rgb(p, q, h);
      b = hue2rgb(p, q, h - 1 / 3);
    }
    const toHex = function (x: any) {
      const hex = Math.round(x * 255).toString(16);
      return hex.length === 1 ? '0' + hex : hex;
    };
    return '#' + toHex(r) + toHex(g) + toHex(b);
  }
  changeView(type: string) {
    var val = type;
    if (val == 'chart') {
      this.isChartView = true;
    } else if (val == 'table') {
      this.isChartView = false;
    }
  }

  sortData(sort: Sort) {
    const data = this.courseDataList;
    if (!sort.active || sort.direction === '') {
      this.courseDataList = data;
      return;
    }

    this.courseDataList = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'coursename':
          return this.allinoneService.compare(
            a.coursename,
            b.coursename,
            isAsc
          );
        case 'category':
          return this.allinoneService.compare(
            a.categoryname,
            b.categoryname,
            isAsc
          );
        case 'instructor':
          return this.allinoneService.compare(
            a.instructorname,
            b.instructorname,
            isAsc
          );
        case 'timespent':
          return this.allinoneService.compare(
            a.totalsecond,
            b.totalsecond,
            isAsc
          );
        case 'completed':
          return this.allinoneService.compare(a.completed, b.completed, isAsc);
        case 'coursetype':
          return this.allinoneService.compare(
            a.coursetype,
            b.coursetype,
            isAsc
          );
        case 'startdate':
          return this.allinoneService.compare(
            a.startdate + a.starttime,
            b.startdate + b.starttime,
            isAsc
          );
        case 'enddate':
          return this.allinoneService.compare(
            a.enddate + a.endtime,
            b.enddate + b.endtime,
            isAsc
          );
        default:
          return 0;
      }
    });
    this.coursesTableData = new MatTableDataSource(this.courseDataList);
  }
}
