<section infinite-scroll [infiniteScrollUpDistance]="2" [infiniteScrollThrottle]="150" (scrolled)="scrolledDown()"
  [class.homepage-container]="isMobileView" [scrollWindow]="!isMobileView">
  <app-breadcrumb *ngIf="!isLoading && width > 1025" [s_breadcrumb]="'Search Courses'"></app-breadcrumb>

  <div class="container" [ngClass]="{'py-2': width > 1024}">
    <div class="search" *ngIf="width < 1025">
      <i class="fa fa-search"></i>
      <input type="search" [(ngModel)]="searchText" class="form-control" id="coursesubname" placeholder="Search"
        autocomplete="off" (ngModelChange)="searchCourse()">
    </div>


    <div *ngIf="searchText == '' && width < 1025 && !isError">
      <h1 class="category">Category</h1>

      <div>
        <mat-list>
          <mat-list-item matRipple *ngFor="let cat of allinOneService.categorieswithcounts"
            (click)="goToCourseByCategoryPage(cat)">{{cat.categoryname}} ({{cat.count}})
            <i class="fa fa-angle-right" aria-hidden="true"></i>
          </mat-list-item>
          <!-- <mat-list-item matRipple (click)="goToCourseByCategoryPage('all')">All Categories
                    <i class="fa fa-angle-right" aria-hidden="true"></i>
                </mat-list-item> -->
        </mat-list>
      </div>
    </div>


    <ng-container *ngIf="!isLoading;else elseTemplate">
      <div *ngIf="!isError">
        <h1 class="showresult" *ngIf="width > 1024">Viewing Results for " {{searchText}} "</h1>

        <div class="row" *ngIf="searchText != ''">
          <div class="col-12 col-lg-3 col-sm-12 mb-3 course-card"
            *ngFor="let course of courses">
            <mat-card (click)="goToRegisterForm(course.courseid)">
              <div class="img-container">
                <img width="270" height="175" [src]="allinOneService.imgurl + course.courseimage" alt="Photo of a"
                  (error)="allinOneService.handleImgError($event)">
              </div>

              <mat-card-content>
                <div class="course-header-title mb-2">
                  <font> {{course.coursename}} </font>
                </div>
                <span class="course-header-instructor">{{course.instructorname}}</span>
                <span class="course-header-category">{{course.categoryname}}</span>
                <span class="course-fee" *ngIf="course.paymenttype == '001' && !privateCourse">Free</span>
                <span class="course-fee" *ngIf="course.paymenttype == '002' && !privateCourse">{{course.currency}}&nbsp;{{course.amount |
                  number:
                  '1.'}}</span>
                  <!-- <span class="course-fee" *ngIf="course.paymenttype == '001'">Free</span>
                <span class="course-fee" *ngIf="course.paymenttype == '002'">{{course.currency}}&nbsp;{{course.amount |
                  number:
                  '1.'}}</span> -->
              </mat-card-content>
              <!-- <mat-card-actions>
                    <button mat-button color="primary">View Detail -></button>
                </mat-card-actions> -->
            </mat-card>
            <div class="setting" *ngIf="course.enroll == 'true'">
              <span class="badge bg-primary">Enrolled</span>
            </div>

          </div>

          <div class="d-flex justify-content-center align-items-center p-5" style="height: 50vh;"
            *ngIf="courses.length == 0">
            No course found
          </div>
        </div>
      </div>


      <div class="check-error" *ngIf="isError">
        <h3 style="margin: 0;">Something went wrong!, please reload</h3><br>
        <button type="button" (click)="reload()" class="btn btn-outline-secondary">
          Reload
        </button>
      </div>

    </ng-container>

    <ng-container *ngIf="gettingMoreCourses && !isMobileView">
      <div class="row">
        <div class="col-12 col-lg-3 col-sm-6 mb-3 course-card" *ngFor="let skeleton of [1,2,3,4];">
          <div class="img-container">
            <ngx-skeleton-loader [theme]="{ height: '160px',width: '100%'}">
            </ngx-skeleton-loader>
          </div>

          <div class="d-flex flex-column">
            <ngx-skeleton-loader [theme]="{ height: '20px',width: '70%'}"></ngx-skeleton-loader>
            <ngx-skeleton-loader [theme]="{ height: '20px',width: '50%'}"></ngx-skeleton-loader>
          </div>
        </div>
      </div>

    </ng-container>

    <ng-container *ngIf="gettingMoreCourses && isMobileView">
      <article class="article d-flex" *ngFor="let n of [0,1,2]">
        <div class="left-article">
          <div>
            <ngx-skeleton-loader [theme]="{ height: '20px',width: '100%'}"></ngx-skeleton-loader>
            <ngx-skeleton-loader [theme]="{ height: '20px',width: '80%'}"></ngx-skeleton-loader>
          </div>
        </div>
      </article>

    </ng-container>

    <!-- Loading Skeleton -->
    <ng-template #elseTemplate>
      <article class="article d-flex" *ngFor="let n of [0,1,2,3,4,5,6]">
        <div class="left-article">
          <div>
            <ngx-skeleton-loader [theme]="{ height: '20px',width: '100%'}"></ngx-skeleton-loader>
            <ngx-skeleton-loader [theme]="{ height: '20px',width: '80%'}"></ngx-skeleton-loader>
          </div>
        </div>
      </article>
    </ng-template>
  </div>

</section>