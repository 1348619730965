import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import * as S3 from 'aws-sdk/clients/s3';
import * as CryptoJS from 'crypto-js';
import { User } from '../models/User';
import { DatePipe } from '@angular/common';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AllInOneService {

  akid: string = environment.akid;
  skid: string = environment.skid;

  imgurl: string =
    'https://assets.khub.cloud/images/';
  docurl: string =
    'https://assets.khub.cloud/references/';
  fileurl: string = 'https://assets.khub.cloud/';
  input_err_msg: string = 'This field may not be blank.';

  domain: string = 'https://api1.khub.omnicloudapi.com/';
  kunyekdomain: string = 'https://api.ky.connectportal.cloud/';
  // iamdomain: string = "https://api.iam.connectportal.cloud/"
  iamdomain: string = "https://api1.khub.omnicloudapi.com/"
  channelUrl: string = this.domain + 'channel/';
  learningPathUrl: string = this.domain + 'learningpath/';
  courseUrl: string = this.domain + 'course/';
  sectionUrl: string = this.domain + 'section/';
  subsectionUrl: string = this.domain + 'subsection/';
  unitUrl: string = this.domain + 'unit/';

  studentUrl: string = this.domain + 'student/';
  categoryUrl: string = this.domain + 'category/';

  userUrl: string = this.domain + 'user/';
  instrucotrId = '9999';

  channeldata: any;

  categories: any = [];
  categorieswithcounts: any = [];
  channels: any = [];
  channel: string = '';

  learningPathList: any[] = [];
  showLearningPath: boolean = false;

  channelid: string = '';
  selectedChannelName: string = 'KHub';
  gettingChannel: boolean = false;
  hideNavBar: boolean = false;
  isLoading: boolean = false;

  showStudioLink : boolean = true;
  showSignUpButton: boolean = true;
  // privatecourse : boolean = false;

  user!: User;

  private secretKey: string = '!#@$26!@#$Gk06&*';
  adminmail: string = 'learn.kunyek@gmail.com';

  // assignmentFileSize: number = 20;
  assignmentFileSize: number = 300;
  assignmentWordCount: number = 1000;

  paymentConfig = [
    {
      appid: '000011',
      appname: 'KHubLocal',
      hostname: 'localhost',
    },
    {
      appid: '000012',
      appname: 'KHub',
      hostname: 'www.omnicloudapi.com',
    },
    {
      appid: '000013',
      appname: 'KHubOmni',
      hostname: 'khub.omnicloudapi.com',
    },
    {
      appid: '000014',
      appname: 'KunyekLearning',
      hostname: 'learn.kunyek.com',
    },
    {
      appid: '000015',
      appname: 'KhubCloud',
      hostname: 'www.khub.cloud',
    },
    {
      appid: '000016',
      appname: 'KhubMIOD',
      hostname: 'miod.khub.cloud',
    },
    
  ];

  currentPaymentConfig: any;
  currentDomain: string = '';
  currentDomainType: string = '001'; // 001 - normal flow, 002 - has access to channel, 003 - no access to channel

  showQrSignin : boolean = true;

  login_page_description = "Topics include design,business,photography, and more";
  assignment_label = "Instructions";

  private encrypted_userid: string = this.encrypt('userid');
  private queryChannel: string = this.encrypt('queryChannel');
  private encrypted_kunyek_userid: string = this.encrypt('kunyek_userid');
  private encrypted_kunyek_password: string = this.encrypt('kunyek_password');
  private encrypted_atoken: string = this.encrypt('atoken');
  private encrypted_appid: string = this.encrypt('appid');
  private encrypted_channelid: string = this.encrypt('channelid');
  private encrypted_categories: string = this.encrypt('categories');
  private encrypted_categorieswithcounts: string = this.encrypt(
    'categorieswithcounts'
  );
  private encrypted_channels: string = this.encrypt('channels');
  private encrypted_username: string = this.encrypt('username');
  private encrypted_quiztimelimit: string = this.encrypt('quizcachelist');
  private encrypted_unitstudytime : string = this.encrypt('courseunitstudytime');

  constructor(
    private router: Router,
    private cookieService: CookieService,
    private datePipe: DatePipe
  ) {}

  getUserId() {
    const encrypted = this.cookieService.get(this.encrypted_userid) || '';
    return this.decrypt(encrypted);
  }
  getQueryChannel() {
    const encrypted = this.cookieService.get(this.queryChannel) || '';
    return this.decrypt(encrypted);
  }
  getSession() {
    return sessionStorage.getItem('session') || '';
  }
  getKunyekUserId() {
    const encrypted =
      this.cookieService.get(this.encrypted_kunyek_userid) || '';
    return this.decrypt(encrypted);
  }

  getUsername() {
    const encrypted = this.cookieService.get(this.encrypted_username) || '';
    return this.decrypt(encrypted);
  }

  getKunyekPassword() {
    const encrypted =
      this.cookieService.get(this.encrypted_kunyek_password) || '';
    return this.decrypt(encrypted);
  }
  getAtoken() {
    const encrypted = this.cookieService.get(this.encrypted_atoken) || '';
    return this.decrypt(encrypted);
  }

  getQuizTimeLimit() {
    const encrypted = this.cookieService.get(this.encrypted_quiztimelimit) || '';
    return this.decrypt(encrypted);
  }

  getUnitStudyTime() {
    const encrypted = this.cookieService.get(this.encrypted_unitstudytime) || '';
    return this.decrypt(encrypted);
  }

  getAppId() {
    const encrypted = this.cookieService.get(this.encrypted_appid);
    return this.decrypt(encrypted) || 'kunyek';
  }
  compare(
    a: number | string | Date,
    b: number | string | Date,
    isAsc: boolean
  ) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  getCategoryWithCounts() {
    try {
      const encrypted = this.cookieService.get(
        this.encrypted_categorieswithcounts
      );
      const value = this.decrypt(encrypted);
      return JSON.parse(value);
    } catch (err) {
      return [];
    }
  }
  getCategory() {
    try {
      const encrypted = this.cookieService.get(this.encrypted_categories);
      const value = this.decrypt(encrypted);
      return JSON.parse(value);
    } catch (err) {
      return [];
    }
  }

  getChannels() {
    try {
      const encrypted = this.cookieService.get(this.encrypted_channels);
      const value = this.decrypt(encrypted);
      return JSON.parse(value);
    } catch (err) {
      return [];
    }
  }

  getChannelid() {
    const encrypted = this.cookieService.get(this.encrypted_channelid) || '';
    const value = this.decrypt(encrypted);
    return value;
  }

  encrypt(value: string) {
    if (value) {
      var key = CryptoJS.enc.Utf8.parse(this.secretKey);
      var iv = CryptoJS.enc.Utf8.parse(this.secretKey);
      var encrypted = CryptoJS.AES.encrypt(
        CryptoJS.enc.Utf8.parse(value.toString()),
        key,
        {
          keySize: 128 / 8,
          iv: iv,
          mode: CryptoJS.mode.CBC,
          padding: CryptoJS.pad.Pkcs7,
        }
      );

      return encrypted.toString();
    } else {
      return '';
    }
  }

  decrypt(value: string) {
    if (value) {
      var key = CryptoJS.enc.Utf8.parse(this.secretKey);
      var iv = CryptoJS.enc.Utf8.parse(this.secretKey);
      var decrypted = CryptoJS.AES.decrypt(value, key, {
        keySize: 128 / 8,
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      });

      return decrypted.toString(CryptoJS.enc.Utf8);
    } else {
      return '';
    }
  }

  categoryByRoute(route: string): string {
    this.categorieswithcounts = this.getCategoryWithCounts();
    const category = this.categorieswithcounts.find(
      (el: any) => el.categoryid == route
    );
    var categoryname = '';
    if (category) {
      categoryname = category.categoryname;
    }

    return categoryname;
  }

  logout() {
    const queryChannel = this.getQueryChannel();
    this.cookieService.deleteAll('/');
    sessionStorage.clear();
    localStorage.clear();
    if (queryChannel) {
      this.setCookie("queryChannel", queryChannel)
    }
    setTimeout(() => {
      window.location.replace('/');
    }, 1000);
  }

  logoutWithoutReload() {
    this.cookieService.deleteAll('/');
    sessionStorage.clear();
    localStorage.clear();
  }

  clearAllcookies() {
    this.cookieService.deleteAll('/');
    sessionStorage.clear();
    localStorage.clear();
  }

  clearUserid() {
    this.cookieService.delete('kunyek_userid');
  }

  replaceLocation(nextroute: any) {
    this.router.navigateByUrl(`login?next=${nextroute}`);
  }

  setCookie(key: string, value: string) {
    const expiredDate = new Date();
    const encrypted_value = this.encrypt(value);
    const encrypted_key = this.encrypt(key);
    expiredDate.setTime(expiredDate.getTime() + 365 * 24 * 60 * 60 * 1000);
    this.cookieService.set(encrypted_key, encrypted_value, {
      expires: expiredDate,
      sameSite: 'Lax',
      path: '/',
    });
  }

  isBase64(str: string) {
    try {
      return btoa(atob(str)) == str;
    } catch (err) {
      return false;
    }
  }

  validateMail(mail: string) {
    if (
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        mail
      )
    ) {
      return true;
    }
    return false;
  }

  checkUserId(userid: string) {
    const checkmail = this.validateMail(userid);
    if (!checkmail && !userid.includes('@')) {
      if (userid.startsWith('09')) {
        userid = '+959' + userid.slice(2, userid.length);
      } else if (userid.startsWith('+959')) {
        userid = userid;
      } else if (userid.startsWith('959')) {
        userid = '+959' + userid.slice(3, userid.length);
      } else {
        userid = '+959' + userid;
      }
    } else {
      userid = userid.toLowerCase();
    }

    return userid;
  }

  formatDBToShow(date: string) {
    // 20210729
    if (date != undefined) {
      const year = date.substring(0, 4);
      const month = date.substring(4, 6);
      const day = date.substring(6, 8);
      return day + '/' + month + '/' + year;
    } else {
      return '';
    }
  }

  fileUploadToS3WithPathForImage(file: File, name: string, path: string) {
    return new Promise<void>((resolve, reject) => {
      const contentType = file.type;
      const bucket = new S3({
        accessKeyId : this.akid,
        secretAccessKey : this.skid,
        region: 'ap-southeast-1',
      });
      const params = {
        Bucket: 'elearningbucket',
        Key: path + '/' + name,
        Body: file,
        ACL: 'public-read',
        ContentType: contentType,
      };
      bucket.upload(params, function (err: any, data: any) {
        if (err) {
          console.log('There was an error uploading your file: ', err);
          reject();
        } else {
          console.log('Successfully uploaded file.', data);
          resolve();
        }
      });
    });
  }

  handleImgError(ev: any) {
    let source = ev.srcElement;
    let imgSrc =
      'https://assets.khub.cloud/essential/defaultimage.jpg';
    source.src = imgSrc;
  }

  formatDateForDiscussionDatePipe(date: string) {
    // 20210729
    if (date != undefined) {
      const year = date.substring(0, 4);
      const month = date.substring(4, 6);
      const day = date.substring(6, 8);
      return year + '/' + month + '/' + day;
    } else {
      return '';
    }
  }

  formatDBToShowInEdit(date: string, spliter?: string) {
    // 20210729
    if (date != undefined) {
      const year = date.substring(0, 4);
      const month = date.substring(4, 6);
      const day = date.substring(6, 8);

      if (spliter == undefined) {
        return year + '-' + month + '-' + day;
      } else {
        return year + spliter + month + spliter + day;
      }
    } else {
      return '';
    }
  }

  generateFileName(file: File) {
    var filetype = file.name.split('.').pop();
    var filename = file.name
      .substring(0, file.name.lastIndexOf('.'))
      .replace(/\s/g, '');
    var date = new Date();
    var millisecond = date.getMilliseconds();
    var time = this.datePipe.transform(date, 'yyyyMMddhhmmss' + millisecond);
    filename = filename + '_' + time + '.' + filetype;
    return filename;
  }

  exportCSV(data: any, name: string) {
    /* generate a worksheet */
    var ws = XLSX.utils.json_to_sheet(data);

    /* add to workbook */
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    console.log(wb.Sheets);

    // Change to csv
    const worksheet = wb.Sheets['Sheet1'];
    var mycsv = XLSX.utils.sheet_to_csv(worksheet);

    this.saveAsFile(mycsv, name);
  }

  saveAsFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer]);
    FileSaver.saveAs(data, fileName);
  }

  scrollTo(id: string) {
    let element = document.getElementById(id);
    element?.scrollIntoView();
  }
}
