<section infinite-scroll [infiniteScrollUpDistance]="2" [infiniteScrollThrottle]="150" (scrolled)="scrolledDown()"
  [scrollWindow]="!isMobileView" [class.homepage-container]="isMobileView">
  <ng-container *ngIf="channeldata && channeldata.cmspage != '001' && !isError">
    <section *ngIf="channeldata.cmspage=='003'" class="container-xxl">
      <div class="position-relative">
        <div class="video-content" [ngStyle]="{'background-image': 'url(' + channeldata.cmsvideodata.imageurl+ ')'}">
          <div [innerHTML]="channeldata.cmsvideodata.text" class="video-text">
          </div>
          <div class="video" *ngIf="!channeldata.cmsvideodata.libraryid">
            <iframe [src]="checkVideoUrl(channeldata.cmsvideodata.videourl) | safe" title="CMS" class="ifrmae">
            </iframe>
          </div>
          <div class="video" *ngIf="channeldata.cmsvideodata.libraryid" (click)="playedvideo()">
            <vm-player class="ifrmae">
              <!-- <vm-loading-screen hideDots='true' >
              </vm-loading-screen> -->
              <vm-video>
                <source [attr.data-src]="channeldata.cmsvideodata.videourl" type="video/mp4" />
              </vm-video>
              <vm-default-ui controls no-loading-screen noLoadingScreen="true" *ngIf="playedVid">
                <!-- <vm-click-to-play [useOnMobile]="!playedVid"></vm-click-to-play>
                <vm-caption-control hideIcon="captions-off"></vm-caption-control>
                <vm-scrim></vm-scrim> -->
              </vm-default-ui>
              <vm-ui>
                <vm-click-to-play [useOnMobile]="!playedVid" *ngIf="!playedVid"></vm-click-to-play>

                <div class="vid-play-btn" *ngIf="!playedVid">
                  <div style="font-size: 1.5rem;
                      padding: 1rem;
                      padding-left: 2rem;
                      padding-right: 2rem;
                      border-radius: 25px;
                      background-color: rgba(182, 182, 182, 0.432);
                      margin: auto;
                      position: absolute;
                      ">
                    <i class='fas fa-play'></i>
                  </div>
                </div>
              </vm-ui>
            </vm-player>
          </div>
        </div>
        <div class="video-bg-img">
          <img [src]="channeldata.cmsvideodata.imageurl" alt="bg" class="video-bg">
        </div>
      </div>
    </section>
     <!-- Custom Page Banner -->
    <div class="div">
      <section class="banner" *ngIf="channeldata.cmspage == '000' && channeldata.cmsimagedata.length > 0">
        <div class="container">
            <div class="text-section">
              <h1>{{ channeldata.cmsimagedata[0]?.text1 }}</h1>
              <p>{{ channeldata.cmsimagedata[0]?.text2 }}</p>
            </div>

            <div class="image-section">
              <img [src]="channeldata.cmsimagedata[0].imageurl" class="d-block w-100" [alt]="channeldata.name">
            </div>
        </div>
      </section>
    </div>


    <section *ngIf="channeldata.cmspage == '002'" class="container-xxl">
      <div id="bannerslider" class="carousel slide carousel-fade carouseldiv" data-bs-ride="carousel">
        <div class="carousel-indicators" *ngIf="channeldata.cmsimagedata.length > 0">
          <button type="button" data-bs-target="#carouselExampleIndicators" [attr.data-bs-slide-to]="index"
            [attr.aria-label]="'Slide' + index" [ngClass]="{'active' : index==0 }" [attr.aria-current]="index==0"
            *ngFor="let img of channeldata.cmsimagedata;let index=index"></button>
        </div>
        <div class="carousel-inner">
          <div class="carousel-item" [ngClass]="{'active' : index==0 }"
            *ngFor="let img of channeldata.cmsimagedata;let index=index" data-bs-interval="5000">
            <div class="d-none d-sm-none d-md-block d-lg-block">
              <div class="carousel-text">
                <div style="font-size: 2vw;" [innerHtml]="img.text">
                </div>
              </div>
            </div>
            <img [src]="img.imageurl" class="d-block w-100 slider-img" [alt]="channeldata.name">
          </div>
        </div>
        <ng-container *ngIf="channeldata.cmsimagedata.length > 1">
          <button class="carousel-control-prev" type="button" data-bs-target="#bannerslider" data-bs-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
          </button>
          <button class="carousel-control-next" type="button" data-bs-target="#bannerslider" data-bs-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
          </button>
        </ng-container>
      </div>
    </section>
  </ng-container>


  <section class=" p-3 pb-5 pt-lg-3 p-sm-0 pt-sm-2 mb-2" >
    <!-- <div infinite-scroll [infiniteScrollUpDistance]="2" [infiniteScrollThrottle]="150" (scrolled)="scrolledDown()"
          [scrollWindow]="false"> -->
    <div class="container" *ngIf="!isError">
      <ng-container *ngIf="!customHomepage; else customHomepageTemplate">
        <ng-container *ngIf="recentCourses.length > 0">
          <div class="d-flex align-items-end justify-content-between my-4">
            <div>
              <h1 style="font-weight: 700;" class="mb-0">New Release</h1>
            </div>
            <div class="d-flex align-items-center view-all" routerLink="/home/courses/new-release">
              View all
              <i class="fa fa-light fa-arrow-right"></i>
            </div>

          </div>

          <ng-container *ngIf="!isLoading; else elseTemplate;">
            <div class="row">
              <!-- <div class="col-12 col-lg-3 col-sm-6 mb-3 course-card" *ngFor="let course of nochannelId |  paginate: { itemsPerPage: 8, currentPage: p }"> -->
              <div class="col-12 col-lg-3 col-sm-6 mb-3 course-card" *ngFor="let course of recentCourses">
                <!--  -->
                <mat-card (click)="goToRegisterForm(course)">
                  <div class="img-container">
                    <img width="270" height="175" [src]="allinOneService.imgurl + course.courseimage" alt="Photo of a"
                      (error)="handleImgError($event)" (load)="imgLoaded($event)">
                  </div>

                  <mat-card-content>
                    <div class="course-header-title mb-2">
                      <font> {{course.coursename}} </font>
                    </div>
                    <span class="course-header-instructor">{{course.instructorname}}</span>
                    <span class="course-header-category">{{course.categoryname}}</span>
                    <span *ngIf="!privateCourse" class="course-header-category student-count-btn">{{(course.studentcounts ? +course.studentcounts : 0) > 1 ?
                      course.studentcounts + ' Students' : course.studentcounts + ' Student'}}</span>
                    <div class="d-flex justify-content-between">
                      <span class="course-fee" *ngIf="course.paymenttype == '001' && !privateCourse ">Free</span>
                      <span class="course-fee" *ngIf="course.paymenttype == '002' && !privateCourse ">
                        {{course.currency}}&nbsp;{{course.amount |
                        number:'1.'}}</span>

                      <div *ngIf="course.enroll == 'true'" (click)="goToLearning($event,course)">
                        <span class="badge bg-primary badge-open">Open</span>
                      </div>
                    </div>
                  </mat-card-content>

                </mat-card>
                <!-- <div class="setting" *ngIf="course.enroll == 'true'">
                  <span class="badge bg-primary">Students : {{course.studentcounts}}</span>
                </div> -->



              </div>

              <!-- <pagination-controls class="my-pagination" (pageChange)="p = $event" previousLabel="" nextLabel="">
                    </pagination-controls> -->
            </div>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="this.mandatoryCourses.length > 0">
          <h1 style="font-weight: 700;" class="my-4">Mandatory Courses</h1>

          <ng-container *ngIf="!isLoading; else elseTemplate;">
            <div class="row">
              <!-- <div class="col-12 col-lg-3 col-sm-6 mb-3 course-card" *ngFor="let course of nochannelId |  paginate: { itemsPerPage: 8, currentPage: p }"> -->
              <div class="col-12 col-lg-3 col-sm-6 mb-3 course-card" *ngFor="let course of mandatoryCourses">
                <!--  -->
                <mat-card (click)="goToRegisterForm(course)">
                  <div class="img-container">
                    <img width="270" height="175" [src]="allinOneService.imgurl + course.courseimage" alt="Photo of a"
                      (error)="handleImgError($event)" (load)="imgLoaded($event)">
                  </div>

                  <mat-card-content>
                    <div class="course-header-title mb-2">
                      <font> {{course.coursename}} </font>
                    </div>
                    <span class="course-header-instructor">{{course.instructorname}}</span>
                    <span class="course-header-category">{{course.categoryname}}</span>
                    <span class="course-fee" *ngIf="course.paymenttype == '001' && !privateCourse ">Free</span>
                    <span class="course-fee"
                      *ngIf="course.paymenttype == '002' && !privateCourse ">{{course.currency}}&nbsp;{{course.amount
                      |
                      number:'1.'}}</span>
                  </mat-card-content>

                </mat-card>
                <div class="setting" *ngIf="course.enroll == 'true'">
                  <span class="badge bg-primary">Enrolled</span>
                </div>



              </div>

              <!-- <pagination-controls class="my-pagination" (pageChange)="p = $event" previousLabel="" nextLabel="">
                    </pagination-controls> -->
            </div>
          </ng-container>
        </ng-container>



        <h1 style="font-weight: 700;" class="my-4">Our Popular Courses</h1>

        <ng-container *ngIf="!isLoading; else elseTemplate;">
          <ng-container *ngIf="nochannelId.length > 0; else emptyTemplate">
            <div class="row">
              <div class="col-12 col-lg-3 col-sm-6 mb-3 course-card" *ngFor="let course of nochannelId">
                <!--  -->
                <mat-card (click)="goToRegisterForm(course)">
                  <div class="img-container">
                    <img width="270" height="175" [src]="allinOneService.imgurl + course.courseimage" alt="Photo of a"
                      (error)="handleImgError($event)" (load)="imgLoaded($event)">
                  </div>

                  <mat-card-content>
                    <div class="course-header-title mb-2">
                      <font> {{course.coursename}} </font>
                    </div>
                    <span class="course-header-instructor">{{course.instructorname}}</span>
                    <span class="course-header-category">{{course.categoryname}}</span>
                    <span *ngIf="!privateCourse " class="course-header-category student-count-btn">{{(course.studentcounts ? +course.studentcounts : 0) > 1 ?
                      course.studentcounts + ' Students' : course.studentcounts + ' Student'}}</span>
                    <div class="d-flex justify-content-between">
                      <span class="course-fee" *ngIf="course.paymenttype == '001' && !privateCourse ">Free</span>
                      <span class="course-fee"
                        *ngIf="course.paymenttype == '002' && !privateCourse ">{{course.currency}}&nbsp;{{course.amount |
                        number:'1.'}}</span>

                      <div *ngIf="course.enroll == 'true'" (click)="goToLearning($event, course)">
                        <span class="badge bg-primary badge-open">Open</span>
                      </div>
                    </div>

                  </mat-card-content>

                </mat-card>

              </div>
            </div>
          </ng-container>
          <ng-template #emptyTemplate>
            <div class="d-flex w-100 align-items-center justify-content-center" style="height:20vh;">
              <div class="text-center">No Courses Found</div>
            </div>
          </ng-template>
        </ng-container>
      </ng-container>
      <ng-template #customHomepageTemplate>
        <ng-container *ngFor="let section of customHomepageCourses">


          <div class="d-flex align-items-end justify-content-between my-4">
            <div>
              <h1 style="font-weight: 700;" class="mb-0">{{section.title}}</h1>
            </div>
            <div class="d-flex align-items-center view-all" [routerLink]="'/home/' +section.title+ '/courses'">
              View all
              <i class="fa fa-light fa-arrow-right"></i>
            </div>

          </div>


          <div class="row">
            <!-- <div class="col-12 col-lg-3 col-sm-6 mb-3 course-card" *ngFor="let course of nochannelId |  paginate: { itemsPerPage: 8, currentPage: p }"> -->
            <div class="col-12 col-lg-3 col-sm-6 mb-3 course-card" *ngFor="let course of section.courses">
              <!--  -->
              <mat-card (click)="goToRegisterForm(course)">
                <div class="img-container">
                  <img width="270" height="175" [src]="allinOneService.imgurl + course.courseimage" alt="Photo of a"
                    (error)="handleImgError($event)" (load)="imgLoaded($event)">
                </div>

                <mat-card-content>
                  <div class="course-header-title mb-2">
                    <font> {{course.coursename}} </font>
                  </div>
                  <span class="course-header-instructor">{{course.instructorname}}</span>
                  <span class="course-header-category">{{course.categoryname}}</span>
                  <span *ngIf="!privateCourse" class="course-header-category student-count-btn">{{(course.studentcounts ? +course.studentcounts : 0) > 1 ?
                    course.studentcounts + ' Students' : course.studentcounts + ' Student'}}</span>
                  <div class="d-flex justify-content-between">
                    <span class="course-fee" *ngIf="course.paymenttype == '001' && !privateCourse">Free</span>
                    <span class="course-fee" *ngIf="course.paymenttype == '002' && !privateCourse">
                      {{course.currency}}&nbsp;{{course.amount |
                      number:'1.'}}</span>

                    <div *ngIf="course.enroll == 'true'" (click)="goToLearning($event,course)">
                      <span class="badge bg-primary badge-open">Open</span>
                    </div>
                  </div>
                </mat-card-content>

              </mat-card>
              <!-- <div class="setting" *ngIf="course.enroll == 'true'">
                <span class="badge bg-primary">Students : {{course.studentcounts}}</span>
              </div> -->



            </div>

            <!-- <pagination-controls class="my-pagination" (pageChange)="p = $event" previousLabel="" nextLabel="">
                  </pagination-controls> -->
          </div>

        </ng-container>

      </ng-template>

      <ng-container *ngIf="gettingMoreCourses">
        <div class="row">
          <div class="col-12 col-lg-3 col-sm-6 mb-3 course-card" *ngFor="let skeleton of [1,2,3,4];">
            <div class="img-container">
              <ngx-skeleton-loader [theme]="{ height: '160px',width: '100%'}">
              </ngx-skeleton-loader>
            </div>

            <div class="d-flex flex-column">
              <ngx-skeleton-loader [theme]="{ height: '20px',width: '70%'}"></ngx-skeleton-loader>
              <ngx-skeleton-loader [theme]="{ height: '20px',width: '50%'}"></ngx-skeleton-loader>
            </div>
          </div>
        </div>

      </ng-container>


      <!-- <div class="container" *ngFor="let chl of withChannel">
              <div *ngIf="chl.channelid!=''">
  
                  <div class="d-flex align-items-center">
                      <h1 style="font-weight: 700;" class="my-4 me-auto" *ngIf="chl.channelid!=''">{{chl.channelname}}
                      </h1>
                      <h1 style="font-weight: 700;" class="my-4 me-auto" *ngIf="chl.channelid==''">Our Popular Courses
                      </h1>
                      <button (click)="goChannelPage(chl.channelid)" style="height: 40px;" mat-stroked-button color="primary">View All</button>
                  </div>
  
                  <ng-container>
                      <div class="row">
                          <div class="col-12 col-lg-3 col-sm-6 mb-3 course-card" *ngFor="let course of chl.channeldata;let i = index">
                              <mat-card (click)="goCourse(course)" *ngIf="i < 4">
                                  <div class="img-container">
                                      <img width="270" height="175" [src]="allinOneService.imgurl + course.courseimage" alt="Photo of a" (error)="handleImgError($event)" (load)="imgLoaded($event)">
                                  </div>
          
                                  <mat-card-content>
                                      <div class="course-header-title mb-2">
                                          <font> {{course.coursename}} </font>
                                      </div>
                                      <span class="course-header-instructor">{{course.instructorname}}</span>
                                      <span class="course-header-category">{{course.categoryname}}</span>
                                  </mat-card-content>
          
                              </mat-card>
                              <div class="setting" *ngIf="course.enroll == 'true'">
                                  <span class="badge bg-primary">Enrolled</span>
                              </div>
                          </div>
                      </div>
                  </ng-container>
  
  
              </div>
  
          </div> -->


      <!-- Loading Skeleton -->
      <ng-template #elseTemplate>
        <div class="row">
          <div class="col-12 col-lg-3 col-sm-6 mb-3 course-card" *ngFor="let skeleton of skeletons;">
            <div class="img-container">
              <ngx-skeleton-loader [theme]="{ height: '160px',width: '100%'}">
              </ngx-skeleton-loader>
            </div>

            <div class="d-flex flex-column">
              <ngx-skeleton-loader [theme]="{ height: '20px',width: '70%'}"></ngx-skeleton-loader>
              <ngx-skeleton-loader [theme]="{ height: '20px',width: '50%'}"></ngx-skeleton-loader>
            </div>
          </div>
        </div>
      </ng-template>
    </div>


    <div class="check-error" *ngIf="isError">
      <h3 style="margin: 0;">Something went wrong!, please reload</h3><br>
      <button type="button" (click)="reload()" class="btn btn-outline-secondary">
        Reload
      </button>
    </div>

  </section>
</section>