<h2>
  Grades
</h2>
<ng-container *ngIf="isFromInstructor; else gradeTemplate">
   <div class="d-flex align-items-center justify-content-center">
      No preview available for Grades.
  </div>
</ng-container>
<ng-template #gradeTemplate>
  <ng-container *ngIf="!isMobileView; else mobileTemplate">
    <table class="table align-middle mb-0 bg-white">
      <thead class="bg-light">
        <tr>
          <th style="min-width: 250px;"></th>
          <th>Status</th>
          <th>Grade</th>
          <!-- <th>Out of</th> -->
        </tr>
      </thead>
      <tbody *ngIf="!gettingGrade">
        <tr *ngFor="let grade of grades; let index = index" class="grade-tr">
          <td *ngIf="!grade.isTotal" (click)="viewlecture(grade)">
            <div  class="d-flex align-items-center flex-row">
              <div class="me-3 d-flex align-items-center justify-content-center" style="width: 20px;">
                <span *ngIf="grade.gradestatus == 'false'">
                  <img [src]="'./../../assets/images/assignment.png'" class="unit-icon" *ngIf="grade.contenttype == '7'">
                  <i class="fas fa-clipboard-list unit-icon d-flex justify-content-center"
                    *ngIf="grade.contenttype == '6'"></i>
                </span>
                <span *ngIf="grade.gradestatus == 'true'">
                  <i class="fas fa-check-circle unit-icon d-flex justify-content-center" style="color: green;"
                    ></i>
                </span>
              </div>
              <div class="d-flex flex-column unit-container">
                <div>
                  {{grade.unitname}}
                </div>
                <div class="unit-type">
                  {{grade.contenttype == '6' ? 'Quiz' : 'Assignment'}}
                </div>
              </div>
            </div>
          </td>
          <td *ngIf="grade.isTotal"></td>
          <td>
            <span *ngIf="grade.gradestatus == 'false'" class="incomplete">Incomplete</span>
              <span *ngIf="grade.gradestatus == 'true'" class="completed">Completed</span>
          </td>
          <td> {{grade.isTotal ? grade.totalPercentage+'%' : grade.grade + ' / ' + grade.totalgrade  }} </td>
          <!-- <td>{{grade.grade}} / {{ grade.totalgrade }}</td> -->
  
          <!-- <td>{{grade.totalgrade}}</td> -->
          <!-- <td>10</td> -->
        </tr>
        <tr *ngIf="grades.length == 0">
          <td colspan="4">
            <span class="nodata">
              This course does not have any graded units.
            </span>
          </td>
        </tr>
      </tbody>
      <tbody *ngIf="gettingGrade">
        <tr>
          <td colspan="4">
            <span class="nodata">
              <div class="spinner-border spinner-border-md" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </span>
          </td>
        </tr>
      </tbody>
    </table>
  </ng-container>
  
  <ng-template #mobileTemplate>
    <ng-container>
      <div class="grade-container card card-body mb-2" *ngFor="let grade of mobileviewgrades" (click)="viewlecture(grade)">
        <div class="d-flex justify-content-between align-items-center">
          <div *ngIf="!grade.isTotal" class="d-flex align-items-center flex-row">
            <div class="me-3 d-flex align-items-center justify-content-center" style="width: 20px;">
              <span *ngIf="grade.gradestatus == 'false'">
                <img [src]="'./../../assets/images/assignment.png'" class="unit-icon" *ngIf="grade.contenttype == '7'">
                <i class="fas fa-clipboard-list unit-icon d-flex justify-content-center"
                  *ngIf="grade.contenttype == '6'"></i>
              </span>
              <span *ngIf="grade.gradestatus == 'true'">
                <i class="fas fa-check-circle unit-icon d-flex justify-content-center" style="color: green;"
                  ></i>
              </span>
            </div>
            <div class="d-flex flex-column unit-container">
              <div class="mb-1">
                {{grade.unitname}}
              </div>
              <div class="unit-type">
                {{grade.contenttype == '6' ? 'Quiz' : 'Assignment'}}
              </div>
            </div>
          </div>
          <div *ngIf="grade.isTotal">
            <div class="unit-type">
              Total
            </div>
          </div>
          <div class="d-flex flex-column align-items-end">
            <div style="font-weight: bolder;">
              <span style="font-size: 18px;" class="mb-1" *ngIf="!grade.isTotal">{{grade.grade}} / {{ grade.totalgrade }}</span>
              <span style="font-size: 18px;" *ngIf="grade.isTotal">{{ grade.totalPercentage }}%</span>
            </div>
            <div style="font-size: 12px;">
              <span *ngIf="grade.gradestatus == 'false'" class="incomplete">Incomplete</span>
              <span *ngIf="grade.gradestatus == 'true'" class="completed">Completed</span>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="gettingGrade">
      <div class="nodata">
        <div class="spinner-border spinner-border-md" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    </ng-container>
  </ng-template>
</ng-template>
