<section *ngIf="!verified">
    <div class="signup-wrapper otp-wrapper">
        <div class="mb-2">
            <!-- <img src="assets/images/khub.png" alt="" class="navbar-brand-logo"> -->
            <h3 style="font-weight: 600;" class="mb-4">Verification
                <span style="    font-size: 0.7rem;
                opacity: 0.8;">( {{allinOneService.getKunyekUserId()}} )</span></h3>
            <span>Enter verification code</span>
        </div>
        <ng-otp-input class="mb-1" (inputStyles)="{'width': '20px'}" #ngOtpInput (onInputChange)="onOtpChange($event)" *ngIf="showOtpComponent" [config]="config">
        </ng-otp-input>
        <div class="d-flex align-items-center">
            <strong class="text-danger my-3" (click)="resend()">Resend Code?</strong>
            <mat-spinner class="mx-3" [strokeWidth]="3" [diameter]="33" *ngIf="isStillResend"></mat-spinner>

        </div>
        <app-button-loader (click)="otpFillNext()" [isStillupload]="isStillVerifying" [button_text]="'Next'" [width]="'100'" [margin_left]="'0'">
        </app-button-loader>
    </div>
</section>


<!-- <section *ngIf="!existsuser && verified && !isuserCreated">
    <div class="signup-wrapper otp-wrapper">
        <div class="mb-2">
            <h3 style="font-weight: 600;" class="mb-2">Enter your name</h3>
        </div>
        <form [formGroup]="userForm" (ngSubmit)="createUserNew()">
            <div class="form-group my-1">
                <input type="text" class="form-control" autofocus placeholder="Full Name" formControlName="name" [ngClass]="{ 'is-invalid': userFormSubmitted && userFormControl.name.errors }">
                <div *ngIf="userFormSubmitted && userFormControl.name.errors" class="invalid-feedback">
                    <div *ngIf="userFormControl.name.errors?.required">
                        {{allinOneService.input_err_msg}}
                    </div>
                </div>
            </div>
            <app-button-loader [isStillupload]="isStillCreateUser" [button_text]="'Submit'" [width]="'100'" [margin_left]="'0'"></app-button-loader>
        </form>
    </div>
</section> -->


<section *ngIf="isuserCreated" style="height: 80vh;">
    <div class="signup-wrapper">
        <div class="mb-2  verified-wrapper">
            <img src="assets/images/illotv_usercheck.webp" alt="" class="navbar-brand-logo">
            <h3 style="font-weight: 600;" class="mb-4">You have successfully verified account.</h3>
        </div>
    </div>
</section>