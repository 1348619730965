<section class="text-sm-start user-section">
  <div class="row">
    <div class="col-12 col-lg-6 col-sm-6 left">
      <div class="signup-wrapper">
        <div class="khub-sign">
          <img src="assets/images/khub.png" alt="" *ngIf="allinoneService.currentDomainType == '001'">
          <img [src]="allinoneService.channeldata.url" alt="" *ngIf="allinoneService.currentDomainType != '001'">
          <div class="line"></div>
          <h3 style="font-weight: 600;" class="">Start learning with <strong class="text-warning">
              {{allinoneService.currentDomainType == '001'? 'KHub' : allinoneService.channeldata.name}}</strong></h3>
        </div>

        <ul class="custom-list">
          <li class="custom-li-item">Easy to learn</li>
          <li class="custom-li-item">Learners can access content anywhere and anytime</li>
          <li class="custom-li-item">{{allinoneService.login_page_description}}</li>
        </ul>
      </div>
    </div>
    <div class="col-12 col-lg-6 col-sm-6 right">
      <div class="signup-wrapper" *ngIf="isQrLogin">
        <div class="d-flex justify-content-center align-items-center b-4">
          <div *ngIf="qrLoading && !showRefresh" class="d-flex justify-content-center align-items-center"
            style="height: 200px;width: 364px;">
            <div class="spinner-border" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
          <div *ngIf="!qrLoading && showRefresh" style="height: 200px;width: 364px;" (click)="getQR()">
            <div style="padding-top: 85px;font-size: 30px; cursor: pointer;" role="status">
              <i class="fa fa-refresh"></i>
            </div>
          </div>
          <ngx-qrcode *ngIf="!qrLoading" qrc-element-type="canvas" [elementType]="elementType"
            [errorCorrectionLevel]="correctionLevel" [value]="value"></ngx-qrcode>
        </div>
        <ol class="custom-list">
          <li class="custom-li-item">Open Connect 365 app in your phone</li>
          <li class="custom-li-item">Go to <strong>More > QR Scan</strong></li>
          <li class="custom-li-item">Point your phone at this screen to confirm sign in</li>
        </ol>
        <div class="mt-4 d-flex justify-content-between">
          <!-- <img src="assets/images/khub.png" alt="" class="navbar-brand-logo"> -->
          <div>
            <span *ngIf="allinoneService.showSignUpButton">Don't have an account? <strong class="sign_forgot"
                (click)="goToSignUP()">Sign
                up</strong></span>
          </div>
          <div style="min-width: 46px;">
            <strong class="sign_forgot" (click)="phLogin()">Sign in</strong>
          </div>
        </div>
      </div>
      <div class="signup-wrapper" *ngIf="!isQrLogin">
        <div class="mb-4">
          <h3 style="font-weight: 600;" class="mb-1">Sign In</h3>
        </div>

        <form [formGroup]="signupForm" (ngSubmit)="signUp()">

          <div class="form-group my-2">
            <input type="text" class="form-control" autofocus placeholder="Email" formControlName="userid"
              [ngClass]="{ 'is-invalid': signupFormSubmitted && signupFormControl.userid.errors }">
            <div *ngIf="signupFormSubmitted && signupFormControl.userid.errors" class="invalid-feedback">
              <div *ngIf="signupFormControl.userid.errors?.required">
                {{allinoneService.input_err_msg}}
              </div>
            </div>

          </div>

          <div class="form-group my-2">
            <div class="input-group">
              <input [type]="fieldTextType ? 'text' : 'password'" class="form-control" placeholder="Password"
                formControlName="password"
                [ngClass]="{ 'is-invalid': signupFormSubmitted && signupFormControl.password.errors }">
              <div class="input-group-append">
                <span class="input-group-text">
                  <i class="fa" [ngClass]="{'fa-eye-slash': !fieldTextType,
                                                               'fa-eye': fieldTextType}"
                    (click)="toggleFieldTextType()"></i>
                </span>
              </div>
              <div *ngIf="signupFormSubmitted && signupFormControl.password.errors" class="invalid-feedback">
                <div *ngIf="signupFormControl.password.errors?.required">
                  {{allinoneService.input_err_msg}}
                </div>
              </div>
            </div>


          </div>

          <div class="col-sm-12 offset-0 mt-3 mb-1 d-flex justify-content-between">
            <div class="form-check">
              <input class="form-check-input" type="checkbox" id="remember" name="_remember"
                formControlName="isrememberme" (change)="onRememberMeChange($event)">
              <label class="form-check-label mb-1 ms-1" for="remember">
                Remember me
              </label>
            </div>
            <div class="form-check d-flex align-items-center" (click)="forgotPassword()">
              <label class="sign_forgot mb-1 ms-1">
                Forgot Password ?
              </label>
            </div>
          </div>

          <app-button-loader [isStillupload]="isStillSigningIn" [button_text]="'Sign In'" [width]="'100'"
            [margin_left]="'0'">
          </app-button-loader>
        </form>
        <div class="mt-4 d-flex justify-content-between">
          <!-- <img src="assets/images/khub.png" alt="" class="navbar-brand-logo"> -->
          <div>
            <span *ngIf="allinoneService.showSignUpButton">Don't have an account? <strong class="sign_forgot"
                (click)="goToSignUP()">Sign
                up</strong></span>
          </div>
          <div>
            <strong class="sign_forgot" (click)="qrLogin()" *ngIf="autoSignInPage">QR Sign in</strong>
          </div>
        </div>

      </div>
    </div>
  </div>

</section>

<!-- <section *ngIf="width < 1025">
    <div class="loading-center" *ngIf="isLoading">
        <mat-spinner [strokeWidth]="3" [diameter]="33"></mat-spinner>

    </div>

    <div *ngIf="retry && !isLoading" class="loading-center">
        <button mat-stroked-button color="primary" (click)="user()">Retry</button>
    </div>
</section> -->
