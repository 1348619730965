import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AllInOneService } from '../../shared/services/all-in-one.service';
import { Location } from '@angular/common';
import { CourseService } from '../../shared/services/course.service';
import { MessageService } from '../../shared/services/message.service';
import { SEOService } from '../../shared/services/seo.service';
import * as Crypto from 'crypto-js';

declare var $: any;
declare var bootstrap: any;

@Component({
  selector: 'app-course-register',
  templateUrl: './course-register.component.html',
  styleUrls: ['./course-register.component.scss'],
})
export class CourseRegisterComponent implements OnInit {
  enrollSuccess: boolean = false;
  show: boolean = false;
  isStillUpload: boolean = false;
  isError: boolean = false;
  isLoading: boolean = true;
  isPreview: boolean = false;
  courseBy: boolean = false;
  bra: boolean = false;
  isVerifying: boolean = false;
  isFromSchedule: boolean = false;

  courseid: string = '';
  coursebyText: string = '';
  category: string = '';
  orderno: string = '';

  course: any;
  instructor: any;

  def_img = '../../../assets/images/khub.png';
  // anonymous: boolean = false;

  isSeeMore : boolean = false;

  privateCourse : boolean = false;

  constructor(
    private courseService: CourseService,
    private route: ActivatedRoute,
    private location: Location,
    public allinOneService: AllInOneService,
    private messageService: MessageService,
    private _seoService: SEOService,
    private router: Router
  ) {
    const pre = this.route.snapshot.queryParamMap.get('preview');
    if (pre) {
      this.isPreview = true;
      if (pre != 'instructor') {
        this.isError = true;
      }
    } else {
      this.isPreview = false;
    }

    this.route.params.subscribe((params) => {
      this.courseid = params['courseid'];
    });
  }

  async ngOnInit(): Promise<any> {
    this.isLoading = true;

    this.orderno = sessionStorage.getItem('orderno') || '';
    if (this.orderno) {
      const await_deletePayment = await this.deletePayment();
      sessionStorage.removeItem('orderno');
    }

    const awaitCourse = await this.getCourse();
    this.isLoading = false;
    // if (awaitCourse == 'success') {
    //   this.getInstructor();
    // } else {
    //   this.isLoading = false;
    // }
    this.privateCourse = this.allinOneService.channeldata.privatecourse ? this.allinOneService.channeldata.privatecourse : false;
    
  }
  get truncatedDescription(): string {
    return this.course.description.length > 200 
      ? this.course.description.substring(0, 200) + '...' 
      : this.course.description;
  }
  toggleSeeMore() {
    this.isSeeMore = !this.isSeeMore;
  }

  deletePayment() {
    return new Promise((resolve) => {
      this.courseService.deletePayment(this.orderno).subscribe(
        (res: any) => {
          resolve(true);
        },
        (err) => {
          resolve(false);
        }
      );
    });
  }

  getCourseBy() {
    const courseby = this.route.snapshot.params['courseby'];
    console.log(courseby + '>>>');

    if (courseby == 'home') {
      this.bra = true;
    } else if (courseby == 'category') {
      this.coursebyText = this.course.categoryname;
      this.courseBy = true;
      this.bra = false;
    } else if (courseby == 'schedule') {
      this.coursebyText = 'Schedule';
      this.bra = false;
      this.courseBy = false;
      this.isFromSchedule = true;
    } else {
      this.coursebyText = this.course.channelname;
      this.bra = false;
      this.courseBy = false;
    }
  }

  getCourse() {
    return new Promise((resolve) => {
      this.courseService.getCourseDetail(this.courseid).subscribe(
        (res: any) => {
          if (res.returncode == '300') {
            this.course = res.data;
            this.course.studentcounts = parseInt(this.course.studentcounts);
            this._seoService.updateTitle('- ' + this.course.coursename);
            this._seoService.updateOgUrl(window.location.href);
            this._seoService.updateDescription(
              this.course.coursename + 'This is for testing purpose'
            );
            this._seoService.updateImg(
              this.allinOneService.imgurl + this.course.courseimage
            );
            this.getCourseBy();
            resolve('success');
          } else {
            this.isError = true;
            if (res.returncode == '210') {
              setTimeout(() => {
                this.router.navigateByUrl('login');
                this.allinOneService.logout();
              }, 2000);
            } else {
              this.messageService.openSnackBar(res.status, 'warn');
              if (res.statuscode == '200') {
                setTimeout(() => {
                  this.router.navigateByUrl('/home');
                }, 1000);
              }
            }

            setTimeout(() => {
              if (res.returncode == '404') {
                this.location.back();
              }
            }, 3000);
            resolve('error');
          }
        },
        (err) => {
          this.isError = true;

          resolve('error');
        }
      );
    });
  }

  goToCategoryOrChannel() {
    if (this.course)
      if (this.isFromSchedule) {
        this.router.navigateByUrl(`/home/courses/schedule`);
      } else {
        if (this.courseBy == true) {
          this.router.navigateByUrl(`/courses/${this.course.category}`);
        } else {
          this.router.navigateByUrl(
            `/channels/${this.course.channelid}/courses`
          );
        }
      }
    // console.log(data);
    // this.router.navigateByUrl(`course/channel/04879723585555297936`);
  }

  search(course: any) {
    console.log('INSTRUCTOR SERACH>>>');
    // console.log(course.profileid);
    // console.log(course.instructorid);
    // instructor/:instructorid/:profileid

    var profileId = 'Instructor';
    if (course.profileid) {
      profileId = course.profileid;
    }

    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() =>
        this.router.navigateByUrl(`instructor/${course.instructorid}/${profileId}`)
      );

    // this.router
    //   .navigateByUrl('/', { skipLocationChange: true })
    //   .then(() =>
    //     this.router.navigateByUrl(
    //       `courses/search?q=${this.course.instructorname}`
    //     )
    //   );
  }

  reload() {
    window.location.reload();
  }

  gotoCourse() {
    this.router.navigateByUrl(`/courses/${this.courseid}/lectures/`);
  }

  gotoName() {}

  getInstructor() {
    this.courseService.getInstructor(this.course.instructorid).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.instructor = res;
          this.isLoading = false;
          // this.anonymous = true;
        } else {
          this.messageService.openSnackBar(res.status, 'warn');
          setTimeout(() => {
            if (res.returncode == '404') {
              this.location.back();
            }
          }, 3000);
        }
      },
      (err) => {
        this.isLoading = false;
      }
    );
  }

  enrollCourse(): void {
    const atoken = this.allinOneService.getAtoken();
    if (!atoken) {
      const url: any = this.router.url.toString();
      this.allinOneService.replaceLocation(
        url.toString().replaceAll('/', ',').substring(1)
      );
      return;
    }
    this.isStillUpload = true;
    if (this.course.paymenttype == '001') {
      this.courseService.enrollCourse(this.courseid, []).subscribe(
        (res: any) => {
          if (res.returncode == '300') {
            var myOffcanvas = document.getElementById('offcanvasTop');
            var bsOffcanvas = new bootstrap.Offcanvas(myOffcanvas);

            bsOffcanvas.show();
          } else {
            if (res.returncode == '210') {
              setTimeout(() => {
                this.router.navigateByUrl('login');
                this.allinOneService.logout();
              }, 2000);
            } else {
              this.messageService.openSnackBar(res.status, 'warn');
            }
          }
          this.isStillUpload = false;
        },
        (err) => {
          this.isStillUpload = false;
        }
      );
    } else {
      // const url = window.location.href;
      // console.log(url);
      // return;

      this.courseService
        .addpayment(
          this.course.courseid,
          this.course.amount,
          '',
          this.allinOneService.currentPaymentConfig.appid
        )
        .subscribe(
          (res: any) => {
            if (res.returncode == '300') {
              this.orderno = res.orderno;
              // Go to payment gateway
              var formData = new URLSearchParams();
              formData.set('amount', this.course.amount);
              formData.set('pname', this.course.coursename);
              formData.set('pcode', this.course.courseid);
              formData.set('currency', this.course.currency);
              formData.set('cid', this.allinOneService.getKunyekUserId());
              formData.set(
                'cname',
                this.allinOneService.getUsername() || 'KHub User'
              );
              formData.set('phone', '');
              formData.set('address', '');
              formData.set('remark', 'KHub - ' + this.course.coursename);
              formData.set('paymenttype', 'KBZ');
              formData.set(
                'appid',
                this.allinOneService.currentPaymentConfig.appid
              );
              formData.set('refno', res.orderno);
              formData.set(
                'appname',
                this.allinOneService.currentPaymentConfig.appname
              );
              formData.set('email', '');
              formData.set('desc', 'KHub - ' + this.course.coursename);
              let sign =
                'appname=' +
                formData.get('appname') +
                '&refno=' +
                formData.get('refno') +
                '&amount=' +
                formData.get('amount') +
                '&currency=' +
                formData.get('currency');
              formData.set('sign', Crypto.SHA256(sign).toString());
              formData.set('resultpageskip', 'true');
              formData.set(
                'cancelurl',
                'https://' +
                  window.location.hostname +
                  '/payment/cancel/' +
                  this.course.courseid +
                  '/' +
                  this.orderno
              );
              console.log(formData.toString());
              this.courseService.paymentgateway(formData.toString()).subscribe(
                (event: any) => {
                  console.log(event);

                  let arr = event.split('"');
                  window.open(arr[5], '_self');
                },
                (err) => {
                  this.messageService.openSnackBar(
                    'Something went wrong!',
                    'warn'
                  );
                  this.isStillUpload = false;
                  const await_deletePayment = this.deletePayment();
                }
              );
            } else {
              this.isStillUpload = false;
              this.messageService.openSnackBar(
                res.message || res.status || res.error,
                'warn'
              );
            }
          },
          (err) => {
            this.isStillUpload = false;
          }
        );
    }
  }

  startCourse() {
    this.router.navigateByUrl(`/courses/${this.courseid}/lectures/`);
  }

  handleImgError(ev: any) {
    let source = ev.srcElement;
    let imgSrc = 'https://assets.khub.cloud/essential/defaultimage.jpg';
    source.src = imgSrc;
  }

  back() {
    // this.location.back()
    this.course.enroll = 'true';
  }
  goToLearning() {
    this.router.navigateByUrl(`/courses/${this.course.courseid}/lectures/`);
  }

  // closeCanvas(){
  //   var myOffcanvas = document.getElementById('offcanvasTop')
  //   var bsOffcanvas = new bootstrap.Offcanvas(myOffcanvas);

  //   bsOffcanvas.hide();
  //   this.course.enroll = 'true';
  // }

  verifyPayment() {
    this.isStillUpload = true;
    this.courseService.verifyPayment(this.course.courseid).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          if (res.enrolled == 'false') {
            this.course.enroll = res.enrolled;
            this.messageService.openSnackBar('Payment Failed.', 'warn');
          } else {
            if (this.course.coursetype != '002') {
              this.course.enroll = res.enrolled;
            } else {
              this.course.instructorled = 'true';
              this.course.enroll = 'false';
            }
          }
          this.course.pending = 'false';
        } else {
          this.messageService.openSnackBar('Server Error', 'warn');
        }
        this.isStillUpload = false;
      },
      (err) => {
        this.isStillUpload = false;
      }
    );
  }

  goToPreCourse(courseid: string) {
    // this.router.navigateByUrl(`course/home/${courseid}`,);
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigateByUrl(`course/home/${courseid}`));
  }
}
