<app-breadcrumb *ngIf="!isLoading && !isError && bra ==true" [s_breadcrumb]="course.coursename"
  (emit)="goToCategoryOrChannel()"></app-breadcrumb>
<app-breadcrumb *ngIf="!isLoading && !isError &&  bra==false" [a_breadcrumb]="course.coursename"
  [s_breadcrumb]="coursebyText" (emit)="goToCategoryOrChannel()"></app-breadcrumb>


<ng-container *ngIf="!isLoading;else elseTemplate">
  <div *ngIf="!isError">

    <section class="billboard  p-3 pt-3   pt-lg-5 pt-sm-5  text-sm-start">
      <div class="container">
        <div class="alert alert-warning d-flex align-items-center" role="alert"
          *ngIf="isPreview && course.coursestatus == 0">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor"
            class="bi bi-exclamation-triangle-fill flex-shrink-0 me-2" viewBox="0 0 16 16" role="img"
            aria-label="Warning:">
            <path
              d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
          </svg>
          <div>
            This course is in draft mode
          </div>
        </div>




        <div class="d-flex align-items-center justify-content-between billboard-wrapper">
          <div class="w-50 w-sm-100 left-banner-side">
            <p>
              <span style="font-size: 20px;font-weight: 700;">{{course.coursename}}</span>
              <span class="badge bg-main ms-2" *ngIf="course.coursetype == '002' && course.enroll == 'false'"
                matTooltip="This course is instructor-led. Please contact the instructor for more details.">
                Instructor-Led Course
              </span>
            </p>

            <p class="fs-6 my-3">
              {{course.coursesubname}}
            </p>


            <div class="align-items-center container show-flex" style="max-width: 100%;">


              <div class="instructor my-2 " style="max-width: 100%;">
                <div class="instructor-default-img" *ngIf="course.profileimage == ''">
                  {{course.instructorname.charAt(0)}}
                </div>
                <img width="40" height="40" class="rounded-circle" *ngIf="course.profileimage != ''"
                  [src]="course.profileimageurl" alt="">
                <div class="d-flex flex-column">
                  <span class="instructor-name fw-bold" (click)="search(course)">{{course.instructorname}}</span>
                  <span class="institute">{{course.institute}}</span>
                </div>
              </div>
            </div>

            <div *ngIf="!privateCourse" class="mt-3 d-flex align-items-center row">
              <div class="col-6 d-flex flex-column">
                <div style="color: gray;">
                  Price
                </div>
                <div style="font-size: 18px;font-weight: bold; line-height: 1.5;">
                  <span *ngIf="course.paymenttype == '002'">
                    {{course.currency+ ' ' +(course.amount | number : '1.')}}
                  </span>
                  <span *ngIf="course.paymenttype == '001'">
                    Free
                  </span>
                </div>
              </div>
              <div class="col-6 d-flex flex-column">
                <div style="color: gray;">
                  Students
                </div>
                <div style="font-size: 18px;font-weight: bold; line-height: 1.5;">
                  {{course.studentcounts | number : '1.'}}
                </div>
              </div>
            </div>
            <div class="mt-3 d-flex align-items-center row" *ngIf="course.coursetype == '002' && course.startdate">
              <div class="col-6 d-flex flex-column">
                <div style="color: gray;">
                  Start Date
                </div>
                <div style="font-size: 18px;font-weight: bold; line-height: 1.5;">
                  {{allinOneService.formatDBToShow(course.startdate)}}
                </div>
              </div>
              <div class="col-6 d-flex flex-column">
                <div style="color: gray;">
                  End Date
                </div>
                <div style="font-size: 18px;font-weight: bold; line-height: 1.5;">
                  {{allinOneService.formatDBToShow(course.enddate)}}
                </div>
              </div>
            </div>

            <div class="d-flex align-items-center mt-3 w-100">
              <ng-container
                *ngIf="course.instructorled == 'false' && course.coursestatus == 1 && course.enroll == 'false' && (course.coursetype != '002' || (course.coursetype == '002' && course.paymenttype == '002' && course.ongoing == 'true')) ">
                <app-button-loader
                  *ngIf="course.pending == 'false' && course.access == '001' && course.prerequisitescompleted == 'true'"
                  [width]="'100'" class="w-auto" [button_text]="'Enroll'" [isStillupload]="isStillUpload"
                  (click)="enrollCourse()">
                </app-button-loader>

                <button *ngIf="course.access=='002' && course.enroll == 'false'" class="mat-flat-button mat-primary"
                  style="padding: 0.2rem 1.3rem; margin: 0 0.5rem 0 0 !important; width: 150px;"
                  (click)="goToLearning()">
                  Open
                </button>

                <button *ngIf="course.pending=='true'" class="mat-flat-button mat-primary"
                  style="padding: 0.2rem 1.3rem; margin: 0 0.5rem 0 0 !important; width: 150px;">
                  Pending
                </button>
                <div class="d-flex ms-2" *ngIf="course.pending=='true'">

                  <span>
                    <i class="fas fa-spinner fa-spin me-2" style="height: 14px;" *ngIf="isStillUpload"></i>
                    <span class="verifypayment" *ngIf="!isStillUpload" (click)="verifyPayment()">Verify Payment</span>
                    <span *ngIf="isStillUpload">Verifying</span>
                  </span>
                </div>
              </ng-container>
              <app-button-loader
                *ngIf="course.enroll == 'true' && course.pending == 'false' && ((course.coursetype == '002' && course.ongoing == 'true') ||(course.coursetype != '002')) "
                [width]="'100'" class="w-auto" [button_text]="'Open'" [isStillupload]="isStillUpload"
                (click)="gotoCourse()">
              </app-button-loader>
            </div>

            <div *ngIf="course.instructorled == 'true' && course.paymenttype == '002'" class="instructorledmsg">
              <i class="fas fa-info-circle me-2"></i>Please wait for instructor to add you to a class.
            </div>

          </div>
          <div class="w-50 d-flex justify-content-end right-banner-side">
            <img class="img-fluid course-banner-img  rounded" (error)="handleImgError($event)"
              [src]="allinOneService.imgurl + course.courseimage" alt="" />
          </div>
        </div>
      </div>
    </section>

    <section *ngIf="course.prerequisites.length > 0 && !course.requirements && !course.description"
      class="billboard  p-3 pb-0  pt-lg-3 pt-sm-3    text-sm-start">
      <div class="container">
        <div class="d-flex align-items-start billboard-wrapper">
          <div class="left-banner-side w-100">
            <h1 style="font-weight: 700;" class="mb-1">Prerequisites </h1>
            <div class="row mt-4">
              <div class="col-12 col-sm-12 col-md-6 col-lg-4 mb-3" *ngFor="let pc of course.prerequisites">
                <div class="card card-body d-flex flex-row align-items-center p-2 pre-card cus-point"
                  (click)="goToPreCourse(pc.courseid)">
                  <div class="me-3">
                    <img class="rounded" (error)="handleImgError($event)"
                      [src]="allinOneService.imgurl + pc.courseimage" alt="" width="120px" />
                  </div>
                  <div class="d-flex flex-column">
                    <div class="pre-course-name">
                      {{pc.coursename}}
                    </div>
                    <div class="pre-instructor">
                      <span class="badge bg-main" *ngIf="pc.completed == 'true'">Completed</span>
                      <span class="badge bg-secondary" *ngIf="pc.completed == 'false'">Not Completed</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section *ngIf="course.requirements != '' || course.description != ''"
      class="billboard  p-3 pb-0   pt-lg-3 pt-sm-3    text-sm-start">
      <div class="container">
        <div class="d-flex align-items-start billboard-wrapper">
          <div class="left-banner-side w-50">
            <div *ngIf="course.requirements != ''">
              <h1 style="font-weight: 700;" class="mb-1">Requirements</h1>
              <p class="fs-6 my-4" [innerHTML]="course.requirements"></p>
            </div>
            <div *ngIf="course.description != ''">
              <h1 style="font-weight: 700;" class="mb-1">Description</h1>
              
              <div [ngClass]="{'show-more-container': course.description.length > 200}">
                <p class="fs-6 my-4" [innerHTML]="show ? course.description : truncatedDescription"></p>
              </div>

              <button *ngIf="course.description.length > 200" mat-button color="primary" class="d-flex justify-end"
                      style="margin-left: -1rem;" (click)="show = !show">
                <span *ngIf="!show" class="fw-bold">Show more <i class="fa fa-angle-down" aria-hidden="true"></i></span>
                <span *ngIf="show" class="fw-bold">Show less <i class="fa fa-angle-up" aria-hidden="true"></i></span>
              </button>
            </div>

          </div>
          <div class="right-banner-side w-50" *ngIf="course.prerequisites.length > 0">
            <h1 style="font-weight: 700;" class="mb-1">Prerequisites </h1>
            <div class="row mt-4">
              <div class="col-12 mb-3" *ngFor="let pc of course.prerequisites">
                <div class="card card-body d-flex flex-row align-items-center p-2 cus-point pre-card"
                  (click)="goToPreCourse(pc.courseid)">
                  <div class="me-3">
                    <img class="rounded" (error)="handleImgError($event)"
                      [src]="allinOneService.imgurl + pc.courseimage" alt="" width="120px" />
                  </div>
                  <div class="d-flex flex-column">
                    <div class="pre-course-name">
                      {{pc.coursename}}
                    </div>
                    <div class="pre-instructor">
                      <span class="badge bg-main" *ngIf="pc.completed == 'true'">Completed</span>
                      <span class="badge bg-secondary" *ngIf="pc.completed == 'false'">Not Completed</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>




    <!-- <section *ngIf="course.requirements != ''" class="billboard  p-3 pb-0   pt-lg-3 pt-sm-3    text-sm-start">
      <div class="container">
        <div class="d-flex align-items-start billboard-wrapper">
          <div class="left-banner-side">
            <h1 style="font-weight: 700;" class="mb-1">Requirements </h1>
            <p class="fs-6 mt-4" [innerHTML]="course.requirements"></p>
          </div>
        </div>
      </div>
    </section>


    <section *ngIf="course.description != ''" class="billboard p-3 pb-0 pt-lg-3 pt-sm-3 text-sm-start">
      <div class="container">
        <div class="d-flex align-items-start billboard-wrapper">
          <div class="left-banner-side">
            <h1 style="font-weight: 700;" class="mb-1">Description</h1>
            <div class="" [ngClass]="{'show-more-container':  course.description.length > 200}" [class.show]="show">
              <p class="fs-6 mt-4" [innerHTML]="course.description"></p>
            </div>
            <button *ngIf="course.description.length > 200" mat-button color="primary" class="d-flex justify-end"
              style="margin-left: -1rem;" (click)="show = !show">
              <span *ngIf="!show" class="fw-bold">Show more <i class="fa fa-angle-down" aria-hidden="true"></i></span>
              <span *ngIf="show" lass="fw-bold">Show less <i class="fa fa-angle-up" aria-hidden="true"></i></span>
            </button>

          </div>
        </div>
      </div>
    </section> -->


    <section class="section-crriculum p-3   text-sm-start">
      <div class="container">
        <div class="">
          <div class="">
            <h1 style="font-weight: 700;">Outline</h1>
            <p class="fs-5 my-4">
              Inside you'll learn
            </p>
          </div>

          <div class=" custom-accordion  accordion mb-2">
            <div class="accordion-item" *ngFor="let section of course.sections;let s = index;">
              <div *ngIf="isSeeMore ? s < course.sections.length : s < 3">
                <h2 class="accordion-header" [id]="'panelsStayOpen-heading' + s">
                  <button class=" custom-accordion-button accordion-button collapsed" type="button"
                    data-bs-toggle="collapse" [attr.data-bs-target]="'#panelsStayOpen-collapse' + s"
                    aria-expanded="true" aria-controls="panelsStayOpen-headingOne">
                    <!-- <strong class="mx-1 text-warning" > {{ s+ 1+ ' - '}}</strong> -->
                    {{ section.sectionname}}

                  </button>
                </h2>

                <div [id]="'panelsStayOpen-collapse' + s" class="accordion-collapse collapse"
                  aria-labelledby="panelsStayOpen-headingOne">
                  <div class="section-accordion-body">

                    <div class="accordion-item" *ngFor="let subsection of section.subsections;let ss = index;">
                      <h2 class="accordion-header"
                        *ngIf="(subsection.units.length > 1 && subsection.subsectionname != '') || (subsection.units.length == 1 && !(subsection.units[0].unitname == '' || subsection.subsectionname == '' || subsection.units[0].unitname == subsection.subsectionname))">
                        <button
                          class=" custom-accordion-button custom-sub-accordion-button subsectioname accordion-button collapsed"
                          type="button" data-bs-toggle="collapse"
                          [attr.data-bs-target]="'#panelsStayOpen-collapseSubsection' + s +'-' + ss"
                          aria-expanded="true" aria-controls="panelsStayOpen-headingOne">
                          <!-- <strong class="mx-1 text-warning" style="letter-spacing: 2px;">{{s+ 1 + '.' + (ss + 1 )+ ' - '}}</strong>  -->
                          {{subsection.subsectionname}}

                        </button>
                      </h2>

                      <div class="accordion-header"
                        *ngIf="(subsection.units.length > 1 && subsection.subsectionname == '') || (subsection.units.length == 1 && (subsection.units[0].unitname == '' || subsection.subsectionname == '' || subsection.units[0].unitname == subsection.subsectionname))">
                        <ul>
                          <li *ngFor="let unit of subsection.units;let su = index;" class="d-flex align-items-center">
                            <span *ngIf="unit.contenttype == 1">
                              <i class="fa fa-play-circle-o document" aria-hidden="true"></i>
                              <span class="mx-2">{{unit.unitname ? unit.unitname : 'Video'}}</span>
                            </span>
                            <span *ngIf="unit.contenttype == 2">
                              <i class="fa fa-file-o document" aria-hidden="true"></i>
                              <span class="mx-2">{{unit.unitname ? unit.unitname : 'File'}}</span>
                            </span>
                            <div *ngIf="unit.contenttype == 3">
                              <i class="fa fa-file-text-o document" aria-hidden="true"></i>
                              <span class="mx-2">{{unit.unitname ? unit.unitname : 'Article'}}</span>
                            </div>
                            <div *ngIf="unit.contenttype == 4">
                              <i class="fa fa-link" aria-hidden="true"></i>
                              <span class="mx-2">{{unit.unitname ? unit.unitname : 'Url'}}</span>
                            </div>
                            <div *ngIf="unit.contenttype == 5">
                              <i class="fas fa-paperclip" aria-hidden="true"></i>
                              <span class="mx-2">{{unit.unitname ? unit.unitname : 'Attachment'}}</span>
                            </div>
                            <div *ngIf="unit.contenttype == 6">
                              <i class="fas fa-clipboard-list" aria-hidden="true"></i>
                              <span class="mx-2">{{unit.unitname ? unit.unitname : 'Quizz'}}</span>
                            </div>
                            <div *ngIf="unit.contenttype == 7">
                              <img [src]="'./../../assets/images/assignment.png'" class="unit-icon">
                              <span class="mx-2">{{unit.unitname ? unit.unitname : 'Assignment'}}</span>
                            </div>
                            <div *ngIf="unit.contenttype == 8">
                              <img [src]="'./../../assets/images/discussion.png'" class="unit-icon">
                              <span class="mx-2">{{unit.unitname ? unit.unitname : 'Discussion'}}</span>
                            </div>
                            <div *ngIf="unit.contenttype == 10">
                              <i class="fas fa-file-audio" aria-hidden="true"></i>
                              <span class="mx-2">{{unit.unitname ? unit.unitname : 'Audio'}}</span>
                            </div>
                          </li>
                        </ul>
                      </div>

                      <div [id]="'panelsStayOpen-collapseSubsection' + s +'-' + ss" class="accordion-collapse collapse"
                        aria-labelledby="panelsStayOpen-headingSubsectionOne">
                        <div class="accordion-body">
                          <ul>
                            <li *ngFor="let unit of subsection.units;let su = index;">
                              <span *ngIf="unit.contenttype == 1">
                                <i class="fa fa-play-circle-o document" aria-hidden="true"></i>
                                <span class="mx-2">{{unit.unitname ? unit.unitname : 'Video'}}</span>
                              </span>
                              <span *ngIf="unit.contenttype == 2">
                                <i class="fa fa-file-o document" aria-hidden="true"></i>
                                <span class="mx-2">{{unit.unitname ? unit.unitname : 'File'}}</span>
                              </span>
                              <div *ngIf="unit.contenttype == 3">
                                <i class="fa fa-file-text-o document" aria-hidden="true"></i>
                                <span class="mx-2">{{unit.unitname ? unit.unitname : 'Article'}}</span>
                              </div>
                              <div *ngIf="unit.contenttype == 4">
                                <i class="fa fa-link" aria-hidden="true"></i>
                                <span class="mx-2">{{unit.unitname ? unit.unitname : 'Url'}}</span>
                              </div>
                              <div *ngIf="unit.contenttype == 5">
                                <i class="fas fa-paperclip" aria-hidden="true"></i>
                                <span class="mx-2">{{unit.unitname ? unit.unitname : 'Attachment'}}</span>
                              </div>
                              <div *ngIf="unit.contenttype == 6">
                                <i class="fas fa-clipboard-list" aria-hidden="true"></i>
                                <span class="mx-2">{{unit.unitname ? unit.unitname : 'Quiz'}}</span>
                              </div>
                              <div *ngIf="unit.contenttype == 7">
                                <img [src]="'./../../assets/images/assignment.png'" class="unit-icon">
                                <span class="mx-2">{{unit.unitname ? unit.unitname : 'Assignment'}}</span>
                              </div>
                              <div *ngIf="unit.contenttype == 8">
                                <img [src]="'./../../assets/images/discussion.png'" class="unit-icon">
                                <span class="mx-2">{{unit.unitname ? unit.unitname : 'Discussion'}}</span>
                              </div>
                              <div *ngIf="unit.contenttype == 10">
                                <i class="	fas fa-file-audio" aria-hidden="true"></i>
                                <span class="mx-2">{{unit.unitname ? unit.unitname : 'Audio'}}</span>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>

          <!-- <p class="mb-3" style="text-align: right;color: var(--main-color);">See More</p> -->
          <div *ngIf="course.sections.length > 3" class="d-flex justify-content-center align-items-center">
            <button class="mb-3" style="color: var(--main-color);" mat-button (click)="toggleSeeMore()">
              <div class="d-flex flex-column justify-content-center align-items-center">
                <p style="margin: 0; padding: 0; line-height: 2;">{{ isSeeMore ? 'See Less': 'See More' }} </p>
                <i class="{{isSeeMore ? 'fas fa-angle-up' : 'fas fa-angle-down'}}"></i>
              </div>
            </button>
          </div>


        </div>
      </div>
    </section>


    <div class="offcanvas offcanvas-top" tabindex="-1" id="offcanvasTop" aria-labelledby="offcanvasTopLabel">
      <div class="offcanvas-body">
        <div class="d-flex offcanvas-mobile">
          <img class="img-fluid rounded" (error)="handleImgError($event)"
            [src]="allinOneService.imgurl + course.courseimage" alt="" style="width: 500px;height: auto;" />

          <div class="flex-column text-sm-start  mx-3 my-2">
            <h1 style=" font-weight: 700;">{{course.coursename}}</h1>

            <button mat-flat-button color="primary" class="mx-0 py-1 my-3" (click)="startCourse()">
              Open Course
            </button>

            <button mat-stroked-button color="primary" class="mx-2 py-1 my-3" data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasTop" (click)="back()">
              Maybe Later
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="check-error" *ngIf="isError">
    <h3 style="margin: 0;">Something went wrong!, please reload</h3><br>
    <button type="button" (click)="reload()" class="btn btn-outline-secondary">
      Reload
    </button>
  </div>
</ng-container>


<!-- Loading Skeleton -->
<ng-template #elseTemplate>


  <div class="container p-3 pt-3   pt-lg-5 pt-sm-5">
    <div class="d-flex align-items-center justify-content-between billboard-wrapper">
      <div class="w-50 w-sm-100 left-banner-side">
        <ngx-skeleton-loader count="1"></ngx-skeleton-loader>

        <ngx-skeleton-loader [theme]="{ height: '20px',width: '80%'}">
        </ngx-skeleton-loader>

        <div class="d-flex align-items-center">

          <ngx-skeleton-loader style="width: auto;" appearance="circle" [theme]="{ height: '60px',width: '60px'}">
          </ngx-skeleton-loader>

          <ngx-skeleton-loader [theme]="{ height: '20px',width: '25%'}">
          </ngx-skeleton-loader>
        </div>

        <ngx-skeleton-loader class="mx-2" [theme]="{ height: '40px',width: '30%'}">
        </ngx-skeleton-loader>
      </div>
      <div class="w-50 mx-5 d-flex justify-content-end right-banner-side">
        <ngx-skeleton-loader [theme]="{ height: '300px',width: '100%'}">
        </ngx-skeleton-loader>
      </div>
    </div>
  </div>

  <div class="container p-3 pt-3 pt-lg-5 pt-sm-5 d-flex flex-column">
    <ngx-skeleton-loader [theme]="{ height: '20px',width: '20%'}"></ngx-skeleton-loader>
    <ngx-skeleton-loader [theme]="{ height: '20px',width: '40%'}"></ngx-skeleton-loader>
  </div>
</ng-template>
