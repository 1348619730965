import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-button-loader',
  templateUrl: './button-loader.component.html',
  styleUrls: ['./button-loader.component.scss']
})
export class ButtonLoaderComponent implements OnInit {

  @Input() width!: string;
  @Input() margin_left!: string;

  @Input() button_text!: string;
  @Input() isStillupload!: boolean;

  constructor() { }

  ngOnInit(): void {
    if (this.width == null) {
      this.width = "auto";
    }
  }

}
