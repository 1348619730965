<section>

    <app-breadcrumb *ngIf="!isLoading" s_breadcrumb="Learning Track" [a_breadcrumb]="learningPathName"
        (emit)="goToLearningPath()"></app-breadcrumb>

    <div class="container pb-5">

        <div class="category-title d-flex" *ngIf="!isLoading">{{learningPathName}}'s Courses
        </div>

        <ng-container *ngIf="!isLoading;else elseTemplate">
            <div class="row">

                <div class="col-12 col-lg-3 col-sm-6 mb-3 course-card" *ngFor="let course of courses" (click)="goToRegisterForm(course.courseid)">
                    <!--  -->
                    <mat-card>
                        <div class="img-container">

                            <img width="270" height="175" [src]="allinOneService.imgurl + course.courseimage"
                                alt="Photo of a" (error)="allinOneService.handleImgError($event)">

                        </div>

                        <mat-card-content>
                            <div class="course-header-title mb-2">
                                <font> {{course.coursename}} </font>
                            </div>
                            <span class="course-header-instructor"
                                >{{course.instructorname}}</span>
                            <span class="course-header-category"
                                >{{course.categoryname}}</span>
                            <div class="d-flex justify-content-between">
                                <span class="course-fee" *ngIf="course.paymenttype == '001'"
                                    >Free</span>
                                <span class="course-fee" *ngIf="course.paymenttype == '002'"
                                    >{{course.currency}}&nbsp;{{course.amount
                                    |
                                    number:'1.'}}</span>

                                <div *ngIf="course.enroll == 'true'" (click)="goToLearning(course.courseid, $event)">
                                    <span class="badge bg-primary badge-open">Open</span>
                                </div>
                            </div>

                        </mat-card-content>

                    </mat-card>

                    <div class="borderstype"></div>
                </div>

            </div>

            <!-- <pagination-controls *ngIf="courses.length > 8" class="my-pagination" (pageChange)="p = $event" previousLabel=""
      nextLabel="">
    </pagination-controls> -->

            <div class="d-flex justify-content-center align-items-center p-5" style="height: 50vh;"
                *ngIf="courses.length == 0">
                No course found
            </div>
        </ng-container>

        <!-- Loading Skeleton -->
        <ng-template #elseTemplate>
            <article class="article d-flex" *ngFor="let n of [0,1,2,3,4,5,6]">
                <div class="left-article">
                    <div>
                        <ngx-skeleton-loader [theme]="{ height: '20px',width: '100%'}"></ngx-skeleton-loader>
                        <ngx-skeleton-loader [theme]="{ height: '20px',width: '80%'}"></ngx-skeleton-loader>
                    </div>
                </div>
            </article>
        </ng-template>
    </div>

</section>