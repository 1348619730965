import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { AllInOneService } from '../../shared/services/all-in-one.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Course } from '../../shared/models/Course';
import { CourseService } from '../../shared/services/course.service';
import { MessageService } from '../../shared/services/message.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { SEOService } from '../../shared/services/seo.service';

@Component({
  selector: 'app-course-by-category',
  templateUrl: './course-by-category.component.html',
  styleUrls: ['./course-by-category.component.scss'],
})
export class CourseByCategoryComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  isLoading: boolean = true;
  courses: Course[] = [];
  searchText = '';
  category: string = '';
  subcategory: string = '';
  categoryid: string = '';
  subcategoryid: string = '';
  p: number = 1;
  enroll: string = '';
  end: string = '';
  isError: boolean = false;
  isAll: boolean = false;
  allCoursesLoaded: boolean = false;
  gettingMoreCourses: boolean = false;
  isRecentCourses: boolean = false;
  isCustomCoures: boolean = false;

  subTitle: any[] = [];
  privateCourse : boolean = false;

  constructor(
    private courseService: CourseService,
    public allinOneService: AllInOneService,
    private messageService: MessageService,
    private route: ActivatedRoute,
    public deviceService: DeviceDetectorService,
    private _seoService: SEOService,
    private router: Router
  ) {
    console.log(this.deviceService.getDeviceInfo());
    this.route.data.subscribe((data) => {
      this.isRecentCourses = data.recent;
      this.isCustomCoures = data.custom;
      if (this.isRecentCourses || this.isCustomCoures) {
        if (this.isCustomCoures) {
          this.route.params.subscribe((params) => {
            this.category = params['type'];
          });
        }
      } else {
        this.route.params.subscribe((params) => {
          this.categoryid = params['category'];
          this.subcategoryid = params['subcategory']
            ? params['subcategory']
            : '';
          this.isAll = this.categoryid == 'all';
          this.category = this.allinOneService.categoryByRoute(this.categoryid);
        });
      }
    });
  }
  ngAfterViewInit(): void {
    // this.allinOneService.scrollTo('container');
  }

  ngOnInit(): void {
    this.privateCourse = this.allinOneService.channeldata.privatecourse ? this.allinOneService.channeldata.privatecourse : false;
    console.log("privateCourse >>", this.privateCourse)
    if (this.isRecentCourses) {
      this.category = 'New Release';
      this.getCoursesWithChannel(true);
      this._seoService.updateTitle('- New Releases');
    } else if (this.isCustomCoures) {
      this.getCustomCourses();
      this._seoService.updateTitle('- ' + this.category);
    } else {
      if (this.categoryid == 'all') {
        this.category = 'All Categories';
        this.getCoursesWithChannel(false, true);
      } else {
        this.subTitle =
          this.allinOneService.categorieswithcounts.find(
            (item: any) => item.categoryid == this.categoryid
          )?.subcategorylist || [];
        if (this.subcategoryid) {
          const temp = this.subTitle.find(
            (item: any) => item.categoryname == this.subcategoryid
          );
          if (temp) {
            this.subcategory = temp.subcategoryname;
          } else {
            this.goToMaincategory();
          }
        }
        this.getCourses();
      }
    }
  }
  ngOnDestroy(): void {
    // this.router.routeReuseStrategy.shouldReuseRoute = () => true;
  }
  getCustomCourses(): void {
    this.courseService.getCustomCourses(this.end, this.category).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.courses.push.apply(this.courses, res.data);
          this.end = res.end;
          if (this.end == '' || res.data.length < 16) {
            this.allCoursesLoaded = true;
          }
        } else {
          if (res.returncode == '210') {
            setTimeout(() => {
              this.router.navigateByUrl('login');
              this.allinOneService.logout();
            }, 2000);
          } else {
            this.messageService.openSnackBar(res.status, 'warn');
          }
        }
        this.isLoading = false;
        this.gettingMoreCourses = false;
      },
      (err) => {
        this.isLoading = false;
        this.isError = true;
        this.gettingMoreCourses = false;
      }
    );
  }

  getCoursesWithChannel(
    recentCourse: boolean = false,
    all: boolean = false
  ): void {
    this.courseService
      .getCoursesWithChannel(this.end, recentCourse, all)
      .subscribe(
        (res: any) => {
          if (res.returncode == '300') {
            this.courses.push.apply(this.courses, res.data);
            this.end = res.end;
            if (this.end == '' || res.data.length < 16) {
              this.allCoursesLoaded = true;
            }
          } else {
            if (res.returncode == '210') {
              setTimeout(() => {
                this.router.navigateByUrl('login');
                this.allinOneService.logout();
              }, 2000);
            } else {
              this.messageService.openSnackBar(res.status, 'warn');
            }
          }
          this.isLoading = false;
          this.gettingMoreCourses = false;
        },
        (err) => {
          this.isLoading = false;
          this.isError = true;
          this.gettingMoreCourses = false;
        }
      );
  }

  getCourses(): void {
    this.courseService
      .getCoursesByCategory(this.categoryid, this.subcategoryid, this.end)
      .subscribe(
        (res: any) => {
          if (res.returncode == '300') {
            res.data.map((course: any) => {
              this.courses.push({
                pkey: course.pkey,
                courseid: course.courseid,
                coursename: course.coursename,
                coursesubname: course.coursesubname,
                courseimage: course.courseimage,
                description: course.description,
                instructorid: course.instructorid,
                instructorname: course.instructorname,
                enroll: course.enroll,
                categoryname: course.categoryname,
                paymenttype: course.paymenttype,
                amount: course.amount,
                currency: course.currency,
                institute: course.institute,
                studentcounts: course.studentcounts,
              });
            });

            this.end = res.end;
            if (this.end == '' || res.data.length < 16) {
              this.allCoursesLoaded = true;
            }
          } else {
            if (res.returncode == '210') {
              setTimeout(() => {
                this.router.navigateByUrl('login');
                this.allinOneService.logout();
              }, 2000);
            } else {
              this.messageService.openSnackBar(res.status, 'warn');
            }
          }
          this.isLoading = false;
          this.gettingMoreCourses = false;
        },
        (err) => {
          this.isLoading = false;
          this.gettingMoreCourses = false;
        }
      );
  }
  handleImgError(ev: any) {
    let source = ev.srcElement;
    let imgSrc =
      'https://bucketelearning.s3.ap-southeast-1.amazonaws.com/essential/defaultimage.jpg';
    source.src = imgSrc;
  }
  goToRegisterForm(courseid: string) {
    this.router.navigateByUrl(`course/category/${courseid}`);
  }

  goToMaincategory() {
    this.router.navigateByUrl(`courses/${this.categoryid}`);
  }

  scrolledDown() {
    if (!this.allCoursesLoaded && !this.gettingMoreCourses && !this.isLoading) {
      this.gettingMoreCourses = true;
      if (this.isRecentCourses) {
        this.getCoursesWithChannel(true);
      } else if (this.isCustomCoures) {
        this.getCustomCourses();
      } else {
        if (this.isAll) {
          this.getCoursesWithChannel(false, true);
        } else {
          this.getCourses();
        }
      }
    }
  }
}
