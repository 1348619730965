<app-breadcrumb [s_breadcrumb]="channel.name" *ngIf="!isLoading"></app-breadcrumb>

<section class="bg-light billboard  p-3 pt-5   pt-lg-5 pt-sm-5    text-sm-start" *ngIf="!isError">
  <ng-container *ngIf="!isLoading; else elseContainer;">
    <div class="container">
      <div class="mb-3 d-block d-sm-none d-lg-none d-md-none">
        <img class="img-fluid channel-img" [src]="channel.image" [alt]="channel.name" (error)="allinOneService.handleImgError($event)"
          (load)="imgLoaded($event)" />
      </div>
      <div class="d-sm-flex align-items-center justify-content-between">
        <div>
          <h1 style="font-weight: 700;">{{channel.name}}</h1>
          <p class="fs-6 my-3">
            {{channel.description}}
          </p>
        </div>
        <div class="w-50 d-none d-sm-block" style="padding-left: 100px;">
          <img class="img-fluid channel-img" [src]="channel.image" [alt]="channel.name" (error)="allinOneService.handleImgError($event)"
            (load)="imgLoaded($event)" />
        </div>
      </div>
    </div>
  </ng-container>
  <ng-template #elseContainer>
    <div class="container p-3 pt-3   pt-lg-5 pt-sm-5">
      <div class="d-flex align-items-center justify-content-between billboard-wrapper flex-wrap">
        <div class="w-50 left-banner-side">
          <ngx-skeleton-loader [theme]="{ height: '40px',width: '30%'}">
          </ngx-skeleton-loader>
          <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
          <ngx-skeleton-loader [theme]="{ height: '20px',width: '90%'}">
          </ngx-skeleton-loader>
          <ngx-skeleton-loader [theme]="{ height: '20px',width: '80%'}">
          </ngx-skeleton-loader>
          <ngx-skeleton-loader [theme]="{ height: '20px',width: '70%'}">
          </ngx-skeleton-loader>
        </div>
        <div class="w-50 mx-5 d-flex justify-content-end right-banner-side d-none">
          <ngx-skeleton-loader [theme]="{ height: '300px',width: '70%'}">
          </ngx-skeleton-loader>
        </div>
      </div>
    </div>
  </ng-template>
</section>


<section class=" p-3 pt-lg-3 p-sm-0 pt-sm-2 mb-2">
  <div class="container" *ngIf="!isError">
    <h1 style="font-weight: 700;" class="my-4">Courses</h1>

    <ng-container *ngIf="!isLoading; else elseTemplate;">
      <div class="row">
        <div class="col-12 col-lg-3 col-sm-6 mb-3 course-card"
          *ngFor="let course of courses |  paginate: { itemsPerPage: 8, currentPage: p }">
          <mat-card (click)="goToRegisterForm(course)">
            <div class="img-container">
              <img width="270" height="175" [src]="allinOneService.imgurl + course.courseimage" alt="Photo of a"
                (error)="allinOneService.handleImgError($event)" (load)="imgLoaded($event)">
            </div>

            <mat-card-content>
              <div class="course-header-title mb-2">
                <font> {{course.coursename}} </font>
              </div>
              <span class="course-header-instructor">{{course.instructorname}}</span>
              <!-- <span class="course-header-instructor">{{course.institute}}</span> -->
              <span class="course-header-category">{{course.categoryname}}</span>
              <span class="course-fee" *ngIf="course.paymenttype == '001'">Free</span>
              <span class="course-fee" *ngIf="course.paymenttype == '002'">{{course.currency}}&nbsp;{{course.amount | number:
                '1.'}}</span>
            </mat-card-content>
          </mat-card>
          <div class="setting" *ngIf="course.enroll == 'true'">
            <span class="badge bg-primary">Enrolled</span>
          </div>
        </div>

        <pagination-controls *ngIf="courses.length > 0" class="my-pagination" (pageChange)="p = $event" previousLabel=""
          nextLabel="">
        </pagination-controls>
      </div>
      <div *ngIf="courses.length == 0" class="nodata">
        No courses found.
      </div>
    </ng-container>


    <!-- Loading Skeleton -->
    <ng-template #elseTemplate>
      <div class="row">
        <div class="col-12 col-lg-3 col-sm-6 mb-3 course-card" *ngFor="let skeleton of skeletons;">
          <div class="img-container">
            <ngx-skeleton-loader [theme]="{ height: '160px',width: '100%'}">
            </ngx-skeleton-loader>
          </div>

          <div class="d-flex flex-column">
            <ngx-skeleton-loader [theme]="{ height: '20px',width: '70%'}"></ngx-skeleton-loader>
            <ngx-skeleton-loader [theme]="{ height: '20px',width: '50%'}"></ngx-skeleton-loader>
          </div>
        </div>
      </div>
    </ng-template>
  </div>


  <div class="check-error" *ngIf="isError">
    <h3 style="margin: 0;">Something went wrong!, please reload</h3><br>
    <button type="button" (click)="reload()" class="btn btn-outline-secondary">
      Reload
    </button>
  </div>

</section>