import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AllInOneService } from '../../shared/services/all-in-one.service';
import { CourseService } from '../../shared/services/course.service';
import { MessageService } from '../../shared/services/message.service';
import { SEOService } from '../../shared/services/seo.service';

@Component({
  selector: 'app-verify-certificate',
  templateUrl: './verify-certificate.component.html',
  styleUrls: ['./verify-certificate.component.scss']
})
export class VerifyCertificateComponent implements OnInit {

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    if (window.innerWidth < this.mobileViewWidth) {
      this.isMobileView = true;
    }
    else{
      this.isMobileView = false;
    }
  }

  certificateid: string = '';
  certificateurl : string = '';
  isLoading: boolean = true;
  isError: boolean = false;
  notFound: boolean = false;
  isMobileView: boolean = false;
  course: any;

  mobileViewWidth: number = 768;

  constructor(
    public allinoneService: AllInOneService,
    private route: ActivatedRoute,
    private router: Router,
    private courseService: CourseService,
    private messageService: MessageService,
    private _seoService: SEOService
  ) { 
    this.route.params.subscribe((params) => {
      this.certificateid = params['certificateid'];
    });
    this._seoService.updateTitle('Course Completion Certificate')
  }

  ngOnInit(): void {
    this.verifyCertificate();
    if (window.innerWidth < this.mobileViewWidth) {
      this.isMobileView = true;
    }
  }

  verifyCertificate(){
    this.courseService.verifyCertificate(this.certificateid).subscribe((res: any) => {
      if(res.returncode == "300"){
        this.certificateurl = res.imageurl;
        this.course = res;
      }
      else{
        if(res.returncode == '240'){
          this.notFound = true;
        }
        else{
          this.messageService.openSnackBar(res.message || res.status || 'Server Error', 'warn');
          this.isError = true;
        }
      }
      this.isLoading= false;
    }, err => {
      this.isLoading = false;
      this.isError = true;
    })
  }

  reload(){
    window.location.reload();
  }

  goToRegisterForm() {
    this.router.navigateByUrl(`course/category/${this.course.courseid}`);
  }
}
