import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { DatePipe } from '@angular/common';
import { TemplateParseResult } from '@angular/compiler';
import { Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import * as saveAs from 'file-saver';
import { ConfirmDialogComponent } from '../../shared/components/confirm-dialog/confirm-dialog.componet';
import { AllInOneService } from '../../shared/services/all-in-one.service';
import { CourseService } from '../../shared/services/course.service';
import { LoadingService } from '../../shared/services/loading.service';
import { MessageService } from '../../shared/services/message.service';
import { SubmissionDetailsComponent } from '../submission-details/submission-details.component';

interface FileList {
  id: any;
  title: any;
  subTitle: any;
  mark: any;
  classdbid: string;
  date: string;
  filename: string;
  fileurl: string;
  time: string;
  type: string;
  remark: string;
  grade: string;
  feedback: string;
  unitid: string;
  sectionid: string;
  totalgrade: string;
}

@Component({
  selector: 'app-dropbox',
  templateUrl: './dropbox.component.html',
  styleUrls: ['./dropbox.component.scss'],
})
export class DropboxComponent implements OnInit {
  @ViewChild('choosefile') choosefile: any;

  @Input('classid') classid: string = '';
  @Input('courseid') courseid: string = '';
  @Input('sections') sections: any[] = [];
  @Input('units') units: any[] = [];

  newSubmission: boolean = false;
  submitted: boolean = false;
  fileSelected: any = '';
  filename: string = '';
  filetype: string = '';
  filterSectionid: string = 'all';

  isEditing: boolean = false;
  isLoading: boolean = false;
  isSaving: boolean = false;
  isPopup: boolean = false;
  form = this.formBuider.group({
    title: ['', Validators.required],
    remark: [''],
    sectionid: [''],
    unitid: [''],
  });

  allowedFiletype = ['txt', 'docx', 'xlsx', 'pptx', 'pdf'];

  list: FileList[] = [];
  filteredList: FileList[] = [];
  filteredUnits: any[] = [];

  get formControl() {
    return this.form.controls;
  }

  constructor(
    public dialogRef: MatDialogRef<DropboxComponent>,
    private formBuider: FormBuilder,
    public allinoneService: AllInOneService,
    private messageService: MessageService,
    private courseService: CourseService,
    public datepipe: DatePipe,
    private dialog: MatDialog,
    private loadingService: LoadingService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (data.popup && data.classid && data.courseid) {
      this.isPopup = true;
      this.classid = data.classid;
      this.courseid = data.courseid;
      this.sections = data.sections;
      this.units = data.units;
    }
  }

  ngOnInit(): void {
    console.log(this.units);
    this.getSubmittedFiles();
  }

  filterSectionChange(event: any) {
    this.filterSectionid = event.target.value;
    if (this.filterSectionid == 'all') {
      this.filteredList = this.list;
    } else {
      this.filteredList = this.list.filter(
        (item: FileList) => item.sectionid == this.filterSectionid
      );

      this.filteredUnits = this.units.filter(
        (item: any) => item.sectionid == this.filterSectionid
      );
    }
  }

  uploadFile(event: any) {
    var filetype = event[0].name.split('.').pop();
    if (this.allowedFiletype.includes(filetype)) {
      this.fileSelected = event[0];
      this.filename = this.fileSelected.name;
      this.filetype = filetype;
    } else {
      this.messageService.openSnackBar(
        'This file type is not allowed.',
        'warn'
      );
    }
    this.choosefile.nativeElement.value = '';
  }

  sectionChange() {
    var tempSection = this.formControl.sectionid.value.toString().trim();
    this.filteredUnits = this.units.filter(
      (item: any) => item.sectionid == tempSection
    );
  }

  onFileSelected(event: any) {
    var filetype = event.target.files[0].name.split('.').pop();
    if (this.allowedFiletype.includes(filetype)) {
      this.fileSelected = event.target.files[0];
      this.filename = this.fileSelected.name;
      this.filetype = filetype;
    } else {
      this.messageService.openSnackBar(
        'This file type is not allowed.',
        'warn'
      );
    }
    this.choosefile.nativeElement.value = '';
  }

  getSubmittedFiles() {
    this.list = [];
    this.isLoading = true;
    this.courseService
      .getStudentDropboxList(this.courseid, this.classid)
      .subscribe(
        (res: any) => {
          if (res.returncode == '300') {
            res.datalist.map((item: any) => {
              this.list.push({
                id: item.classdbid,
                title: item.title,
                subTitle: '',
                mark: false,
                classdbid: item.classdbid,
                date: item.date,
                filename: item.filename,
                fileurl: item.fileurl,
                time: item.time,
                type: item.type,
                remark: item.remark,
                sectionid: item.sectionid,
                grade: item.grade,
                feedback: item.feedback,
                unitid: item.unitid,
                totalgrade: item.totalgrade,
              });
            });
            this.filteredList = this.list;
          } else {
            this.messageService.openSnackBar(
              res.message || res.status || res.error,
              'warn'
            );
          }
          this.isLoading = false;
        },
        (err) => {
          this.isLoading = false;
        }
      );
  }

  sumbitForm() {
    this.submitted = true;
    if (!this.fileSelected || this.formControl.title.invalid) {
      return;
    }
    this.isSaving = true;
    if (this.isPopup) {
      this.dialogRef.disableClose = true;
    }
    var filename = this.filename
      .substring(0, this.filename.lastIndexOf('.'))
      .replace(/\s/g, '');
    var date = new Date();
    var millisecond = date.getMilliseconds();
    var time = this.datepipe.transform(date, 'yyyyMMddhhmmss' + millisecond);
    filename = filename + '_' + time + '.' + this.filetype;
    var title = this.formControl.title.value.toString().trim();
    var remark = this.formControl.remark.value.toString().trim();
    var sectionid = this.formControl.sectionid.value.toString().trim();
    var unitid = this.formControl.unitid.value.toString().trim();
    var path = 'dropbox/' + this.courseid + '/' + this.classid;

    this.allinoneService
      .fileUploadToS3WithPathForImage(this.fileSelected, filename, path)
      .then(
        () => {
          //Success
          this.courseService
            .studentFileUpload(
              this.courseid,
              this.classid,
              sectionid,
              unitid,
              remark,
              title,
              filename,
              this.filetype
            )
            .subscribe(
              (res: any) => {
                if (res.returncode == '300') {
                  this.isSaving = false;
                  this.list.push({
                    id: res.classdbid,
                    title: title,
                    subTitle: '',
                    mark: false,
                    classdbid: res.classdbid,
                    date: res.date,
                    filename: filename,
                    fileurl: res.fileurl,
                    time: res.time,
                    type: this.filetype,
                    remark: remark,
                    sectionid: sectionid,
                    grade: '',
                    feedback: '',
                    unitid: unitid,
                    totalgrade: '',
                  });
                  if (this.filterSectionid == 'all') {
                    this.filteredList = this.list;
                  } else {
                    this.filteredList = this.list.filter(
                      (item: FileList) => item.sectionid == this.filterSectionid
                    );
                  }
                  this.cancel();
                } else {
                  this.isSaving = false;
                  this.messageService.openSnackBar(
                    res.message || res.status || res.error,
                    'warn'
                  );
                }
                if (this.isPopup) {
                  this.dialogRef.disableClose = false;
                }
              },
              (err) => {
                this.isSaving = false;
                if (this.isPopup) {
                  this.dialogRef.disableClose = false;
                }
              }
            );
        },
        () => {
          //Fail
          this.isSaving = false;
          this.messageService.openSnackBar(
            'Error while uploading your file.',
            'warn'
          );
        }
      );
  }

  openNewSubmission() {
    this.newSubmission = true;
    if (this.filterSectionid != 'all') {
      this.formControl.sectionid.setValue(this.filterSectionid);
    }
    if (this.isPopup) {
      this.dialogRef.updateSize('600px', '80vh');
    }
  }

  swipeAction(event: any, index: number) {
    const action = event.action;
    const id = event.value || event.id;
    if (action == 'edit') {
      // Download
      this.downloadFile(
        this.filteredList[index].fileurl,
        this.filteredList[index].filename
      );
    } else {
      // Delete
      if (this.filteredList[index].unitid) {
        this.messageService.openSnackBar(
          'You cannot delete this submission.',
          'warn'
        );
        return;
      }
      this.deleteFile(id, index);
    }
  }

  sortData(sort: Sort) {
    const data = this.list;
    if (!sort.active || sort.direction === '') {
      this.list = data;
      return;
    }
    this.list = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'title':
          return this.compare(a.title, b.title, isAsc);
        case 'filename':
          return this.compare(a.filename, b.filename, isAsc);
        case 'grade':
          return this.compare(a.grade, b.grade, isAsc);
        case 'feedback':
          return this.compare(a.feedback, b.feedback, isAsc);
        case 'remark':
          return this.compare(a.remark, b.remark, isAsc);
        case 'date':
          return this.compare(a.date, b.date, isAsc);
        default:
          return 0;
      }
    });
  }
  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  downloadFile(url: string, name: string) {
    this.courseService.downloadFile(url).subscribe((response: any) => {
      saveAs(response, name);
    }),
      (error: any) => {
        this.messageService.openSnackBar('Download failed.', 'warn');
      };
  }

  deleteFile(classdbid: string, index: number) {
    if (this.filteredList[index].unitid) {
      return;
    }
    const dialog = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      width: '250px',
      closeOnNavigation: true,
      scrollStrategy: new NoopScrollStrategy(),
    });

    dialog.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) {
        this.loadingService.show();
        if (this.isPopup) {
          this.dialogRef.disableClose = true;
        }
        this.courseService.deleteSubmittedFile(classdbid).subscribe(
          (res: any) => {
            if (res.returncode == '300') {
              this.list.splice(index, 1);
              if (this.filterSectionid == 'all') {
                this.filteredList = this.list;
              } else {
                this.filteredList = this.list.filter(
                  (item: FileList) => item.sectionid == this.filterSectionid
                );
              }
            } else {
              this.messageService.openSnackBar(
                res.message || res.status || res.error,
                'warn'
              );
            }
            this.loadingService.hide();
            if (this.isPopup) {
              this.dialogRef.disableClose = false;
            }
          },
          (err) => {
            this.loadingService.hide();
            if (this.isPopup) {
              this.dialogRef.disableClose = false;
            }
          }
        );
      }
    });
  }

  cancel() {
    this.form.reset();
    this.fileSelected = '';
    this.filename = '';
    this.filetype = '';
    this.newSubmission = false;
    this.submitted = false;
    if (this.isPopup) {
      this.dialogRef.updateSize('90vw', '80vh');
    }
  }

  openDetail(assignment: any) {
    const dialog = this.dialog.open(SubmissionDetailsComponent, {
      maxWidth: '95vw',
      width: '500px',
      height: 'atuo',
      closeOnNavigation: true,
      scrollStrategy: new NoopScrollStrategy(),
      data: {
        assignment: assignment,
      },
    });

    dialog.afterClosed().subscribe((dialogResult) => {});
  }

  dismiss() {
    this.dialogRef.close();
  }
}
