<section infinite-scroll [infiniteScrollUpDistance]="2" [infiniteScrollThrottle]="150" (scrolled)="scrolledDown()"
[scrollWindow]="true">

<app-breadcrumb *ngIf="!isLoading" [s_breadcrumb]="instructor"></app-breadcrumb>

<div class="container pb-5">
  <div class="instructor-title d-flex" *ngIf="instructor != ''">
    {{ instructor }}'s Courses
  </div>
  <div class="instructor-title d-flex" *ngIf="instructor == ''">
    Instructor's Courses
  </div>

  <ng-container *ngIf="!isLoading;else elseTemplate">
    <div class="row">
      <div class="col-12 col-lg-3 col-sm-12 mb-3 course-card "
        *ngFor="let course of courses">
        <mat-card (click)="goToRegisterForm(course.courseid)">
          <div class="img-container">
            <img width="270" height="175" [src]="allinOneService.imgurl + course.courseimage" alt="Photo of a"
              (error)="allinOneService.handleImgError($event)">
          </div>

          <mat-card-content>
            <div class="course-header-title mb-2">
              <font> {{course.coursename}} </font>
            </div>
            <span class="course-header-instructor">{{course.instructorname}}</span>
            <span class="course-header-category">{{course.categoryname}}</span>
            <span class="course-fee" *ngIf="course.paymenttype == '001'">Free</span>
            <span class="course-fee" *ngIf="course.paymenttype == '002'">{{course.currency}}&nbsp;{{course.amount |
              number:
              '1.'}}</span>
          </mat-card-content>
        </mat-card>
        <!-- *ngIf="course.enroll == 'true'" -->
        <div class="setting" *ngIf="course.enroll == 'true'">
          <span class="badge bg-primary">Enrolled</span>
        </div>

        <div class="borderstype"></div>
      </div>

    </div>

    <!-- <pagination-controls *ngIf="courses.length > 8" class="my-pagination" (pageChange)="p = $event" previousLabel=""
      nextLabel="">
    </pagination-controls> -->

    <div class="d-flex justify-content-center align-items-center p-5" style="height: 50vh;" *ngIf="courses.length == 0">
      No course found
    </div>
  </ng-container>
  <ng-container *ngIf="gettingMoreCourses">
    <article class="article d-flex" *ngFor="let n of [0, 1, 2]">
      <div class="left-article">
        <div>
          <ngx-skeleton-loader
            [theme]="{ height: '20px', width: '100%' }"
          ></ngx-skeleton-loader>
          <ngx-skeleton-loader
            [theme]="{ height: '20px', width: '80%' }"
          ></ngx-skeleton-loader>
        </div>
      </div>
    </article>

  </ng-container>

  <!-- Loading Skeleton -->
  <ng-template #elseTemplate>
    <article class="article d-flex" *ngFor="let n of [0, 1, 2, 3, 4, 5, 6]">
      <div class="left-article">
        <div>
          <ngx-skeleton-loader
            [theme]="{ height: '20px', width: '100%' }"
          ></ngx-skeleton-loader>
          <ngx-skeleton-loader
            [theme]="{ height: '20px', width: '80%' }"
          ></ngx-skeleton-loader>
        </div>
      </div>
    </article>
  </ng-template>
</div>

</section>